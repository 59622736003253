<div class="row pad_30">
<div class="col-xs-12 col-sm-6 col-md-4 m-b-20">
    <div class="group" [formGroup]="group">

        <label class="placeholder_white custom_label" for="inputSSN">{{ 'SSN_LABEL' | translate }}</label>
        <input type="tel" id="inputSSN" class="form-control" minlength="11" maxlength="11"
          (keyup)="validateSSN($event, $event.target.value)" [formControlName]="ssnControlName" required
          (blur)="maskSSN($event.target.value)" (focus)="unmaskSSN($event)" autocomplete="off"
          ssnBlockCopyPaste />

        <div class="error_info">
          <i class="fa fa-exclamation error_icon" aria-hidden="true" *ngIf="isErrorInfo"
            [class.red_error]="isErrorInfo && !isError" [class.cursor_hand_none]="isError"></i>
          <div class="error_info_popup error_new_ui">
            <div class="left_div">
              <ul>
                <li class="alert"><i class="icon-warning"></i> {{ 'COMMONS_OOPS' | translate }}</li>
                <li class="message">{{errorMessage}}</li>
              </ul>
            </div>
            <div class="right_div">
              <div class="close" aria-hidden="true">
                <i class="icon-cancel"></i>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="isError" class="custom-error error_new_ui">
          <div class="left_div">
            <ul>
              <li class="alert"><i class="icon-warning"></i> {{'COMMONS_OOPS' | translate}}</li>
              <li class="message">{{errorMessage}}</li>
            </ul>
          </div>
          <div class="right_div">
            <div class="close" aria-hidden="true" (click)="closeSSNError()">
              <i class="icon-cancel"></i>
            </div>
          </div>
        </div>
    </div>
</div>
</div>
<div class="row pad_30">
<div class="col-xs-12 col-sm-6 col-md-4 m-b-20">
    <div class="group" [formGroup]="group">
        <label class="placeholder_white custom_label" for="confirmSSN">{{ 'CONFIRM_SSN_LABEL' | translate }}</label>
        <input type="tel" id="confirmSSN" class="form-control" minlength="11" maxlength="11"
        [formControlName]="confirmControlName"
          (keyup)="validateConfirmSSN($event, $event.target.value)"
          (blur)="storeConfirmSSN($event.target.value)" (focus)="unmaskConfirmSSN($event)" required autocomplete="off"
          ssnBlockCopyPaste />


        <div class="error_info">
          <i class="fa fa-exclamation error_icon" aria-hidden="true" *ngIf="isConfirmErrorInfo"
            [class.red_error]="isConfirmErrorInfo && !isConfirmError" [class.cursor_hand_none]="isConfirmError"></i>
          <div class="error_info_popup error_new_ui">
            <div class="left_div">
              <ul>
                <li class="alert"><i class="icon-warning"></i> {{'COMMONS_OOPS' | translate}}</li>
                <li class="message">{{confirmErrorMessage}}</li>
              </ul>
            </div>
            <div class="right_div">
              <div class="close" aria-hidden="true">
                <i class="icon-cancel"></i>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="isConfirmError" class="custom-error error_new_ui">
          <div class="left_div">
            <ul>
              <li class="alert"><i class="icon-warning"></i> {{'COMMONS_OOPS' | translate}}</li>
              <li class="message">{{confirmErrorMessage}}</li>
            </ul>
          </div>
          <div class="right_div">
            <div class="close" aria-hidden="true" (click)="closeConfirmSSNError()">
              <i class="icon-cancel"></i>
            </div>
          </div>
        </div>

    </div>
</div>
</div>

<!--

<div class="col-xs-12 col-sm-6 col-md-4 m-b-20">
    <div class="group" [formGroup]="group">
         <label class="placeholder_white custom_label" for="config.name">
            {{config.label|translate}}
        </label>
        <input type="tel" class="form-control " [formControlName]="config.name" validateSpaceInput
            [value]="config.value | interpolate | async" [readonly]="readonly">

        <div class="error_info">
            <i class="fa fa-exclamation error_icon" aria-hidden="true" *ngIf="isErrorInfo"
                [class.red_error]="isErrorInfo && !isError" [class.cursor_hand_none]="isError"></i>
            <div class="error_info_popup error_new_ui">
                <div class="left_div">
                    <ul>
                        <li class="alert"><i class="icon-warning"></i> {{ 'COMMONS_OOPS' | translate }}</li>
                        <li class="message">{{errorMessage}}</li>
                    </ul>
                </div>
                <div class="right_div">
                    <div class="close" aria-hidden="true">
                        <i class="icon-cancel"></i>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>

<div class="col-xs-12 col-sm-6 col-md-4 m-b-20">
    <div class="group" [formGroup]="group">

        <label class="placeholder_white custom_label" for="confirmSSN">{{ 'CONFIRM_SSN_LABEL' | translate }}</label>
        <input type="tel" id="confirmSSN" class="form-control" minlength="11" maxlength="11"
            formControlName="confirmssn" required autocomplete="off" ssnBlockCopyPaste />

    </div>
</div>


-->
