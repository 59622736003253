<form class="mobile_container animated pad20 padding20 dynamic-form pad30 overflhide" [formGroup]="form">
  <i class="icon-info globalCustomInstructions" tabindex="0" *ngIf="instructionsAvailable" (click)="setCustomInstruction()"></i>
  <ng-container *ngFor="let option of config;let i = index;">
    <ng-container>
      <button *ngIf="!!option['swipe_right']" type="button" class="btn arrowrightctr" [disabled]="!form.valid"
        (click)="goNext(form.value,$event,'NEXT')"> <i class="icon-arrow-right" role="img" [attr.aria-label]="'SCREEN_FARWARD_ARROW' | translate"></i></button>
      <button *ngIf="!!option['swipe_left']" type="button" class="btn arrowleftctr" (click)="getPrevSwipe($event)"><i
          class="icon-arrow-left" role="img" [attr.aria-label]="'SCREEN_BACKWARD_ARROW' | translate"></i></button>
    </ng-container>
    <div class="animated pad20 posrel animated"
      [class.slideInRight]="(currentSlideWithDirection  === option['name']+'_'+'_right') ? true : false"
      [class.slideInLeft]="(currentSlideWithDirection  === option['name']+'_'+'_left') ? true:false"
      [class.hide]="(currentSlide  !== option['name']+'_') ? true:false">
      <div class="pad_30 posrel">
        <dynamic-header *ngIf="!!option['header']" [header]="option['header']"></dynamic-header>
        <div class="row" *ngIf="!!option['header_continue']">
          <div class="col-sm-12 col-md-12 col-lg-12 consent_description">
            <div class="header_txt_white">
              <h2> {{ option['header_continue'] }} {{firstName}} !</h2>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="!!option['doc_name']">
          <div class="col-sm-12 col-md-12 col-lg-12">
            <download-file [fileName]="option['doc_name']" (downloadInstructionsFile)="onInstructionsFileDownload()">
            </download-file>
          </div>
        </div>
        <div class="row" tabindex="0" *ngIf="!!option['body']">
          <div class="col-sm-12 col-md-12 col-lg-12 consent_description">
            <div class="consent_txt intlSubHeader">
              <p [innerHTML]="option['body']"></p>
            </div>
          </div>
        </div>
        <div *ngIf="!!option['list']">
          <ul class="wotcListAlignment">
            <li *ngFor="let option of option['list']">{{option}}</li>
          </ul>
        </div>
        <div class="header_txt_white" *ngIf="option['upload']">
          <wotcdocument-category [DocUpload]="option['upload']" (documentOnDownload)="wotcDocdownload($event)">
          </wotcdocument-category>
        </div>
        
        <!-- <pre>{{ form.value | json }}</pre> -->
    
        <div [ngClass]="{layoutLandscape: !isMvrPsp}" class=" m-b-20">
          <div class="row m-t-25 intlFormElements">
            <div>
              <ng-container *ngFor="let field of option.inputs;">
                <div *ngIf="field.type !== 'BUTTON'" [class.hide]="field.type === 'ESignature'" [attr.id]="field.type + '_control'">
                  <div *ngIf ="field.type!=='CONFIRM'" dynamicField [config]="field" [group]="form"> </div>
                  <div *ngIf ="field.type=='CONFIRM' && field.value != null" class="col-xs-12 col-md-12 m-b-20">
                     {{field.label}}  :  {{field.value}}
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
        <div *ngIf="!isDCConsentForm" class="row fixedbtn">
          <ng-container *ngFor="let buttonConfig of option.inputs;let k = index;">
            <div class="col-xs-12 col-sm-4 col-md-3 btnpd fixedbtnb"
              *ngIf="buttonConfig.type === 'BUTTON' && buttonConfig.show">
              <button type='submit' [ngClass]="decideButtonclass[k]"
                [disabled]='buttonConfig.disabled == false ? false : !form.valid'
                (click)="handleSubmit(form.value,$event,buttonConfig.label)">{{buttonConfig.label}}</button>
            </div>
          </ng-container>
        </div>

        <div *ngIf="isDCConsentForm" class="row fixedbtn">
          <div class="col-xs-12 col-sm-4 col-md-3 btnpd fixedbtnb">
            <button type="submit" class="btn button font_extrabold senButton grow" attr.data-id="agreeConsentButton"
              (click)="handleConsent($event, 'AGREE')">
              {{ 'CONSENT_AGREE_BTN' | translate }}
            </button>
          </div>
          <div class="col-xs-12 col-sm-4 col-md-3 btnpd fixedbtnb">
            <button type="submit" class="btn button font_extrabold senButton grow" attr.data-id="disagreeConsentButton"
              (click)="handleConsent($event, 'DECLINED')">
              {{ 'CONSENT_DECLINE' | translate }}
            </button>
          </div>
          
        </div>
        <div class="clearfix"></div>

      </div>
    </div>
  </ng-container>
</form>
