import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Field } from 'framework/dynamicCard/models/field.interface';
import { FieldConfig } from 'framework/dynamicCard/models/fieldconfig.interface';

declare var window: any;

@Component({
  selector: 'app-form-text-content',
  templateUrl: './form-text-content.component.html',
  styleUrls: ['./form-text-content.component.scss']
})
export class FormTextContentComponent implements Field, OnInit {
  config: FieldConfig;
  group: FormGroup;
  postOfficeName: string;
  postOfficeAddressLine1: string;
  postOfficeAddressLine2: string;
  postOfficeCoordinates: string;
  postOfficePostalCode: string;
  isPostOfficeSelected: boolean = false;
  employerName: string;
  
  constructor() { }

  ngOnInit(): void {
    if (!!this.config && !!this.config['displayContent']) {
      this.isPostOfficeSelected = this.config['displayContent']['isPostOfficeSelected'];
      
      if (this.isPostOfficeSelected) {
        let fullPostOfficeAddress = JSON.parse(this.config['displayContent']['selectedOptionDetails']);        
        
        this.postOfficeName = !!fullPostOfficeAddress['name'] ? fullPostOfficeAddress['name']: '';
        this.postOfficeAddressLine1 = !!fullPostOfficeAddress['address'] ? fullPostOfficeAddress['address']['address1'] : '';
        this.postOfficeAddressLine2 = !!fullPostOfficeAddress['address'] ? fullPostOfficeAddress['address']['address4'] + ', ' + fullPostOfficeAddress['address']['address5'] : '';
        this.postOfficePostalCode = !!fullPostOfficeAddress['address'] ? fullPostOfficeAddress['address']['postcode']: '';
      } else {
        this.employerName = !!this.config['displayContent']['employerName'] ? this.config['displayContent']['employerName'] : '';
      }
    }
  }
  
  ngAfterViewInit() {
    window.track_event('show selected branch/employed and documents', {
      stage_name: 'international',
      action_value: 'load'
    }, true, true);
  }
}
