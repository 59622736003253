import { Component, ViewContainerRef, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Field } from '../../models/field.interface';
import { FieldConfig } from '../../models/fieldconfig.interface';
import { SharedService } from '../../../../app/shared/services/shared.service';
import { LocalStorageService } from 'framework/localstorage.service';
import { WorkflowService } from 'framework/workflow.service';
import { EventBusService } from 'framework/eventbus.service';
import { RuleLengths } from 'app/shared/form-validation/model/validation-rules-config';

declare let $: any;

@Component({
  selector: 'form-input',
  styleUrls: ['form-input.component.scss'],
  templateUrl: './form-input.component.html'
})

export class FormInputComponent implements Field {
  config: FieldConfig;
  group: FormGroup;
  isError: boolean;
  errorFieldDetails: any;
  storeUserEnteredIdNumber: any;
  readonly: boolean = false;
  isRequired: boolean = false;
  isFocused: boolean = false;
  storeFieldValue: any = '';
  isWotcSSNField: boolean = false;

  errorMsgConfig: Object = {
    message: 'COMMONS_COUNTRY_ERROR',
    alert: 'COMMONS_OOPS',
    isMessagePopUpShow: true
  };

  constructor(private _sharedService: SharedService,
    private localStorage: LocalStorageService,
    private _workflow: WorkflowService,
    private _eventBus: EventBusService) {
    this._sharedService.fieldErrorNotified$.subscribe(data => {
      this.isError = data['isError']
      this.errorFieldDetails = data['details'];
      this.openErrorPopUp();
    });
  }

  ngOnInit() {
    let lockPrePopulatedFields = (this.localStorage.getItem('pa_lock_pre_population_for_custom_form_fields') == 'Y' ? true : false);
    let isSalaryEnabled = (this.localStorage.getItem('is_salary_enabled') == 'Y' ? true : false);

    this.readonly = false;
    this.isRequired = false;
    if (this.config.value === undefined) {
      this.config.value = '';
    }

    if(this.config.name == 'RNF_NUMBER' || this.config.name == 'NAME_AS_PER_DOCUMENT'){
      let hasRnfNumber = this.group.controls['DO_YOU_HAVE_RNF_NUMBER'].value;
      this.updateRNFFields(hasRnfNumber);
      let that = this;
      this._eventBus.dynamicRadioClickedAnnounced$.subscribe(item => {
        console.log(that.group);
        if (item['selectedObj']['groupName'] == 'DO_YOU_HAVE_RNF_NUMBER') {
          that.updateRNFFields(item['selectedObj']['option']);
        }
      }); 
    } else if(this.config.name == 'PROVIDE_SHARE_CODE' || this.config.name == 'PROVIDE_SHARE_CODE_1'){
      const hasShareCode = this.group.controls['DO_YOU_HAVE_UK_GOVT_SHARE_CODE'].value;
      const hasRegisteredNewCode = this.group.controls['REGISTER_NEW_SHARE_CODE'].value;
      this.updateRTWShareCodeFields(null, hasShareCode, hasRegisteredNewCode);
      let that = this;
      this._eventBus.dynamicRadioClickedAnnounced$.subscribe(item => {
        
        if (item['selectedObj']['groupName'] == 'DO_YOU_HAVE_UK_GOVT_SHARE_CODE') {
          that.updateRTWShareCodeFields(item['selectedObj']['groupName'], item['selectedObj']['option'], 'FALSE');
        }
        if (item['selectedObj']['groupName'] == 'REGISTER_NEW_SHARE_CODE') {
          that.updateRTWShareCodeFields(item['selectedObj']['groupName'], 'FALSE', item['selectedObj']['option']);
        }
      }); 
    } else if (this.config.name === '3006') {
      this.isWotcSSNField = true;
    }

    if(this.config.name == 'UAN_NUMBER'){
      let hasUanNumber = this.group.controls['HAS_UAN_NUMBER'].value;
      this.updateUANFields(hasUanNumber);
      let that = this;
      this._eventBus.dynamicRadioClickedAnnounced$.subscribe(item => {
        console.log(that.group);
        if (item['selectedObj']['groupName'] == 'HAS_UAN_NUMBER') {
          that.updateUANFields(item['selectedObj']['option']);
        }
      }); 
    }
    
    if (this.config.value !== '') {
      this.handleInvalidSIN();
      this.handleSINReadOnly();
      if (lockPrePopulatedFields) {
        let fieldValue = this.config.field_value;
        if (fieldValue && fieldValue.startsWith("${")) {
          this.readonly = true;
        }
      }
      let value = this.config.value;
      if (value && value.startsWith("${")) {
        (<FormControl>this.group.controls[this.config.name]).setValue('');
        let that = this;
        this._sharedService.getInterpolationData(this.config.value).then(function (resp) {
          if (resp && resp != '') {
            that.config.value = resp;
            that.storeFieldValue = that.config.value;
            (<FormControl>that.group.controls[that.config.name]).setValue(that.config.value);
          } else {
            that.readonly = false;
          }
        }
        );
      } else if (!!value && !value.startsWith("${")) {
        this.storeFieldValue = this.config.value;
      }
    }

    if (this.config['validation_data'] && (this.config['validation_data']['is_required'] || this.config['validation_data']['validation'] === 'REQUIRED')) {
      this.isRequired = true;
    }

    if (this.config['validation_data']) {
      if (this.config['validation_data']['is_editable'] != undefined && this.config['validation_data']['is_editable'] !== null && this.config['validation_data']['is_editable'] === false)
      this.readonly = true;
    }

    this._eventBus.jobTypeSelectedAnnounced$.subscribe(item => {
      if (item != 'agency') {
        this.removeAgencyFields();
      } else {
        let optionalFields = ['LAST_BONUS_RECEIVED' , 'AGENCY_CONTACT_EMAIL'];
        if(!optionalFields.includes(this.config.name)){
        this.group.controls[this.config.name].setValidators(Validators.compose([Validators.required]));
        }
        this.config['isHidden'] = false;
        this.group.controls[this.config.name].updateValueAndValidity();
      }
      if (isSalaryEnabled !== undefined && !isSalaryEnabled && (this.config.name === 'FIXED_SALARY' || this.config.name === 'LAST_BONUS_RECEIVED')) {
          this.group.controls[this.config.name].clearValidators();
          this.group.controls[this.config.name].setValue('');
          this.config.value = '';
          this.config['isHidden'] = true;
      }
      this.group.controls[this.config.name].updateValueAndValidity();
    });

    if (this.config['name'] === 'DBS_DRIVING_LICENSE_NUMBER') {
      let that = this;
      this.group.controls['DBS_DRIVING_LICENSE_NUMBER']?.valueChanges
        .subscribe(value => {
          let errorMessage = this.config['validation_data']['error_message'];
          if (!!errorMessage) {
            //Showing DVLA specific error message.
            let ukIndex = errorMessage.indexOf("_UK");
            let dbsIssueCountry = this.group.controls['DBS_ISSUE_COUNTRY']?.value;
            let isUKDVLA = dbsIssueCountry === 'UNITED KINGDOM' && (!!value && value.length >= RuleLengths.ukdvladrivingLicenseMinLength && value.length <= RuleLengths.drivingLicenseMaxLength);
            if (isUKDVLA) {
              if (ukIndex === -1) {
                errorMessage += '_UK';
              }
            } else {
              if (ukIndex !== -1) {
                errorMessage = errorMessage.substr(0, ukIndex);
              }
            }
            this.config['validation_data']['error_message'] = errorMessage;
          }
          if (that.readonly && !value) {
            that.readonly = false;
          }
        });
    } else if (this.config['name'] === 'DBS_PASSPORT_NUMBER') {
      let that = this;
      this.group.controls['DBS_PASSPORT_NUMBER']?.valueChanges
        .subscribe(value => {
          if (that.readonly && !value) {
            that.readonly = false;
          }
        });
    }
    let that = this;
    this._eventBus.formInputErrorAnnounced$.subscribe(item => {
      if (!!item && !!item['name']) {
        if (item['name'] === that.config['name']) {
          console.log("formInputErrorAnnounced -------", item);
          that.group.controls[item['name']]?.setErrors({ isInvalid: 'true' });
          that.group.controls[item['name']]?.markAsDirty();
        }
      }
    });
  }

  private updateRNFFields(hasRnfNumber){

    if(this.config.name == 'RNF_NUMBER' || this.config.name == 'NAME_AS_PER_DOCUMENT'){
      if(hasRnfNumber == 'TRUE'){  //item['selectedObj']['option'] == 'TRUE'
        if (this.config.name == 'RNF_NUMBER') {
          this.group.controls[this.config.name].setValidators(Validators.compose([Validators.required]));
          this.config['isHidden']=false;
        }
        if (this.config.name == 'NAME_AS_PER_DOCUMENT') {
          this.group.controls[this.config.name].clearValidators();
          this.group.controls[this.config.name].setValue('');
          this.config.value='';
          this.config['isHidden']=true;
        }
        this.group.controls[this.config.name].updateValueAndValidity();
      } else {
        if (this.config.name == 'RNF_NUMBER') {
          this.group.controls[this.config.name].clearValidators();
          this.group.controls[this.config.name].setValue('');
          this.config.value='';
          this.config['isHidden']=true;
        }
        if (this.config.name == 'NAME_AS_PER_DOCUMENT') {
          this.group.controls[this.config.name].setValidators(Validators.compose([Validators.required]));
          this.config['isHidden']=false;
        }
        this.group.controls[this.config.name].updateValueAndValidity();
      }
    }
    
    if(hasRnfNumber === undefined){
      this.config['isHidden']=true;
    }

  }

  private updateRTWShareCodeFields(groupName, hasShareCode, hasRegisteredNewCode){
    if(this.config.name === 'PROVIDE_SHARE_CODE' || this.config.name === 'PROVIDE_SHARE_CODE_1' 
      || this.config.name === 'REGISTER_NEW_SHARE_CODE'){
      if(hasShareCode === 'TRUE' && this.config.name !== 'PROVIDE_SHARE_CODE_1'){  //item['selectedObj']['option'] == 'TRUE'
        if (this.config.name === 'PROVIDE_SHARE_CODE') {
          this.setFieldOption(this.config.name, false);
        }
        if (this.config.name === 'REGISTER_NEW_SHARE_CODE') {
          this.setFieldOption(this.config.name, true);
        }

        // if (this.config.name === 'PROVIDE_SHARE_CODE_1') {
        //   this.group.controls[this.config.name].clearValidators();
        //   this.group.controls[this.config.name].setValue('');
        //   this.config.value='';
        //   this.config['isHidden']=true;
        // }

        if ('REGISTER_NEW_SHARE_CODE' === groupName ) {
          if (this.config.name == 'PROVIDE_SHARE_CODE') {
            this.setFieldOption(this.config.name, true);
          }
        }

        this.group.controls[this.config.name].updateValueAndValidity();
      } else {
        if (this.config.name == 'PROVIDE_SHARE_CODE') {
          this.setFieldOption(this.config.name, true);
        }

        if (this.config.name == 'REGISTER_NEW_SHARE_CODE') {
          this.setFieldOption(this.config.name, false);
        }

        if (this.config.name == 'PROVIDE_SHARE_CODE_1') {
          if(!hasRegisteredNewCode || hasRegisteredNewCode === 'FALSE') {
            this.setFieldOption(this.config.name, true);
          } else {
            this.setFieldOption(this.config.name, false);
          }
          
        }


        this.group.controls[this.config.name].updateValueAndValidity();
      }

    }

    if(hasShareCode === undefined){
      this.config['isHidden']=true;
    }

  }

  private setFieldOption(fieldName: string, isHidden: boolean) {
    if (isHidden) {
      this.group.controls[fieldName].clearValidators();
      this.group.controls[fieldName].setValue('');
      this.config.value = '';
      this.config['isHidden'] = true;
    } else {
      this.group.controls[fieldName].setValidators(Validators.compose([Validators.required]));
      this.config['isHidden']=false;
    }
  }

  private updateUANFields(hasUanNumber){

    if(this.config.name == 'UAN_NUMBER' || this.config.name == 'HAS_UAN_NUMBER'){
      if(hasUanNumber == 'TRUE'){  
        if (this.config.name == 'UAN_NUMBER') {
          if( this.config["validation_data"] && this.config["validation_data"]["regex"]) {
            let regEx = this.config["validation_data"]["regex"];
            this.group.controls[this.config.name].setValidators(Validators.compose([Validators.required, Validators.pattern(regEx)]));
          }
          this.config['isHidden']=false;
        }
        this.group.controls[this.config.name].updateValueAndValidity();
      } else{
        if(this.config.name == 'UAN_NUMBER') {
          this.group.controls[this.config.name].clearValidators();
          this.group.controls[this.config.name].setValue('');
          this.config.value='';
          this.config['isHidden']=true;
        }
        this.group.controls[this.config.name].updateValueAndValidity();
      }
    }
    
    if(hasUanNumber === undefined){
      this.config['isHidden']=true;
    }
  }

  private removeAgencyFields() {
    if (this.config.name === 'AGENCY_NAME' || this.config.name === 'AGENCY_BRANCH' || this.config.name === 'AGENCY_ADDRESS'
      || this.config.name === 'AGENCY_CONTACT_PHONE_NUMBER' || this.config.name === 'AGENCY_CONTACT_EMAIL') {
      this.group.controls[this.config.name].clearValidators();
      this.group.controls[this.config.name].setValue('');
      this.config.value = '';
      this.config['isHidden'] = true;
    }
    this.group.controls[this.config.name].updateValueAndValidity();
  }

  ngAfterViewInit() {
    this.maskIdNumber();
  }

  onBlur() {

    // 5342 PPA-14974 National Insurance Number Formatting
    if (this.config.name.startsWith('NINSNB')) {
      this.toUpperCase();
    }
    this.maskIdNumber();
  }

  onFocus() {
    this.unmaskIdNumber();
  }

  openErrorPopUp() {
    let isMessagePopUpShow = true;
    this.updateErrorConfig(isMessagePopUpShow);
  }

  // Open or close Error message popup when we click exclamation or close icon
  closeErrorPopUp() {
    let isMessagePopUpShow = false;
    this.updateErrorConfig(isMessagePopUpShow);
  }

  private getErrorMessage(): string {
    let errorMessage: string = ''

    if (this.errorFieldDetails['validation_data']['specific_error_message']) {
      errorMessage = this.errorFieldDetails['validation_data']['specific_error_message'];
    } else if (this.errorFieldDetails['validation_data']['error_message']) {
      errorMessage = this.errorFieldDetails['validation_data']['error_message'];
    }

    return errorMessage;
  }

  // update our error config
  private updateErrorConfig(isMessagePopUpShow) {
    this.errorMsgConfig['isMessagePopUpShow'] = isMessagePopUpShow;
    this.errorMsgConfig['message'] = this.getErrorMessage();
  }

  showFullLabelText() {
    $('[data-toggle="popover"]').popover();
  }

  unmaskIdNumber() {
    $('input').on('click', function (e) {
      $('[data-toggle="popover"]').each(function () {
        //the 'is' for buttons that trigger popups
        //the 'has' for icons within a button that triggers a popup
        if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
          $(this).popover('hide');
        }
      });
    });

    if (this.config["isMaskIdNumber"] !== undefined && this.config["isMaskIdNumber"]) {
      (<FormControl>this.group.controls[this.config.name]).setValue(this.storeUserEnteredIdNumber);
    }
  }

  maskIdNumber() {
    //config['validation_data']['regex]
    if (this.config["isMaskIdNumber"] !== undefined && this.config["isMaskIdNumber"]) {
      if (this.group.controls[this.config.name].value !== undefined) {
        let originalValue = this.group.controls[this.config.name].value;
        this.storeUserEnteredIdNumber = originalValue;
        (<FormControl>this.group.controls[this.config.name + '_HIDDEN']).setValue(originalValue);
        let newVal = '';
        let inputIdNo = originalValue.trim();

        if (inputIdNo !== undefined && inputIdNo.length > 0) {
          let repeatedCharacter = this.repeat('*', inputIdNo.length);

          newVal = inputIdNo.replace(inputIdNo.substr(0, inputIdNo.length), repeatedCharacter);
          (<FormControl>this.group.controls[this.config.name]).setValue(newVal);
        }
      }
    }
  }

  repeat(str, count) {
    let array = [];

    for (let i = 0; i < count;)
      array[i++] = str;
    return array.join('');
  }

  handleSINReadOnly() {
    let lockLevelAts = this._workflow.getLockLevelForATS();
    if (!!lockLevelAts && !!this.config['value'] && this.config['name'] === 'SINSNB') {
      this.readonly = true;
      this.maskIdNumber();
      this.config['isMaskIdNumber'] = false;
    } else {
      this.readonly = false;
    }
  }

  handleInvalidSIN() {
    if (this.config['name'] === 'SINSNB' || this.config['name'] === "SINSNB_CONFIRM") {
      if (!this._sharedService.validateSINSNB(this.config['value'])) {
        this.config['value'] = '';
        (<FormControl>this.group.controls[this.config.name]).setValue('');
      }
    }
  }

  toUpperCase() {
    let enteredText = <string>this.group.controls[this.config.name].value
    this.group.controls[this.config.name].setValue(enteredText.toUpperCase());
  }

  showOriginalValue() {
    this.isFocused = true;
  }
  
  showMaskedValue(): string {
    if (this.isFocused && !this.readonly) {
      return this.storeFieldValue;
    }
    return this.maskDL(this.storeFieldValue);
  }

  maskDL(value: string): string {
    if (!value) return '';
    
    let length = value.length;
    return '*'.repeat(Math.max(0, length - 4)) + value.slice(-4);
  }

  showMaskedSSNValue(): string {
    if (this.isFocused && !this.readonly) {
      return this.storeFieldValue;
    }
    return this.maskSSN(this.storeFieldValue);
  }

  maskSSN(value: string): string {
    if (!value) return '';
    
    let length = value.length;
    return '*'.repeat(Math.max(0, length - 4)) + value.slice(-4);
  }
 
  onBlurDL() {
    if (!this.readonly) {
      let newValue = this.group.controls[this.config.name].value;
      this.storeFieldValue = newValue;
    }
    this.isFocused = false;
  }

  onBlurSSN() {
    if (!this.readonly) {
      let newValue = this.group.controls[this.config.name].value;
      this.storeFieldValue = newValue;
    }
    this.isFocused = false;
  }

  validateNumberInput(event) {
    if (!!event && !!event.key && ((event.key >= 0 && event.key <= 9) || event.key === 'Backspace' || event.key === 'Control' || event.key === 'Tab'
      || event.key === 'Delete' || (event.key == 'a' && event.ctrlKey) || (event.key == 'A' && event.ctrlKey))) {
      return true;
    }
    return false;
  }

}