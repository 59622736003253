import { NgModule, Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalStorageService } from '../../../framework/localstorage.service';
import { WorkflowService } from '../../../framework/workflow.service';
import { Store } from '@ngrx/store';
import * as ACTIONS_INTERFACE from '../../actions';
import { DocumentUploadConfig } from './../document-upload.config';
import { SharedService } from 'app/shared/services/shared.service';
import { BehaviorSubject } from 'rxjs';

declare var window: any;
@Component({
  selector: 'optional-documents',
  templateUrl: './optional-documents-upload.component.html',
  providers: [WorkflowService]
})

export class OptionalDocumentUploadComponent implements OnInit {
  static STAGE_ID: string;
  static STEP_ID: string;
  static STEP_INDEX: number;

  @Input() inputOptionalGroupObject: any;
  @Input() configOptions: any;
  @Input() inputOptionalIsDocEmptyList: boolean;
  @Input() inputIsOptionalDocsPresent: boolean;
  @Input() isInviteEiaf: any;
  _data = new BehaviorSubject<any>([]);

  @Output() outputOptionalUploadAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() outputOptionalDocumentOnDelete: EventEmitter<any> = new EventEmitter<any>();
  @Output() outputOptionalDocumentOnDownload: EventEmitter<any> = new EventEmitter<any>();
  @Output() outputOptionalDocumentOnView: EventEmitter<any> = new EventEmitter<any>();
  @Output() outputOptionalDocumentOnInstruction: EventEmitter<any> = new EventEmitter<any>();
  @Output() outputOptionalDownloadInstructionGuide: EventEmitter<any> = new EventEmitter<any>();
  @Output() outputOptionalOnNextClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() outputOptionalOnSaveExitClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() outputOptionalLeftSwipe: EventEmitter<any> = new EventEmitter<any>();

  SUB_STEP_ID: string;
  SUB_STEP_INDEX: number;
  PARENT_STAGE_STEP_CONFIG: Object;
  payLoad: Object;
  currentStatus: string = 'VISITED';
  startTime: any;
  firstName: string = '';
  companyName: any;
  isInviteAssessment: boolean = false;

  constructor(private localStorage: LocalStorageService,
    private shareService: SharedService, private _store: Store<ACTIONS_INTERFACE.AppState>) {
    this.startTime = new Date().getTime();
  }

  ngOnInit() {
    this.isInviteAssessment = this.shareService.checkInviteIsAssessment();
  }

  // After View Init function
  ngAfterViewInit() {
    setTimeout(() => {
      this.firstName = this.localStorage.getItem('first_name');
      this.companyName = this.localStorage.getItem('company_name');
    }, 200);

    this.processConfig();
  }

  processConfig(): void {
    this.PARENT_STAGE_STEP_CONFIG = this.configOptions['PARENT_CONFIG'];
    this.SUB_STEP_ID = DocumentUploadConfig.subStepIndexMapping['optional']['subStep'];
    this.SUB_STEP_INDEX = DocumentUploadConfig.subStepIndexMapping['optional']['subStepIndex'];

    this.setState();
  }

  setState(): void {
    this.payLoad = {
      name: this.PARENT_STAGE_STEP_CONFIG['STAGE_ID'],
      step: this.PARENT_STAGE_STEP_CONFIG['STEP_ID'],
      stepIndex: this.PARENT_STAGE_STEP_CONFIG['STEP_INDEX'],
      subStep: this.SUB_STEP_ID,
      subStepIndex: this.SUB_STEP_INDEX,
      status: this.currentStatus,
      dbRecordPresent: this.PARENT_STAGE_STEP_CONFIG['IS_DB_RECORD_PRESENT']
    };

    this._store.dispatch({ type: this.currentStatus, payload: this.payLoad });
  }

  optionalUploadAction(obj) {
    obj['slide'] = 'optional';
    this.outputOptionalUploadAction.emit(obj);
  }

  optionalDeleteAction(obj) {
    this.outputOptionalDocumentOnDelete.emit(obj);
  }

  optionalDownloadAction(obj) {
    obj['slide'] = 'optional';
    this.outputOptionalDocumentOnDownload.emit(obj);
  }

  optionalViewAction(obj) {
    this.outputOptionalDocumentOnView.emit(obj);
  }

  optionalInstructionAction(obj) {
    this.outputOptionalDocumentOnInstruction.emit(obj);
  }

  onOptionalNextClick() {
    let obj = {};

    if (this.currentStatus === 'VISITED') {
      this.currentStatus = 'COMPLETED';
    }
    this.setState();

    obj['COMPONENT_STATE'] = this.payLoad;
    obj['startTime'] = this.startTime;
    window.track_event("save data, go to next stage", {stage_name: 'optional documents', action_value: 'next click'}, true, true);
    
    this.outputOptionalOnNextClick.emit(obj);
  }

  onOptionalSaveExitClick() {
    let obj = {};
    this.currentStatus = 'PENDING';
    this.setState();

    obj['COMPONENT_STATE'] = this.payLoad;
    obj['startTime'] = this.startTime;

    this.outputOptionalOnSaveExitClick.emit(obj);
    this.currentStatus = 'VISITED';
  }

  doSwipe(direction: string) {
    if (direction === 'swiperight') {
      this.getPrevSwipe();
    }
    if (direction === 'swipeleft') {
      this.onOptionalNextClick();
    }
  }

  getPrevSwipe() {
    if (!this.inputOptionalIsDocEmptyList || this.isInviteEiaf) {
      this.outputOptionalLeftSwipe.emit();
    }
  }
}
