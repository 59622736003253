import { AfterViewInit, Component, OnInit } from '@angular/core';
import { PreAuthService } from 'app/pre-auth/pre-auth.service';
import { LocalStorageService } from 'framework/localstorage.service';
import { SharedService } from '../shared/services/shared.service';
import { environment } from 'environments/environment';
import { TranslateService } from 'framework/i18n';
import { Router } from '@angular/router';

declare var window: any;

@Component({
  selector: 'app-base-login',
  templateUrl: './base-login.component.html',
  styleUrls: ['./base-login.component.css']
})
export class BaseLoginComponent implements OnInit {
  selectedPage = 'KEYCLOAK';
  isAuthyCallback:boolean = false;
  isAuthyLoggedIn: boolean = false;
  isAuthyTokenExpired: boolean = false;
  authyPolicyType: string = '';

  constructor(private _sharedService: SharedService,
    private localStorage: LocalStorageService,
    private preAuthService: PreAuthService,
    private translate: TranslateService,
    private router: Router) {
    }

  ngOnInit(): void {
    this.isAuthyCallback = this.localStorage.getItem("isAuthyCallback");
    this.isAuthyLoggedIn = this.localStorage.getItem("isAuthyLoggedIn");

    this.localStorage.setItem('user_authenticator', 'KEYCLOAK');
    this.isAuthyTokenExpired = this.localStorage.getItem("isAuthyTokenExpired");
    
    if (this.isAuthyCallback && this.isAuthyTokenExpired) {
      this.router.navigate(['expired-link']);
      return;
    }

    let trackObj = {
      stage_name: 'base-login',
      action_value: 'url click'
    };

    window.track_event('login attempt', trackObj, true, true);

    this.setAuthEngine();
       
  }

  setAuthEngine() {
    this.localStorage.setItem('user_authenticator', 'KEYCLOAK');

    let trackObj = {
      stage_name: 'base-login',
      action_value: 'get'
    };
   
    // sign in to keycloak
    this.selectedPage = 'KEYCLOAK';
    window.track_event('keycloak redirect', trackObj, true, true);
    
  }
}
