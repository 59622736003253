import {
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  Input,
  OnChanges,
  OnInit,
  Type,
  ViewContainerRef
} from '@angular/core';
import { FormGroup } from '@angular/forms';

import { FormButtonComponent } from './../../components/form-button/form-button.component';
import { FormInputComponent } from './../../components/form-input/form-input.component';
import { FormESignatureComponent } from './../../components/form-esignature/form-esignature.component';
import { FormHiddenComponent } from './../../components/form-hidden/form-hidden.component';
import { FormSelectComponent } from './../../components/form-select/form-select.component';
import { FormRadioComponent } from './../../components/form-radio/form-radio.component';
import { FormRadioExplanationComponent } from './../../components/form-radio-explanation/form-radio-explanation.component';
import { FormCheckboxComponent } from './../../components/form-checkbox/form-checkbox.component';
import { FormTextareaComponent } from './../../components/form-textarea/form-textarea.component';
import { LocationPickerComponent } from './../../components/location-picker/location-picker.component';
import { FormDateComponent } from './../../components/form-date-picker/form-date.component';
import { FormObjectSelectComponent } from './../../components/form-object-select/form-object-select.component';
import { FormNumericInputComponent } from './../../components/form-numeric-input/form-numeric-input.component';
import { FormHtmlLabelComponent } from './../../components/form-html-label/form-html-label.component';
import { FormCheckboxExtendedComponent } from './../../components/form-extended-checkbox/form-checkbox-extended.component';
import { PhoneComponent } from './../../components/phone/phone.component';

import { Field } from '../../models/field.interface';
import { FieldConfig } from '../../models/fieldconfig.interface';
import { FormSSNComponent } from '../form-ssn/form-ssn.component';
import { FormDateRangeComponent } from '../form-date-range/form-date-range.component';
import { DynamicDocumentUploadComponent } from '../document-upload/document-upload.component';
import { MultipleCustomSelectComponent } from 'framework/dynamicCard/components/multiple-custom-select/multiple-custom-select.component';
import { DynamicDetailsCountryComponent } from 'app/international/cspi-custom-questions/dynamic-details-country.component';
import { FormTextContentComponent } from '../form-text-content/form-text-content.component';

@Directive({
  selector: '[dynamicField]'
})
export class DynamicFieldDirective implements Field, OnChanges, OnInit {
  components: { [type: string]: Type<Field> } = {
    button: FormButtonComponent,
    BUTTON: FormButtonComponent,
    input: FormInputComponent,
    esignature: FormESignatureComponent,
    text: FormInputComponent,
    TEXT: FormInputComponent,
    MaskDLTextBox: FormInputComponent,
    Text: FormInputComponent,
    RADIO: FormRadioComponent,
    YesNoQuestionWithExplanation: FormRadioExplanationComponent,
    CHECKBOX: FormCheckboxComponent,
    TSCHECKBOX: FormCheckboxComponent,
    ADDRESS: FormTextareaComponent,
    HIDDEN: FormHiddenComponent,
    phoneNumber: PhoneComponent,
    SELECT: FormSelectComponent,
    select: FormSelectComponent,
    Select: FormSelectComponent,
    LIST: FormInputComponent,
    textarea: FormInputComponent,
    COUNTRY: LocationPickerComponent,
    country: LocationPickerComponent,
    STATE: LocationPickerComponent,
    state: LocationPickerComponent,
    CITY: FormInputComponent,
    city: FormInputComponent,
    citystate: FormInputComponent,
    address: FormInputComponent,
    datePicker: FormInputComponent,
    DATE: FormDateComponent,
    Date: FormDateComponent,
    date: FormDateComponent,
    MaskDobTextBox: FormDateComponent,
    PHONE: PhoneComponent,
    phone: PhoneComponent,
    Phone: PhoneComponent,
    OBJECTSELECT: FormObjectSelectComponent,
    NUMERIC: FormNumericInputComponent,
    MaskSSNTextBox: FormNumericInputComponent,
    HTMLLABEL: FormHtmlLabelComponent,
    TextArea: FormTextareaComponent,
    CHECKBOXEX: FormCheckboxExtendedComponent,
    hidden: FormHiddenComponent,
    SSN: FormSSNComponent,
    DATERANGE : FormDateRangeComponent,
    document: DynamicDocumentUploadComponent,
    MULTICOUNTRY: MultipleCustomSelectComponent,
    NATIONALITY: LocationPickerComponent,
    DYNAMIC_DETAILS_COUNTRY: DynamicDetailsCountryComponent,
    TEXTCONTENT: FormTextContentComponent
  };

  @Input()
  config: FieldConfig;

  @Input()
  componentInfo: Object;

  @Input()
  group: FormGroup;

  component: ComponentRef<Field>;

  constructor(
    private resolver: ComponentFactoryResolver,
    private container: ViewContainerRef
  ) { }

  ngOnChanges() {
    if (this.component) {
      this.component.instance.config = this.config;
      this.component.instance.group = this.group;
    }
  }

  ngOnInit() {
    if (!this.components[this.config.type]) {
      const supportedTypes = Object.keys(this.components).join(', ');
      throw new Error(
        `Trying to use an unsupported type (${this.config.type}).
        Supported types: ${supportedTypes}`
      );
    }
    const component = this.resolver.resolveComponentFactory<Field>(this.components[this.config.type]);
    this.component = this.container.createComponent(component);
    this.component.instance.config = this.config;
    this.component.instance.group = this.group;
  }
}
