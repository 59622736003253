import { Component, Input, Output, EventEmitter, OnInit, AfterViewInit, OnChanges, DoCheck } from '@angular/core';
import { FormGroup, FormControl, FormArray, FormBuilder, Validators } from '@angular/forms';
import { EmploymentConfig } from '../employment.config';
import { EmploymentService } from '../employment.service';
import { SharedService } from '../../shared/services/shared.service';
import { BehaviorSubject } from 'rxjs';

import { EventBusService } from 'framework/eventbus.service';

// State
import { Store } from '@ngrx/store';
import * as ACTIONS_INTERFACE from '../../actions';
import { distinctUntilChanged, filter, switchMap, tap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';


declare var google: any;
declare var jQuery: any;

@Component({
  selector: 'city-state',
  templateUrl: './city-state.component.html',
  styleUrls: ['./city-state.component.css']
})
export class CityStateComponent implements OnInit, AfterViewInit, DoCheck {

  // SE functionality
  static STAGE_ID: string;
  static STEP_ID: string;
  static STEP_INDEX: number;
  // State Maintenance Related Activity
  static BUTTON_CLICKED: string = 'CLICKED_SAVE_EXIT_BUTTON';

  private _zipCodeError = new BehaviorSubject<any>([]);

  @Input('group')
  public empSubForm: FormGroup;
  @Input() row: any;
  @Input() options: any;
  @Input() empType: any;
  @Input() userDetails: any;
  @Input() configOptions: any;
  @Input() isReviewEdit: boolean;
  @Input() isATSData: any;
  @Input() isPaDOTDirectEnabled: boolean;

  @Input()
  set zipCodeError(value) {
    this._zipCodeError.next(value);
  }
  get zipCodeError() {
    return this._zipCodeError.getValue();
  }

  // @Input()
  // set inputCountryDecisionChanged(value) {
  //     this._todata.next(value);
  // };

  // get inputCountryDecisionChanged() {
  //     return this._todata.getValue();
  // }

  @Output() onCityState: EventEmitter<any> = new EventEmitter();
  @Output() onCityStateSwipe: EventEmitter<any> = new EventEmitter();
  public SUB_STEP_ID: string;
  public SUB_STEP_INDEX: number;

  stateList: any = [];
  cityList: any = [];
  zipCodeList: any = [];

  isStateListToShow: boolean = false;
  isCityListToShow: boolean = false;
  isZipListToShow: boolean = false;

  stateCode: string = '';
  cityCode: string = '';

  optionsStateCode: any;

  // _todata = new BehaviorSubject<any>([]);
  countryShortName;
  autocomplete;
  autocompleteState;
  cityStateId;
  cityId;
  stateId;
  zipId;
  input;
  stateInput;
  country;
  cityStateOptions;
  stateShortName;
  cityprepopulate;
  countryOptions;
  viewInit: boolean;
  isValidCityState;
  isOpenGoogleSuggestion: boolean;
  cityStateSuccessCollection: any[] = [];
  cityStateErrFlag: any;
  showCitySateErr: boolean;
  showCityErr: boolean = false;
  showStateErr: boolean = false;
  PARENT_STAGE_STEP_CONFIG: Object;
  currentStatus: string = 'VISITED';
  payLoad: Object;
  startTime: any;
  inputState;
  getGeoOption: any;
  cityOptions;
  stateOptions;
  validCityFlag: boolean = false;
  validStateFlag: boolean = false;
  isStateChanged: boolean = false;
  showZipError: boolean = false;
  selectedState: string;
  selectedCity: string;
  selectedZipCode: string;
  stateTypeahead = new EventEmitter<string>();
  cityTypeahead = new EventEmitter<string>();
  zipCodeTypeahead = new EventEmitter<string>();

  constructor(private _es: EmploymentService,
    private _eventBus: EventBusService, // Auto Generated PPA-8745 - Arun Supreet for Save n Exit Patch
    private shareService: SharedService,
    private _store: Store<ACTIONS_INTERFACE.AppState>) {
    this.startTime = new Date().getTime();
  }

  ngOnInit() {
    this.zipCodeList = [];
    this.isZipListToShow = false;
    this.cityStateId = 'empCityState_' + this.row;
    this.cityId = 'empCity_' + this.row;
    this.stateId = 'empState_' + this.row;
    this.zipId = 'empZipCode_' + this.row;
    this.optionsStateCode = this.options['stateOptions'];
    this.countryOptions = this.options['countryOptions'];
    this.getGeoOption = this._es.getGeoOptions();

    // this.cityStateOptions = this._es.geoOption['state'];
    this.cityOptions = this.getGeoOption['city'];
    this.stateOptions = this.getGeoOption['state'];
    if (this.countryOptions['countryShortName']) {
      this.cityOptions['componentRestrictions']['country'] = this.countryOptions['countryShortName'];
      this.stateOptions['componentRestrictions']['country'] = this.countryOptions['countryShortName'];
    }
    this.viewInit = false;
    this.isOpenGoogleSuggestion = false;
    this.cityStateErrFlag = false;
    this.showCitySateErr = false;

    this.selectedState = this.empSubForm.controls['stateName'].value;
    this.selectedCity = this.empSubForm.controls['city'].value;
    this.selectedZipCode = this.empSubForm.controls['zipCode'].value;
    this.stateServerSideSearch();
    this.cityServerSideSearch();
    this.zipCodeServerSideSearch();
  }

  ngAfterViewInit(): void {
    // console.log('----------- ngAfterViewInit -----------------');
    this.processConfig();
    this.viewInit = true;
    this.subcribeToFormChanges();
    if (this.countryOptions['country']) {
      this.country = this.countryOptions['country'];
    }
    if (this.countryOptions['countryShortName']) {
      this.countryShortName = this.countryOptions['countryShortName'];
    }

    if (this.optionsStateCode !== undefined && this.optionsStateCode['stateCode'] !== undefined) {
      this.stateCode = this.optionsStateCode['stateCode'];
    }
    this.validateCity(this.empSubForm.controls['city'].value);
    this.validateState(this.empSubForm.controls['stateName'].value);
  }

  ngDoCheck() {
    if (this.viewInit && this.countryOptions && this.countryOptions['countryShortName']) {
      if (this.countryOptions['countryShortName'] !== this.countryShortName) {
        this.getGeoOption = this._es.getGeoOptions();
        let cityOptions = this._es.geoOption['city'];
        let stateOptions = this._es.geoOption['state'];
        if (this.countryOptions['countryShortName']) {
          this.countryShortName = this.countryOptions['countryShortName'];
        }
        cityOptions['componentRestrictions']['country'] = this.countryShortName;
        stateOptions['componentRestrictions']['country'] = this.countryShortName;
      }
    }
  }

  ngOnDestroy() {
  }

  // Methods to Remove Google API
  // Get state method

  stateLookUp(data, event) {
    let stateNameValue = (<FormControl>this.empSubForm.controls['stateName']).value;
    if (!stateNameValue || stateNameValue === '') {
      let queryParam = {
        'countryCode': this.countryShortName,
        'stateParam': data.trim()
      };
      // call shared service to get state datas
      this.shareService.stateLookupSwitch(queryParam).subscribe(response => {
        let stateData = response;
        console.log('state Data: ' + JSON.stringify(stateData));
        this.stateList = stateData['state-data-list'];
        if (this.stateList.length > 0) {
          this.isStateListToShow = true;
        } else {
          this.isStateListToShow = false;
        }
      }, error => {

      });
    }
  }

  // Get city method
  cityLookUp(data) {
    if (data.length > 0) {
      let queryParam = {
        'countryCode': this.countryShortName,
        'stateCode': (!!this.empSubForm.value.stateCode) ? this.empSubForm.value.stateCode : '',
        'cityParam': data.trim()
      };

      // call shared service to get city datas
      this.shareService.cityLookupSwitch(queryParam).subscribe(response => {
        let cityData = response;
        this.cityList = cityData['city-data-list'];
        if (this.cityList.length > 0) {
          this.isCityListToShow = true;
        } else {
          this.isCityListToShow = false;
        }
      }, error => {

      });
    }
  }

  zipLookUp(data, event) {
    if (data.length > 0) {
      let queryParam = {
        'countryCode': this.countryShortName,
        'zipParam': data,
        'stateCode': (this.empSubForm.value.stateCode !== '') ? this.stateCode : '',
        'placeCode': (this.empSubForm.value.city !== '') ? this.cityCode : ''
      };

      this.shareService.zipLookupSwitch(queryParam).subscribe(response => {
        let zipData = response;
        this.zipCodeList = zipData['zipcode-data-list'];
        if (this.zipCodeList.length > 0) {
          this.isZipListToShow = true;
        } else {
          this.isZipListToShow = false;
        }
      }, error => {
        this.showZipError = true;
      });
    } else if (data.length === 0) {
      /*
          1st time user gives value and accordingly, all values get set.
          2nd time user completes erase that value, then city, state related all values should be set empty.
      */
      if (this.zipCodeList.length > 0) {
        this.isZipListToShow = false;
        // this.clearFieldValues('cityState');
      }
    }
  }

  checkStateDecisionChanged(data) {
    let selectedCity = (<FormControl>this.empSubForm.controls['stateCode']).value;
    if (selectedCity !== data['state_code']) {
      this.isStateChanged = true;
    } else {
      this.isStateChanged = false;
    }
  }

  /* ------------- Bind methods start here ------------- */
  
  setFieldValues(values): void {

    if (values['zip_code'] !== '' && values['zip_code'] !== undefined) {
      (<FormControl>this.empSubForm.controls['zipCode']).setValue(values['zip_code']);
    }
    if (values['city'] !== '' && values['city'] !== undefined) {
      (<FormControl>this.empSubForm.controls['city']).setValue(values['city']);
    }

    if (values['state_code']) {
      (<FormControl>this.empSubForm.controls['stateName']).setValue(values['state_name']);
      (<FormControl>this.empSubForm.controls['stateCode']).setValue(values['state_code']);
      this.stateCode = this.stateShortName = values['state_code'];
    }
  }

  clearFieldValues(type) {
    if (type === 'city') {
      (<FormControl>this.empSubForm.controls['city']).setValue('');
    } else if (type === 'cityState') {
      (<FormControl>this.empSubForm.controls['city']).setValue('');
      (<FormControl>this.empSubForm.controls['stateName']).setValue('');
      this.stateCode = this.stateShortName = '';
    }
  }

  private processConfig(): void {
    this.PARENT_STAGE_STEP_CONFIG = this.configOptions['PARENT_CONFIG'];
    this.SUB_STEP_ID = EmploymentConfig.subStepIndexMapping['city_state']['subStep'];
    this.SUB_STEP_INDEX = EmploymentConfig.subStepIndexMapping['city_state']['subStepIndex'];
    this.setState();
  }

  private setState(): void {
    this.payLoad = {
      name: this.PARENT_STAGE_STEP_CONFIG['STAGE_ID'],
      step: this.PARENT_STAGE_STEP_CONFIG['STEP_ID'],
      stepIndex: this.PARENT_STAGE_STEP_CONFIG['STEP_INDEX'],
      subStep: this.SUB_STEP_ID,
      subStepIndex: this.SUB_STEP_INDEX,
      iteration: this.row,
      status: this.currentStatus,
      dbRecordPresent: this.PARENT_STAGE_STEP_CONFIG['IS_DB_RECORD_PRESENT']
    };

    this._store.dispatch({ type: this.currentStatus, payload: this.payLoad });
  }

  doSwipe(direction: string, data) {
    // alert(direction);
    if (direction === 'swiperight') {
      this.getPrevSwipe();
    }
    if (direction === 'swipeleft') {
      if (!!data['city'] && !!data['stateName']) {
        this.cityState(data);
      }
    }
  }

  getPrevSwipe() {
    this.isCityListToShow = false;
    this.isStateListToShow = false;
    this.isZipListToShow = false;
    this.onCityStateSwipe.emit();
  }

  private subcribeToFormChanges() {
    const myFormValueChanges$ = this.empSubForm.valueChanges;
  }

  private onCityStateBlur() {
    if (!this.showCitySateErr) {
      setTimeout(() => {
        this.showCitySateErr = true;
      }, 50);
    }
  }

  openShowCityErr() {
    this.showCityErr = false;
  }

  openShowStateErr() {
    this.showStateErr = false;
  }

  closeCityError() {
    this.showCityErr = true;
  }

  closeStateError() {
    this.showStateErr = true;
  }

  private cityStateValidation() {
    this.isValidCityState = false;

    let cityState = this.empSubForm.controls['cityState'].value;
    let city = this.empSubForm.controls['city'].value;
    let state = this.empSubForm.controls['stateName'].value;
    if (!this.isOpenGoogleSuggestion) {
      if (`${city},${state}` === cityState) {
        this.isValidCityState = true;
        this.showCitySateErr = false;
        this.updateCityStateSuccessCollection();
        this.validCityFlag = true;
        this.validStateFlag = true;
      }
    }
  }

  private updateCityStateSuccessCollection() {
    this.cityStateSuccessCollection.push('valid');
    if (this.cityStateSuccessCollection.length > 0) {
      this.cityStateErrFlag = true;
    }
  }

  saveExit(obj) {
    // PPA-8745 - Auto-generated via regex - Arun, Supreet, Save n Exit Patch
    var re = this._eventBus.logoutAnnounced({ dummy: "workflow_exit" });
    if (re == undefined) return;
  }

  
  cityState(obj): void {
    this.isCityListToShow = false;
    this.isStateListToShow = false;
    this.isZipListToShow = false;

    if (this.isATSData) {
      this.showCitySateErr = false;
      this.isValidCityState = true;
      this.isOpenGoogleSuggestion = true;
      this.validCityFlag = true;
      this.validStateFlag = true;
    }

    let indexDetail: Object = {};
    indexDetail['row'] = this.row;
    obj['indexDetail'] = indexDetail;
    if (!!this.stateCode) {
      obj['stateCode'] = this.stateCode;
    }
    if (this.currentStatus === 'VISITED') {
      this.currentStatus = 'COMPLETED';
    }

    // check if decision changed
    if (this.isStateChanged) {
      obj['isStateDecisionChanged'] = true;
    }
    this.isStateChanged = false;

    // Update the state
    this.setState();
    obj['COMPONENT_STATE'] = this.payLoad;
    obj['startTime'] = this.startTime;
    this.onCityState.emit(obj);
  }

  private validateCity(value) {
    if (value !== undefined && value != '' && value !== null) {
      this.validCityFlag = true;
    } else {
      this.validCityFlag = false;
    }
  }

  private validateState(value) {
    if (value !== undefined && value != '' && value !== null) {
      this.validStateFlag = true;
    } else {
      this.validStateFlag = false;
    }
  }

  openServerError() {
    this.zipCodeError = true;
  }

  closeServerError() {
    this.zipCodeError = false;
  }

  private stateServerSideSearch() {
    this.stateTypeahead.pipe(
      distinctUntilChanged(),
      filter(term => {  return (term !== undefined && term !== null) }),
      switchMap(term => this.shareService.stateLookupSwitch({ 'countryCode': this.countryShortName, 'stateParam': term })
      .pipe(
        catchError(() => of({ 'state-data-list': [] }))
        )
      )
    ).subscribe(stateData => {
      this.stateList = stateData['state-data-list'];
    }, (err) => {
      console.log(err);
      this.stateList = [];
    });
  }

  private cityServerSideSearch() {
    this.cityTypeahead.pipe(
      distinctUntilChanged(),
      filter(term => {  return (term !== undefined && term !== null) }),
      tap(term => this.setCityValue(term)),
      switchMap(term => this.shareService.cityLookupSwitch({ 'countryCode': this.countryShortName, 'stateCode': (!!this.empSubForm.value.stateCode) ? this.empSubForm.value.stateCode : '', 'cityParam': term })
      .pipe(
        catchError(() => of({ 'city-data-list': [] }))
        )
      )
    ).subscribe(cityData => {
      this.cityList = cityData['city-data-list'];
    }, (err) => {
      console.log(err);
      this.cityList = [];
    });
  }

  private zipCodeServerSideSearch() {
    this.zipCodeTypeahead.pipe(
      distinctUntilChanged(),
      filter(term => {  return (term !== undefined && term !== null) }),
      switchMap(term => this.shareService.zipLookupSwitch({ 'countryCode': this.countryShortName, 'zipParam': !!term ? term : '', 'stateCode': (this.empSubForm.value.stateCode !== '') ? this.stateCode : '', 'placeCode': (this.empSubForm.value.city !== '') ? this.cityCode : '' })
      .pipe(
        catchError(() => of({ 'zipcode-data-list': [] }))
        )
      )
    ).subscribe(zipData => {
      this.zipCodeList = zipData['zipcode-data-list'];
    }, (err) => {
      console.log(err);
      this.zipCodeList = [];
    });
  }

  trackStateChange(value) {
    console.log("state trackChange----");
    let selectedData = !!value ? value : '';
    this.validateState(selectedData['state_name']);
    this.setFieldValues(selectedData);
    let type = 'city';
    this.clearFieldValues(type);
    this.selectedCity = '';
    this.cityList=[];
    this.cityTypeahead.emit('');
  }
  
  onClearStateData() {
    console.log("onClearStateData----");
    (<FormControl>this.empSubForm.controls['stateName']).setValue('');
    (<FormControl>this.empSubForm.controls['stateCode']).setValue('');
    this.stateLookUp('', null);
  }

  trackCityChange(value) {
    console.log("city trackCityChange----");
    let selectedData = !!value ? value : '';
    this.validateCity(selectedData['city']);
    this.setFieldValues(selectedData);
  }

  onClearCityData() {
    console.log("onClearCityData----");
    (<FormControl>this.empSubForm.controls['city']).setValue('');
    this.cityLookUp('');
    this.cityTypeahead.emit('');
  }

  trackZipCodeChange(value) {
    console.log("city trackZipCodeChange----");
    let selectedData = !!value ? value : '';
    this.setFieldValues(selectedData);
  }

  onClearZipCodeData() {
    console.log("onClearZipCodeData----");
    (<FormControl>this.empSubForm.controls['zipCode']).setValue('');
  }

  setCityValue(city) {
    (<FormControl>this.empSubForm.controls['city']).setValue(city);
  }

}
