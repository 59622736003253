import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from 'framework/localstorage.service';
import { SharedService } from 'app/shared/services/shared.service';
import {ActivatedRoute, Router} from '@angular/router';
import { ProfileService } from 'app/profile/profile.service';
import { TranslateService } from '../../../framework/i18n';
import { AppConfig } from 'app/app.config';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from 'environments/environment';
import { WorkflowService } from 'framework/workflow.service';

declare var $: any;
declare var window: any;
@Component({
  selector: 'app-unsupported-device',
  templateUrl: './unsupported-device.component.html',
  styleUrls: ['./unsupported-device.component.css'],
  providers: [ProfileService]
})
export class UnsupportedDeviceComponent implements OnInit {

  url: string;
  inviteSubType: string;
  errorMessage: string;
  invitesubtypeRID: boolean = false;
  firstName: string;
  companyName: string;
  isCaptureDLPrefillEnabled: boolean = false;
  showOptOut: boolean = false;
  showQRScreen: boolean = false;
  unsupportedDeviceVersion: boolean = false;
  deviceVersion: string = '';
  device: string = '';
  timer = 0;


  constructor(private _sharedService: SharedService,
    private localStorage: LocalStorageService,
    private _profileSevice: ProfileService,
    private _router: Router,
    private deviceService:DeviceDetectorService,
              private _activatedRoute: ActivatedRoute,
              private _workflow: WorkflowService) { }

  ngOnInit() {
    this.inviteSubType = this.localStorage.getItem("subType");
    let invite_url = this.localStorage.getItem("invite_url");
    let invite_key = this.localStorage.getItem("invite_key");
    this.firstName = this.localStorage.getItem("first_name");
    this.companyName = this.localStorage.getItem('company_name');
    this.showOptOut = (this.localStorage.getItem('show_opt_out') === 'YES') ? true : false;
    this.getOsVersion();

    if (!!invite_key) {
      this.url = this.getInviteUrl(invite_url, invite_key);
    } else {
      this.url = invite_url || window.location.origin;
    }

    window.track_event('page loaded', { stage_name: 'unsupported', action_value: 'load' }, true, true);
    
    this._activatedRoute.queryParams.subscribe(param => {
      if (param) {
        this.unsupportedDeviceVersion = param["unsupportedDeviceVersion"] as boolean;
      }
    });

    if (this.inviteSubType === AppConfig.RIGHT_ID_ROUTER) { // Rightid + CBSV
      this.invitesubtypeRID = true;
    } else {
      this.invitesubtypeRID = false;  // RIGHTID ONLY
    }
    
    let is_capture_dl_prefill = this.localStorage.getItem('is_capture_dl_prefill');
    if (is_capture_dl_prefill === 'Y'){
      this.isCaptureDLPrefillEnabled = true;
      this.invitesubtypeRID = true;
      
      if (this.showOptOut) {
        window.track_event('show optout option', { stage_name: 'unsupported', action_value: 'load' }, true, true);
        this.timer = 60; //TODO: This value needs to be fetched from table.
        this.showOptOut = false;
        this.triggerTimer(this);
      }
    }
    console.log("UnsupportedDeviceComponent inviteSubType and url ", this.inviteSubType,  this.url);
  }

  private getInviteUrl(invite_url, invite_key): string {
    if(!invite_url) {
      //code added to fix empty QR code defect
      invite_url = window.location.origin;
    }
    console.log("Invite url::"+invite_url);
    let index = invite_url.indexOf("#");
    let inviteUrl = '';
    if (index !== -1) {
      inviteUrl = invite_url.substring(0, index);
    } else {
      inviteUrl = invite_url;
    }
    return inviteUrl + '#/invite/?key=' + invite_key;
  }

  optoutRightId() {
    window.track_event('optout flow selected', { stage_name: 'unsupported', action_value: 'click' }, true, true);
    $('#optoutModal').modal('show');
  }

  processOptOut() {
    window.track_event('optout confirmed', { stage_name: 'unsupported', action_value: 'click' }, true, true);
    window.track_event('rid opted out', { stage_name: 'unsupported', action_value: 'result' }, true, true);
    
    if (!this.isCaptureDLPrefillEnabled) {
      //RightID only
      this.localStorage.setItem('rid_status', 'OPTED_OUT_DEVICE');
    } else {
      //Right ID and Prefill or Prefill Only scenario
      this.localStorage.setItem('import_data_result', 'OPTED_OUT_DEVICE');
      this.localStorage.setItem('import_data_status', 'COMPLETED');
      let isInviteRightIDOnly = this._sharedService.checkInviteIsRightId();
      if(this.invitesubtypeRID || isInviteRightIDOnly) {
        this.localStorage.setItem('rid_status', 'OPTED_OUT_DEVICE');
      }
    }
    
    $('#optoutModal').modal('hide');
    
    const inviteId = this.localStorage.getItem('inviteId');
    if (!!inviteId) {
      $('#consentLoadWaitModal').modal('show');
      let data = {
        'status' : 'OPTED_OUT_DEVICE'
      }

      this._sharedService.updateInviteStatus(data, inviteId).subscribe(
        respone =>  {
          if (this.invitesubtypeRID || this.isCaptureDLPrefillEnabled) {
            this.continuePAFlow();
          } else {
            $('#consentLoadWaitModal').modal('hide');
            window.track_event('thank you page', { stage_name: 'unsupported', action_value: 'redirect' }, true, true);
            this._router.navigate(['/profile/thankyou']);
          }
        }, error => {
          $('#consentLoadWaitModal').modal('hide');
          window.track_event('update invite status', { stage_name: 'unsupported', action_value: 'error' }, true, true);
          console.error('failed to update applicant data');
        }
      );

    }

  }

  continuePAFlow() {

    const sessionState = this.localStorage.getItem('session_state');
    
    if (!!sessionState) {
      window.track_event('continue pa flow', { stage_name: 'unsupported', action_value: 'save' }, true, true);
      this.continueToPAWorfklow();
    } else {
      this.redirectToLoginOrRegister();
    }

  }

  continueToPAWorfklow(){
    let workflow_id = this.localStorage.getItem("workflow_instance_id");

    this._workflow.getWorkFlow(workflow_id).subscribe(response => {
      window.track_event('workflow data', { stage_name: 'unsupported', action_value: 'fetch' }, true, true);
      $('#consentLoadWaitModal').modal('hide');
      this._router.navigate([response['URL']]);
    },
    error => {
      window.track_event('workflow data', { stage_name: 'unsupported', action_value: 'error' }, true, true);
      this.redirectToLoginOrRegister();
      console.log(error);
    });
  }

  redirectToLoginOrRegister(){
    let invite_key = this.localStorage.getItem("invite_key");

    let navigationExtras = {
      queryParams: { 'key': invite_key}
    };
    window.track_event('redirect to login or register', { stage_name: 'unsupported', action_value: 'redirect' }, true, true);
    $('#consentLoadWaitModal').modal('hide');
    this._router.navigate(['/invite/'+invite_key], navigationExtras);
  }

  closeOptOutModal() {
    $('#logout_confirmation_modal').modal('hide');
  }

  nextCard() {
    this.showQRScreen = true;
  }

  getOsVersion() {
    if ((this.deviceService.isMobile() || this.deviceService.isTablet()) && this.deviceService.getDeviceInfo().os.includes('Android')) {
     //this.device = 'Android v9.0';
     this.device = 'Android - v' + environment.minAndroidVersion +'.0';
     this.deviceVersion = this.deviceService.getDeviceInfo().userAgent.match(/android\s([0-9\.]*)/i)[0].toString();
    } else if ((this.deviceService.isMobile() || this.deviceService.isTablet()) && (this.deviceService.getDeviceInfo().os.includes('iOS') || this.deviceService.getDeviceInfo().os.includes('Mac')) ) {
     //this.device = 'ios v12';
     this.device = 'iOS - v' + environment.minIosVersion + '.0';
     let deviceDetails : string[] = this.deviceService.getDeviceInfo().userAgent.match(/OS (\d+)_(\d+)_?(\d+)?/);
     this.deviceVersion = "iOS "+deviceDetails[1] + "." + deviceDetails[2];
    } else  {
      this.deviceVersion = this.deviceService.getDeviceInfo().os_version;
    }
  }

  triggerTimer(obj: any){
    obj.timer = obj.timer - 1;
    if(obj.timer > 0){
        setTimeout(function() {
          obj.triggerTimer(obj);
       },1000)
     }else{
       obj.showOptOut = true;
    }
  }

}
