import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AppConfig } from '../app.config';
import { WorkflowService } from '../../framework/workflow.service';
import { LocalStorageService } from '../../framework/localstorage.service';
import { throwError } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { SharedService } from 'app/shared/services/shared.service';
import { TranslateService } from 'framework/i18n';

declare var window: any;

@Injectable()
export class ProfileService {
  isLoggedIn: boolean = false;
  headerOptions: any;

  constructor(private _http: HttpClient,
    private _workflow: WorkflowService,
    private _localStorage: LocalStorageService,
    private _translate: TranslateService,
  ) {
    this.headerOptions = this._workflow.getHeaderOptions();
  }

  static getSecurityQuestions(): Array<Object> {
    let types = [
      { id: 'secret_question_city', name: 'SECRET_QUESTION_TYPE_CITY' },
      { id: 'secret_question_school', name: 'SECRET_QUESTION_TYPE_SCHOOL' },
      { id: 'secret_question_father', name: 'SECRET_QUESTION_TYPE_FATHER' },
      { id: 'secret_question_mother', name: 'SECRET_QUESTION_TYPE_MOTHER' },
      { id: 'secret_question_pet', name: 'SECRET_QUESTION_TYPE_PET' },
      { id: 'secret_question_spouse', name: 'SECRET_QUESTION_TYPE_SPOUSE' }
    ];
    return types;
  }

  validatePassword(value: Object): Observable<Object> {
    let body = {
      "key": this._localStorage.getItem('key'),
      "password": value['password']
    };

    this.headerOptions = this._workflow.getHeaderOptions();
    return this._http.post(AppConfig.API_ENDPOINT() + '/api/v1/validate-password', body, this.headerOptions)
      .pipe(
        map(res => this._extractData(res)),
        catchError(err => this.handleError('password_policy_not_met', err))
      );
  }

  setPassword(value: Object): Observable<Object> {
    let body = {
      "key": this._localStorage.getItem('key'),
      "profile_id": this._localStorage.getItem('profile_id'),
      "first_name": this._localStorage.getItem('first_name'),
      "last_name": this._localStorage.getItem('last_name'),
      "email": this._localStorage.getItem('applicant_email'),
      "old_email": this._localStorage.getItem('applicant_email'),
      "password": value['password'],
      "secret_question": value['secretQuestion'],
      "secret_answer": value['secretAnswer']
    };

    let traces = {};
    traces['action'] = 'SignUp';
    this.headerOptions = this._workflow.getHeaderOptionsWithTraces(traces);

    return this._http.post(AppConfig.API_ENDPOINT() + '/api/v1/registration', body, this.headerOptions)
      .pipe(
        map(res => this._extractData(res)),
        map(res => this._doActionForRegistration(res)),
        catchError(err => this.handleError('registration_api_failed', err))
      );
  }

  /**
   * do the registration with oidc service
   *
   * @param value
   * @returns
   */
  oidcUserRegistration(value: Object): Observable<Object> {
    this._localStorage.setItem('stateId',  uuidv4());
    console.log('stateId', this._localStorage.getItem('stateId'));

    let mfaValue = !!this._localStorage.getItem('pa_enable_mfa') ? this._localStorage.getItem('pa_enable_mfa'): '';
    
    let body = {
      "key": this._localStorage.getItem('key'),
      "systemId": this._localStorage.getItem('system_id'),
      "firstName": this._localStorage.getItem('first_name'),
      "lastName": this._localStorage.getItem('last_name'),
      "email": this._localStorage.getItem('applicant_email'),
      "oldEmail": this._localStorage.getItem('applicant_email'),
      "password": value['password'],
      "sessionId" : this._localStorage.getItem('stateId'),
      "additionalProperties": {
        "key": this._localStorage.getItem('invite_key'),
        "profile_id" : this._localStorage.getItem('profile_id'),
        "invite_id" :  this._localStorage.getItem('invite_id'),
        "email": this._localStorage.getItem('applicant_email'),
        "custom_styles": this._localStorage.getItem('custom_styles'),
        "language": this._localStorage.getItem('language'),
        "language_country_code": this._localStorage.getItem('language_country_code'),
        "enableMfa": mfaValue
      }
    };

    let traces = {};
    traces['action'] = 'SignUp';
    this.headerOptions = this._workflow.getHeaderOptionsWithTraces(traces);

    return this._http.post(AppConfig.API_ENDPOINT() + '/api/v1/authn/registration', body, this.headerOptions)
      .pipe(
        map(res => this._extractData(res)),
        map(res => this._doOidcActionForRegistration(res)),
        catchError(err => this.handleError('oidc registration_api_failed', err))
      );
  }

  handleError(eventName, error) {
    console['server'](eventName, error);

    if (error.status === 500) {
      console.log('ERROR CODE 500');
      return throwError(new Error(error.status));
    } else if (error.status === 400) {
      console.log('ERROR CODE 400');
      return throwError(new Error(error.status));
    } else if (error.status === 409) {
      console.log('ERROR CODE 409');
      return throwError(new Error(error.status));
    } else if (error.status === 406) {
      console.log('ERROR CODE 406');
      return throwError(new Error(error.status));
    } else if (error.status === 502) {
      console.log('ERROR CODE 502');
      return throwError(new Error(error.status));
    } else if (error.status === 404) {
      console.log('ERROR CODE 404');
      return throwError(new Error(error.status));
    } else {
      return throwError(new Error(error.status));
    }
  }

  getlanguageList() {
    return this._localStorage.getItem('allowed_language');
  }

  getProfile(value: any): Observable<Object> {
    this.headerOptions = this._workflow.getHeaderOptions();

    return this._http.get(AppConfig.API_ENDPOINT() + '/api/v1/profile/' + value + '/?t=' + new Date(), this.headerOptions)
      .pipe(
        map(res => this._extractData(res)),
        map(res => this._doAction(res)),
        catchError(err => this.handleError('get_profile_failed', err))
      );
  }

  putInviteWithSelectedCountry(countryCode): Observable<Object> {
    let inviteBody = {
      'key': this._localStorage.getItem('key'),
      'current_address_country': countryCode
    };

    let traces = {};
    traces['action'] = 'WorkflowStarted';
    this.headerOptions = this._workflow.getHeaderOptionsWithTraces(traces);

    return this._http
      .put(AppConfig.API_ENDPOINT() + `/api/v1/invite`, inviteBody, this.headerOptions)
      .pipe(
        map(res => res),
        catchError(err => this._handleCountryError('invite_selected_country_failed', err))
      );
  }

  inviteByProfile(profileId: string): Observable<Object> {
    /*
     * Need to make the HTTP GET API call with Token and will return
     * 410 - expired - > Login
     * 200 - > Set Password
    */

    return this._http
      .get(AppConfig.API_ENDPOINT() + `/api/v1/profile-lookup/invite?profileId=${profileId}`, this.headerOptions)
      .pipe(
        map(res => this._extractData(res)),
        map(res => this._doInviteByProfileAction(res)),
        catchError(err => this._handleError('invite_by_profile_failed', err))
      );
  }

  inviteById(inviteId: any): Observable<Object> {
    /*
     * Need to make the HTTP GET API call with Token and will return
     * 410 - expired - > Login
     * 200 - > Set Password
    */

    return this._http
      .get(AppConfig.API_ENDPOINT() + `/api/v1/invite/` + inviteId, this.headerOptions)
      .pipe(
        map(res => this._extractData(res)),
        map(res => this._doInviteByProfileAction(res)),
        catchError(err => this._handleError('invite_by_id_failed', err))
      );
  }

  unsubscribeFromSmsByInviteKey(inviteKey: string): Observable<Object> {
    return this._http
      .put(AppConfig.API_ENDPOINT() + `/api/web/invite/unsubscribe/` + inviteKey, {})
      .pipe(
        map(res => this._extractData(res)),
        map(res => this._doUnsubscribeSMSAction(res)),
        catchError((error: any) => {
          console['server']('unsubscribe_sms_invite_failed', error);
          // console.log('ERROR CODE ' + error.status);
          return throwError(new Error(error.status));
        }));
  }

  getUnsubscribeFromSmsByInviteKey(inviteKey: string): Observable<Object> {
    return this._http
      .get(AppConfig.API_ENDPOINT() + `/api/web/invite/unsubscription/` + inviteKey)
      .pipe(
        map(res => this._extractData(res)),
        map(res => this._doUnsubscribeSMSAction(res)),
        catchError((error: any) => {
          console['server']('get_unsubscription_failed', error);
          // console.log('ERROR CODE ' + error.status);
          return throwError(new Error(error.status));
        }));
  }

  updateSecurityInfo(email: string, secret_question: string, secret_answer: string): Observable<Object> {
    console.log("update security Info for ----:" + email + ":" + secret_question + ":" + secret_answer + ":");
    let body = {
      "email": email,
      "secret_question": secret_question,
      "secret_answer": secret_answer
    };
    this.headerOptions = this._workflow.getHeaderOptions();

    return this._http.put(AppConfig.API_ENDPOINT() + '/api/v1/registration/security', body, this.headerOptions)
      .pipe(
        map(res => this._extractData(res)),
        map(res => this._doProfileAction(res)),
        catchError(err => this.handleError('update_security_info_failed', err)));
  }

  getSecurityInfo(email: string): Observable<Object> {
    let body = {
      "email": email
    };
    this.headerOptions = this._workflow.getHeaderOptions();
    
    return this._http.post(AppConfig.API_ENDPOINT() + '/api/v1/registration/security', body, this.headerOptions)
      .pipe(
        map(res => this._extractData(res)),
        map(res => this._doGetSecurityAction(res)),
        catchError(err => this.handleError('get_security_info_failed', err)));

  }

  private _doGetSecurityAction(response: Object) {
    this._localStorage.setItem('secret_question', response['secret_question']);

    return response;
  }

  private _extractData(res: Object) {
    return (res) || {};
  }

  private _doAction(response: Object) {
    if (this._localStorage.getItem('profile_id') == null) {
      this._localStorage.setItem('profile_id', response["attributes"]['profile_id'][0]);
    }
    this.isLoggedIn = true;

    return response;
  }

  private _doOidcActionForRegistration(response: Object) {
    console.log('res is: ', response);
    if(!!response && !!response['systemId'] && !!response['stateToken'] && !!response['status'] && !!response['factors'] && !!response['factors']['0'] && !!response['user']) {
      this._localStorage.setItem("oidc_lgn_res",response);
      this._localStorage.setItem('oidc_st',response['stateToken']);
      this._localStorage.setItem("profile_id",(response['systemId'] || response['system_id']));

      return response['stateToken'];
    }
    else if (!!response && !!response['accessToken']) {
      if (this._localStorage.getItem('profile_id') == null) {
        this._localStorage.setItem('profile_id', response["attributes"]['additionalProperties']['profile_id'][0]);
      }
      
      this._localStorage.setItem('access_token', response['accessToken']);
      this._localStorage.setItem('session_state', response['sessionState']);
  
      this.isLoggedIn = true;
      this._workflow.getHeaderOptions();
  
      return response;
    }
    else {
      return null;
    }
  }

  private _doActionForRegistration(response: Object) {
    if (this._localStorage.getItem('profile_id') == null) {
      this._localStorage.setItem('profile_id', response["attributes"]['profile_id'][0]);
    }

    this._localStorage.setItem('access_token', response['access_token']);
    this._localStorage.setItem('session_state', response['session_state']);
    console.log("session_state :", response['session_state']);

    this.isLoggedIn = true;
    this._workflow.getHeaderOptions();

    return response;
  }

  private _doProfileAction(response: Object) {
    return response;
  }

  private _doInviteByProfileAction(response: Object) {
    this._localStorage.setItem('inviteType', response['type']);
    this._localStorage.setItem('subType', response['subType']);
    this._localStorage.setItem('key', response['key']);
    this._localStorage.setItem('instance', response['instance']);
    this._localStorage.setItem('inviteId', response['id']);
    this._localStorage.setItem('invite_id', response['id']);
    this._localStorage.setItem('profile_id', response['profile_id']);
    this._localStorage.setItem('system_id', response['system_id']);
    this._localStorage.setItem('applicant_email', response['applicant']['email']);
    this._localStorage.setItem('first_name', response['applicant']['first_name']);
    this._localStorage.setItem('last_name', response['applicant']['last_name']);
    this._localStorage.setItem('company_name', response['account']['name']);
    this._localStorage.setItem('is_report_on', response['account']['is_report_on']);
    this._localStorage.setItem('account_id', response['account']['id']);
    this._localStorage.setItem('middle_name', response['applicant']['middle_initial']);
    this._localStorage.setItem('wps_number', response['applicant']['provider_ref_number']);
    this._localStorage.setItem('is_gender_mandatory', response['applicant']['is_gender_mandatory']);
    this._localStorage.setItem('invite_app_status', '');
    this._localStorage.setItem('da_url', response['da_url']);
    this._localStorage.setItem('applicant_status', response['applicant']['status']);
    this._localStorage.setItem('f_name', response['applicant']['first_name']);
    this._localStorage.setItem('rid_status', response['account']['rid_status']);
    this._localStorage.setItem('place_search_api', response['account']['place_search_api']);
    this._localStorage.setItem('SMS_OPTOUT_ENABLED', response['account']['SMS_OPTOUT_ENABLED']);
    this._localStorage.setItem('iso_country_code', response['identity_current_residence_country']);
    this._localStorage.setItem('lock_current_country', response['lock_current_residence_country']);


    this._localStorage.setItem('import_data_status', response['account']['import_data_status']);
    this._localStorage.setItem('import_data_result', response['account']['import_data_result']);
    if (!!response["package"]) {
      this._localStorage.setItem('package_name', response["package"]['name']);
      this._localStorage.setItem('package_components', response["package"]['distinct_components']);
      if (!!response["package"]["scheme"]) {
        this._localStorage.setItem('scheme', response["package"]["scheme"]);
      }
    }

    this._localStorage.setItem('is_international', response["account"]["is_intl"]);
    // Set preference value 1101
    this._localStorage.setItem('isIntlPreferenceOn', response['account']['is_intl']);

    if (response['applicant'] !== undefined && response['applicant']['data'] !== undefined
      && response['applicant']['data']['APPLICANT_STATUS'] !== undefined) {
      this._localStorage.setItem('review_status', response['applicant']['data']['APPLICANT_STATUS']);
    }

    if (response['applicant']['middle_initial'] === undefined) {
      this._localStorage.setItem('m_name', '');
    } else {
      this._localStorage.setItem('m_name', response['applicant']['middle_initial']);
    }

    this._localStorage.setItem('l_name', response['applicant']['last_name']);
    this._localStorage.setItem('pa_dot_direct_preference', response['account']['pa_dot_direct_preference']);
    this._localStorage.setItem('pa_dot_drug_order_preference', response['account']['pa_dot_drug_order_preference']);

    if (response['account']['pa_dot_limit_employment_verification']) {
      this._localStorage.setItem('pa_dot_limit_employment_verification', response['account']['pa_dot_limit_employment_verification']);
    }

    if (response['account']['reason_for_leaving_mandatory']) {
      this._localStorage.setItem('reason_for_leaving_mandatory', response['account']['reason_for_leaving_mandatory']);
    }

    if (response['account']['ea_account_bg_report_completion_eta']) {
      this._localStorage.setItem('ea_account_bg_report_completion_eta', response['account']['ea_account_bg_report_completion_eta']);
    }

    if (response['account']['pa_lock_pre_population_for_custom_form_fields']) {
      this._localStorage.setItem('pa_lock_pre_population_for_custom_form_fields', response['account']['pa_lock_pre_population_for_custom_form_fields']);
    }

    if (response['account']['is_ea_rightId']) {
      this._localStorage.setItem('is_ea_rightId', response['account']['is_ea_rightId']);
    }

    // Set filter eduction type preference
    if (response['account']['pa_filter_education_types']) {
      this._localStorage.setItem('pa_filter_education_types', response['account']['pa_filter_education_types']);
    }

    if (response['account']['is_candidate_age_based_scope']) {
      this._localStorage.setItem('is_candidate_age_based_scope', response['account']['is_candidate_age_based_scope']);
    }

    if (!!response['current_address_country']) {
      this._localStorage.setItem('current_address_country', response['current_address_country']);
    }

    if (!!response['is_country_based_consent']) {
      this._localStorage.setItem('is_country_based_consent', response['is_country_based_consent']);
    }

    if (!!response['disable_tos']) {
      this._localStorage.setItem('disable_tos', true);
    }

    if (response['account']['country_of_residence_pref_value']) {
      this._localStorage.setItem('country_of_residence_pref_value', response['account']['country_of_residence_pref_value']);
    }

    if (response['account']['pa_lock_down_name']) {
      this._localStorage.setItem('pa_lock_down_name', response['account']['pa_lock_down_name']);
    }
    if (response['account']['pa_do_not_override_name']) {
      this._localStorage.setItem('pa_do_not_override_name', response['account']['pa_do_not_override_name']);
    }

    if (response['account']['user_alert_on_error']) {
      this._localStorage.setItem('user_alert_on_error', response['account']['user_alert_on_error']);
    }

    if (response['account']['use_font_based_signature']) {
      this._localStorage.setItem('use_font_based_signature', response['account']['use_font_based_signature']);
    }

    if (response['account']['SIGNATURE_FONT']) {
      this._localStorage.setItem('SIGNATURE_FONT', response['account']['SIGNATURE_FONT']);
    }

    if (response['account']['enable_customer_feedback_survey']) {
      this._localStorage.setItem('enable_customer_feedback_survey', response['account']['enable_customer_feedback_survey']);
    }

    if (response['account']['next_gen_customer_feedback']) {
      this._localStorage.setItem('next_gen_customer_feedback', response['account']['next_gen_customer_feedback']);
    }

    if (!!response['account'] && !!response['account']['enable_mfa'] && response['account']['enable_mfa'].toUpperCase()) {
      this._localStorage.setItem('pa_enable_mfa', response['account']['enable_mfa']);

      if (response['account']['pa_user_auth_model']) {
        this._localStorage.setItem('user_auth_model', response['account']['pa_user_auth_model']);
      }
    }

    if (!!response['is_survey_captured']) {
      this._localStorage.setItem('is_survey_captured', response['is_survey_captured']);
    }

    this._localStorage.setItem("selected_invite_notification", response['notification']);

    if (!!response && !!response['type'] && response['type'].toUpperCase() === 'CONSENT' && !!response['account'] && !!(response['account']['setting_form_enable_electronic_signature_only'])) {
      this._localStorage.setItem('setting_form_enable_electronic_signature_only', response['account']['setting_form_enable_electronic_signature_only']);
    }

    if (!!response && !!response['custom_styles']) {
      this._localStorage.setItem('custom_styles', response['custom_styles']);
    }

    if (!!response && response['account']['is_salary_enabled']) {
      this._localStorage.setItem('is_salary_enabled', response['account']['is_salary_enabled']);
    }

    if (!!response && !!response['account'] && !!response['account']['support_chat_experience']) {
      this._localStorage.setItem('support_chat_experience', response['account']['support_chat_experience']);
    }

    if (!!response && !!response['account'] && !!response['account']['enhanced_dashboard']) {
      this._localStorage.setItem('enhanced_dashboard', response['account']['enhanced_dashboard']);
    }

    if (!!response && !!response['account'] && !!response['account']['credit_card_pay_enabled']) {
      this._localStorage.setItem('credit_card_pay_enabled', response['account']['credit_card_pay_enabled']);
    }
    if (!!response && !!response['account'] && !!response['account']['payment_partner_pref_value']) {
      this._localStorage.setItem('payment_partner_pref_value', response['account']['payment_partner_pref_value']);
    }

    if (!!response && !!response['applicant'] && !!response['applicant']['pending_info_due_date']) {
      this._localStorage.setItem('pending_info_due_date', response['applicant']['pending_info_due_date']);
    }

    if (!!response && !!response['applicant'] && !!response['applicant']['id']) {
      this._localStorage.setItem('applicant_id', response['applicant']['id']);
    }

    if (!!response && !!response['account'] && !!response['account']['azure_cognitive_search_enabled']) {
      this._localStorage.setItem('azure_cognitive_search_enabled', response['account']['azure_cognitive_search_enabled']);
    }

    if (!!response && !!response['account'] && response['account']['lookup_service_url']) {
      this._localStorage.setItem('lookup_service_url', response['account']['lookup_service_url']);
    }

    if (!!response && !!response['account'] && response['account']['show_biometric_consent']) {
      this._localStorage.setItem('show_biometric_consent', response['account']['show_biometric_consent']);
    }
    if (!!response && !!response['account'] && response['account']['is_fp_enabled']) {
      this._localStorage.setItem('is_fp_enabled', response['account']['is_fp_enabled']);
    }
    if (!!response && !!response['account'] && !!response['account']['accent_character_translation_enabled']) {
      this._localStorage.setItem('accent_character_translation_enabled', response['account']['accent_character_translation_enabled']);
    }

    if (!!response && !!response['account'] && response['account']['Switch_url']) {
      this._localStorage.setItem('switch_url', response['account']['Switch_url']);
    }
    if (!!response && !!response['account'] && response['account']['enable_yoti_with_switch']) {
      this._localStorage.setItem('enable_yoti_with_switch', response['account']['enable_yoti_with_switch']);
    }

    if (response['show_language_selection'] !== undefined) {
      this._localStorage.setItem('show_language_selection', response['show_language_selection']);
    }

    if (!!response && !!response['account'] && !!response['account']['rid_retry_count']) {
      this._localStorage.setItem('rid_retry_count', parseInt(response['account']['rid_retry_count']));
    }

    if (!!response && !!response['account'] && response['account']['esignature_min_size']) {
      this._localStorage.setItem('esignature_min_size', parseInt(response['account']['esignature_min_size']));
    }
    
    if (!!response['account']['enable_collect_edu'] && response['account']['enable_collect_edu']) {
      this._localStorage.setItem('enable_collect_edu', response['account']['enable_collect_edu']);
    }

    // 6565 / 6567 - Dispatch selfie preference.
    let dispatchSelfie = 'N';
    if (response['account']['is_dispatch_rightid_selfie']) {
      dispatchSelfie = 'Y';
    }
    this._localStorage.setItem('is_dispatch_rightid_selfie', dispatchSelfie);

    // 6522 / 6701 - SSN Mandatory flag account level for ATS
    let ssnMandatory = 'N';
    if (response['account']['is_ssn_mandatory'] === 'Y') {
      ssnMandatory = 'Y'
    }
    this._localStorage.setItem('is_ssn_mandatory', ssnMandatory);

    // 6565 / 6567 Check for dispatch selfie msg.
    // TODO: Needs to be revisted. Only falls through to language file entry for now.
    let dispatchMsg = response['account']['dispatch_selfie_msg'];
    if (dispatchMsg) {
      this._localStorage.setItem('dispatch_selfie_msg', dispatchMsg);
    } else {
      dispatchMsg = this._translate.instant('DISPATCH_SELFIE_MSG');
      this._localStorage.setItem('dispatch_selfie_msg', dispatchMsg);
    }

    if (AppConfig.isFeatureAvailable('app-features', 'contact-us')
      && this._localStorage.getItem('active_chat') !== undefined
      && this._localStorage.getItem('active_chat') === 'N') {
      if (response['account'] !== undefined && response['account']['contactus_text'] !== undefined) {
        this._localStorage.setItem('contactus', response['account']['contactus_text']);
      }
    }

    let packageName = response["package"] !== undefined ? response['package']['name'] : '';

    this._localStorage.setItem('package_name', packageName);

    return response;
  }

  private _doUnsubscribeSMSAction(response: Object) {
    return response;
  }

  forgotPassword(emailID): Observable<Object> {
    this._localStorage.setItem('applicant_email', emailID);
    let lang = this._localStorage.getItem('language') !== undefined ? this._localStorage.getItem('language') : "";

    let body = {
      "email": emailID,
      "language": lang
    }
    return this._http
      .post(AppConfig.API_ENDPOINT() + `/api/v1/password/forgot`, body)
      .pipe(
        map(res => this._extractDataForForgotPassword(res)),
        map(res => this._doActionForForgotPassword(res)),
        catchError(err => this._handleError('forgot_passwrd_failed', err)));
  }

  private _extractDataForForgotPassword(res: Object) {
    return (res) || {};
  }

  private _doActionForForgotPassword(response: Object) {
    this._localStorage.setItem('otp', response['otp']);

    return response;
  }

  resetPassword(value): Observable<Object> {
    let body = {
      "otp": this._localStorage.getItem('otp'),
      "email": value['email'],
      "new_password": value['password'],
      "secret_question": value['secretQuestion'],
      "secret_answer": value['secretAnswer']
    };

    return this._http
      .post(AppConfig.API_ENDPOINT() + `/api/v1/password/reset`, body, this.headerOptions)
      .pipe(
        map(res => this._extractDataForResetPassword(res)),
        map(res => this._doActionForResetPassword(res)),
        catchError((error: any) => {
          let errorMessage: string;
          console['server']('reset_pwd_failed', error);

          switch (error.status) {
            case 400:
                errorMessage = '400_ERROR';
                break;
            case 409:
              errorMessage = '409_ERROR';
              break;
            case 500:
              errorMessage = 'HEAVY LOAD';
              break;
            case 401:
              let _body = error || {};
              errorMessage = error.status + ":" + _body['error']['error'];
              break;
            case 410:
              //Bad request
              errorMessage = 'KEY EXPIRED';
              break;
            case 404:
              break;
            case 423:
              errorMessage = 'ACCOUNT_LOCKED';
              break;
          }
          return throwError(errorMessage);
        })
      );
  }

  private _extractDataForResetPassword(res: Object) {
    return (res) || {};
  }

  private _doActionForResetPassword(response: Object) {
    return response;
  }

  validateOtp(otp: string): Observable<Object> {
    this._localStorage.setItem('otp', otp);

    return this._http.get(AppConfig.API_ENDPOINT() + '/api/v1/password/otp/' + otp + '/?t=' + new Date(), this.headerOptions)
      .pipe(
        map(res => this._extractDataForValidatePassword(res)),
        map(res => this._doActionForValidatePassword(res)),
        catchError(err => this._handleError('validate_otp_failed', err)));
  }

  private _extractDataForValidatePassword(res: Object) {
    return (res) || {};
  }

  private _doActionForValidatePassword(response: Object) {
    this._localStorage.setItem('applicant_email', response['email']);
    return response;
  }

  private _handleError(eventName, error: any, isLogError?: boolean) {
    let _body = error || {};
    let errorMessage: string;

    if (error.status !== 410)
      console['server'](eventName, error);

    switch (error.status) {
      case 400:
          errorMessage = '400_ERROR';
          break;
      case 409:
        errorMessage = '409_ERROR';
        break;
      case 500:
        errorMessage = 'HEAVY LOAD';
        break;
      case 401:
      case 410:
        //Bad request
        errorMessage = 'INVITE EXPIRED';
        break;
      case 404:
        break;
    }

    if(!errorMessage && !!error.errorCode) {
      errorMessage = error.errorCode;
    }

    return throwError(errorMessage);
  }

  private _handleCountryError(eventName, error: any) {
    let errorMessage: string;
    console['server'](eventName, error);

    switch (error.status) {
      case 400:
        errorMessage = '400 REQUEST';
        break;
      case 401:
        break;
      case 404:
        break;
    }
    return throwError(errorMessage);
  }

  validatePasswordLogic(applicantEmail: any, password: string): Promise<any> {
    let body = {
      "key": applicantEmail,
      "password": password
    };

    this.headerOptions = this._workflow.getHeaderOptions();
    return this._http
    .post(AppConfig.API_ENDPOINT() + '/api/v1/validate-password/validate-app-id', body, this.headerOptions)
    .pipe(
      map(async res => await this._extractData(res)),
      catchError((error: any) => {
        let errorMessage: string;

        switch (error.status) {
          case 400:
            errorMessage = 'INVALID_PASSWORD';
            break;
        }
        return throwError(errorMessage);
      })
    ).toPromise();
  }

  getUserApprovedFactorsForPasswordRecovery(systemId: string) {
    return this._http.get(AppConfig.API_ENDPOINT() + '/api/v1/authn/fetch-factors/' + systemId)
      .pipe(
        map((response) => response),
        map((response) => response),
        catchError((err) => err)
      );
  }
}
