/*
 * Application wide mapping requirements
 *  1. Menu mapping
 *  2. Modules' Constants mapping
 *  3. Version
 *
*/

/*
 * app.config.ts to use features.config.ts to determine the feature on | off
*/

import { FeatureConfig } from './features.config';
import { AppConstants } from './app.constants';
import { LanguageConstants } from './language.constants';
import { AppURLs } from './app.urls';

import { environment } from '../environments/environment';
import { Injectable } from "@angular/core";

@Injectable()
export class AppConfig {
  // Environment identifier for Document upload header switch
  static ENVIRONMENT: string = 'INTL';

  // Language precedence to be applicable to the application
  static LANGUAGE_PRECENDENCE = 'invite';

  // Switch = true will turn on Doc Upload
  static switchDocumentUpload: boolean = false;

  // Application Level Constansts
  static APP_CONSTANTS = AppConstants;

  // Application Level URLS
  static APP_URLS = AppURLs;

  // Application Level Language
  static LANG_CONSTANTS = LanguageConstants;

  static RIGHT_ID_ROUTER = 'RightIDRouter';

  static SYSTEM = 'PA2';

  static menus = {
    'main-data-v1': {
      'DISPLAY_NAME': 'MENU_ITEM_1',
      'LINK': '/home/my-data',
      'MENU_IMG': '../../assets/img/import.png',
      'ICON_CLASS': 'icon-users',
      'STEPS': ['import-step-v1', 'auto-mvr-step-v1', 'name-step-v1', 'dob-step-v1'],
      'ORDER': 0,
      'VISIBLE': false
    },
    'residential-data-v1': {
      'DISPLAY_NAME': 'MENU_ITEM_2',
      'LINK': '/home/residential-data',
      'MENU_IMG': '../../assets/img/import.png',
      'ICON_CLASS': 'icon-residential-data',
      'STEPS': ['address-step-v1'],
      'ORDER': 0,
      'VISIBLE': false
    },
    'employment-data-v1': {
      'DISPLAY_NAME': 'MENU_ITEM_3',
      'LINK': '/home/employment',
      'MENU_IMG': '../../assets/img/consent.png',
      'ICON_CLASS': 'icon-work-experience',
      'STEPS': ['employment-step-v1'],
      'ORDER': 0,
      'VISIBLE': false
    },
    'education-data-v1': {
      'DISPLAY_NAME': 'MENU_ITEM_4',
      'LINK': '/home/education',
      'MENU_IMG': '../../assets/img/consent.png',
      'ICON_CLASS': 'icon-education-data',
      'STEPS': [],
      'ORDER': 0,
      'VISIBLE': false
    },
    'mvr-data-v1': {
      'DISPLAY_NAME': 'MENU_ITEM_5',
      'LINK': '/home/mvr',
      'MENU_IMG': '../../assets/img/consent.png',
      'ICON_CLASS': 'icon-driver-license',
      'STEPS': [],
      'ORDER': 0,
      'VISIBLE': false
    },
    'identification-data-v1': {
      'DISPLAY_NAME': 'MENU_ITEM_6',
      'LINK': '/home/personal-data',
      'MENU_IMG': '../../assets/img/personal data.png',
      'ICON_CLASS': 'icon-fingerprint-with-password-entry',
      'STEPS': ['identity-step-v1'],
      'ORDER': 0,
      'VISIBLE': false
    },
    'agreement-v1': {
      'DISPLAY_NAME': 'MENU_ITEM_7',
      'LINK': '/home/consent',
      'MENU_IMG': '../../assets/img/consent.png',
      'ICON_CLASS': 'icon-your-consent',
      'STEPS': ['consent-step-v1', 'disclosure-step-v1'],
      'ORDER': 0,
      'VISIBLE': false
    },
    'rightId-agreement-data-v1': {
      'DISPLAY_NAME': 'MENU_ITEM_30',
      'LINK': '/home/consent',
      'MENU_IMG': '../../assets/img/consent.png',
      'ICON_CLASS': 'icon-your-consent',
      'STEPS': ['consent-step-v1', 'disclosure-step-v1'],
      'ORDER': 0,
      'VISIBLE': false
    },
    'contact-data-v1': {
      'DISPLAY_NAME': 'MENU_ITEM_8',
      'LINK': '/home/contact',
      'MENU_IMG': '../../assets/img/consent.png',
      'ICON_CLASS': 'icon-contact-icon',
      'STEPS': ['contact-step-v1'],
      'ORDER': 0,
      'VISIBLE': false
    },
    'reviewedit-data-v1': {
      'DISPLAY_NAME': 'MENU_ITEM_9',
      'LINK': '/home/review/show-all',
      'MENU_IMG': '../../assets/img/consent.png',
      'ICON_CLASS': 'icon-eye-scan',
      'STEPS': ['reviewedit-step-v1'],
      'ORDER': 0,
      'VISIBLE': false
    },
    'crfudf-data-v1': {
      'DISPLAY_NAME': 'MENU_ITEM_10',
      'LINK': '/home/additional-data',
      'MENU_IMG': '../../assets/img/consent.png',
      'ICON_CLASS': 'icon-search',
      'STEPS': ['crfudf-step-v1'],
      'ORDER': 0,
      'VISIBLE': false
    },
    'criminal-data-v1': {
      'DISPLAY_NAME': 'MENU_ITEM_11',
      'LINK': '/home/criminal-data',
      'MENU_IMG': '../../assets/img/consent.png',
      'ICON_CLASS': 'icon-balance',
      'STEPS': ['criminal-step-v1'],
      'ORDER': 0,
      'VISIBLE': false
    },
    'licence-data-v1': {
      'DISPLAY_NAME': 'MENU_ITEM_12',
      'LINK': '/home/licence-data',
      'MENU_IMG': '../../assets/img/consent.png',
      'ICON_CLASS': 'icon-identification-data',
      'STEPS': ['licence-step-v1'],
      'ORDER': 0,
      'VISIBLE': false
    },
    'reference-data-v1': {
      'DISPLAY_NAME': 'MENU_ITEM_13',
      'LINK': '/home/reference-data',
      'MENU_IMG': '../../assets/img/consent.png',
      'ICON_CLASS': 'icon-customer',
      'STEPS': ['reference-step-v1'],
      'ORDER': 0,
      'VISIBLE': false
    },
    'wotc-data-v1': {
      'DISPLAY_NAME': 'MENU_ITEM_14',
      'LINK': '/home/wotc',
      'MENU_IMG': '../../assets/img/wotc.png',
      'ICON_CLASS': 'icon-customer',
      'STEPS': [],
      'ORDER': 0,
      'VISIBLE': false
    },
    'documentupload-data-v1': {
      'DISPLAY_NAME': 'MENU_ITEM_15',
      'LINK': '/home/document-upload',
      'MENU_IMG': '../../assets/img/document_upload.png',
      'ICON_CLASS': 'icon-text-file',
      'STEPS': ['documentupload-step-v1'],
      'ORDER': 0,
      'VISIBLE': false
    },
    'international-data-v1': {
      'DISPLAY_NAME': 'MENU_ITEM_16',
      'LINK': '/home/international',
      'MENU_IMG': '../../assets/img/consent.png',
      'ICON_CLASS': 'icon-search',
      'STEPS': ['international-step-v1'],
      'ORDER': 0,
      'VISIBLE': false
    },
    'eiaf-main-data-v1': {
      'DISPLAY_NAME': 'MENU_ITEM_17',
      'LINK': '/home/eiaf-main-data',
      'MENU_IMG': '../../assets/img/consent.png',
      'ICON_CLASS': 'icon-users',
      'STEPS': ['eiaf/name-step-v1'],
      'ORDER': 0,
      'VISIBLE': false
    },
    'eiaf-criminal-data-v1': {
      'DISPLAY_NAME': 'MENU_ITEM_18',
      'LINK': '/home/eiaf-criminal-data',
      'MENU_IMG': '../../assets/img/consent.png',
      'ICON_CLASS': 'icon-search',
      'STEPS': ['eiaf/criminal-step-v1'],
      'ORDER': 0,
      'VISIBLE': false
    },
    'eiaf-circumstance-data-v1': {
      'DISPLAY_NAME': 'MENU_ITEM_19',
      'LINK': '/home/eiaf-circumstance-data',
      'MENU_IMG': '../../assets/img/consent.png',
      'ICON_CLASS': 'icon-users',
      'STEPS': ['eiaf/circumstance-step-v1'],
      'ORDER': 0,
      'VISIBLE': false
    },
    'self-schedule-data-v1': {
      'DISPLAY_NAME': 'MENU_ITEM_20',
      'LINK': '/home/self-schedule',
      'MENU_IMG': '../../assets/img/document_upload.png',
      'ICON_CLASS': 'icon-text-file',
      'STEPS': ['self-schedule-step-v1'],
      'ORDER': 0,
      'VISIBLE': false
    },
    'online-schedule-data-v1': {
      'DISPLAY_NAME': 'MENU_ITEM_22',
      'LINK': '/home/online-schedule',
      'MENU_IMG': '../../assets/img/document_upload.png',
      'ICON_CLASS': 'icon-text-file',
      'STEPS': ['online-schedule-step-v1'],
      'ORDER': 0,
      'VISIBLE': false
    },
    'confirm-data-v1': {
      'DISPLAY_NAME': 'MENU_ITEM_21',
      'LINK': '/home/confirm',
      'MENU_IMG': '../../assets/img/document_upload.png',
      'ICON_CLASS': 'icon-text-file',
      'STEPS': ['confirm-step-v1'],
      'ORDER': 0,
      'VISIBLE': false
    },
    'rightid-data-v1': {
      'DISPLAY_NAME': 'MENU_ITEM_31',
      'LINK': '/identity',
      'MENU_IMG': '../../assets/img/document_upload.png',
      'ICON_CLASS': 'icon-text-file',
      'STEPS': ['rightid-step-v1'],
      'ORDER': 0,
      'VISIBLE': false
    },
    'import-data-v1': {
      'DISPLAY_NAME': 'MENU_ITEM_32',
      'LINK': '/home/import-data',
      'MENU_IMG': '../../assets/img/document_upload.png',
      'ICON_CLASS': 'icon-text-file',
      'STEPS': ['import-data-step-v1'],
      'ORDER': 0,
      'VISIBLE': false
    },
    'missing-info-data-v1': {
      'DISPLAY_NAME': 'MENU_ITEM_24',
      'LINK': '/home/dynamic-information-capture',
      'MENU_IMG': '../../assets/img/document_upload.png',
      'ICON_CLASS': 'icon-text-file',
      'STEPS': ['missing-info-step-v1'],
      'ORDER': 0,
      'VISIBLE': false
    },
    'wotc-personal-data-v1': {
      'DISPLAY_NAME': 'MENU_ITEM_17',
      'LINK': '/home/wotc-personal-data',
      'MENU_IMG': '../../assets/img/consent.png',
      'ICON_CLASS': 'icon-users',
      'STEPS': ['wotc-personal-step-v1'],
      'ORDER': 0,
      'VISIBLE': false
    },
    'payment-information-data-v1': {
      'DISPLAY_NAME': 'MENU_ITEM_27',
      'LINK': '/home/payment',
      'MENU_IMG': '../../assets/img/consent.png',
      'ICON_CLASS': 'icon-checkout',
      'STEPS': ['payment-information-step-v1'],
      'ORDER': 0,
      'VISIBLE': false
    },
    'bandwidth-check-data-v1': {
      'DISPLAY_NAME': 'MENU_ITEM_28',
      'LINK': '/home/bandwidth-check',
      'MENU_IMG': '../../assets/img/consent.png',
      'ICON_CLASS': 'icon-gauge-meter',
      'STEPS': ['bandwidth-check-step-v1'],
      'ORDER': 0,
      'VISIBLE': false
    },
    'self-declaration-form-data-v1': {
      'DISPLAY_NAME': 'MENU_ITEM_29',
      'LINK': '/home/self-declaration-form',
      'MENU_IMG': '../../assets/img/consent.png',
      'ICON_CLASS': 'icon-search',
      'STEPS': ['self-declaration-form-step-v1'],
      'ORDER': 0,
      'VISIBLE': false
    },
    'id-verification-data-v1': {
      'DISPLAY_NAME': 'MENU_ITEM_34',
      'LINK': '/home/id-verification',
      'MENU_IMG': '../../assets/img/import.png',
      'ICON_CLASS': 'icon-text-file',
      'STEPS': ['idv-profile-step-v1', 'idv-kbq-step-v1'],
      'ORDER': 0,
      'VISIBLE': false
    },
    'instant-identity-data-v1': {
      'DISPLAY_NAME': 'MENU_ITEM_35',
      'LINK': '/home/instant-identity',
      'MENU_IMG': '../../assets/img/import.png',
      'ICON_CLASS': 'icon-text-file',
      'STEPS': ['instant-identity-step-v1'],
      'ORDER': 0,
      'VISIBLE': false
    }
  };

  static getMenuMap(): Object {
    return AppConfig.menus;
  }

  static getOrderStatusCaptions(): Object {
    return {
      'Processed': {
        'Text': 'DASHBOARD_ORDER_STATUS_CAPTION_2',
        'Color': 'green f-w-600',
        'Count': 0
      },
      'InProgress': {
        'Text': 'DASHBOARD_ORDER_STATUS_CAPTION_1',
        'Color': 'gray f-w-600',
        'Count': 0
      }
    }
  }

  /*
   * UDF, CRF order is made configurable as per UX need
  */

  static getUdfCrfOrder(): Object {
    return {
      'udf': 0,
      'crf': 1
    }
  }

  // Default minimum required age is not set to 14
  static GET_DEFAULTMINAGE(): number {
    return 14;
  }

  static API_ENDPOINT(): string {
    return '';
    // return 'https://pa-ua.fadv.com';
  }

  static BARCODE_API_ENDPOINT(): string {
    return 'https://apitest.fadv.com/dlparser/barcodes/file';
  }

  static LOG_API_ENDPOINT(): string {
    return '/api/web/track-request';
  }

  // Status Bar
  static getstatusbarWeigtage(): Object {
    let weightagedata = {
      'weightagedata': {
        'paname': 2,
        'padob': 1,
        'residentaldata': 8,
        'personaldata': 1,
        // 'consentslide1': 4,
        // 'consentslide2': 2,
        'contact': 1,
        'education': 4,
        'employment': 10,
        'mvr': 1,
        'reviewedit': 1,
        'crfudf': 1,
        'criminal': 1,
        'licence': 1,
        'reference': 1,
        'documentUpload': 2,
        'intl': 1,
        'eiafCircumstanceData': 1,
        'eiafMainData': 1,
        'eiafCriminalData': 2,
        'selfScheduleData': 1,
        'onlineScheduleData': 2,
        'paymentInfoData': 1,
        'bandwidthCheckData': 1,
        // 'total': 32,
        'rightIdData': 17,
        'instantIdentityIdData': 12,
        'total': 17,
        'totalseconds': 180 // 3 minutes converts to seconds
      }
    };

    return weightagedata;
  }

  // Supported Languages
  static getSupportedLanguageList(): Object {
    let languages = {
      'bg': 'bg',
      'cs': 'cs',
      'de': 'de',
      'de-de': 'de',
      'en': 'en',
      'en-us': 'en',
      'en-gb': 'en-gb',
      'es': 'es-419',
      'es-us': 'es-419',
      'es-es': 'es-es',
      'es-419': 'es-419',
      'es-mx': 'es-419',
      'es-ar': 'es-419',
      'es-bo': 'es-419',
      'es-cl': 'es-419',
      'es-co': 'es-419',
      'es-cr': 'es-419',
      'es-do': 'es-419',
      'es-ec': 'es-419',
      'es-sv': 'es-419',
      'es-gt': 'es-419',
      'es-hn': 'es-419',
      'es-ni': 'es-419',
      'es-pa': 'es-419',
      'es-py': 'es-419',
      'es-pe': 'es-419',
      'es-pr': 'es-419',
      'es-uy': 'es-419',
      'es-ve': 'es-419',
      'ko': 'ko',
      'nl': 'nl',
      'fr': 'fr',
      'fr-gf': 'fr',
      'fr-fr': 'fr',
      'fr-ca': 'fr-ca',
      'hu': 'hu',
      'hu-hu': 'hu',
      'id': 'id',
      'id-id': 'id',
      'it': 'it',
      'it-it': 'it',
      'ja': 'ja',
      'ja-jp': 'ja',
      'pl': 'pl',
      'pl-pl': 'pl',
      'pt': 'pt',
      'pt-pt': 'pt',
      'pt-br': 'pt-br',
      'ro': 'ro',
      'sk': 'sk',
      'sv-se': 'sv-se',
      'th': 'th',
      'tr': 'tr',
      'vi': 'vi',
      'zh': 'zh-cn',
      'zh-hk': 'zh-hk',
      'hk': 'zh-hk',
      'zh-sg': 'zh-sg',
      'zh-tw': 'zh-tw',
      'zh-hans': 'zh-cn',
      'zh-cn': 'zh-cn',
      'zh-my': 'zh-my',
      'zu': 'zu'
    };

    return languages;
  }

  static getSalesForceChatLanguage(lang: string) {
    let languages = {
      'bg': 'bg',
      'cs': 'cs',
      'de': 'de',
      'en': 'en',
      'en-gb': 'en-gb',
      'es-419': 'es',
      'es-us': 'es',
      'es-es': 'es',
      'es-mx': 'es',
      'es-ar': 'es',
      'es-bo': 'es',
      'es-cl': 'es',
      'es-co': 'es',
      'es-cr': 'es',
      'es-do': 'es',
      'es-ec': 'es',
      'es-sv': 'es',
      'es-gt': 'es',
      'es-hn': 'es',
      'es-ni': 'es',
      'es-pa': 'es',
      'es-py': 'es',
      'es-pe': 'es',
      'es-pr': 'es',
      'es-uy': 'es',
      'es-ve': 'es',
      'ko': 'ko',
      'nl': 'nl',
      'fr': 'fr',
      'fr-gf': 'fr',
      'fr-fr': 'fr',
      'fr-ca': 'fr_CA',
      'hu': 'hu',
      'hu-hu': 'hu',
      'id': 'id',
      'id-id': 'id',
      'it': 'it',
      'it-it': 'it',
      'ja': 'ja',
      'ja-jp': 'ja',
      'pl': 'pl',
      'pl-pl': 'pl',
      'pt': 'pt',
      'pt-pt': 'pt',
      'pt-br': 'pt',
      'ro': 'ro',
      'sk': 'sk',
      'sv-se': 'sv',
      'th': 'th',
      'tr': 'tr',
      'vi': 'vi',
      'zh': 'zh-cn',
      'zh-hk': 'zh-hk',
      'hk': 'zh-hk',
      'zh-sg': 'zh-sg',
      'zh-tw': 'zh-tw',
      'zh-hans': 'zh-cn',
      'zh-cn': 'zh-cn',
      'zh-my': 'zh-my',
    };

    return !languages[lang] ? null : languages[lang];
  }

  // Supported Languages
  static getDisplayLanguageList(): Object {
    return this.LANG_CONSTANTS.diplayLanguageList;
  }

  static getDefaultLanguage(): string {
    if (environment &&
      environment.hasOwnProperty('languageEditEnabled') &&
      environment.languageEditEnabled === true) {
      // console.log(' #--> Setting zulu default lang for language edit mode');
      return 'zu';
    } else {
      // console.log(' #--> Setting default lang for language edit mode');
      return environment.default_language;
    }
  }

  static getUrlsBlockList() {
    let url: Array<any>;
    url = ['login', 'forgot-password', 'set-password'];
    return url;
  }

  static isFeatureAvailable(stage, feature): boolean {
    let status: boolean = false;
    const featuresList: Object = FeatureConfig.modulesList[stage];

    if (featuresList !== undefined) {
      status = (featuresList[feature] !== undefined) ? featuresList[feature] : false;
    }

    return status;
  }

  /**
   * Configuring the minimum latency required in client machines/mobile devices
   * to access our application.
   * TODO - Need to check do we to configure upload/download theresholds separately
   * returns - the value in mbps
   */
  static getMinThreshold() {
    //TODO : To confirm the actual threshold.
    return 0.02;
  }

  static componentList = {
    'my-data': 'MENU_ITEM_1',
    'residential-data': 'MENU_ITEM_2',
    'employment': 'MENU_ITEM_3',
    'education': 'MENU_ITEM_4',
    'mvr': 'MENU_ITEM_5',
    'personal-data': 'MENU_ITEM_6',
    'consent': 'MENU_ITEM_7',
    'contact': 'MENU_ITEM_8',
    'show-all': 'MENU_ITEM_9',
    'additional-data': 'MENU_ITEM_10',
    'criminal-data': 'MENU_ITEM_11',
    'licence-data': 'MENU_ITEM_12',
    'reference-data': 'MENU_ITEM_13',
    'wotc': 'MENU_ITEM_14',
    'document-upload': 'MENU_ITEM_15',
    'international': 'MENU_ITEM_16',
    'eiaf-main-data': 'MENU_ITEM_17',
    'eiaf-criminal-data': 'MENU_ITEM_18',
    'eiaf-circumstance-data': 'MENU_ITEM_19',
    'self-schedule': 'MENU_ITEM_20',
    'online-schedule': 'MENU_ITEM_22',
    'confirm': 'MENU_ITEM_21',
    'identity': 'MENU_ITEM_23',
    'dynamic-information-capture': 'MENU_ITEM_24',
    'login': 'COMPONENT_TITLE_1',
    'diff-email': 'COMPONENT_TITLE_2',
    'forgot-password': 'COMPONENT_TITLE_3',
    'set-password': 'COMPONENT_TITLE_4',
    'country-consent': 'COMPONENT_TITLE_5',
    'reset-password': 'COMPONENT_TITLE_6',
    'invite': 'COMPONENT_TITLE_7',
    'expired': 'COMPONENT_TITLE_8',
    'invalid': 'COMPONENT_TITLE_9',
    'invalid-otp': 'COMPONENT_TITLE_10',
    'dashboard': 'COMPONENT_TITLE_11',
    'pages': 'COMPONENT_TITLE_12',
    'profile': 'COMPONENT_TITLE_13',
    'disagreeterms': 'COMPONENT_TITLE_14',
    'private-browsing': 'COMPONENT_TITLE_15',
    'unsupported-browsing': 'COMPONENT_TITLE_16',
    'unsupported-device': 'COMPONENT_TITLE_17',
    'unsubscribe': 'COMPONENT_TITLE_18',
    'app-landing': 'COMPONENT_TITLE_19',
    'identity-pre-check': 'COMPONENT_TITLE_20',
    'app-applicant-info': 'COMPONENT_TITLE_21',
    'app-id-select': 'COMPONENT_TITLE_22',
    'app-id': 'COMPONENT_TITLE_23',
    'app-selfie': 'COMPONENT_TITLE_24',
    'selfie-review': 'COMPONENT_TITLE_27',
    'app-confirmation': 'COMPONENT_TITLE_25',
    'app-error': 'COMPONENT_TITLE_26',
    'payment-information-data': 'MENU_ITEM_27',
    'bandwidth-check-data': 'MENU_ITEM_28',
    'self-declaration-form': 'MENU_ITEM_29',
  }

  static currentBrowser(browser: string) {
    if (browser === 'OPERA' && ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1)) {
      return true;
    } else if (browser === 'CHROME' && (navigator.userAgent.indexOf("Chrome") != -1)) {
      return true;
    } else if (browser.toUpperCase() === 'SAFARI' && (navigator.userAgent.indexOf("Safari") != -1)) {
      return true;
    } else if (browser.toUpperCase() === 'FIREFOX' && ((navigator.userAgent.indexOf("Firefox") != -1))) {
      return true;
    } else if (browser === 'IE' && ((navigator.userAgent.indexOf("MSIE") != -1) || (!!document['documentMode'] == true))) {
      return true;
    } else {
      return false;
    }
  }
}
