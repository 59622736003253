// import { debug } from 'util';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, FormArray, FormBuilder, Validators } from '@angular/forms';
import { AppConfig } from './../../app/app.config';
import { progressBarStatus } from './../../framework/progressbar.service';
import { EmploymentConfig } from './employment.config';
import { JobModel } from './job-model';
import { EmploymentService } from './employment.service';
import { ClipboardService } from '../clipboard/clipboard.service';
import { EventBusService } from './../../framework/eventbus.service';
import { TimeStampDateFormat } from '../pipes/dateformat.pipe';
import { StatusbarService } from './../status-bar/status-bar.service';
import { arrowService } from './../../framework/arrow.service';
import { recordidService } from './employment-recordid.service';
import { GapFindService } from './../../framework/gapfind.service';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { WorkflowService } from '../../framework/workflow.service';
import { LocalStorageService } from '../../framework/localstorage.service';
import { TranslateService } from 'framework/i18n';
import { UtilService } from '../../framework/utils/util.service';
import * as dateUtils from './../../framework/utils/date.utils';
import { AppConstants } from 'app/app.constants';
import { SharedService } from './../shared/services/shared.service';
import { CustomFormService } from 'app/custom-form/custom-form.service';
import { CategoryComponent } from './category/category.component';
import CitadelidBridge from '@citadelid/bridge'
import { CitadelidLookupService } from 'app/shared/services/citadelid-lookup.service';
import { v4 as uuidv4 } from 'uuid';
import { PlaidConfig, NgxPlaidLinkService, PlaidLinkHandler } from "ngx-plaid-link";
import { InstructionsService } from 'app/instructions/instructions.service';
import { DatetocontactComponent } from './datetocontact/datetocontact.component';
import { VerificationConfirmComponent } from './verification-confirm/verification-confirm.component';
declare let $: any;
declare let window: any;
declare let jQuery: any;

@Component({
  selector: 'employment',
  templateUrl: './employment.component.html',
  styleUrls: ['./employment.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [EmploymentService, progressBarStatus,
    StatusbarService, arrowService, recordidService,
    TimeStampDateFormat, GapFindService, ClipboardService,
    UtilService]
})
export class EmploymentComponent implements OnInit {
  static STAGE_ID: string = 'employment-data-v1';
  static STEP_ID: string = 'employment-step-v1';
  static STEP_INDEX: number = 0;
  DOMESTIC_COUNTRY_CODE: string = AppConfig.APP_CONSTANTS.DOMESTIC_COUNTRY_CODE;
  public workflow_instance_id: string;
  public IsSaveSuccess: boolean = false;
  public PARENT_STAGE_STEP_CONFIG: Object;
  data$: any;
  decisionChangeRes$: any;
  private plaidLinkHandler: PlaidLinkHandler;
  nextURL: string;
  nextIntlURL: string;
  empFinalcardlist: any = [];
  /*
   * STEP_ID 'employment-data-v1' with STEP_INDEX 0 can have multiple ITERATION values 0,1,2 based on the number off iterations
   * for number of years of employment-data-v1 & that ITERATION will be set in child components based on 'row' property value.
   *
   * For a child component it like:::
   * subStepID_subStepIndex_iteration
   *
   * country_1_(0|1|2|3)
  */

  // countryDecisionChanged: any;
  steps: Array<Object>;

  employmentForm: FormGroup;
  currentSlide: string;
  jobFromcontrol: FormControl;
  currentSlideWithDirection: string;
  jobs: any;
  isJobfromValid: boolean = false;
  isJobToValid: boolean = false;
  empSteps: any;
  minYear: number;
  sliderIndex: number;
  empTypes: string[] = [];
  empCategories: Object[];
  verificationFlags: string[] = [];
  activeEmpType: string;
  sameCountries: boolean[] = [];
  isSameCountry: boolean;
  options: any[] = [];
  defaultConfig: Object = {};
  defaultCountryOptions;
  isCollectMinYearEmp: boolean;
  isScopeCompleted: boolean;
  minCurrentJob: Object;
  minPriorJob: Object;
  currentJobDetails: any[] = [];
  prvJob: Object;
  userDetails;
  isReachPriorConfirm: boolean;
  datecontact: boolean;
  datetoContact: any;
  initexistingflag: any;
  currentJob: boolean = false;
  activeGap: any = [];
  isNoCurrentJob: boolean;
  isNoPriorJob: boolean;
  accountName: string;
  dateConfigs: any[];
  isNoCurrentJobVisited: boolean;
  prvCurrentJob: Object = {};
  prvPriorJob: Object = {};
  pendingPeriods: any;
  jobFromDateCollection;
  jobToDateCollection;
  existingform: any;

  gapLimit: number;
  isGapResolve: boolean;
  isGaps: boolean[];
  allRecordIdForGap = [];
  gapRecordId = null;
  isVerificationDecisionChanged: boolean = false;
  previousSlide: string = null;
  eapreferencevalue: string;
  empContactMaxTimeFrame: number;
  isCheckSelfEmploymentEnabled: boolean[];
  isTypeDecisionChanged: boolean;

  gapOptions: any;
  isFinalGapResolve: boolean;
  recursionPriorCheck: boolean = false;
  activeGapOptions;
  currentRow: number;
  recordId: any = null;
  isDbRecordPresent;
  dateFlag: boolean;
  isGetCompleted;
  isFormInit: boolean;
  stateConfig: Object;
  allRecordId;
  allCurrentJobsObj: any;
  nextdisplayname;
  employmentDataConfig;
  ErrorCode: any;
  setGapValueRandom: any;
  toggleNocontactName: boolean = false;
  isFromClipboard;
  isNeverEmployedFromReview: boolean = false;
  querySub;
  isReview: boolean;
  cbOptions: Object;
  clipboardStep: string;
  isReviewEdit: boolean;
  isReviewAdd: boolean;
  isATSData: boolean = false;
  atsArrayDataIndex: any;
  ats_data_delete_link: string;
  selectedATSCardId: string = '';
  isGapGeneratedFromJobPeriod: boolean;
  selectedDates: any = [];
  prefflag: boolean = false;
  decisionChangedIndicator: boolean = false;
  fromServerError: boolean;
  isScopeAvailable: boolean = false;
  remainingYearMonthMsg: Object = {};
  serverGapcheck: boolean = false;
  // multiplePositions: any = [];
  editInd: any;
  currentEditableObj: any = {};
  // isINTLEmployeeId: boolean = false;
  // isINTLCompNameInLocal: boolean = false;
  // isINTLDepartment: boolean = false;
  employeePositionList: any = {};
  afterPositioSaveServerObj: any = {};
  isINTLCompNameInLocalArr: any;
  isINTLEmployeeIdArr: any;
  isINTLDepartmentArr: any;
  intlAbaCbcArr: any;
  positionchange: number = 0;
  updateMultiPositionModal: number = 0;
  addMorePositions: any;
  isINTLFieldsCaptured: any;
  isCustomFormCaptured: any;
  isCustomInstruction: boolean = false;
  instructions: any;
  isGapData: any[] = [];
  isMultiPositionEnabled: boolean;
  isStandardFormExtension: boolean;
  isAgencyExtensionForm: boolean;
  isFormDeleted: boolean[] = [];
  companyPrefillConfig: any[] = [];
  selectedCompany: any[] = []; 
  ATSData = {
    'employment-data-list': [],
    'display-data-list': [],
    'state': {
      'step_status': '',
      'stage_status': '',
      'current_iteration': '',
      'current_stage': '',
      'current_step': '',
      'current_step_index': '',
      'current_sub_step': '',
      'current_sub_step_index': '',
      'current_status': ''
    },
    'pending-emp-period': {
      'year': '',
      'month': ''
    },
    'showATSdata': {
      'display': false
    }
  };
  regularData = {
    'employment-data-list': [],
    'state': {
      'step_status': '',
      'stage_status': '',
      'current_iteration': '',
      'current_stage': '',
      'current_step': '',
      'current_step_index': '',
      'current_sub_step': '',
      'current_sub_step_index': '',
      'current_status': ''
    },
    'pending-emp-period': {
      'year': '',
      'month': ''
    }
  };

  isAjaxCallProccess: boolean = false;
  responseStatus$: any;
  showEndDate: boolean = false;
  addNewPosition: boolean = false;
  selfEmploymentPreferenceEnabled: boolean = false;
  jobTypes: Object[];
  consentCountry: string;
  isConsentCountry: boolean = false;
  posGapFlag: any = [];
  isValidPosition: any = true;
  dotLimitEmploymentVerification: boolean = false;
  isPaDOTDirectEnabled = false;
  isDotLicencePreferenceEnabled = false;
  zipCodeError: boolean = false;
  isReasonForLeavingMandatory: boolean = false;
  futureDateFlags: any = {};
  isCandidateAgeBasedScope: boolean = false;
  dobError: boolean = false;
  candidateAgeBasedScopeError:boolean = false;
  faaSafetyCheckPreferenceEnabled: boolean = false;
  applicantID: string = '';
  showBlankPage: boolean = false;
  isAlertUserOnBlankScreen: boolean = false;
  isEmploymentGapInDaysEnabled: boolean = false;
  isCategoryNeverEmployedPreferenceEnabled: boolean = false;
  employerPhoneEmailOptional: any = {};
  isCurrentEmpNeverEmployed: boolean = false;
  isAdditionalEmpPrefEnabled: boolean = false;
  isPresentEmpAutoFulfilment: boolean = false;
  isPlaidPreferenceEnabled: boolean = false;
  isOptOutCitadelData: boolean[];
  isCitadelData: boolean [];
  isInvalidCitadelData: boolean = false;
  showCitadelData: boolean[];
  reportingManagerSkip: boolean = false;
  @ViewChild(CategoryComponent ) empCategoryComponent: CategoryComponent ; 
  @ViewChild(DatetocontactComponent) empDateToContactComponent: DatetocontactComponent;
  @ViewChild(VerificationConfirmComponent) empVerificationConfirmComponent: VerificationConfirmComponent;
  isTriggerTrackEvent: boolean = true;
  plaidLinkToken: string = '';
  config: PlaidConfig = {
    token: "",
    product: EmploymentConfig.employmentPlaidProduct,
    countryCodes: EmploymentConfig.employmentPlaidCountries,
    env: '',
    onSuccess: (token, metadata) => this.onPlaidSuccess(token, metadata),
    onExit: (error, metadata) => this.onPlaidExit(error, metadata)
  };
  selectedEmpDetailsDate: any;
  showPlaidData: boolean[];
  isOptOutAutoFullEmpData: boolean[];
  selectedCurrentEmpCountry: string = '';
  isIntlPmpComponentAvailable: boolean = false;
  isINTLSUPComponentAvailable: boolean = false;
  isIntlSUPCountrySpecificDataRequired: boolean = false;
  isInSchoolAddressDetailsRequired: boolean = false;

  constructor(private _route: Router,
    private _activatedRoute: ActivatedRoute,
    private _fb: FormBuilder,
    private _es: EmploymentService,
    private _cs: ClipboardService,
    private _progressbarStatus: progressBarStatus,
    private _statusBar: StatusbarService,
    private _eventBus: EventBusService,
    private _tsPipe: TimeStampDateFormat,
    private _arrowservice: arrowService,
    private _recordidservice: recordidService,
    private localStorage: LocalStorageService,
    private _ts: TranslateService,
    private _empGapService: GapFindService,
    private _workflow: WorkflowService,
    private _utilService: UtilService,
    private _sharedService: SharedService,
    private _cfs: CustomFormService,
    private _citadelIdLookupService: CitadelidLookupService,
    private _plaidLinkService: NgxPlaidLinkService,
    private instructionsService: InstructionsService
  ) {
    this.data$ = new BehaviorSubject({ counter: 0 });
    this.decisionChangeRes$ = new BehaviorSubject({ res: false });
    this.responseStatus$ = new BehaviorSubject({ res: false });
    this._es.setCategories(this._utilService.getArrayFromJsonString(this._ts.translate(EmploymentConfig.EMPLOYMENT_CATEGORIES_CURRENT)));
  }

  ngOnInit(): void {
    this.atsArrayDataIndex = this.localStorage.getItem('atsArrayDataIndex');
    this.selectedATSCardId = this.localStorage.getItem('selectedATSCardId');
    if (this.localStorage.getItem("pa_dot_limit_employment_verification")) {
      this.dotLimitEmploymentVerification = (this.localStorage.getItem("pa_dot_limit_employment_verification") === 'Y');
    }
    // console.log("employment ngOnInit pa_dot_limit_employment_verification " + this.localStorage.getItem("pa_dot_limit_employment_verification") + ":" + this.dotLimitEmploymentVerification + ":");
    let dotDirectPrefValue = this.localStorage.getItem('pa_dot_direct_preference')
    this.isPaDOTDirectEnabled = (dotDirectPrefValue != '' && dotDirectPrefValue != null && dotDirectPrefValue != undefined ? true : false);
    this.isReasonForLeavingMandatory = (this.localStorage.getItem('reason_for_leaving_mandatory') == 'Y' ? true : false);
    this.isCandidateAgeBasedScope = (this.localStorage.getItem('is_candidate_age_based_scope') == 'Y' ? true : false);
    this.applicantID = this.localStorage.getItem("invite_key");
    this.isAlertUserOnBlankScreen = (this.localStorage.getItem('user_alert_on_error') == 'YES' ? true : false);
    this.showBlankPage = false;
    this.isEmploymentGapInDaysEnabled = this._es.isEmploymentGapInDaysEnabled();
    this.isPresentEmpAutoFulfilment = this._es.isPresentEmpAutoFulfilment();
    this.isPlaidPreferenceEnabled = this._es.isPlaidPreferenceEnabled();
    this.isINTLSUPComponentAvailable = this._sharedService.checkDistinctComponentAvailable('INTLSUP');
    this.isInvalidCitadelData = false;

    this.nextURL = this._es.getNextURL();
    this.nextIntlURL = this._es.getNextIntlURL();
    this.nextdisplayname = this._es.getNextDisplayName();

    this.PARENT_STAGE_STEP_CONFIG = {
      STAGE_ID: EmploymentComponent.STAGE_ID,
      STEP_ID: EmploymentComponent.STEP_ID,
      STEP_INDEX: EmploymentComponent.STEP_INDEX
    };

    this.datetoContact = [];
    this.existingform = true;
    this.steps = this._workflow.getSteps(EmploymentComponent.STAGE_ID);

    this.eapreferencevalue = this._es.getEmpContactEaPreference();
    // getMultiPositionPreference returns true if allow_multi_position is true in workflow preference
    this.isMultiPositionEnabled = this._es.getMultiPositionPreference();
    if (this.eapreferencevalue !== undefined && this.eapreferencevalue !== null) {
      this.prefflag = true;
    }

    this.empContactMaxTimeFrame = this._es.getEmpContactTimeFrame();

    if (this.eapreferencevalue === undefined || this.eapreferencevalue === null ||
      this.eapreferencevalue === '' || this.eapreferencevalue === 'NO') {
      this.toggleNocontactName = true;
    }
    this.isIntlPmpComponentAvailable = this._sharedService.checkDistinctComponentAvailable('INTLPMP');
    this.isDotLicencePreferenceEnabled = this._es.isDotLicensePreferenceEnabled();
    this.isCategoryNeverEmployedPreferenceEnabled = this._es.isCategoryNeverEmployedPreferenceEnabled();
    this.selfEmploymentPreferenceEnabled = this._es.isSelfEmploymentCheckEnabled();
    this.faaSafetyCheckPreferenceEnabled = this._es.isFaaSafetyCheckEnabled();
    this.employerPhoneEmailOptional = this._es.employerPhoneEmailOptionalPreferences();
    this.reportingManagerSkip = this._es.reportingManagerSkip(this.isINTLSUPComponentAvailable); 

    this.jobTypes = this._utilService.getArrayFromJsonString(this._ts.translate(EmploymentConfig.EMPLOYMENT_JOB_TYPES));

    EmploymentConfig.resetStepAndIndexMapping('custform');
    EmploymentConfig.deleteStepAndIndexMapping('payroll-lookup');
    EmploymentConfig.deleteStepAndIndexMapping('emp-details');
    EmploymentConfig.deleteStepAndIndexMapping('data-provider');
    this.isStandardFormExtension = this._es.isStandardFormExtension();
    if (this.isStandardFormExtension) {
      this.isAgencyExtensionForm = this._es.isAgencyExtensionForm();
      EmploymentConfig.addNewStepAndIndexMapping('custform');
    }

    if (this.isPlaidPreferenceEnabled) {
      let subSteps = EmploymentConfig.getSteps();
      let index = subSteps.indexOf("category");
      EmploymentConfig.addNewStepAndIndexMappingAtIndex('emp-details', index + 1);
      EmploymentConfig.addNewStepAndIndexMappingAtIndex('data-provider', index + 2);
    } 
    
    if (this.isPresentEmpAutoFulfilment) {
      let subSteps = EmploymentConfig.getSteps();
      let index;
      
      if (this.isPlaidPreferenceEnabled) // TODO, change indexOf page based on result screen after plaid
        index = subSteps.indexOf("data-provider");
      else
        index = subSteps.indexOf("category");
      EmploymentConfig.addNewStepAndIndexMappingAtIndex('payroll-lookup', index + 1);
    }
    // If this.localStorage.getItem('enable_collect_edu') is YES then return true else false and assign to isInSchoolAddressDetailsRequired
    this.isInSchoolAddressDetailsRequired = (this.localStorage.getItem('enable_collect_edu') == 'YES' ? true : false);
    this.empSteps = EmploymentConfig.getSteps();
    this.isReviewEdit = false;
    this.isReviewAdd = false;
    this.isFormInit = false;
    this.cbOptions = {};
    this._recordidservice.makeRecordidEmpty();
    this._arrowservice.setarrowarray(this.empSteps);
    this.isGapGeneratedFromJobPeriod = false;
    this.datecontact = false;
    this.isINTLCompNameInLocalArr = [];
    this.isINTLEmployeeIdArr = [];
    this.isINTLDepartmentArr = [];
    this.isINTLFieldsCaptured = [];
    this.isCustomFormCaptured = [];
    this.intlAbaCbcArr = [];
    this.isCheckSelfEmploymentEnabled = [];
    this.isCitadelData=[];
    this.isOptOutCitadelData=[];
    this.showCitadelData=[];
    this.showPlaidData=[];
    this.isOptOutAutoFullEmpData = [];
    this.empFinalcardlist = EmploymentConfig.finalEmpCardList;
    this.isAdditionalEmpPrefEnabled = false;
    
    this.querySub = this._activatedRoute.queryParams.subscribe(params => {
      if (params['from'] === 'review') {
        this.isFromClipboard = true;
        this.isReview = true;
        this.cbOptions['isFromClipboard'] = this.isFromClipboard;
        this.setNextURL();
        this._recordidservice.setprofileBuilderbutton(this.isReview);
      }

      if (params['step']) {
        this.clipboardStep = params['step'];
      }

      if (this.isReview) {
        if (params['id']) {
          this.isReviewEdit = true;
          this.isReviewAdd = false;
          this.recordId = params['id'];
        } else {
          this.isReviewEdit = false;
          this.isReviewAdd = true;
          this.recordId = null;
        }
      }
    });

    // Cache issue : avoid of workflow ID
    if (this._es.updateWorkflowId()) {
      this.employmentDataConfig = this._es.employmentDataConfig;
      this.isScopeAvailable = (this.employmentDataConfig['scopes'].length > 0) ? true : false;
      this.minYear = this._es.minYear;
      
      if (this.isScopeAvailable && !!this.isCandidateAgeBasedScope) {
        this._es.getCandidateAgeBasedScope().subscribe(response => {
          let data = response;
          console.log("getCandidateAgeBasedScope  data ", data);
          this._es.setAgeBasedScope(data);

          let years = data.years;
          let months = data.months;
          if (years <= 0 && months <= 0) {
            this.isScopeAvailable = false;
          } else {
            this.isScopeAvailable = true;
          }
          this.userDetails['isScopeAvailable'] = this.isScopeAvailable;
          if (years < 0) {
            this.minYear = 0;
          }
          else {
            this.minYear = data.years;
          }
          let index = 0;
          let yearMsg = this._ts.instant('EMPLOYMENT_SCOPE_COMMON_YEARS');
          if (years == 1) {
            yearMsg = this._ts.instant('EMPLOYMENT_SCOPE_COMMON_YEAR');
          }
          this.remainingYearMonthMsg[index] = this.minYear + " " + yearMsg;
          if (!!months) {
            let monthMsg = this._ts.instant('EMPLOYMENT_SCOPE_COMMON_MONTHS');
            if (months == 1) {
              monthMsg = this._ts.instant('EMPLOYMENT_SCOPE_COMMON_MONTH');
            }
            this.remainingYearMonthMsg[index] += ' ' + months + ' ' + monthMsg;
          }

          this.gapLimit = this._es.gapLimit;
          this.getData();
        }, error => {
          //Show error message
          console.log("employment error ", error);
          if (error === "412") {
            //Missing DOB
            this.dobError = true;
          } else {
            this.candidateAgeBasedScopeError = true;
          }
        });
      } else {
        let index = 0;
        this.remainingYearMonthMsg[index] = this.minYear + " " + this._ts.instant('EMPLOYMENT_SCOPE_YEARS');
        this.gapLimit = this._es.gapLimit;
        this.getData();
      }
    }

    this.isCollectMinYearEmp = false;
    this.isScopeCompleted = false;
    this.activeGapOptions = [];
    this.userDetails = this._es.getUserDetails;
    this.userDetails['isScopeAvailable'] = this.isScopeAvailable;
    this.pendingPeriods = [];

    let userFirstName = this.localStorage.getItem('first_name');
    this.accountName = this.localStorage.getItem('company_name') || '';
    this.isNoCurrentJob = false;
    this.isNoCurrentJobVisited = false;
    this.dateConfigs = [];
    this.gapOptions = [];
    this.isGaps = [];
    this.isGapResolve = false;
    this.isFinalGapResolve = false;

    if (userFirstName) {
      this.userDetails['firstname'] = userFirstName;
    }
    
    if (!this.isReviewAdd) {
      this.defaultCountryOptions = EmploymentConfig.defaultCountryOptions();
      let countryOptions = {};
      countryOptions['country'] = this.defaultCountryOptions['defaultCountry'];
      countryOptions['countryShortName'] = this.defaultCountryOptions['defaultCountryShortName'];

      let activeGapOption = null;
      this.activeGapOptions.push(activeGapOption);
      this.defaultConfig['countryOptions'] = countryOptions;
      let empType = this.activeEmpType = null;
      let verificationFlag = '';
      let sameCountry = this.isSameCountry = false;
      this.empTypes.push(empType);
      this.verificationFlags.push(verificationFlag);
      this.options.push(this.defaultConfig);
      this.sameCountries.push(sameCountry);
    }
    this._progressbarStatus.setEmployement('true');

    this.instructions = this._es.getCustomInstructions();
    if (this.instructions !== undefined) {
      this.prepareCustomInstruction();
    }

    this.initConsentCountry();
    this.disableFutureDateFlags();
  }

  prepareCustomInstruction() {
    this.isCustomInstruction = true;
    if (!this.isFromClipboard) {
      this.setCustomInstruction();
    }
  }

  ngOnDestroy(): void {
    this.unsetVariables();
  }

  private unsetVariables() {
    this._es.recordIdObj = {};
    this._es.recordIdGapArr = [];
    this.isReviewEdit = false;
    this.isReviewAdd = false;
    this.isReview = false;
    this.isFormInit = false;
    this.cbOptions = {};
    this._recordidservice.makeRecordidEmpty();
    this._es.setServerData([]);
    this.getLatestRecords();
    this._es.tempGapRecordId = null;
    this._es.setAgeBasedScope({});
  }

  private getData() {
    let fetchFromProfile = true;
    if (this.isReview) {
      fetchFromProfile = false;
    }
    
    this._es.getData(fetchFromProfile).subscribe(response => {
      this.isDbRecordPresent = true;
      this.dateFlag = true;
      this.isGetCompleted = true;
      this.IsSaveSuccess = true;
      // let data = JSON.parse(response._body);
      let data = response;
      this.processData(data);
      this.decideToDisplayAdditionalCard(this.regularData['employment-data-list']);

      if (!this.isReviewAdd) {
        if (!!this.regularData && this.regularData['employment-data-list'] !== undefined
          && this.regularData['employment-data-list'].length > 0) {
          for (let i = 0; i < this.regularData['employment-data-list'].length; i++) {
            this.setMultipositionList(this.regularData, i);
          }
        }
      }

      this.updateServerData(this.regularData);
      this.generateEmpForm(this.regularData);
      
      if (!this.isReview) {
        this.setRemainingYearAndMonthExisting(this.regularData);
      }
      if (this.isReview) {
        jQuery('#empWaitModal').modal('hide');
      }
      
      return;
    }, error => {
      if (this.isReview)
        this.hideEmpWaitModal();
      this.isGetCompleted = true;
      if (this.isAlertUserOnBlankScreen) {
        this.showBlankPage = true;
      }
    });
  }

  setCustomInstruction() {
    if (!!this.instructions) {
      this.isCustomInstruction = true;
      this._eventBus.customInstructionAnnounced({
        'section': this._ts.instant('CUSTOM_INSTRUCTION_EMPLOYMENT'),
        'instructions': this.instructions
      });
    }
  }

  setNextURL() {
    if (this.nextIntlURL !== undefined) {
      this.nextURL = this.nextIntlURL;
    } else {
      this.nextURL = '/home/review/show-all';
    }
  }

  private setRemainingYearAndMonthExisting(yearAndMonth) {
    let len = yearAndMonth['employment-data-list'].length;
    let priordetailsindex;

    for (let i = 0; i < len; i++) {
      if (i > 0) {
        if (this.isEmploymentGapInDaysEnabled) {
          this.validateYearMonthAndDayExisting(yearAndMonth, i - 1, i);
        } else {
          this.validateYearAndMonthExisting(yearAndMonth, i - 1, i);
        }
      }
    }
  }

  private validateYearAndMonthExisting(yearAndMonth, i, priordetailsindex) {
    if (yearAndMonth !== null && yearAndMonth !== undefined) {
      if (yearAndMonth['employment-data-list'][i]['pending-emp-period']['year'] !== undefined
        && yearAndMonth['employment-data-list'][i]['pending-emp-period']['year'] > 1
        && yearAndMonth['employment-data-list'][i]['pending-emp-period']['month'] !== undefined
        && yearAndMonth['employment-data-list'][i]['pending-emp-period']['month'] > 1) {
        this.remainingYearMonthMsg[priordetailsindex] = yearAndMonth['employment-data-list'][i]['pending-emp-period']['year'] + ' ' + this._ts.instant('EMPLOYMENT_SCOPE_COMMON_YEARS') + ' ' + yearAndMonth['employment-data-list'][i]['pending-emp-period']['month'] + ' ' + this._ts.instant('EMPLOYMENT_SCOPE_COMMON_MONTHS');
      } else if (yearAndMonth['employment-data-list'][i]['pending-emp-period']['year'] !== undefined
        && yearAndMonth['pending-emp-period']['year'] == 1
        && yearAndMonth['pending-emp-period']['month'] !== undefined
        && yearAndMonth['pending-emp-period']['month'] == 1) {
        this.remainingYearMonthMsg[priordetailsindex] = yearAndMonth['employment-data-list'][i]['pending-emp-period']['year'] + ' ' + this._ts.instant('EMPLOYMENT_SCOPE_COMMON_YEAR') + ' ' + yearAndMonth['employment-data-list'][i]['pending-emp-period']['month'] + ' ' + this._ts.instant('EMPLOYMENT_SCOPE_COMMON_MONTH');
      } else if (yearAndMonth['employment-data-list'][i]['pending-emp-period']['year'] !== undefined
        && yearAndMonth['employment-data-list'][i]['pending-emp-period']['year'] == 1
        && yearAndMonth['employment-data-list'][i]['pending-emp-period']['month'] !== undefined
        && yearAndMonth['employment-data-list'][i]['pending-emp-period']['month'] > 1) {
        this.remainingYearMonthMsg[priordetailsindex] = yearAndMonth['employment-data-list'][i]['pending-emp-period']['year'] + ' ' + this._ts.instant('EMPLOYMENT_SCOPE_COMMON_YEAR') + ' ' + yearAndMonth['employment-data-list'][i]['pending-emp-period']['month'] + ' ' + this._ts.instant('EMPLOYMENT_SCOPE_COMMON_MONTHS');
      } else if (yearAndMonth['employment-data-list'][i]['pending-emp-period']['year'] !== undefined
        && yearAndMonth['employment-data-list'][i]['pending-emp-period']['year'] > 1
        && yearAndMonth['employment-data-list'][i]['pending-emp-period']['month'] !== undefined
        && yearAndMonth['employment-data-list'][i]['pending-emp-period']['month'] == 1) {

        if (priordetailsindex == 0) {
          this.remainingYearMonthMsg[priordetailsindex] = yearAndMonth['employment-data-list'][i]['pending-emp-period']['year'] + ' ' + this._ts.instant('EMPLOYMENT_SCOPE_COMMON_YEARS');
        }
        else {
          this.remainingYearMonthMsg[priordetailsindex] = yearAndMonth['employment-data-list'][i]['pending-emp-period']['year'] + ' ' + this._ts.instant('EMPLOYMENT_SCOPE_COMMON_YEARS') + ' ' + yearAndMonth['employment-data-list'][i]['pending-emp-period']['month'] + ' ' + this._ts.instant('EMPLOYMENT_SCOPE_COMMON_MONTH');
        }
      }
      else {
        if (yearAndMonth['employment-data-list'][i]['pending-emp-period']['year'] !== undefined
          && yearAndMonth['employment-data-list'][i]['pending-emp-period']['year'] == 0
          && yearAndMonth['employment-data-list'][i]['pending-emp-period']['month'] !== undefined
          && yearAndMonth['employment-data-list'][i]['pending-emp-period']['month'] > 1) {
          this.remainingYearMonthMsg[priordetailsindex] = yearAndMonth['employment-data-list'][i]['pending-emp-period']['month'] + ' ' + this._ts.instant('EMPLOYMENT_SCOPE_COMMON_MONTHS');
        } else if (yearAndMonth['employment-data-list'][i]['pending-emp-period']['year'] !== undefined
          && yearAndMonth['employment-data-list'][i]['pending-emp-period']['year'] == 0
          && yearAndMonth['employment-data-list'][i]['pending-emp-period']['month'] !== undefined
          && yearAndMonth['employment-data-list'][i]['pending-emp-period']['month'] == 1) {
          this.remainingYearMonthMsg[priordetailsindex] = yearAndMonth['employment-data-list'][i]['pending-emp-period']['month'] + ' ' + this._ts.instant('EMPLOYMENT_SCOPE_COMMON_MONTH');
        } else if (yearAndMonth['employment-data-list'][i]['pending-emp-period']['year'] !== undefined
          && yearAndMonth['employment-data-list'][i]['pending-emp-period']['year'] > 1
          && yearAndMonth['employment-data-list'][i]['pending-emp-period']['month'] !== undefined
          && yearAndMonth['employment-data-list'][i]['pending-emp-period']['month'] == 0) {
          this.remainingYearMonthMsg[priordetailsindex] = yearAndMonth['employment-data-list'][i]['pending-emp-period']['year'] + ' ' + this._ts.instant('EMPLOYMENT_SCOPE_COMMON_YEARS');
        } else if (yearAndMonth['employment-data-list'][i]['pending-emp-period']['year'] !== undefined
          && yearAndMonth['employment-data-list'][i]['pending-emp-period']['year'] == 1
          && yearAndMonth['employment-data-list'][i]['pending-emp-period']['month'] !== undefined
          && yearAndMonth['employment-data-list'][i]['pending-emp-period']['month'] == 0) {
          this.remainingYearMonthMsg[priordetailsindex] = yearAndMonth['employment-data-list'][i]['pending-emp-period']['year'] + ' ' + this._ts.instant('EMPLOYMENT_SCOPE_COMMON_YEAR');
        }
      }
    }
  }

  private validateYearMonthAndDayExisting(data, i, priordetailsindex) {
    if (data !== null && data !== undefined) {
      let yearAndMonth = data['employment-data-list'][i]['pending-emp-period'];
      this.setRemainingScopeYearMonthAndDay(yearAndMonth, priordetailsindex);
    }
  }

  private setRemainingYearAndMonth(yearAndMonth) {
    let priordetailsindex
    if (this.currentRow == null || this.currentRow == undefined) {
      priordetailsindex = 0;
    }
    else {
      priordetailsindex = this.currentRow + 1;
    }

    if (yearAndMonth !== null && yearAndMonth !== undefined) {
      if (yearAndMonth['year'] !== undefined
        && yearAndMonth['year'] > 1
        && yearAndMonth['month'] !== undefined
        && yearAndMonth['month'] > 1) {
        this.remainingYearMonthMsg[priordetailsindex] = yearAndMonth['year'] + ' ' + this._ts.instant('EMPLOYMENT_SCOPE_COMMON_YEARS') + ' ' + yearAndMonth['month'] + ' ' + this._ts.instant('EMPLOYMENT_SCOPE_COMMON_MONTHS');
      } else if (yearAndMonth['year'] !== undefined
        && yearAndMonth['year'] == 1
        && yearAndMonth['month'] !== undefined
        && yearAndMonth['month'] == 1) {
        this.remainingYearMonthMsg[priordetailsindex] = yearAndMonth['year'] + ' ' + this._ts.instant('EMPLOYMENT_SCOPE_COMMON_YEAR') + ' ' + yearAndMonth['month'] + ' ' + this._ts.instant('EMPLOYMENT_SCOPE_COMMON_MONTH');
      } else if (yearAndMonth['year'] !== undefined
        && yearAndMonth['year'] == 1
        && yearAndMonth['month'] !== undefined
        && yearAndMonth['month'] > 1) {
        this.remainingYearMonthMsg[priordetailsindex] = yearAndMonth['year'] + ' ' + this._ts.instant('EMPLOYMENT_SCOPE_COMMON_YEAR') + ' ' + yearAndMonth['month'] + ' ' + this._ts.instant('EMPLOYMENT_SCOPE_COMMON_MONTHS');
      } else if (yearAndMonth['year'] !== undefined
        && yearAndMonth['year'] > 1
        && yearAndMonth['month'] !== undefined
        && yearAndMonth['month'] == 1) {

        if (priordetailsindex == 0) {
          this.remainingYearMonthMsg[priordetailsindex] = yearAndMonth['year'] + ' ' + this._ts.instant('EMPLOYMENT_SCOPE_COMMON_YEARS');
        }
        else {
          this.remainingYearMonthMsg[priordetailsindex] = yearAndMonth['year'] + ' ' + this._ts.instant('EMPLOYMENT_SCOPE_COMMON_YEARS') + ' ' + yearAndMonth['month'] + ' ' + this._ts.instant('EMPLOYMENT_SCOPE_COMMON_MONTH');
        }
      }
      else {
        if (yearAndMonth['year'] !== undefined
          && yearAndMonth['year'] == 0
          && yearAndMonth['month'] !== undefined
          && yearAndMonth['month'] > 1) {
          this.remainingYearMonthMsg[priordetailsindex] = yearAndMonth['month'] + ' ' + this._ts.instant('EMPLOYMENT_SCOPE_COMMON_MONTHS');
        } else if (yearAndMonth['year'] !== undefined
          && yearAndMonth['year'] == 0
          && yearAndMonth['month'] !== undefined
          && yearAndMonth['month'] == 1) {
          this.remainingYearMonthMsg[priordetailsindex] = yearAndMonth['month'] + ' ' + this._ts.instant('EMPLOYMENT_SCOPE_COMMON_MONTH');
        } else if (yearAndMonth['year'] !== undefined
          && yearAndMonth['year'] > 1
          && yearAndMonth['month'] !== undefined
          && yearAndMonth['month'] == 0) {
          this.remainingYearMonthMsg[priordetailsindex] = yearAndMonth['year'] + ' ' + this._ts.instant('EMPLOYMENT_SCOPE_COMMON_YEARS');
        } else if (yearAndMonth['year'] !== undefined
          && yearAndMonth['year'] == 1
          && yearAndMonth['month'] !== undefined
          && yearAndMonth['month'] == 0) {
          this.remainingYearMonthMsg[priordetailsindex] = yearAndMonth['year'] + ' ' + this._ts.instant('EMPLOYMENT_SCOPE_COMMON_YEAR');
        }
      }
    }
  }

  private setRemainingYearMonthAndDay(yearAndMonth) {
    let priordetailsindex
    if (this.currentRow == null || this.currentRow == undefined) {
      priordetailsindex = 0;
    }
    else {
      priordetailsindex = this.currentRow + 1;
    }
    this.setRemainingScopeYearMonthAndDay(yearAndMonth, priordetailsindex);
  }

  private setRemainingScopeYearMonthAndDay(yearAndMonth, priordetailsindex) {
    if (yearAndMonth !== null && yearAndMonth !== undefined) {
      let years = yearAndMonth['year'];
      let months = yearAndMonth['month'];
      let days = yearAndMonth['days'];
      console.log("setRemainingScopeYearMonthAndDay scope: ", years, months, days);
      let remainingScopeMsg = '';
      if (!!years) {
        if (years > 1) {
          remainingScopeMsg += years + ' ' + this._ts.instant('EMPLOYMENT_SCOPE_COMMON_YEARS');
        } else if (years === 1) {
          remainingScopeMsg += years + ' ' + this._ts.instant('EMPLOYMENT_SCOPE_COMMON_YEAR');
        }
      }
      if (!!months) {
        if (months > 0 && years > 0) {
          remainingScopeMsg += ' ';
        }
        if (months > 1) {
          remainingScopeMsg += months + ' ' + this._ts.instant('EMPLOYMENT_SCOPE_COMMON_MONTHS');
        } else if (months === 1) {
          remainingScopeMsg += months + ' ' + this._ts.instant('EMPLOYMENT_SCOPE_COMMON_MONTH');
        }
      }
      if (!!days) {
        if (days > 0 && (years > 0 || months > 0)) {
          remainingScopeMsg += ' ';
        }
        if (days > 1) {
          remainingScopeMsg += days + ' ' + this._ts.instant('EMPLOYMENT_SCOPE_COMMON_DAYS');
        } else if (days === 1) {
          remainingScopeMsg += days + ' ' + this._ts.instant('EMPLOYMENT_SCOPE_COMMON_DAY');
        }
      }
      this.remainingYearMonthMsg[priordetailsindex] = remainingScopeMsg;
    }
  }

  private updateServerData(response) {
    let data = response;
    let empDataList = data['employment-data-list'];
    this._es.setServerData(empDataList);
  }

  private generateEmpForm(data) {
    let res = data['employment-data-list'];
    this.stateConfig = data['state'];
    let options: Object = {};
    let configOptionslen = this.options.length;
    let configOption = this.options[configOptionslen - 1];
    if (configOption && configOption['countryOptions']) {
      options['country_name'] = configOption.countryOptions['country'];
      options['countryShortCode'] = options['country'] = configOption.countryOptions['countryShortName'];
    }

    if (!this.isReview) {
      this.recordId = null;
      this.initGaps();
      this.sliderIndex = this.currentRow = 0;

      if (!!this.stateConfig['stage_status'] && this.stateConfig['stage_status'] === 'REVIEW_EDIT_PENDING') {
        this.isReviewEdit = true;
        this.isReview = true;
        this.isReviewAdd = false;

        if (this.isAlertUserOnBlankScreen && !this.stateConfig['current_iteration']) {
          this.showBlankPage = true;
        } else if (this.stateConfig['current_iteration'].indexOf('***') > -1) {
          this.recordId = this.stateConfig['current_iteration'].split('***')[1] || null;
        }
      } else if (!!this.stateConfig['stage_status'] && this.stateConfig['stage_status'] === 'REVIEW_ADD_PENDING') {
        this.isReviewAdd = true;
        this.isReview = true;
        this.isReviewEdit = false;

        if (this.isAlertUserOnBlankScreen && !this.stateConfig['current_iteration']) {
          this.showBlankPage = true;
        } else if (this.stateConfig['current_iteration'].indexOf('***') > -1) {
          this.recordId = this.stateConfig['current_iteration'].split('***')[1] || null;
          if (!this.recordId && res.length > 0) {
            let lastObj = res[res.length - 1];
            this.recordId = lastObj['id'] || null;
          }
        } else {
          this.activeEmpType = null;
        }
      }
    }

    if (this.showBlankPage) {
      console['server']('emp_blank_screen', new Error('Blank Screen shown for  Applicant ID: ' + this.applicantID));
      return;
    }
 
    if (res && res.length > 0) {
      if (this.isReviewEdit) {
        this.initEditEmploymentForm(res);
        return;
      } else if (this.isReviewAdd) {
        this.sliderIndex = this.empSteps.indexOf('category');
        this.currentRow = 0;
        this.activeEmpType = null;
        let currentItem: Object = {};
        this.initReviewConfig();
        let isPrePopulate: boolean = true;
        this.isGaps = [];
        this.gapOptions = [];
        if ((this.stateConfig['current_status'] === 'PENDING')) {
          this.gapAvialabilityCheck('final', isPrePopulate);
        }
        if (this.stateConfig['current_status'] === 'COMPLETED' && (this.empFinalcardlist.indexOf(this.stateConfig['current_sub_step']) > -1)) {
          this.recordId = null;
          this.gapAvialabilityCheck();
        }

        if (this.isGaps[this.currentRow]) {
          this.initDateOptions();
        }
        if (this.isGaps[this.currentRow]) {
          let gapObj = this.gapOptions[this.currentRow];
          currentItem['start-date'] = this._es.getDateToTs(gapObj['from']['date']);
          currentItem['end-date'] = this._es.getDateToTs(gapObj['to']['date']);
          this.sliderIndex = this.empSteps.indexOf('category');
          if (this.stateConfig['stage_status'] === 'REVIEW_ADD_PENDING' && this.stateConfig['current_status'] === 'PENDING' && !this.recordId) {
            let lastObj = res[res.length - 1];
            if (!!lastObj['start-date'] && !!lastObj['end-date'] && lastObj['start-date'] === this._es.getDateToTs(gapObj['from']['date']) && lastObj['end-date'] === this._es.getDateToTs(gapObj['to']['date'])) {
              this.recordId = res[res.length - 1]['id'];
            } else {
              this.recordId = null;
            }
          }
        }
        // Save & Exit for Review add
        if (!!this.recordId) {
          let currentItemFromServer;
          let currentItemFromServerArr = res.filter(obj => {
            return obj['id'] === this.recordId;
          });

          if (currentItemFromServerArr.length > 0) {
            currentItemFromServer = currentItemFromServerArr[0];
            options = currentItem = this._es.mapToOption(currentItemFromServer);
            if (!!currentItem['countryName'] && !!currentItem['countryCode']) {
              options['country_name'] = configOption.countryOptions['country'] = currentItem['countryName'];
              options['countryShortCode'] = options['country'] = configOption.countryOptions['countryShortName'] = currentItem['countryCode'];
              this.initDateOptions(currentItem);
            }

            if (!!this.stateConfig['current_sub_step'] && this.stateConfig['current_status'] === 'PENDING') {
              this.sliderIndex = this.empSteps.indexOf(this.stateConfig['current_sub_step']);
            } else if (!!this.stateConfig['current_sub_step'] && this.stateConfig['current_status'] === 'COMPLETED'
              && this.stateConfig['stage_status'] !== 'REVIEW_ADD_PENDING') {
              let currentSliderIndex = this.empSteps.indexOf(this.stateConfig['current_sub_step']);
              this.sliderIndex = currentSliderIndex + 1;
            }
            this.empCategories = this._utilService.getArrayFromJsonString(this._ts.translate(EmploymentConfig.EMPLOYMENT_CATEGORIES));
            let empType = this.activeEmpType = currentItem['type'] || null;
            this.empTypes = [];
            this.empTypes.push(empType);
            this.initEmploymentForm(options);

            let rowObj = {
              row: 0
            };
            let currentItemObj = currentItem;
            currentItemObj['indexDetail'] = rowObj;
            //this.checkSelfEmploymentRequired(currentItem['countryCode'], this.currentRow);
            this.makeDynamicFormGroupControls(currentItemObj, currentItem)
            this.setSlider(this.sliderIndex, 'right', 0);
            this.isFormInit = true;
            return;
          }
        }
        if (!!this.stateConfig['stage_status'] && this.stateConfig['stage_status'] === 'COMPLETED' && res.length === 1) {
          if (this.stateConfig['current_iteration'].indexOf('***') > -1 && this.stateConfig['current_sub_step'] !== 'job-period') {
            if (!this.isReviewAdd) {
              this.recordId = this.stateConfig['current_iteration'].split('***')[1] || null;
            }
          } else {
            this.recordId = null;
          }
        }
      } else {
        if (this.stateConfig['stage_status'] === 'PENDING') {
          this.initExistingEmploymentForm(res);
          this.alertUserOnBlankPage(this.currentSlide);
          return;
        }
      }
    } else {
      if (this.isReviewAdd) {
        this.sliderIndex = this.empSteps.indexOf('category');
        this.currentRow = 0;
        this.activeEmpType = null;
        this.initReviewConfig();
      } else {
        if (!this.isScopeAvailable) {
          this.sliderIndex = this.empSteps.indexOf('verification');
        } else {
          this.sliderIndex = this.empSteps.indexOf('category');
        }
        this.currentRow = 0;
      }
    }
    this.initEmploymentForm(options);

    if (this.currentRow == 0 && !this.isReview) {
      this.empCategories = this._utilService.getArrayFromJsonString(this._ts.translate(EmploymentConfig.EMPLOYMENT_CATEGORIES_CURRENT));
    } else {
      this.empCategories = this._utilService.getArrayFromJsonString(this._ts.translate(EmploymentConfig.EMPLOYMENT_CATEGORIES));
    }
    this.isFormInit = true;
    this.setSlider(this.sliderIndex, 'right', this.currentRow);
    this.alertUserOnBlankPage(this.currentSlide);
    return;
  }

  private initEditEmploymentForm(data) {
    let currentItemArr = data.filter(obj => {
      return obj['id'] === this.recordId;
    });

    if (currentItemArr.length > 0) {
      let currentItem = currentItemArr[0];
      if (currentItem['is_current'] == true) {
        this.currentJob = true;
        this.empCategories = this._utilService.getArrayFromJsonString(this._ts.translate(EmploymentConfig.EMPLOYMENT_CATEGORIES_CURRENT));
      } else {
        this.currentJob = false;
        this.empCategories = this._utilService.getArrayFromJsonString(this._ts.translate(EmploymentConfig.EMPLOYMENT_CATEGORIES));
      }
      this.setMultiPositionListForReviewEdit(currentItem);
      let currentItemIndex = currentItemArr.indexOf(currentItem);
      this.currentRow = 0;

      if (currentItem['type'] === 'current') {
        currentItem['datetocontact'] = this._es.getFormatedDate(currentItem['when-to-contact'], 'MMMM dd,YYYY');
        if (currentItem['datetocontact'] !== undefined) {
          this.selectedDates[this.currentRow] = currentItem['datetocontact'];
        }
      }
      this.isCitadelData[this.currentRow] = (currentItem['source'] === EmploymentConfig.EMP_SOURCE_CITADEL);
      if (this.isCitadelData[this.currentRow]) {
        this.showCitadelData[this.currentRow] = true;
      } else {
        if (currentItem['type'] === 'current') {
          this.isOptOutCitadelData[this.currentRow] = true;
        }
      }

      if (this.stateConfig['stage_status'] === 'REVIEW_EDIT_PENDING') {
        this.createCurrentSlide(currentItem);
      } else {
        this.sliderIndex = 1;
        this.setSlider(this.sliderIndex, 'right', this.currentRow);
      }

      if (currentItem['source_id'] === undefined) {
        this.selectedCompany[this.currentRow] = '';
      } else {
        let obj = {};
        obj['id'] = currentItem['source_id']
        this.selectedCompany[this.currentRow] = obj;
      }


      let objForINTL = {};
      objForINTL['country'] = currentItem['country'];
      objForINTL['id'] = this.recordId;
      this.prepareIntlFlagArray(objForINTL, this.currentRow);
      let options: Object = {};
      this.initReviewConfig(currentItem, currentItemIndex);
      this.initEmploymentForm(currentItem);
      let currentItemObj = currentItem;
      let rowObj = {
        row: 0
      };

      currentItemObj['indexDetail'] = rowObj;
      this.isCheckSelfEmploymentEnabled[this.currentRow] = this._es.isSelfEmploymentCheckRequired(currentItem['type'], currentItem['country']);
      this.makeDynamicFormGroupControls(currentItemObj, currentItem);
      this.alertUserOnBlankPage(this.currentSlide);
      this.isFormInit = true;
    }
    this.isINTLFieldsCaptured[this.currentRow] = false;
    this.isCustomFormCaptured[this.currentRow] = false;
  }

  private initExistingEmploymentForm(serverData) {
    this.intiEmpConfig();
    let data = (JSON.parse(JSON.stringify(serverData)));
    let dataLen: number = data.length;

    let currentItem: Object;
    let sanatizedData: Object[] = null;
    sanatizedData = serverData;
    let sanatizedDataLen = sanatizedData.length;

    for (let i = 0; i < sanatizedDataLen; i++) {
      if (i == 0 && !this.isReview && this.stateConfig['stage_status'] === 'PENDING') {
        this.empCategories = this._utilService.getArrayFromJsonString(this._ts.translate(EmploymentConfig.EMPLOYMENT_CATEGORIES_CURRENT));
      } else {
        this.empCategories = this._utilService.getArrayFromJsonString(this._ts.translate(EmploymentConfig.EMPLOYMENT_CATEGORIES));
      }

      this.currentRow = i;
      currentItem = sanatizedData[i];
      let countryOptions: Object = {};
      let defaultConfig: Object = {};
      let empType: any;
      let actualPosition: number;

      if (!!currentItem['id']) {
        this._es.setRecordIdCollection(i, currentItem['id']);
      }
      this.isCitadelData[i] = (currentItem['source'] === EmploymentConfig.EMP_SOURCE_CITADEL);
      if (this.isCitadelData[i]) {
        this.showCitadelData[i] = true;
      } else {
        if (currentItem['type'] === 'current') {
          this.isOptOutCitadelData[this.currentRow] = true;
        }
      }
      
      if (currentItem['source'] === EmploymentConfig.EMP_SOURCE_PLAID) {
        this.showPlaidData[i] = true;
      }
      
      if (i === 0) {
        countryOptions['country'] = currentItem['country_name'] || this.defaultCountryOptions['defaultCountry'];
        countryOptions['country_name'] = currentItem['country_name'] || this.defaultCountryOptions['defaultCountry'];
        countryOptions['countryShortName'] = currentItem['country'] || this.defaultCountryOptions['defaultCountryShortName'];
        if (currentItem['type'] === 'prior') {
          this.isNoCurrentJob = true;
        } else {
          this.isNoCurrentJob = false;
        }
      } else {
        countryOptions['country'] = currentItem['country_name'] || '';
        countryOptions['country_name'] = currentItem['country_name'] || '';
        countryOptions['countryShortName'] = currentItem['country'] || sanatizedData[i - 1]['country'];
      }

      if (currentItem['source_id'] === undefined) {
        this.selectedCompany[i] = '';
      } else {
        let obj = {};
        obj['id'] = currentItem['source_id']
        this.selectedCompany[i] = obj;
      }

      if(!!currentItem['id']) {
        let objForINTL = {};
        objForINTL['country'] = currentItem['country'];
        objForINTL['id'] = currentItem['id'];
        this.prepareIntlFlagArray(objForINTL, i);
      }

      empType = this.activeEmpType = currentItem['type'] || null;
      defaultConfig['countryOptions'] = countryOptions;
      defaultConfig['stateOptions'] = {
        'stateCode': currentItem['state_code']
      };
      this.options.push(defaultConfig);
      this.setCompanyPrefillConfig(i, countryOptions['countryShortName'], currentItem['state_code']);
      this.selectedDates[this.currentRow] = '';

      if (this.activeEmpType === 'current' && !this.isReview) {
        if (!!currentItem['start-date']) {
          currentItem['jobFrom'] = this._es.getFormatedDate(currentItem['start-date'], this.getDateFormat());
          this.initDateOptions(currentItem, i);
          let newJob: JobModel = new JobModel();
          let employmentDataItem = {};
          let obj = this._es.mapToOption(currentItem);
          this._es.setCurrentJobObjCollection(i, obj);
          this.allCurrentJobsObj = null;
          this.allCurrentJobsObj = JSON.stringify(this._es.currentJobObj);
          this.formPayload(employmentDataItem, obj, newJob);

          this._es.jobs.push(newJob);
          this.jobs = this._es.jobs;
          actualPosition = (!!currentItem['id']) ? data.map(function (o) { return o['id']; }).indexOf(currentItem['id']) : dataLen - 1;
          this.prePopulateJobperiod(currentItem, actualPosition);
        } else {
          this.initDateOptions(currentItem);
        }
        if (currentItem['when-to-contact']) {
          let objpro = { 'contactdate': true };
          this.datetoContact.push(objpro);
          currentItem['datetocontact'] = this._es.getFormatedDate(currentItem['when-to-contact'], 'MMMM dd,YYYY');
          if (currentItem['datetocontact'] !== undefined) {
            this.selectedDates[this.currentRow] = currentItem['datetocontact'];
          }
        }
      } else if (this.activeEmpType !== 'current' && !this.isReview) {
        if (!!currentItem['start-date']) {
          currentItem['jobFrom'] = this._es.getFormatedDate(currentItem['start-date'], this.getDateFormat());
          currentItem['jobTo'] = this._es.getFormatedDate(currentItem['end-date'], this.getDateFormat());
          this.initDateOptions(currentItem, i);
          let newJob: JobModel = new JobModel();

          let employmentDataItem = {};
          let obj = this._es.mapToOption(currentItem);
          this.formPayload(employmentDataItem, obj, newJob);
          this._es.jobs.push(newJob);
          this.jobs = this._es.jobs;
          actualPosition = (!!currentItem['id']) ? data.map(function (o) { return o['id']; }).indexOf(currentItem['id']) : dataLen - 1;
          this.prePopulateJobperiod(currentItem, actualPosition);
        } else {
          if (sanatizedDataLen === 1) {
            this.isNoCurrentJob = true;
          }
          this.initDateOptions(currentItem, null);
        }
      }
      this.empTypes.push(empType);
      this.recordId = currentItem['id'] || null;

      if (!!this.recordId) {
        this._recordidservice.setRecordid(i, this.recordId);
      }
      let currentItemObj = currentItem;
      let rowObj = {
        row: i
      };

      currentItemObj['indexDetail'] = rowObj;
      if (i === 0) {
        this.initEmploymentForm(currentItem);
        if (currentItem['datetocontact']) {
          this.exidatetocontact(i);
        }
      } else {
        this.addEsForm(currentItem);
        if (currentItem['datetocontact']) {
          this.exidatetocontact(i);
        }
      }
      if (i === sanatizedDataLen - 1) {
        this.existingFormSettings(sanatizedData, currentItem);
        if (!!this.showBlankPage) {
          return;
        }
        let isPrePopulate: boolean = true;
        if (i > 1) {
          this.existingGapAvialabilityCheck(null, isPrePopulate);
        }

        if (!this.isGaps[sanatizedDataLen - 1] && i > 1) {
          this.initexistingflag = true;
          this.existingGapAvialabilityCheck('final', isPrePopulate);
        }

        if (!!this.gapOptions && !!this.gapOptions[this.currentRow]) {
          let gapOptions = this.gapOptions[this.currentRow];
          if (!!gapOptions['to'] &&
            !!gapOptions['to']['obj'] &&
            gapOptions['to']['obj']['type'] === 'current') {

            this.isGapGeneratedFromJobPeriod = true;
            this.userDetails['isGap'] = true;
          }

          currentItem['start-date'] = this._es.getDateToTs(this.gapOptions[this.currentRow + 1]['from']['date'])
          currentItem['end-date'] = this._es.getDateToTs(this.gapOptions[this.currentRow + 1]['to']['date'])
        }
      }
      
      this.isCheckSelfEmploymentEnabled[this.currentRow] = this._es.isSelfEmploymentCheckRequired(currentItem['type'], currentItem['country']);
      this.makeDynamicFormGroupControls(currentItemObj, currentItem);
      this.isINTLFieldsCaptured[this.currentRow] = false;
      this.isCustomFormCaptured[this.currentRow] = false;
    }

    if (!!this.gapRecordId) {
      this.recordId = this.gapRecordId;
      this.gapRecordId = null;
    }
    this.isFormInit = true;
  }

  private getDataWithoutEscapedGap(serverData): Object[] {
    let data = (JSON.parse(JSON.stringify(serverData)));
    let dataLen: number = data.length;
    let dataWihoutEscapedGap: Object[] = null;
    let escapedGapArr = serverData.filter(obj => {
      return !!obj['type'];
    });

    if (!!escapedGapArr && escapedGapArr.length > 0) {
      dataWihoutEscapedGap = escapedGapArr;
    }
    return dataWihoutEscapedGap;
  }

  private existingFormSettings(data: Object[], currentItem: Object) {
    let dataLen = data.length;
    let i = dataLen - 1;
    let empType: string;

    if (this.stateConfig['stage_status'] === 'PENDING') {
      if (!!this.stateConfig['current_iteration'] && this.stateConfig['current_iteration'].indexOf('***') > -1) {
        let iterationIdString = this.stateConfig['current_iteration'].split('***')[0];
        if (!!iterationIdString) {
          let iterationId = parseInt(iterationIdString, 10) || 0;
          if (iterationId !== undefined) {
            if (iterationId >= dataLen) {
              if (this.isAlertUserOnBlankScreen) {
                this.showBlankPage = true;
              }
              return;
            }
            if (currentItem['id'] !== data[iterationId]['id']) {
              this.currentRow = iterationId;
              this.recordId = data[iterationId]['id'] || null;
              this.activeEmpType = data[iterationId]['type'];
              if (this.stateConfig['current_status'] === 'COMPLETED' && this.stateConfig['current_sub_step'] === 'current-employment-confirm') {
                this.recordId = currentItem['id'] || null;
                this.currentRow = i;
                this.activeEmpType = currentItem['type'];
              }
            }
          }
        }
      }
    }

    if (!!this.isNoCurrentJob) {
      if (i === 0 &&
        ((this.stateConfig['current_sub_step'] === 'prior-employment-confirm' && this.stateConfig['current_status'] !== 'COMPLETED' && !currentItem['end-date'])
          || (this.stateConfig['current_sub_step'] !== 'job-period' && this.stateConfig['current_status'] === 'COMPLETED' && (!currentItem['state_name'] || !currentItem['end-date'])))) {
        this.isNoCurrentJobVisited = false;
      } else {
        this.isNoCurrentJobVisited = true;
      }
    }

    if ((this.stateConfig['current_status'] === 'COMPLETED' && !!currentItem['current_employment_confirm']) || (i > 0 && this.stateConfig['current_status'] === 'COMPLETED' && !!currentItem['prior_employment_confirm'])) {
      let countryOptions: Object = {};
      let defaultConfig: Object = {};
      let nextItem = {};

      if (!!currentItem['current_employment_confirm']) {
        this.activeEmpType = 'current';
      } else if (!!currentItem['prior_employment_confirm']) {
        this.activeEmpType = 'prior';
      }

      empType = this.activeEmpType = this.activeEmpType;
      this.empTypes.push(empType);

      countryOptions['country_name'] = '';
      countryOptions['countryShortName'] = nextItem['country'] = data[i]['country'];

      defaultConfig['countryOptions'] = countryOptions;
      this.options.push(defaultConfig);

      this.initDateOptions(nextItem, i);
      this.addEsForm(nextItem);
      this.recordId = null;
      defaultConfig['countryOptions'] = countryOptions;
      let currentcomponenttype = this.stateConfig['current_sub_step'];
      this.sliderIndex = this.empSteps.indexOf(currentcomponenttype);
      this.currentRow = dataLen;
      this.sliderIndex = this.empSteps.indexOf(currentcomponenttype);
      this.setSlider(this.sliderIndex, 'right', dataLen);
    } else if (this.stateConfig['current_status'] === 'COMPLETED' &&
      this.activeEmpType === 'prior' &&
      this.stateConfig['current_sub_step'] === 'job-period') {

      let isPrePopulate: boolean = true;
      if (i > 1) {
        this.gapAvialabilityCheck('final');
      } else if (i > 1) {
        this.gapAvialabilityCheck(null, isPrePopulate);
      }

      if (!!this.isGaps[i]) {
        if (i === 0) {
          if (this.gapOptions[i]['gapStatus'] === 'tail' && dataLen === 1) {
            this.isGapGeneratedFromJobPeriod = false;
            let currentcomponenttype = this.stateConfig['current_sub_step'];
            this.sliderIndex = this.empSteps.indexOf(currentcomponenttype);
          } else {
            this.isGapGeneratedFromJobPeriod = true;
            let currentcomponenttype = this.stateConfig['current_sub_step'];
            this.sliderIndex = this.empSteps.indexOf(currentcomponenttype);
          }
        } else {
          let currentcomponenttype = this.stateConfig['current_sub_step'];
          this.sliderIndex = this.empSteps.indexOf(currentcomponenttype);
          this.isGapGeneratedFromJobPeriod = false;
        }
      } else {
        let currentcomponenttype = this.stateConfig['current_sub_step'];
        this.sliderIndex = this.empSteps.indexOf(currentcomponenttype);
      }
      this.setSlider(this.sliderIndex, 'right', i);
    } else {
      this.createCurrentSlide();
    }
    this.getLatestRecords();
  }

  private intiEmpConfig() {
    this.dateConfigs = [];
    this.gapOptions = [];
    this.isGaps = [];
    this.isGapResolve = false;
    this.isFinalGapResolve = false;
    this.empTypes = [];
    this.options = [];
  }

  private initReviewConfig(data?, indexValue?) {
    this.intiEmpConfig();
    this.defaultCountryOptions = EmploymentConfig.defaultCountryOptions();
    let countryOptions = {};
    let activeGapOption = null;
    let empType: string;
    if (!data) {
      countryOptions['country'] = this.defaultCountryOptions['defaultCountry'];
      countryOptions['countryShortName'] = this.defaultCountryOptions['defaultCountryShortName'];
    } else {
      countryOptions['country'] = data['country_name'] || this.defaultCountryOptions['defaultCountry'];
      countryOptions['countryShortName'] = data['country'] || this.defaultCountryOptions['defaultCountryShortName'];
    }

    this.activeGapOptions.push(activeGapOption);
    this.defaultConfig['countryOptions'] = countryOptions;
    if (!data) {
      empType = this.activeEmpType;
    } else {
      empType = this.activeEmpType = data['type'];
    }

    let verificationFlag = '';
    let sameCountry = this.isSameCountry = false;
    this.empTypes.push(empType);
    this.verificationFlags.push(verificationFlag);
    this.options.push(this.defaultConfig);
    this.sameCountries.push(sameCountry);
    this.initGaps();


    if (!this.isReviewEdit) {
      this.initDateOptions();
      this.initPendingPeriod();
    } else {
      this.initDateOptions(data, indexValue);
    }
  }

  private updateLocalData(serverData, index) {
    const employmentSubFormArr = <FormArray>this.employmentForm.controls['employmentSubForm'];
    let employmentSubFormArrLen = employmentSubFormArr.length;
    for (let i = index + 1; i < employmentSubFormArrLen; i++) {
      if (!!this._es.recordIdObj[i]) {
        delete this._es.recordIdObj[i];
        this.allRecordId = this._es.recordIdObj;
      }
    }

    if (!!this.dateConfigs[index + 1]) {
      this.dateConfigs.splice((index + 1), 1);
    }

    if (!!this._es.jobs[index + 1]) {
      this._es.jobs.splice((index + 1), 1);
      this.jobs = this._es.jobs;
    }

    if (!!this.options[index + 1]) {
      this.options.splice((index + 1), 1);
    }

    serverData.splice((index + 1), 1);
    this._es.setServerData(serverData);
    this.getLatestRecords();
  }

  private isDecisionChanged(obj: Object, serverProperty: string, componentProperty: string, index: number): boolean {
    let isDecisionChanged: boolean = false;
    let currentServerData: Object = null;
    let serverData: Object[] = [];
    serverData = (JSON.parse(JSON.stringify(this._es.getServerData())));
    let serverDataLen = serverData.length;
    if (!this.isReview) {
      if (!!serverData[index]) {
        currentServerData = serverData[index];
        if (currentServerData[serverProperty] !== undefined && currentServerData[serverProperty] !== null
          && obj[componentProperty] !== undefined && obj[componentProperty] !== null) {
          if (obj[componentProperty] !== currentServerData[serverProperty]) {
            isDecisionChanged = true;
          }
        }
      }
    } else if (this.isReview) {
      if (serverData !== null && serverData !== undefined) {
        for (let i = 0; i < serverData.length; i++) {
          if (serverData[i]['id'] === obj['id']) {
            currentServerData = serverData[i];
            if (currentServerData[serverProperty] !== undefined && currentServerData[serverProperty] !== null
              && obj[componentProperty] !== undefined && obj[componentProperty] !== null) {
              if (obj[componentProperty] !== currentServerData[serverProperty]) {
                isDecisionChanged = true;
              }
            }
          }
        }
      }
    }
    return isDecisionChanged;
  }

  private setSlider(index, direction, i?): void {
    if (!!this.currentSlide) {
      this.previousSlide = this.currentSlide;
    }

    let seprator: string = '_';
    this.currentSlide = this.empSteps[index];

    if (i !== null && i > -1) {
      this.currentSlide += `${seprator}${i}`;
    }

    if (direction) {
      this.currentSlideWithDirection = `${this.currentSlide}${seprator}${direction}`;
    }
    this.hideEmpWaitModal();
  }

  alertUserOnBlankPage(currentSlide: any) {
    if (this.isAlertUserOnBlankScreen) {
      const employmentSubFormArr = <FormArray>this.employmentForm.controls['employmentSubForm'];
      let employmentSubFormArrLen = employmentSubFormArr.length;
      let isBlankPage = true;
      for (let i = 0; i < employmentSubFormArrLen; i++) {
        const subFormGroup = <FormGroup>employmentSubFormArr.controls[i];

        if (currentSlide === 'verification_' + i && !this.isScopeAvailable) {
          isBlankPage = false;
        } else if (currentSlide === 'category_' + i) {
          isBlankPage = false;
        } else if (currentSlide === 'emp-details_' + i) {
          if (this.isPlaidPreferenceEnabled && this.empTypes[i] === 'current') {
            isBlankPage = false;
          }
        } else if (currentSlide === 'data-provider_' + i) {
          isBlankPage = false;
        } else if (currentSlide === 'payroll-lookup_' + i) {
          if (this.isPresentEmpAutoFulfilment && this.empTypes[i] === 'current') {
            isBlankPage = false;
          }
        } else if (currentSlide === 'verification-confirm_' + i) {
          if (this.empTypes[i] === 'current' || this.isCheckSelfEmploymentEnabled[i]) {
            isBlankPage = false;
          }
        } else if (currentSlide === 'country_' + i || currentSlide === 'city-state_' + i) {
          if (this.empTypes[i] === 'current' || this.empTypes[i] === 'prior' || (this.empTypes[i] === 'selfEmployed' && (this.isPaDOTDirectEnabled || this.selfEmploymentPreferenceEnabled))) {
            isBlankPage = false;
          }
        } else if (currentSlide === 'company-name_' + i) {
          if (this.empTypes[i] === 'current' || this.empTypes[i] === 'prior' || this.isCheckSelfEmploymentEnabled[i] || (this.empTypes[i] === 'selfEmployed' && this.isPaDOTDirectEnabled)) {
            isBlankPage = false;
          }
        } else if (currentSlide === 'reporting-manager-details_' + i) {
          if ((this.empTypes[i] === 'current' || this.empTypes[i] === 'prior' || this.isCheckSelfEmploymentEnabled[i] || (this.empTypes[i] === 'selfEmployed' && this.isPaDOTDirectEnabled)) && (this.options[i]['countryOptions']['countryShortName'] != AppConstants.DOMESTIC_COUNTRY_CODE || this.isPaDOTDirectEnabled || this.isINTLSUPComponentAvailable)) {
            isBlankPage = false;
          }
        } else if (currentSlide === 'position_' + i) {
          if ((this.empTypes[i] === 'current' || this.empTypes[i] === 'prior') && (this.options[i]['countryOptions']['countryShortName'] != AppConstants.DOMESTIC_COUNTRY_CODE)) {
            isBlankPage = false;
          }
        } else if (currentSlide === 'job-period_' + i) {
          if (this.empTypes[i] !== null) {
            isBlankPage = false;
          }
        } else if (currentSlide === 'job-title_' + i) {
          if (this.empTypes[i] === 'current' || this.empTypes[i] === 'prior' || this.isCheckSelfEmploymentEnabled[i] || (this.empTypes[i] === 'selfEmployed' && this.isPaDOTDirectEnabled) || (this.empTypes[i] === 'selfEmployed' && this.faaSafetyCheckPreferenceEnabled && this.selfEmploymentPreferenceEnabled)) {
            isBlankPage = false;
          }
        } else if (currentSlide === 'intl-emp_' + i) {
          if ((this.empTypes[i] === 'current' || this.empTypes[i] === 'prior' || this.isCheckSelfEmploymentEnabled[i]) && (this.isINTLDepartmentArr[i] || this.isINTLEmployeeIdArr[i] || this.isINTLCompNameInLocalArr[i] || ((this.empTypes[i] === 'current' || this.empTypes[i] === 'prior') && this.selfEmploymentPreferenceEnabled))) {
            isBlankPage = false;
          }
        } else if (currentSlide === 'custform_' + i) {
          if (this.isStandardFormExtension && (this.empTypes[i] === 'current' || this.empTypes[i] ===  'prior' || this.empTypes[i] ===  'selfEmployed')) {
            isBlankPage = false;
          }
        } else if (currentSlide === 'reconfirm_' + i) {
          if ((!this.isScopeAvailable || this.isAdditionalEmpPrefEnabled) && !this.isReview) {
            isBlankPage = false;
          }
        }

        if(!isBlankPage){
          break;
        }
      }
      if (isBlankPage) {
        this.showBlankPage = true;
        console['server']('emp_blank_screen', new Error('Blank Screen shown for  Applicant ID: ' + this.applicantID));
      }
    }
  }

  private initEmploymentForm(options): void {
    this.employmentForm = this._fb.group({
      employmentSubForm: this._fb.array([
        this.initEsForm(options),
      ])
    });

    if (this.stateConfig['stage_status'] === 'PENDING' || this.isReviewEdit) {
      this.isFormInit = false;
    } else {
      this.isFormInit = true;
    }
  }

  private initEsForm(options) {
    let type = '';
    let empPositionVal: any = '';
    let intlStartDateVal: any = '';
    let intlEndDateVal: any = '';
    let employeeIdVal: any = '';
    let departmentVal: any = '';
    let jobTypeVal: any = '';
    let form: any = '';
    let dateToContactVal: any = '';
    let hasDriverDuties: any = '';
    let reasonForLeaving: any = '';
    let paSyncIdVal: any = undefined;
    let faaSafetyCheck: any = '';
    let dataProvider: any;

    if (this.isConsentCountryValue()) {
      this.updateDefaultConsentCountry(this.options[this.options.length - 1]);
    }

    if (options['type'] !== undefined) {
      type = options['type'];
    }

    if (options['intl-start-date'] !== undefined) {
      intlStartDateVal = this._es.getFormatedDate(options['intl-start-date'], this.getDateFormat());
    }

    if (options['intl-end-date'] !== undefined) {
      intlEndDateVal = this._es.getFormatedDate(options['intl-end-date'], this.getDateFormat());
    }

    if (options['emp_position'] !== undefined) {
      empPositionVal = options['emp_position'];
    }

    if (options['employee_id'] !== undefined) {
      employeeIdVal = options['employee_id'];
    }

    if (options['department'] !== undefined) {
      departmentVal = options['department'];
    }

    if (options['job_type'] !== undefined) {
      jobTypeVal = options['job_type'];
    }
    if (options['form'] !== undefined) {
      form = options['form'];
    }
    if (options['has_driver_duties'] !== undefined) {
      hasDriverDuties = options['has_driver_duties'];
    }
    if (options['faa_safety_check'] !== undefined) {
      faaSafetyCheck = options['faa_safety_check'];
    }

    if (options['reason_for_leaving'] !== undefined) {
      reasonForLeaving = options['reason_for_leaving'];
    }

    if (!!options && !!options['when-to-contact']) {
      dateToContactVal = this._es.getFormatedDate(options['when-to-contact'], 'MMMM dd,YYYY');
    }

    if (options['pa_sync_id'] !== undefined) {
      paSyncIdVal = options['pa_sync_id'];
    }
    
    if (options['data_provider'] !== undefined) {
      dataProvider = options['data_provider'];
    }

    let frmCtrls = {
      type: [type, Validators.required],
      intlStartDate: [intlStartDateVal, Validators.compose([Validators.required])],
      intlEndDate: [intlEndDateVal, Validators.required],
      empPosition: [empPositionVal, Validators.required],
      employeeId: [employeeIdVal, Validators.required],
      department: [departmentVal, Validators.required],
      jobType: [jobTypeVal, Validators.required],
      datetocontact: [dateToContactVal, Validators.compose([Validators.required])],
      paSyncId: [paSyncIdVal]
    };

    if (!this.isScopeAvailable) {
      frmCtrls['confirm'] = ['confirm', Validators.required];
    }
    if (!this.isScopeAvailable || this.isAdditionalEmpPrefEnabled) {
      frmCtrls['reconfirm'] = ['reconfirm', Validators.required];
    }
    if (this.isStandardFormExtension) {
      frmCtrls['form'] = [form];
    }
    if (this.dotLimitEmploymentVerification) {
      frmCtrls['driverDuties'] = [hasDriverDuties, Validators.required];
    }
    if (this.faaSafetyCheckPreferenceEnabled) {
      frmCtrls['faaSafetyCheck'] = [faaSafetyCheck, Validators.required];
    }
    if (this.isPaDOTDirectEnabled || this.isReasonForLeavingMandatory) {
      frmCtrls['reasonForLeaving'] = [reasonForLeaving, Validators.required];
    }
    if (this.isPlaidPreferenceEnabled) {
      frmCtrls['empDataProvider'] = [dataProvider, Validators.required];
    }
    return this._fb.group(frmCtrls);
  }

  private jobFromValidator(control: FormControl): { [s: string]: boolean } {
    if (!!control.value && control.value.length > 0 && control.value.indexOf(' ') > -1) {
      let formGroup = control['_parent'];
      this.jobFromcontrol = control;
      this.jobFromDateCollection = this._es.jobFromDateCollection;
      this.jobToDateCollection = this._es.jobToDateCollection;

      if (!!formGroup && !!formGroup.contains('jobTo')) {
        let formatedFromDate = this._es.formatDate(control.value);
        let toDate = '';
        if (!!this.gapOptions[this.currentRow]) {
          toDate = this.gapOptions[this.currentRow]['to']['date'];
        } else {
          toDate = formGroup.controls['jobTo'].value;
        }

        let formatedToDate = this._es.formatDate(toDate);
        let monthDiff = this._es.monthDiffOfDates(formatedFromDate, formatedToDate);
        if (monthDiff < 0) {
          this.isJobfromValid = false;
          // console.log("formatedFromDate", formatedFromDate);
          // console.log("formatedToDate", formatedToDate);
          return { invalidDate: true };
        }
        if (monthDiff >= 0) {
          this.isJobfromValid = true;
          return { invalidDate: false };
        }
      }
    }
  }

  private addEsForm(options): void {
    const control = <FormArray>this.employmentForm.controls['employmentSubForm'];
    control.push(this.initEsForm(options));
  }

  empCategory(obj): void {
    if (!this.isAjaxCallProccess) {
      let jobFromCurrent: any;
      let jobToCurrent: any;
      let typeCheckFlag: boolean;
      let padvFlag: boolean;
      this._recordidservice.setprofileBuilderbutton(false);
      obj['trakEventMsg'] = 'category is provided';
      let options = {};
      let row = this.currentRow = obj['indexDetail']['row'];
      this.posGapFlag[row] = false;
      padvFlag = this.getPadvtype();
      this.activeEmpType = obj['type'];
      typeCheckFlag = this.getTypeCheckFlag(this.activeEmpType);

      // Reset isCheckSelfEmploymentEnabled flag to false
      if (this.activeEmpType !== 'selfEmployed') {
        this.isCheckSelfEmploymentEnabled[this.currentRow] = false;
      }

      let serverData: Object[] = [];
      serverData = (JSON.parse(JSON.stringify(this._es.getServerData())));
      if (!!serverData[row] && !!serverData[row]['start-date']) {
        jobFromCurrent = serverData[row]['start-date'];
      }
      if (!!serverData[row] && !!serverData[row]['end-date']) {
        jobToCurrent = serverData[row]['end-date'];
      }
      let serverDataLen = serverData.length;
      if (this.initexistingflag && serverDataLen - 1 >= row) {
        let tempServerData = [];
        for (let i = 0; i < row; i++) {
          tempServerData.push(serverData[i]);
        }
        this.initexistingflag = false;
        serverData = tempServerData;
      }
      const control = <FormArray>this.employmentForm.controls['employmentSubForm'];
      let controlLen = control.length;
      if (!this.isCitadelData[this.currentRow]) {
        this.showCitadelData[this.currentRow] = false;
        this.isCitadelData[this.currentRow] = false;
        this.isInvalidCitadelData = false;
      }
      
      if (this.isPlaidPreferenceEnabled && obj['type'] === 'current') {
        this.showPlaidData[this.currentRow] = false;
      }

      if (this.activeEmpType === 'current') {
        if (!this.ATSData['showATSdata']['display']) {
          this.isOptOutCitadelData[this.currentRow] = false;
        }
        /**
         * Date options will update only in case of new Form, Its should restrict in case of ATS and ReviewEdit
         * 1. Normal Flow
         * 2. Review Add
         * 3. Non-ATS
         *
         * Check below is Non-ATS and Not-review Edit, then update date options
         */
        if (padvFlag && !this.isReviewEdit) {
          this.initDateOptions(obj, this.currentRow);
        }
      } else {
        //Clear Citadel data if type is not current.
        if (!!this.isCitadelData[this.currentRow]) {
          this.clearEmploymentData(obj, this.currentRow);
          this.isCitadelData[this.currentRow] = false;
        }
        this.showCitadelData[this.currentRow] = false;
        this.isOptOutCitadelData[this.currentRow] = false;
        this.isInvalidCitadelData = false;

        if (padvFlag) {
          if (serverData.length > 0) {
            this.prvJob = this._es.findMinimumJob(serverData);
            let minimumJob = this.prvJob = this._es.findMinimumJob(serverData);
            let minimumJobMap = this._es.mapToOption(minimumJob);

            if (!!this.gapOptions[row]) {
              minimumJobMap['gapOption'] = this.gapOptions[row];
              options['start-date'] = this._es.getDateToTs(minimumJobMap['gapOption']['from']['date'])
              options['end-date'] = this._es.getDateToTs(minimumJobMap['gapOption']['to']['date'])
            }

            if (!this.gapOptions[row]) {
              this.isGapResolve = false;
            }

            if (this.currentRow === (controlLen - 1) && !this.isReview && jobFromCurrent === undefined && jobToCurrent === undefined) {
              this.initDateOptions(minimumJobMap, this.currentRow, true);
            }

            if (this.currentRow === (controlLen - 1) && !this.isReview && jobFromCurrent !== undefined && jobToCurrent !== undefined) {
              this.initDateOptions(minimumJobMap, this.currentRow, false);
            }
          } else {
            let isUpdateInitialItem = false;
            this.initDateOptions(null, null, null, isUpdateInitialItem);
          }
        }

        if (row == 0 && !this.isReview) {
          this.currentJob = true;
        } else if (row > 0 && !this.isReview) {
          this.currentJob = false;
        } else if (this.isReviewEdit) {
          let gap = this.userDetails['isGap']
          if (this.currentJob == true && !gap) {
            this.currentJob = true;
          } else {
            this.currentJob = false;
          }
        }
      }

      if (row === 0 && this.isReviewAdd && this.userDetails['isGap']) {
        this.currentJob = false;
        let opts = {};
        opts['start-date'] = options['start-date'];
        opts['end-date'] = options['end-date'];
        this.makeDynamicFormGroupControls(obj, opts);
      }

      if (row === 0 && this.isReviewAdd && !this.userDetails['isGap']) {
        this.currentJob = false;
        let opts = {};
        opts['from'] = '';
        opts['to'] = '';
        this.dateConfigs[0] = opts;
      }

      /**
       * The below function will execute only in case of multi position is enabled.. Particularly to bind gap
       * If it is not multi position, That we are handling in initDateOptionFunction.
       * 1. this.userDetails['isGap'] - Gap must be true
       * 2. padvFlag - source - ATS is false, PADV is true
       * 3. isMultiPositionEnabled - multiposition must be true
       * 4. typeCheckFlag - Check employment type
       */
      if ((!this.isReviewAdd || !this.isReviewEdit) && this.userDetails['isGap'] && typeCheckFlag && padvFlag && this.isMultiPositionEnabled) {
        this.setPositionGapDate(row, options);
      }

      if (this.empTypes.length - 1 <= controlLen) {
        this.empTypes[row] = obj['type'];
      } else {
        this.empTypes.push(obj['type']);
      }

      if (this.isReview) {
        obj['id'] = this.recordId;
      }
      this.isTypeDecisionChanged = this.isDecisionChanged(obj, 'type', 'type', this.currentRow);

      if (this.isTypeDecisionChanged) {
        let opts = {};
        if (this.isReviewEdit && !!obj['jobFrom'] && !!obj['jobTo']) {
          opts['start-date'] = obj['jobFrom'];
          opts['end-date'] = obj['jobTo'];
        }

        if (this.isReviewEdit && obj['type'] === 'neverEmployed') {
          let checkList = ['COMPONENT_STATE', 'id', 'indexDetail', 'startTime', 'trakEventMsg', 'type']; 

          for (let key in obj) {
            if (checkList.indexOf(key) < 0) 
              obj[key] = '';
          }
        }

        this.makeDynamicFormGroupControls(obj, opts);
        this.changeDecision(obj, this.currentRow);
        this.initDateOptions(obj, this.currentRow, false);
      } else if (this.currentRow === (controlLen - 1)) {
        this.makeDynamicFormGroupControls(obj, options);
      }
      if (row === 0 && !this.isReview) {
        obj['is_current'] = true;
      }

      let currentServerData = serverData[this.currentRow];
      if (this.currentRow !== undefined && this.currentRow !== null) {
        if (!this.isReview) {
          if (currentServerData !== undefined)
            this.recordId = currentServerData['id'];
        }
      }

      if(obj['type'] === 'neverEmployed'){
          this.initPayload(obj);
      } else {
        this.afterCategorySave(obj);
      }      
    }
  }

  initiateCitadelLookup() {
    jQuery('#empWaitModal').modal('show');
    console.log("Citadel lookup ", this.isPresentEmpAutoFulfilment);
    let that = this;
    this.citadelTrackEvent('import attempt', 'user opts for citadel', true, true);
    let status = undefined;
    this._citadelIdLookupService.getBridgeToken().subscribe(response => {
      console.log("bridgeToken response ", response);
      if (!!response) {
        const bridge = CitadelidBridge.init({
          bridgeToken: response.bridge_token,
          onLoad: function () {
            jQuery('#empWaitModal').modal('hide');
            console.log("Citadel Bridge loaded");
          },
          onSuccess: (publicToken, metaData) => {
            console.log("Citadel onSuccess", publicToken, metaData);
            status = 'success';
            //Get Verification Details
            that.getEmployeeVerificationDetailsFromCitadel(publicToken);
          },
          onEvent: function(event_type, payload) {
            // all events fire this function. event_type indicates what the event is,
            // payload has additional information depending on the event.
            if (event_type === 'ERROR') {
              jQuery('#empWaitModal').modal('hide');
              that.citadelTrackEvent('import system unable to communicate', 'event error', true, true);
            }
          },
          onClose: function () {
            if (!status) {
              // Optional, called when Bridge is closed by the user
              that.citadelTrackEvent('click', 'widget closed by user', true, true);
              that.citadelTrackEvent('citadel import cancelled', 'user aborts', true, true);
              that.workflowInstanceAuditTrack("Closed Citadel widget");
              console.log("Citadel Bridge closed");
              that.empCitadelOptout();
            }
          }
        });
        bridge.open();
        //jQuery('#empWaitModal').modal('hide');
      } else {
        jQuery('#empWaitModal').modal('hide');
      }
    }, error => {
      jQuery('#empWaitModal').modal('hide');
      // that.citadelTrackEvent('clicked', 'connection to citadel service failed', true, true);
      that.citadelTrackEvent('import system unable to communicate', 'error', true, true);
      jQuery('#empCitadelErrorModal').modal('show');
    });
  }

  getEmployeeVerificationDetailsFromCitadel(token: string) {
    jQuery('#empWaitModal').modal('show');
    this.clearEmploymentData({}, this.currentRow);
    this.isCitadelData[this.currentRow] = false;
    this._citadelIdLookupService.getEmploymentDetails(token).subscribe(response => {
      console.log("citadel emp details ", response['employments'], response);
        this.isCitadelData[this.currentRow] = true;
        let employmentsData = response['employments'];
        this.prepopulateCitadelEmpData(employmentsData);
        this.showCitadelData[this.currentRow] = true;
        this.citadelTrackEvent('import successful system got valid candidate data', 'success', true, true);
        jQuery('#empWaitModal').modal('hide');
    }, error => {
      let errorStatus = this.localStorage.getItem('citadel_error_status');
      if(errorStatus === 'CITADEL_DATA_ERROR'){
        console.log("Invalid data from Citadel ");
        this.isInvalidCitadelData = true;
        this.citadelTrackEvent('import system got invalid candidate data', 'data error', true, true);
        jQuery('#empWaitModal').modal('hide');
        jQuery('#empCitadelErrorModal').modal('show');
      }
      if(errorStatus === 'CITADEL_IMPORT_SYSTEM_ERROR'){
        jQuery('#empWaitModal').modal('hide');
        this.citadelTrackEvent('import system unable to communicate', 'import error', true, true);
        jQuery('#empCitadelErrorModal').modal('show');
      }
      
    });
  }

  prepopulateCitadelEmpData(employmentsData) {
    console.log('prepopulateCitadelEmpData employmentsData ', employmentsData);
    let citadelEmpData = employmentsData[0];

    let countryCode = citadelEmpData['company']['address']['country'];
    /*if (!countryCode) {
      countryCode = 'US';//Default to US
    }*/
    (<FormControl>this.employmentForm.controls['employmentSubForm']['controls'][this.currentRow].controls['countryCode']).setValue(countryCode);
    (<FormControl>this.employmentForm.controls['employmentSubForm']['controls'][this.currentRow].controls['city']).setValue(citadelEmpData['company']['address']['city']);
    (<FormControl>this.employmentForm.controls['employmentSubForm']['controls'][this.currentRow].controls['stateCode']).setValue(citadelEmpData['company']['address']['state']);

    let startDate = citadelEmpData['start_date'];
    startDate = dateUtils.getDateFromYYYYMMDD(startDate);
    let jobFrom = this._es.getFormatedDate(startDate.getTime(), this.getDateFormat());
    (<FormControl>this.employmentForm.controls['employmentSubForm']['controls'][this.currentRow].controls['companyName']).setValue(citadelEmpData['company']['name']);
    (<FormControl>this.employmentForm.controls['employmentSubForm']['controls'][this.currentRow].controls['jobFrom']).setValue(jobFrom);
    (<FormControl>this.employmentForm.controls['employmentSubForm']['controls'][this.currentRow].controls['jobTitle']).setValue(citadelEmpData['job_title']);
  }

  clearEmploymentData(obj, currentIteration) {
    const control = <FormArray>this.employmentForm.controls['employmentSubForm'];
    const formGroup = <FormGroup>control.controls[currentIteration];
    this.removeCurrentEmp(obj, currentIteration);
    (<FormControl>formGroup.controls['datetocontact']).setValue('');
    this.dateConfigs[currentIteration] = {};
    this.selectedDates[this.currentRow] = '';

    if (this.isConsentCountryValue()) {
      let countryData = JSON.parse(this.consentCountry);
      (<FormControl>formGroup.controls['countryName']).setValue(countryData['country_name']);
      (<FormControl>formGroup.controls['countryCode']).setValue(countryData['country_code']);
    }
  }


  private isEligibleToAddElementForFirstIteration(element): boolean {
    if (element['is_current'] || (element['is_current'] === undefined && element['End Date'] === 'Present')) {
      return true;
    }
    return false;
  }

  private isEligibleToAddElementForFurtherIterations(element): boolean {
    if (!element['is_current'] || (element['is_current'] === undefined && element['End Date'] !== 'Present')) {
      return true;
    }
    return false;
  }

  private filterAtsData(type): boolean {
    let rowIndex = this.currentRow;
    let atleastOneDataAvailable = false;
    this.ATSData['display-data-list'].forEach(element => {
      element['display_card'] = false;
      let addElement = false;
      if (rowIndex === 0) {
        addElement = this.isEligibleToAddElementForFirstIteration(element);
      } else if (rowIndex > 0) {
        addElement = this.isEligibleToAddElementForFurtherIterations(element);
      }
      if (element['type'] === type /*|| element['type'] === '' || element['type'] === undefined */) {
        if (addElement) {
          element['display_card'] = true;
          atleastOneDataAvailable = true;
        }
      }
    });
    return atleastOneDataAvailable;
  }

  empCategorySwipe(obj) {

     /**************************************************************************************/
     /* BELOW PIECE OF CODE HAS BEEN COMMENTED TO AVOID SWIPE LEFT FROM CATEGORY PAGE */
     /**************************************************************************************/
  }

  /**
   * update multiple position list for the current row.
   */
  private UpdateMultiplePositionCurrentRow(currentServerData) {
    if (!this._utilService.isEmpty(currentServerData) && !this._utilService.isEmpty(currentServerData['employment_position_list'])) {

      let data = currentServerData['employment_position_list'];
      this.employeePositionList[this.currentRow] = [];
      let index = 0;
      for (let currentData of data) {
        let option = {};
        if (!this._utilService.isEmpty(currentData['id'])) {
          option['id'] = currentData['id'];
        } else {
          option['id'] = undefined;
        }
        option['empPosition'] = currentData['position'];
        option['intlStartDate'] = this._es.getLangFormatedDate(currentData['start-date'], this.getDateFormat());
        option['intlEndDate'] = this._es.getLangFormatedDate(currentData['end-date'], this.getDateFormat());
        if (this._utilService.isEmpty(currentData['end-date'])) {
          option['intlEndDate'] = 'Present';
        }
        option['status'] = true;
        this.employeePositionList[this.currentRow][index++] = option;
      }
      let rand = this._utilService.getCryptoRandom();
      this.positionchange = rand;
    }
  }

  private makeDynamicFormGroupControls(obj, options) {
    let row = obj['indexDetail']['row'];
    const subFormArray = <FormArray>this.employmentForm.controls['employmentSubForm'];
    let subFormArrayLength = subFormArray.length;
    const subFormGroup = <FormGroup>subFormArray.controls[row];

    let givenEmpType = this.empTypes[row];
    let companyNameVal: string = '';
    let jobTitleVal: string = '';
    let jobFromVal: string = '';
    let countryNameVal: string = '';
    let countryCodeVal: string = '';
    let jobToVal: string = '';
    let cityVal: string = '';
    let stateNameVal: string = '';
    let stateCodeVal: string = '';
    let cityStateVal: string = '';
    let zipCodeVal: string = '';
    let supervisorNameVal: string = '';
    let supervisorNoVal: string = '';
    let datetocontactVal: string = '';
    let permissionTocontact: any = ''
    let whentocontact: any = '';
    let companyNameInLocalLangVal: any = '';
    let companyAddressVal: any = '';
    let managerNameVal: any = '';
    let positionVal: any = '';
    let managerCountryCodeVal: any = '';
    let phoneNumberVal: any = '';
    let managerEmailVal: any = '';
    let driverDutiesVal: any = '';
    let reasonForLeavingVal: any = '';
    let streetAddressVal: any = '';
    let employmentDataProvider: any;
    let managerResidenceCountryNameVal: any = '';
    let managerResidenceCountryCodeVal: any = '';
    let managerResidenceStateNameVal: any = '';
    let managerResidenceStateCodeVal: any = '';

    if (options) {
      companyNameVal = options['name'];
      jobTitleVal = options['job-title'];
      if (options['country_name'] !== undefined) {
        countryNameVal = options['country_name'];
      }

      if (options['company_name_in_local_lang'] !== undefined) {
        companyNameInLocalLangVal = options['company_name_in_local_lang'];
      }

      if (options['manager_name'] !== undefined) {
        managerNameVal = options['manager_name'];
      }

      if (options['manager_position'] !== undefined) {
        positionVal = options['manager_position'];
      }

      if (options['manager_email'] !== undefined) {
        managerEmailVal = options['manager_email'];
      }

      if (options['manager_country_code'] !== undefined) {
        managerCountryCodeVal = options['manager_country_code'];
      }

      if (options['manager_phone_number'] !== undefined) {
        phoneNumberVal = options['manager_phone_number'];
      }

      if (options['company_address'] !== undefined) {
        if (this.isPaDOTDirectEnabled && this.DOMESTIC_COUNTRY_CODE === options['country']) {
          streetAddressVal = options['company_address'];
        }
        companyAddressVal = options['company_address'];
      }

      if (options['country'] !== undefined) {
        countryCodeVal = options['country'];
        this.selectedCurrentEmpCountry = countryCodeVal;
      }

      if (options['start-date']) {
        jobFromVal = this._es.getFormatedDate(options['start-date'], this.getDateFormat());
      }

      if (options['end-date']) {
        jobToVal = this._es.getFormatedDate(options['end-date'], this.getDateFormat());
      }
      if (options['has_driver_duties'] !== undefined) {
        driverDutiesVal = options['has_driver_duties'];
      }

      if (options['reason_for_leaving'] !== undefined) {
        reasonForLeavingVal = options['reason_for_leaving'];
      }

      if (options['street_address'] !== undefined) {
        streetAddressVal = options['street_address'];
      }
      
      if (options['manager_residence_country'] !== undefined) {
        managerResidenceCountryCodeVal = options['manager_residence_country'];
        this.isIntlSUPCountrySpecificDataRequired = true;
      }
      
      if (options['manager_residence_country_name'] !== undefined) {
        managerResidenceCountryNameVal = options['manager_residence_country_name'];
      }

      if (options['manager_residence_state_name'] !== undefined) {
        managerResidenceStateNameVal = options['manager_residence_state_name'];
      }

      if (options['manager_residence_state'] !== undefined) {
        managerResidenceStateCodeVal = options['manager_residence_state'];
        this.isIntlSUPCountrySpecificDataRequired = true;
      }

      stateNameVal = options['state_name'] || '';
      cityVal = options['city'] || '';
      stateCodeVal = options['state_code'];
      zipCodeVal = options['zip'] || '';
      supervisorNameVal = options['contact'];
      supervisorNoVal = options['contact-number'];
      permissionTocontact = options['permission-to-contact'];
      whentocontact = new Date(options['when-to-contact']);
    }

    if (givenEmpType === 'selfEmployed' && this.isPaDOTDirectEnabled) {
      let companyName: FormControl = new FormControl(companyNameVal, Validators.required);
      let jobTitle: FormControl = new FormControl(jobTitleVal, Validators.required);
      let countryName: FormControl = new FormControl(countryNameVal, Validators.required);
      let countryCode: FormControl = new FormControl(countryCodeVal);
      let city: FormControl = new FormControl(cityVal, Validators.required);
      let stateName: FormControl = new FormControl(stateNameVal, Validators.required);
      let stateCode: FormControl = new FormControl(stateCodeVal, Validators.required);
      let zipCode: FormControl = new FormControl(zipCodeVal);
      let streetAdrress: FormControl = new FormControl(streetAddressVal, Validators.compose([Validators.required]));
      let managerName: FormControl = new FormControl(managerNameVal, Validators.required);
      let position: FormControl = new FormControl(positionVal, Validators.required);
      let managerCountryCode: FormControl = new FormControl(managerCountryCodeVal, Validators.required);
      let phoneNumber: FormControl = new FormControl(phoneNumberVal, Validators.required);
      let managerEmail: FormControl = new FormControl(managerEmailVal, Validators.required);
      let driverDuties: FormControl = new FormControl(driverDutiesVal, Validators.compose([Validators.required]));
      let reasonForLeaving: FormControl = new FormControl(reasonForLeavingVal, Validators.required);
      let companyAddress: FormControl = new FormControl(companyAddressVal, Validators.required);
 
      subFormGroup.addControl('companyName', companyName);
      subFormGroup.addControl('jobTitle', jobTitle);
      subFormGroup.addControl('countryName', countryName);
      subFormGroup.addControl('countryCode', countryCode);
      subFormGroup.addControl('city', city);
      subFormGroup.addControl('stateName', stateName);
      subFormGroup.addControl('stateCode', stateCode);
      subFormGroup.addControl('zipCode', zipCode);      
      subFormGroup.addControl('managerName', managerName);
      subFormGroup.addControl('position', position);
      subFormGroup.addControl('managerCountryCode', managerCountryCode);
      subFormGroup.addControl('phoneNumber', phoneNumber);
      subFormGroup.addControl('managerEmail', managerEmail);
      subFormGroup.addControl('driverDuties', driverDuties);
      subFormGroup.addControl('reasonForLeaving', reasonForLeaving);
      subFormGroup.addControl('companyAddress', companyAddress);

      if (this.isINTLSUPComponentAvailable && this.isIntlSUPCountrySpecificDataRequired) {
        let managerResidenceCountry: FormControl = new FormControl(managerResidenceCountryNameVal, Validators.required);
        let managerResidenceCountryCode: FormControl = new FormControl(managerResidenceCountryCodeVal, Validators.required);
        let managerResidenceStateName: FormControl = new FormControl(managerResidenceStateNameVal, Validators.required);
        let managerResidenceStateCode: FormControl = new FormControl(managerResidenceStateCodeVal, Validators.required);
        
        subFormGroup.addControl('managerResidenceCountry', managerResidenceCountry);
        subFormGroup.addControl('managerResidenceCountryCode', managerResidenceCountryCode);
        subFormGroup.addControl('managerResidenceStateName', managerResidenceStateName);
        subFormGroup.addControl('managerResidenceStateCode', managerResidenceStateCode);
      } else if (this.isINTLSUPComponentAvailable && obj['isCountryDecisionChanged']) {
        let existControlsCheckArr = [];
        existControlsCheckArr = ['managerResidenceCountry', 'managerResidenceCountryCode', 'managerResidenceStateName', 'managerResidenceStateCode'];
        
        this.deleteExistingControllers(existControlsCheckArr, subFormGroup);
      }
      
      if (this.isPaDOTDirectEnabled) {
        subFormGroup.addControl('streetAddress', streetAdrress);
      }

      let existControlsCheckArr = [];
      if(options !== undefined && options['country'] === this.DOMESTIC_COUNTRY_CODE){
        existControlsCheckArr = ['permissionToContact', 'companyAddress'];
      } else{
        existControlsCheckArr = ['permissionToContact'];
      }      
      this.deleteExistingControllers(existControlsCheckArr, subFormGroup);

      if (row == 0 && this.currentJob) {
        let existControlsCheckArr = ['jobTo'];
        this.deleteExistingControllers(existControlsCheckArr, subFormGroup);
        let jobFrom: FormControl = new FormControl(jobFromVal, Validators.required);
        subFormGroup.addControl('jobFrom', jobFrom);
      } else {
        let jobTo: FormControl = new FormControl(jobToVal, Validators.compose([Validators.required]));
        let jobFrom: FormControl = new FormControl(jobFromVal, Validators.compose([Validators.required]));
        subFormGroup.addControl('jobFrom', jobFrom);
        subFormGroup.addControl('jobTo', jobTo);
      }
    } else if (givenEmpType === 'current' || givenEmpType === 'prior' || (givenEmpType === 'selfEmployed' && this.selfEmploymentPreferenceEnabled)) {
      let companyName: FormControl = new FormControl(companyNameVal, Validators.required);
      let jobTitle: FormControl = new FormControl(jobTitleVal, Validators.required);
      let countryName: FormControl = new FormControl(countryNameVal, Validators.required);
      let countryCode: FormControl = new FormControl(countryCodeVal);
      let city: FormControl = new FormControl(cityVal, Validators.required);
      let stateName: FormControl = new FormControl(stateNameVal, Validators.required);
      let stateCode: FormControl = new FormControl(stateCodeVal, Validators.required);
      let zipCode: FormControl = new FormControl(zipCodeVal);
      let jobFrom: FormControl = new FormControl(jobFromVal, Validators.compose([Validators.required]));
      let streetAdrress: FormControl = new FormControl(streetAddressVal, Validators.compose([Validators.required]));

      let existControlsCheckArr = ['jobTo'];
      this.deleteExistingControllers(existControlsCheckArr, subFormGroup);

      let companyNameInLocalLang: FormControl = new FormControl(companyNameInLocalLangVal, Validators.required);
      let companyAddress: FormControl = new FormControl(companyAddressVal, Validators.required);
      let managerName: FormControl = new FormControl(managerNameVal, Validators.required);
      let position: FormControl = new FormControl(positionVal, Validators.required);
      let managerCountryCode: FormControl = new FormControl(managerCountryCodeVal, Validators.required);
      let phoneNumber: FormControl = new FormControl(phoneNumberVal, Validators.required);
      let managerEmail: FormControl = new FormControl(managerEmailVal, Validators.required);
      
      if (givenEmpType !== 'selfEmployed' || (givenEmpType === 'selfEmployed' && this.selfEmploymentPreferenceEnabled)) {
        subFormGroup.addControl('countryName', countryName);
        subFormGroup.addControl('countryCode', countryCode);
        subFormGroup.addControl('city', city);
        subFormGroup.addControl('stateName', stateName);
        subFormGroup.addControl('stateCode', stateCode);
        subFormGroup.addControl('zipCode', zipCode);
        
        if (this.isPaDOTDirectEnabled) {
          subFormGroup.addControl('streetAddress', streetAdrress);
        }
      }

      if (givenEmpType === 'selfEmployed') {
        if (row === 0 && this.currentJob) {
          let existControlsCheckArr = ['jobTo'];
          this.deleteExistingControllers(existControlsCheckArr, subFormGroup);
          let jobFrom: FormControl = new FormControl(jobFromVal, Validators.required);
          subFormGroup.addControl('jobFrom', jobFrom);
        } else {
          let jobTo: FormControl = new FormControl(jobToVal, Validators.compose([Validators.required]));
          let jobFrom: FormControl = new FormControl(jobFromVal, Validators.compose([Validators.required]));
          subFormGroup.addControl('jobFrom', jobFrom);
          subFormGroup.addControl('jobTo', jobTo);
        }
      }

      if (givenEmpType !== 'selfEmployed' || (givenEmpType === 'selfEmployed' && this.isCheckSelfEmploymentEnabled[row])) {
        subFormGroup.addControl('companyName', companyName);
        subFormGroup.addControl('jobTitle', jobTitle);
        subFormGroup.addControl('jobFrom', jobFrom);
        subFormGroup.addControl('companyNameInLocalLang', companyNameInLocalLang);
        subFormGroup.addControl('companyAddress', companyAddress);
        subFormGroup.addControl('managerName', managerName);
        subFormGroup.addControl('position', position);
        subFormGroup.addControl('managerCountryCode', managerCountryCode);
        subFormGroup.addControl('phoneNumber', phoneNumber);
        subFormGroup.addControl('managerEmail', managerEmail);
        
        if (this.isINTLSUPComponentAvailable && this.isIntlSUPCountrySpecificDataRequired) {
          let managerResidenceCountry: FormControl = new FormControl(managerResidenceCountryNameVal, Validators.required);
          let managerResidenceCountryCode: FormControl = new FormControl(managerResidenceCountryCodeVal, Validators.required);
          let managerResidenceStateName: FormControl = new FormControl(managerResidenceStateNameVal, Validators.required);
          let managerResidenceStateCode: FormControl = new FormControl(managerResidenceStateCodeVal, Validators.required);
          
          subFormGroup.addControl('managerResidenceCountry', managerResidenceCountry);
          subFormGroup.addControl('managerResidenceCountryCode', managerResidenceCountryCode);
          subFormGroup.addControl('managerResidenceStateName', managerResidenceStateName);
          subFormGroup.addControl('managerResidenceStateCode', managerResidenceStateCode);
        } else if (this.isINTLSUPComponentAvailable && obj['isCountryDecisionChanged']) {
          let existControlsCheckArr = [];          
          existControlsCheckArr = ['managerResidenceCountry', 'managerResidenceCountryCode', 'managerResidenceStateName', 'managerResidenceStateCode'];
          
          this.deleteExistingControllers(existControlsCheckArr, subFormGroup);
        }

        if (this.isCheckSelfEmploymentEnabled[row]) {
          let permissionToContact: FormControl = new FormControl(permissionTocontact, Validators.required);
          subFormGroup.addControl('permissionToContact', permissionToContact);
        }
      }

      if (givenEmpType !== 'selfEmployed' && options !== undefined && options['country'] === this.DOMESTIC_COUNTRY_CODE) {
        let existControlsCheckArr = [];
        if (this.isPaDOTDirectEnabled) {
          existControlsCheckArr = ['companyNameInLocalLang', 'companyAddress'];
        } else {
          existControlsCheckArr = ['companyNameInLocalLang', 'companyAddress'];
        }
        this.deleteExistingControllers(existControlsCheckArr, subFormGroup);
      }

      if (givenEmpType === 'selfEmployed' && !this.isCheckSelfEmploymentEnabled[row]) {
        let existControlsCheckArr = ['companyNameInLocalLang', 'companyAddress', 'managerName',
          'position', 'managerCountryCode', 'phoneNumber', 'managerEmail', 'companyName', 'managerResidenceCountry', 'managerResidenceCountryCode', 'managerResidenceStateName', 'managerResidenceStateCode'];
        this.deleteExistingControllers(existControlsCheckArr, subFormGroup);
      }

      if (givenEmpType === 'current') {
        let existControlsCheckArr = ['jobTo'];
        this.deleteExistingControllers(existControlsCheckArr, subFormGroup);
        let permissionToContact: FormControl = new FormControl(permissionTocontact, Validators.required);
        subFormGroup.addControl('permissionToContact', permissionToContact);
      }

      if (givenEmpType === 'prior') {
        let existControlsCheckArr = ['permissionToContact'];
        this.deleteExistingControllers(existControlsCheckArr, subFormGroup);
        let jobTo: FormControl = new FormControl(jobToVal, Validators.compose([Validators.required]));

        subFormGroup.addControl('jobTo', jobTo);
      }

      if (this.dotLimitEmploymentVerification && (givenEmpType === 'current' || givenEmpType === 'prior')) {
        let driverDuties: FormControl = new FormControl(driverDutiesVal, Validators.compose([Validators.required]));
        subFormGroup.addControl('driverDuties', driverDuties);
      } else {
        let existControlsCheckArr = ['driverDuties'];
        this.deleteExistingControllers(existControlsCheckArr, subFormGroup);
      }

      if ((this.isPaDOTDirectEnabled || this.isReasonForLeavingMandatory ) && (givenEmpType === 'current' || givenEmpType === 'prior' || (givenEmpType === 'selfEmployed' && this.selfEmploymentPreferenceEnabled))) {
        let reasonForLeaving: FormControl = new FormControl(reasonForLeavingVal, Validators.required);
        subFormGroup.addControl('reasonForLeaving', reasonForLeaving);
      } else {
        let existControlsCheckArrReasonForLeaving = ['reasonForLeaving'];
        this.deleteExistingControllers(existControlsCheckArrReasonForLeaving, subFormGroup);
      }
      
      if (this.isPlaidPreferenceEnabled && givenEmpType === 'current') {
        let empDataProvider: FormControl = new FormControl(employmentDataProvider, Validators.required);
        subFormGroup.addControl('empDataProvider', empDataProvider);
      } else {
        let existControlsCheckArr = ['empDataProvider'];
        this.deleteExistingControllers(existControlsCheckArr, subFormGroup);
      }
    } else if (givenEmpType === 'selfEmployed' || givenEmpType === 'unemployed' || givenEmpType === 'inSchool' || givenEmpType === 'internship' ||  givenEmpType === 'neverEmployed') {
      let existControlsCheckArr = ['permissionToContact', 'companyName', 'jobTitle', 'driverDuties', 'reasonForLeaving', 'countryName', 'countryCode', 'city', 'stateName', 'stateCode'];
      this.deleteExistingControllers(existControlsCheckArr, subFormGroup);

      if (row == 0 && this.currentJob) {
        let existControlsCheckArr = ['jobTo'];
        this.deleteExistingControllers(existControlsCheckArr, subFormGroup);
        let jobFrom: FormControl = new FormControl(jobFromVal, Validators.required);
        subFormGroup.addControl('jobFrom', jobFrom);
      } else {
        let jobTo: FormControl = new FormControl(jobToVal, Validators.compose([Validators.required]));
        let jobFrom: FormControl = new FormControl(jobFromVal, Validators.compose([Validators.required]));
        subFormGroup.addControl('jobFrom', jobFrom);
        subFormGroup.addControl('jobTo', jobTo);
      }
      
      // TODO 271858 - Ask additional address details - Add country, state, city and zip if the isSchoolAddressDetailsRequired flag is true
      if (givenEmpType === 'inSchool' && this.isInSchoolAddressDetailsRequired) {
        let schoolCountryName: FormControl = new FormControl(countryNameVal, Validators.required);
        let schoolCountryCode: FormControl = new FormControl(countryCodeVal);
        let schoolCity: FormControl = new FormControl(cityVal, Validators.required);
        let schoolStateName: FormControl = new FormControl(stateNameVal, Validators.required);
        let schoolStateCode: FormControl = new FormControl(stateCodeVal, Validators.required);
        let schoolZipCode: FormControl = new FormControl(zipCodeVal, Validators.required);
        
        subFormGroup.addControl('schoolCountryName', schoolCountryName);
        subFormGroup.addControl('schoolCountryCode', schoolCountryCode);
        subFormGroup.addControl('schoolCity', schoolCity);
        subFormGroup.addControl('schoolStateName', schoolStateName);
        subFormGroup.addControl('schoolStateCode', schoolStateCode);
        subFormGroup.addControl('schoolZipCode', schoolZipCode);
      }
    }
  }

  private deleteExistingControllers(existControlsCheckArr, subFormGroup: FormGroup) {
    for (let i = 0; i <= existControlsCheckArr.length; i++) {
      if (subFormGroup.contains(existControlsCheckArr[i])) {
        subFormGroup.removeControl(existControlsCheckArr[i]);
      }
    }
    return true;
  }

  private processAtsDataToShowOnCategorySave(obj): boolean {    
    if (!this.isReview) {
      if (!this.ATSData['showATSdata']['display']) {
        this.ATSData['showATSdata']['display'] = false;
        let found = this.filterAtsData(obj['type']);
        if (found) {
          this.ATSData['showATSdata']['display'] = true;
          this.sliderIndex = this.empSteps.indexOf('category');
          this.setSlider(this.sliderIndex, 'right', this.currentRow);
          return true;
        }
      }
    }
    this.ATSData['showATSdata']['display'] = false;

    return false;
  }

  private afterCategorySave(obj, item?) {

    if ((!((this.isPresentEmpAutoFulfilment || this.isPlaidPreferenceEnabled) && obj['type'] === 'current') || !!this.isOptOutCitadelData[this.currentRow] || !!this.isOptOutAutoFullEmpData[this.currentRow]) && this.processAtsDataToShowOnCategorySave(obj)) {
      return;
    }
    if (this.isPlaidPreferenceEnabled && obj['type'] === 'current' && !this.isOptOutAutoFullEmpData[this.currentRow]) {
      this.sliderIndex = this.empSteps.indexOf('emp-details');
    } else if (!this.isReviewEdit && this.isPresentEmpAutoFulfilment && obj['type'] === 'current' && !this.isOptOutCitadelData[this.currentRow]) {
      this.sliderIndex = this.empSteps.indexOf('payroll-lookup');
    } else if ((obj['type'] === 'current' || obj['type'] === 'prior') || (obj['type'] === 'selfEmployed' && (this.isPaDOTDirectEnabled || this.selfEmploymentPreferenceEnabled))) {
      this.sliderIndex = this.empSteps.indexOf('country');
    } else if (obj['type'] === 'selfEmployed' && this.isPaDOTDirectEnabled) {
      this.sliderIndex = this.empSteps.indexOf('reporting-manager-details');
    } else {
      if (obj['type'] === 'neverEmployed') {
        if (!this.isReview) {
          this.routeToNextStage();
        }
        return;
      } else
        this.sliderIndex = this.empSteps.indexOf('job-period');
    }
    if (this._utilService.isEmpty(this.employeePositionList) || this._utilService.isEmpty(this.employeePositionList[this.currentRow])) {
      this.employeePositionList[this.currentRow] = [];
    }
    if (this.isReviewAdd) {
      this.addMorePositions = false;
      let rand = this._utilService.getCryptoRandom();
      this.positionchange = rand;
    }
    this.isINTLCompNameInLocalArr[this.currentRow] = false;
    this.isINTLEmployeeIdArr[this.currentRow] = false;
    this.isINTLDepartmentArr[this.currentRow] = false;
    this.isINTLFieldsCaptured[this.currentRow] = false;
    this.isCustomFormCaptured[this.currentRow] = false;
    this.intlAbaCbcArr[this.currentRow] = false;
    // this.isINTLEmployeeId = false;
    // this.isINTLCompNameInLocal = false;
    // this.isINTLDepartment = false;
    this.setSlider(this.sliderIndex, 'right', this.currentRow);
    this.alertUserOnBlankPage(this.currentSlide);
  }
  private afterPayrollLookupSave(obj, item?) {
    if ((!!this.isOptOutCitadelData[this.currentRow] || !!this.isOptOutAutoFullEmpData[this.currentRow]) && this.processAtsDataToShowOnCategorySave(obj)) {
      return;
    }
    if (!!this.isCitadelData[this.currentRow]) {
      this.afterJobTitleSave(obj, item);
    } else {
      this.sliderIndex = this.empSteps.indexOf('country');
      this.setSlider(this.sliderIndex, 'right', this.currentRow);
      this.alertUserOnBlankPage(this.currentSlide);
    }
  }

  private afterEmpDetailsSave(obj, item?){
    this.sliderIndex = this.empSteps.indexOf('data-provider');
    this.setSlider(this.sliderIndex, 'right', this.currentRow);
  }

  private afterDataProviderSave(obj, item?) {
    if (!!this.isOptOutCitadelData[this.currentRow] && this.processAtsDataToShowOnCategorySave(obj)) {
      return;
    }
    
    if (!!this.isCitadelData[this.currentRow] || !!this.showPlaidData[this.currentRow]) {
      this.afterJobTitleSave(obj, item);
      this.selectedEmpDetailsDate = null;
    } else {
      this.sliderIndex = this.empSteps.indexOf('country');
      this.setSlider(this.sliderIndex, 'right', this.currentRow);
      this.alertUserOnBlankPage(this.currentSlide);
    }
  }  

  empVerification(obj): void {
    if (!this.isAjaxCallProccess) {
      obj['trakEventMsg'] = 'verification is provided';
      let cloneObj = (JSON.parse(JSON.stringify(obj)));
      let componetStateObj = obj['COMPONENT_STATE'];
      let verificationFlag: boolean;
      obj = this._es.mapToOption(obj);
      if (!obj['confirm']) {
        //this.routeToNextStage();
        obj['service_action'] = 'PERSIST';
        this.initPayload(obj);
      }
      else {
        this.afterVerificationSave(obj);
      }
      // this.initPayload(obj);
    }
  }

  private getCurrnetFormGroup(index) {
    const control = <FormArray>this.employmentForm.controls['employmentSubForm'];
    const formGroup = <FormGroup>control.controls[index];
    return formGroup;
  }

  private afterVerificationSave(obj, item?) {
    this.sliderIndex = this.empSteps.indexOf('category');
    let row = obj['indexDetail']['row'];

    if (obj['confirm']) {
      this.setSlider(this.sliderIndex, 'right', row);
      this.alertUserOnBlankPage(this.currentSlide);
    }
    else {
      this.routeToNextStage();
    }
  }

  private checkparticulararraypresentation(row) {
    let keynew = row;
    let flag;
    let useroptionLength = this.datetoContact.length;
    if (useroptionLength === 0) {
      return true;
    }

    for (let i = 0; i < useroptionLength; i++) {
      let oldkey = Object.keys(this.datetoContact[i]);
      if (i === keynew - 1) {
        flag = false;
      } else {
        flag = true;
      }
    }
    return flag;
  }

  empVerificationConfirm(obj): void {
    if (!this.isAjaxCallProccess) {
      let i: number = this.currentRow = obj['indexDetail']['row'];
      this.activeEmpType = obj['type'];
      if (this.prefflag) {
        let objpro = { 'nodatetocontact': true };
        let data = this.checkparticulararraypresentation(i);
        if (data) {
          this.datetoContact.push(objpro);
        } else {
          let objper = { 'nodatetocontact': true };
          this.datetoContact[i] = objper;
        }
        obj['trakEventMsg'] = 'verification is confirmed';
        this.decisionchangeindatetocontact(obj, i);
      }
      if (this.isCitadelData[this.currentRow]) {
        jQuery('#empWaitModal').modal('show');
        obj['service_action'] = 'PERSIST';
        this.initPayload(obj);
      } else {
        this.afterVerificationConfirmSave(obj);
      }     
      // this.initPayload(obj);      
      // this.afterVerificationSave(obj);
    }
  }

  private decisionchangeindatetocontact(obj, i) {
    let isDecisionChanged: boolean = this.isDecisionChanged(obj, 'permission-to-contact', 'permissionToContact', i);
    if (isDecisionChanged) {
      if (obj['permissionToContact'] === 'YES') {
        const control = <FormArray>this.employmentForm.controls['employmentSubForm'];
        const formGroup = <FormGroup>control.controls[i];
        formGroup.removeControl('datetocontact');
        obj['datetocontact'] = '';
      }
    }
  }

  empVerificationConfirmDisaggreeOpen(obj) {
    if (!this.isAjaxCallProccess) {
      let currentslide = this.currentSlide;
      let currentindex = currentslide.substring(currentslide.indexOf('_') + 1);
      let index = parseInt(currentindex, 10);

      if (this.eapreferencevalue === 'YES') {
        this._recordidservice.createmodalboxr(index);
        this.prefflag = true;
      } else if (this.eapreferencevalue === 'No') {
        this.prefflag = true;
        this.empVerificationConfirm(obj);
      } else {
        this.empVerificationConfirm(obj);
      }
    }
  }

  private datetocontact() {
    let currentslide = this.currentSlide;
    let currentindex = currentslide.substring(currentslide.indexOf('_') + 1);
    let index = parseInt(currentindex, 10);
    this.datecontact = true;

    const control = <FormArray>this.employmentForm.controls['employmentSubForm'];
    const formGroup = <FormGroup>control.controls[index];
    let datetocontact: FormControl = new FormControl('', Validators.compose([Validators.required]));
    formGroup.addControl('datetocontact', datetocontact);
  }

  private exidatetocontact(i) {
    const control = <FormArray>this.employmentForm.controls['employmentSubForm'];
    const formGroup = <FormGroup>control.controls[i];
    let datetocontact: FormControl = new FormControl('', Validators.compose([Validators.required]));
    formGroup.addControl('datetocontact', datetocontact);
    this.existingform = false;
  }

  datetocontactnext(obj) {
    let serverData: Object[] = [];
    serverData = (JSON.parse(JSON.stringify(this._es.getServerData())));
    let currentServerData = [];
    for (let i = 0; i <= this.currentRow; i++) {
      currentServerData.push(serverData[i]);
    }
    this.currentJobDetails = this._es.getAllCurrentJob(serverData);
    obj['trakEventMsg'] = 'date to contact is provided';
    let i: number = this.currentRow = obj['indexDetail']['row'];
    this.datecontact = false;
    // this.initPayload(obj);
    if (this.isCitadelData[this.currentRow]) {
      jQuery('#empWaitModal').modal('show');
      obj['service_action'] = 'PERSIST';
      this.initPayload(obj);
    } else {
      this.afterVerificationConfirmSave(obj);
    }
    //this.afterVerificationConfirmSave(obj);
    let id = '#disagreeModel_' + i;
    this.closeModal(id);
  }

  empVerificationConfirmSwipe(obj) {
    let direction = 'left';
    let row = this.currentRow || 0;

    if (this.isCitadelData[this.currentRow]) {
      this.sliderIndex = this.empSteps.indexOf('payroll-lookup');
    } else {
      this.sliderIndex = this.empSteps.indexOf('city-state');
    }

    
    if (row == 0) {
      this.empCategories = this._utilService.getArrayFromJsonString(this._ts.translate(EmploymentConfig.EMPLOYMENT_CATEGORIES_CURRENT));
    } else {
      this.empCategories = this._utilService.getArrayFromJsonString(this._ts.translate(EmploymentConfig.EMPLOYMENT_CATEGORIES));
    }
    this.setSlider(this.sliderIndex, direction, this.currentRow);
  }

  private noEmployentVerification() {
    this.routeToNextStage();
  }

  private afterVerificationConfirmSave(obj, item?): void {
    //   let objForINTL = {};
    // objForINTL['country'] = obj['countryCode'];
    // this.prepareIntlFlagArray(objForINTL);
    this.sliderIndex = this.empSteps.indexOf('company-name');
    this.setSlider(this.sliderIndex, 'right', this.currentRow);
    this.alertUserOnBlankPage(this.currentSlide);
  }

  empCompanyName(obj): void {
    if (!this.isAjaxCallProccess) {
      obj['trakEventMsg'] = 'companyname is provided';
      obj = this.currentPriorConfirmflagCheck(obj);
      obj = this._es.mapToOption(obj);
      let i: number = this.currentRow = obj['indexDetail']['row'];
      this.afterCompanyNameSave(obj);
      // this.initPayload(obj);
    }
  }

  private afterCompanyNameSave(obj, item?): void {
    let row = obj['indexDetail']['row'];
    
    if(obj['isCompanyDecisionChanged']) {
      if (!!obj['selectedCompanyList'] && !!obj['selectedCompanyList']['id']) {
        this.selectedCompany[row] = obj['selectedCompanyList'];
      } else {
        this.selectedCompany[row] = '';
      }
    }

    this.disableFutureDateFlags();
    let currnetFormGroup = this.getCurrnetFormGroup(obj['indexDetail']['row']);
    if (!!(<FormControl>currnetFormGroup.controls['companyName'].value)) {
      if (obj['countryCode'] === this.DOMESTIC_COUNTRY_CODE && !this.isPaDOTDirectEnabled && !this.isINTLSUPComponentAvailable) {
        this.sliderIndex = this.empSteps.indexOf('job-period');
      } else {
        if (this.reportingManagerSkip) {
          if ((obj['type'] === 'current' || obj['type'] === 'prior') && this.isMultiplePositionEnabled(obj['countryCode'], obj['type'])) {
            this.sliderIndex = this.empSteps.indexOf('position');
          } else {
            this.sliderIndex = this.empSteps.indexOf('job-period');
          }
        } else {
          this.sliderIndex = this.empSteps.indexOf('reporting-manager-details');
        }
      }
      this.setSlider(this.sliderIndex, 'right', this.currentRow);
    }
    this.alertUserOnBlankPage(this.currentSlide);
  }

  empCompanyNameSwipe(obj) {
    let current = this.currentSlide;
    let row = this.currentRow || 0;
    let direction = 'left';
    this.disableFutureDateFlags();
    if (obj['type'] === 'selfEmployed' && this.isPaDOTDirectEnabled) {
      this.sliderIndex = this.empSteps.indexOf('city-state');
    } else if (obj['type'] === 'current' || (obj['type'] === 'selfEmployed' && (row === 0 && this.currentJob ) && this.isCheckSelfEmploymentEnabled[row])) {
      this.sliderIndex = this.empSteps.indexOf('verification-confirm');
    } else {
      this.sliderIndex = this.empSteps.indexOf('city-state');
    }

    this.setSlider(this.sliderIndex, direction, this.currentRow);
  }

  empJobTitle(obj): void {
    jQuery('#empWaitModal').modal('show');
    if (!this.isAjaxCallProccess) {
      obj['trakEventMsg'] = 'job title is provided';

      obj = this.currentPriorConfirmflagCheck(obj);
      obj = this._es.mapToOption(obj);
      this.activeEmpType = obj['type'];
      let i: number = this.currentRow = obj['indexDetail']['row'];

      obj['service_action'] = 'PERSIST';
      if (!!this.employeePositionList && !!this.employeePositionList[i] && this.employeePositionList[i].length > 0) {
        obj['employment_position_list'] = this.employeePositionList[i];
      }
      this.initPayload(obj);
    }
  }

  empCustomData(obj): void {
    jQuery('#empWaitModal').modal('show');
    if (!this.isAjaxCallProccess) {
      obj['trakEventMsg'] = 'custom data is provided';
      obj = this.currentPriorConfirmflagCheck(obj);
      obj = this._es.mapToOption(obj);
      this.activeEmpType = obj['type'];
      let i: number = this.currentRow = obj['indexDetail']['row'];

      obj['service_action'] = 'PERSIST';
      if (!!this.employeePositionList && !!this.employeePositionList[i] && this.employeePositionList[i].length > 0) {
        obj['employment_position_list'] = this.employeePositionList[i];
      }
      this.initPayload(obj);
    }
  }

  empJobTitleSwipe(obj) {
    let direction = 'left';
    let row = this.currentRow || 0;
    // this.currentSlide = this._arrowservice.getprevarrowarray('job-title', row);
    // this.currentSlideWithDirection = `${this.currentSlide}_${direction}`;

    const control = <FormArray>this.employmentForm.controls['employmentSubForm'];
    const subFormGroup = <FormGroup>control.controls[row];
    if (this.isINTLDepartmentArr[this.currentRow] || this.isINTLEmployeeIdArr[this.currentRow] || this.isINTLCompNameInLocalArr[this.currentRow]
      || ((this.empTypes[this.currentRow] === 'current' || this.empTypes[this.currentRow] === 'prior') /*&& this.selfEmploymentPreferenceEnabled*/
        && subFormGroup !== undefined && !!subFormGroup.controls['countryCode'] && subFormGroup.controls['countryCode'].value !== AppConstants.DOMESTIC_COUNTRY_CODE)) {
      if (this.isReasonForLeavingMandatory &&
        (this.empTypes[this.currentRow] === 'current' || this.empTypes[this.currentRow] === 'prior') &&
        this.isMultiplePositionEnabled(subFormGroup.controls['countryCode'].value, subFormGroup.controls['type'].value)) {
        this.sliderIndex = this.empSteps.indexOf('position');
      } else if ((this.faaSafetyCheckPreferenceEnabled && (this.empTypes[this.currentRow] === 'current' || this.empTypes[this.currentRow] === 'prior' || (this.empTypes[this.currentRow] === 'selfEmployed' && this.selfEmploymentPreferenceEnabled))) &&
        this.isMultiplePositionEnabled(subFormGroup.controls['countryCode'].value, subFormGroup.controls['type'].value)) {
        this.sliderIndex = this.empSteps.indexOf('position');
      } else {
        this.sliderIndex = this.empSteps.indexOf('job-period');
      }
    } else {
      this.sliderIndex = this.empSteps.indexOf('job-period');
    }

    this.setSlider(this.sliderIndex, direction, this.currentRow);
  }

  empCustomDataSwipe(obj) {
    let direction = 'left';
    if (this.isINTLDepartmentArr[this.currentRow] || this.isINTLEmployeeIdArr[this.currentRow] || this.isINTLCompNameInLocalArr[this.currentRow] 
      || ((obj['type'] === 'current' || obj['type'] === 'prior') && this.selfEmploymentPreferenceEnabled
        && obj['countryCode'] !== AppConstants.DOMESTIC_COUNTRY_CODE)) {
      this.sliderIndex = this.empSteps.indexOf('intl-emp');
    } else if ((obj['type'] === 'current' || obj['type'] === 'prior') && this.isMultiplePositionEnabled(obj['countryCode'], obj['type'])) {
      if (this.isReasonForLeavingMandatory && (obj['type'] === 'current' || obj['type'] === 'prior')) {
        this.sliderIndex = this.empSteps.indexOf('job-title');
      } else {
        this.sliderIndex = this.empSteps.indexOf('position');
      }
    } else if ((obj['type'] === 'selfEmployed' && !this.isCheckSelfEmploymentEnabled[this.currentRow] && !this.isPaDOTDirectEnabled) || obj['type'] === 'unemployed'
      || obj['type'] === 'inSchool' || obj['type'] === 'internship') {
      this.sliderIndex = this.empSteps.indexOf('job-period');
    } else {
      this.sliderIndex = this.empSteps.indexOf('job-title');
    }
    this.setSlider(this.sliderIndex, direction, this.currentRow);
  }

  private afterCustformSave(obj, item?): void {
    obj['forms'] = '';
    this.isFormDeleted[this.currentRow] = false;
    this.isCustomFormCaptured[this.currentRow] = true;
    this.afterJobTitleAndExtFormSave(obj, item);
  }

  private afterJobTitleSave(obj, item?): void {
    if (!!obj && !!obj['countryCode'] && !!item && !!item['id'] && obj['COMPONENT_STATE']['subStep'] !== 'intl-emp') {
      if (obj['type'] === 'inSchool' && this.isInSchoolAddressDetailsRequired) {
        this.afterJobTitleAndExtFormSave(obj, item);
      } else {
        let row = this.currentRow || 0;
        let objForINTL = {};
        objForINTL['id'] = item['id'];
        objForINTL['country'] = obj['countryCode'];
        this.prepareIntlFlagArray(objForINTL, row, obj, item);
      }
    } else {
      this.afterJobTitleAndExtFormSave(obj, item);
    }
  }

  private afterJobTitleAndExtFormSave(obj, item?): void {
    this.empCategories = this._utilService.getArrayFromJsonString(this._ts.translate(EmploymentConfig.EMPLOYMENT_CATEGORIES));
    this.localStorage.setItem('isATS', 'NO');
    if (this.atsArrayDataIndex !== -1 && this.selectedATSCardId !== ''
      && this.atsArrayDataIndex !== null && this.selectedATSCardId !== null
      && this.atsArrayDataIndex !== undefined && this.selectedATSCardId !== undefined) {
      this.updateATSRecord().subscribe((value) => {
        if (value.res) {
          console.log('Record Deleted Successfully !!');
        }
      });
    } else {

    }
    let skipExtForm = false;
    if (this.isAgencyExtensionForm && (!obj['jobType'] || obj['jobType'] !== 'agency')) {
      skipExtForm = true;
    }
    if (!this.isScopeAvailable && !this.isReview) {
      this.hideEmpWaitModal();
      if ((this.isINTLDepartmentArr[this.currentRow] || this.isINTLEmployeeIdArr[this.currentRow] || this.isINTLCompNameInLocalArr[this.currentRow]
        || ((this.empTypes[this.currentRow] === 'current' || this.empTypes[this.currentRow] === 'prior')
          && this.selfEmploymentPreferenceEnabled && obj['countryCode'] !== AppConstants.DOMESTIC_COUNTRY_CODE))
        && !this.isINTLFieldsCaptured[this.currentRow]) {
        this.sliderIndex = this.empSteps.indexOf('intl-emp');
        this.setSlider(this.sliderIndex, 'right', this.currentRow);
        this.alertUserOnBlankPage(this.currentSlide);
        return;
      } else if (this.isStandardFormExtension && !skipExtForm && !this.isCustomFormCaptured[this.currentRow] && (obj['type'] === 'current' || obj['type'] === 'prior' || obj['type'] === 'selfEmployed')) {
        this.sliderIndex = this.empSteps.indexOf('custform');
        this.setSlider(this.sliderIndex, 'right', this.currentRow);
        this.alertUserOnBlankPage(this.currentSlide);
        return;
      } else {
        this.routeToNextStage();
      }
    } else {
      let row = this.currentRow;
      if (row === 0 && !this.isNoCurrentJobVisited) {
        this.isNoCurrentJobVisited = true;
      }
      if (obj['type'] === 'current') {
        this._es.setCurrentJobObjCollection(row, obj);
        this.allCurrentJobsObj = null;
        this.allCurrentJobsObj = JSON.stringify(this._es.currentJobObj);
        // console.log(this.allCurrentJobsObj);
      }
      this.sliderIndex = this.empSteps.indexOf('category');

      if (this.activeEmpType === 'prior') {
        this.sliderIndex = this.empSteps.indexOf('category');
      }

      obj['trakEventMsg'] = 'job title is provided';

      if ((this.isINTLDepartmentArr[this.currentRow] || this.isINTLEmployeeIdArr[this.currentRow] || this.isINTLCompNameInLocalArr[this.currentRow] 
        || ((this.empTypes[this.currentRow] === 'current' || this.empTypes[this.currentRow] === 'prior')
          && this.selfEmploymentPreferenceEnabled && obj['countryCode'] !== AppConstants.DOMESTIC_COUNTRY_CODE))
        && !this.isINTLFieldsCaptured[this.currentRow]) {
        this.sliderIndex = this.empSteps.indexOf('intl-emp');
        this.setSlider(this.sliderIndex, 'right', this.currentRow);
        this.alertUserOnBlankPage(this.currentSlide);
        this.hideEmpWaitModal();
        return;
      } else if (this.isStandardFormExtension && !skipExtForm && !this.isCustomFormCaptured[this.currentRow] && (obj['type'] === 'current' || obj['type'] === 'prior' || obj['type'] === 'selfEmployed')) {
        this.sliderIndex = this.empSteps.indexOf('custform');
        this.setSlider(this.sliderIndex, 'right', this.currentRow);
        this.alertUserOnBlankPage(this.currentSlide);
        this.hideEmpWaitModal();
        return;
      }
      if (skipExtForm) {
        this.deleteExtensionForm(obj, this.currentRow);
      }

      if (this.isReview) {
        this.goToReview(obj, item);
        return;
      } else {
        let now: Date = new Date();
        let currentMonth: string = this._es.monthNames[now.getMonth()];
        let currentYear: number = now.getFullYear();
        let minDate = `${currentMonth} ${(now.getFullYear() - this.minYear)}`;
        let formatedCurrentJobFrom = this._es.formatDate(obj['jobFrom']);
        let monthDiffCurrentJob = this._es.monthDiff(formatedCurrentJobFrom);

        let serverData: Object[] = [];
        serverData = this._es.getServerData();
        let jobs = (JSON.parse(JSON.stringify(serverData)));
        let jobsLen = jobs.length;
        this.serverGapcheck = false;
        if (jobsLen - 1 === this.currentRow) {
          this.serverGapcheckfunction(obj);
        }
        else if (jobsLen - 1 > this.currentRow) {
          this.serverGapCheckMoveoubackarrow(obj)
        }

        else {
          this.gapOptions = [];
          this.isGaps = [];
          // this.goToReconfirmSlide();
          this.setSlider(this.sliderIndex, 'right', this.currentRow + 1);
          this.alertUserOnBlankPage(this.currentSlide);
        }
      }
    }
    if (obj['indexDetail']['row'] > 0) {
      this.existingform = false;
    }
  }

  noReconfirm(obj) {
    if (!this.isAjaxCallProccess) {
      // this.initPayload(obj);
      this.goToNextStage(obj);
    }
  }

  empCountry(obj): void {
    // If INTLSUP exists and employment type is not selfEmployed, then call getIntlAdditionalFields to check if the reporting country manager 
    // and state are required for this row's country based on order rule, else go to the existing flow
    if (this.isINTLSUPComponentAvailable && obj['type'] !== 'selfEmployed') {
      let countryFieldsObj = {};
      countryFieldsObj['country'] = obj['countryCode'];
      
      this._es.getIntlSUPAdditionalFields(countryFieldsObj).subscribe((response) => {
        console.log(response);
        let intlAddlFieldsList = !!response['intl-addl-field-list'] ? response['intl-addl-field-list'] : [];
        
        if (intlAddlFieldsList.length > 0) {
          // Loop through array of objects and compare name to CONTACT_STATE_OF_RESIDENCE and CONTACT_COUNTRY_OF_RESIDENCE
          for (const data of intlAddlFieldsList) {
            if (data['name'] === 'CONTACT_STATE_OF_RESIDENCE' || data['name'] === 'CONTACT_COUNTRY_OF_RESIDENCE') {
              this.isIntlSUPCountrySpecificDataRequired = true;
            }
          }
        } else {
          this.isIntlSUPCountrySpecificDataRequired = false;
        }
        
        this.empCountrySave(obj);
      }, error => {
        console.log('Error in getting INTL additional fields');
      });
    } else {
      this.empCountrySave(obj);
    }
  }
  
  empCountrySave(obj: any) {
    if (!this.isAjaxCallProccess) {
      obj['trakEventMsg'] = 'country is entered';
      obj = this.currentPriorConfirmflagCheck(obj);
      let i: number = this.currentRow = obj['indexDetail']['row'];
      this.activeEmpType = obj['type'];
      obj = this._es.mapToOption(obj);
      const control = <FormArray>this.employmentForm.controls['employmentSubForm'];
      let controlLen = control.length;
      let options = [];
      options['country'] = obj['countryCode'];
      
      if (this.isReviewEdit && !!obj['jobFrom'] && !!obj['jobTo']) {
        options['start-date'] = obj['jobFrom'];
        options['end-date'] = obj['jobTo'];
      }
      if (this.currentRow === (controlLen - 1) || (this.isTypeDecisionChanged && this.activeEmpType === 'selfEmployed')) {
        this.isCheckSelfEmploymentEnabled[this.currentRow] = this._es.isSelfEmploymentCheckRequired(this.activeEmpType, obj['countryShortName']);
        this.makeDynamicFormGroupControls(obj, options);
      }

      // check if decision changed-- clear field
      if (obj['isCountryDecisionChanged']) {
        // this.countryDecisionChanged = Math.random();
        const subFormArray = <FormArray>this.employmentForm.controls['employmentSubForm'];
        const subFormGroup = <FormGroup>subFormArray.controls[this.currentRow];
        subFormGroup.controls['stateName'].setValue('');
        subFormGroup.controls['stateCode'].setValue('');
        subFormGroup.controls['city'].setValue('');
        obj['city'] = '';
        obj['stateName'] = '';
        obj['stateCode'] = '';
        if (this.isPaDOTDirectEnabled && obj['countryCode'] === this.DOMESTIC_COUNTRY_CODE) {
          subFormGroup.controls['streetAddress'].setValue('');
          subFormGroup.controls['zipCode'].setValue('');
          obj['streetAddress'] = '';
          obj['zipCode'] = '';
        }
        
        // Clear the datetocontact and permissiontocontact control and the set to null corresponding values for date picker 
        subFormGroup.controls['datetocontact'].setValue("");
        if (!!subFormGroup.controls['permissionToContact'])
          subFormGroup.controls['permissionToContact'].setValue("");
        obj['permissionToContact'] = "";
        obj['datetocontact'] = '';
        
        if (this.isINTLSUPComponentAvailable && !!subFormGroup.controls['managerResidenceCountry'] && !!subFormGroup.controls['managerResidenceStateName']) {
          subFormGroup.controls['managerResidenceCountry'].setValue('');
          subFormGroup.controls['managerResidenceCountryCode'].setValue('');
          subFormGroup.controls['managerResidenceStateCode'].setValue('');
          subFormGroup.controls['managerResidenceStateName'].setValue('');
        }
        
        obj['managerResidenceCountry'] = '';
        obj['managerResidenceCountryCode'] = '';
        obj['managerResidenceStateCode'] = '';
        obj['managerResidenceStateName'] = '';
        
        if (!!this.empVerificationConfirmComponent)
          this.empVerificationConfirmComponent.fromSetDate = null;
        
        if (!!this.empDateToContactComponent)
          this.empDateToContactComponent.fromSetDate = null;
        
        this.clearComapanyFields(subFormGroup, obj);

        this.isCheckSelfEmploymentEnabled[this.currentRow] = this._es.isSelfEmploymentCheckRequired(this.activeEmpType, obj['countryShortName']);
        this.makeDynamicFormGroupControls(obj, options);
      }
      // 180831: Last working date - Store current emp country
      if (!!obj['countryCode']) {
        this.selectedCurrentEmpCountry = obj['countryCode'];
      } 
      
      this.initPayload(obj);
      this.isTypeDecisionChanged = false;
    }
  }

  empCountrySwipe(obj) {
    this.ATSData['showATSdata']['display'] = this.filterAtsData(obj['type']);
    this.IsSaveSuccess = true;
    let direction = 'left';
    let row = this.currentRow || 0;
    if (!this.isReviewEdit && (this.isPresentEmpAutoFulfilment || this.isPlaidPreferenceEnabled ) && obj['type'] === 'current' && !this.ATSData['showATSdata']['display']) {
      if(this.isPlaidPreferenceEnabled && this.isPresentEmpAutoFulfilment){
        this.currentSlide = this._arrowservice.getprevarrowarray('payroll-lookup', row);
      } else {
        this.currentSlide = this._arrowservice.getprevarrowarray('country', row);
      }
      this.currentSlideWithDirection = `${this.currentSlide}_${direction}`;
    } else {
      this.sliderIndex = this.empSteps.indexOf('category');
      this.setSlider(this.sliderIndex, direction, row);
    }
    if (row > 0) {
      let objForINTL = {};
      objForINTL['id'] = this._recordidservice.getRecordid(row - 1);
      objForINTL['country'] = obj['countryCode'];
      this.prepareIntlFlagArray(objForINTL, (row));
    }
  }

  private afterCountrySave(obj, item?): void {
    let currnetFormGroup = this.getCurrnetFormGroup(obj['indexDetail']['row']);
    if (!!(<FormControl>currnetFormGroup.controls['countryName'].value) &&
      !!(<FormControl>currnetFormGroup.controls['countryCode'].value)) {
      this.sliderIndex = this.empSteps.indexOf('city-state');
      obj['countryShortName'] = <FormControl>currnetFormGroup.controls['countryCode'].value;
      let countryOptions = this.options[this.currentRow]['countryOptions'];
      countryOptions['country'] = countryOptions['country_name'] = currnetFormGroup.controls['countryName'].value;
      countryOptions['countryShortName'] = obj['countryShortName'];
      this.options[this.currentRow]['countryOptions'] = countryOptions;
      this.setSlider(this.sliderIndex, 'right', this.currentRow);
      this.alertUserOnBlankPage(this.currentSlide);
      // this.prepareIntlFlagArray(item, this.currentRow);
    }

    this.isCheckSelfEmploymentEnabled[this.currentRow] = this._es.isSelfEmploymentCheckRequired(obj['type'], obj['countryShortName']);
  }

  empCityState(obj): void {
    if(!this.isAjaxCallProccess) {
      obj['trakEventMsg'] = 'city state is entered';
      obj = this.currentPriorConfirmflagCheck(obj);
      let i: number = this.currentRow = obj['indexDetail']['row'];
      this.activeEmpType = obj['type'];

      let currnetFormGroup = this.getCurrnetFormGroup(obj['indexDetail']['row']);

      // check if decision changed-- clear field
      if (obj['isStateDecisionChanged']) {
        this.clearComapanyFields(currnetFormGroup, obj);
      }

      obj = this._es.mapToOption(obj);
      this.initPayload(obj);
    }
  }

  empCityStateSwipe(obj) {
    let direction = 'left';
    let row = this.currentRow || 0;
    this.currentSlide = this._arrowservice.getprevarrowarray('city-state', row);
    this.currentSlideWithDirection = `${this.currentSlide}_${direction}`;
  }

  private afterCityStateSave(obj, item?): void {
    let currnetFormGroup = this.getCurrnetFormGroup(obj['indexDetail']['row']);
    if (!!(<FormControl>currnetFormGroup.controls['city'].value) &&
      !!(<FormControl>currnetFormGroup.controls['stateName'].value)) {
      let countryCode = <FormControl>currnetFormGroup.controls['countryCode'].value;
      let stateCode = <FormControl>currnetFormGroup.controls['stateCode'].value;
      this.setCompanyPrefillConfig(obj['indexDetail']['row'], countryCode, stateCode);

      if (obj['type'] === 'selfEmployed' && this.isPaDOTDirectEnabled) {
        this.sliderIndex = this.empSteps.indexOf('company-name');
      } else if (obj['type'] === 'current' || (obj['type'] === 'selfEmployed' && (this.currentRow === 0 && this.currentJob) && this.isCheckSelfEmploymentEnabled[this.currentRow])) {
        this.sliderIndex = this.empSteps.indexOf('verification-confirm');
      } else if (obj['type'] === 'selfEmployed' && (this.currentRow > 0 || !this.currentJob) && this.isCheckSelfEmploymentEnabled[this.currentRow]) {
        this.sliderIndex = this.empSteps.indexOf('company-name');
      } else if (obj['type'] === 'selfEmployed') {
        this.sliderIndex = this.empSteps.indexOf('job-period');
      } else {
        this.sliderIndex = this.empSteps.indexOf('company-name');
      }

      this.setSlider(this.sliderIndex, 'right', this.currentRow);
      this.alertUserOnBlankPage(this.currentSlide);
    }
  }

  empJobPeriod(obj): void {
    jQuery('#empWaitModal').modal('show');
    
    if (!this.isAjaxCallProccess) {
      let i: number = this.currentRow = obj['indexDetail']['row'];
      this.activeEmpType = obj['type'];

      obj = this.currentPriorConfirmflagCheck(obj);
      obj = this._es.mapToOption(obj);
      let newJob: JobModel = new JobModel();
      
      if (obj['type'] === 'inSchool' && this.isInSchoolAddressDetailsRequired) {        
        obj['countryName'] = obj['schoolCountryName'];
        obj['countryCode'] = obj['schoolCountryCode'];
        obj['stateCode'] = obj['schoolStateCode'];
        obj['stateName'] = obj['schoolStateName'];
        obj['city'] = obj['schoolCity'];
        obj['zipCode'] = obj['schoolZipCode'];
      }
      
      if ((obj['type'] === 'selfEmployed' && !this.isCheckSelfEmploymentEnabled[this.currentRow]) || obj['type'] === 'unemployed'
        || obj['type'] === 'inSchool' || obj['type'] === 'internship') {
        obj['service_action'] = 'PERSIST';
        
        let employmentDataItem = {};
        this.formPayload(employmentDataItem, obj, newJob);
        
        this._es.jobs.push(newJob);
        this.jobs = this._es.jobs;
        this.saveJob(obj, employmentDataItem);
      } else {
        this.afterJobPeriodSave(obj);
      }
    }
  }

  employmentDetails(obj): void {
    if (!this.isAjaxCallProccess) {
      if (!!this.showCitadelData[this.currentRow] || !!this.showPlaidData[this.currentRow]) {
        this.clearEmploymentData(obj, this.currentRow);
        this.showCitadelData[this.currentRow] = false;
        this.showPlaidData[this.currentRow] = false;
        (<FormControl>this.employmentForm.controls['employmentSubForm']['controls'][this.currentRow].controls['companyName']).setValue(obj['companyName']);
      }      
      obj = this.currentPriorConfirmflagCheck(obj);
      obj = this._es.mapToOption(obj);
      let i: number = this.currentRow = obj['indexDetail']['row'];
      this._eventBus.companyNameSelectedAnnounced({
        companyName: obj['companyName']
      });
      let dateOptions = {};
      dateOptions['from'] = this._es.fromDateOptions(obj['jobFrom']);
      dateOptions['to'] = null;
      this.dateConfigs[this.currentRow] = dateOptions;
      this.selectedEmpDetailsDate = obj['jobFrom'];
      this.afterEmpDetailsSave(obj);
      
      let trackObj = {
        'trakEventMsg': 'company name, start date provided',
        'startTime': obj['startTime']
      }
      this.trackEvent(trackObj, 'selected', true, true);
    }
  }


  private processAtsDataToShowOnJobPeriodSwipe(obj) {
    if (!this.isReview) {
      this.ATSData['showATSdata']['display'] = this.filterAtsData(obj['type']);
    } else {
      this.ATSData['showATSdata']['display'] = false;
    }
  }

  empJobPeriodSwipe(obj) {
    this.processAtsDataToShowOnJobPeriodSwipe(obj);
    this.IsSaveSuccess = true;
    let direction = 'left';
    let row = this.currentRow || 0;
    
    if (row === 0) {
      this.existingform = true;
    }
    
    if (obj['type'] === 'selfEmployed' && (this.selfEmploymentPreferenceEnabled || this.isPaDOTDirectEnabled)) {
      if ((this.isCheckSelfEmploymentEnabled[row] || this.isPaDOTDirectEnabled) && !this.reportingManagerSkip) {
        this.sliderIndex = this.empSteps.indexOf('reporting-manager-details');
      } else {
        if (this.reportingManagerSkip && obj['countryCode'] !== this.DOMESTIC_COUNTRY_CODE) {
          this.sliderIndex = this.empSteps.indexOf('company-name');
        } else {
          this.sliderIndex = this.empSteps.indexOf('city-state');
        }
        
      }
    } else {
      if (obj['type'] === 'current' || obj['type'] === 'prior' || (obj['type'] === 'selfEmployed' && this.isCheckSelfEmploymentEnabled[row])) {
        if (obj['countryCode'] !== this.DOMESTIC_COUNTRY_CODE || this.isPaDOTDirectEnabled) {
          if ((obj['type'] === 'current' || obj['type'] === 'prior' || obj['type'] === 'selfEmployed') && this.reportingManagerSkip) {
            this.sliderIndex = this.empSteps.indexOf('company-name');
          } else {
            this.sliderIndex = this.empSteps.indexOf('reporting-manager-details');
          }
        } else {
          this.sliderIndex = this.empSteps.indexOf('company-name');
        }
      } else {
        if (row === 0) {
          this.empCategories = this._utilService.getArrayFromJsonString(this._ts.translate(EmploymentConfig.EMPLOYMENT_CATEGORIES_CURRENT));
        }
        
        if (row === 0 && this.isReview && this.currentJob) {
          this.empCategories = this._utilService.getArrayFromJsonString(this._ts.translate(EmploymentConfig.EMPLOYMENT_CATEGORIES_CURRENT));
        }

        if (row === 0 && this.isReview && !this.currentJob) {
          this.empCategories = this._utilService.getArrayFromJsonString(this._ts.translate(EmploymentConfig.EMPLOYMENT_CATEGORIES));
        }

        if (row > 0 && this.isReview && !this.currentJob) {
          this.empCategories = this._utilService.getArrayFromJsonString(this._ts.translate(EmploymentConfig.EMPLOYMENT_CATEGORIES));
        }

        if (row > 0 && this.isReview && this.currentJob) {
          this.empCategories = this._utilService.getArrayFromJsonString(this._ts.translate(EmploymentConfig.EMPLOYMENT_CATEGORIES));
        }

        this.sliderIndex = this.empSteps.indexOf('category');
      }
    }
    this.setSlider(this.sliderIndex, direction, this.currentRow);
  }

  employmentDetailsSwipe(obj) {
    let direction = 'left';
    this.sliderIndex = this.empSteps.indexOf('category');
    this.setSlider(this.sliderIndex, direction, this.currentRow);
  }


  private prePopulateJobperiod(obj, indexValue) {
    let serverData: Object[] = [];
    serverData = (JSON.parse(JSON.stringify(this._es.getServerData())));
    let jobs = (JSON.parse(JSON.stringify(serverData)));
    let jobsLen = jobs.length;
    let currentServerData = [];

    for (let i = 0; i <= indexValue; i++) {
      currentServerData.push(serverData[i]);
    }

    if (obj['type'] === 'current') {
      this.currentJobDetails = this._es.getAllCurrentJob(currentServerData);
      this.minCurrentJob = this.prvJob = this._es.findMinimumCurrentJobObj(currentServerData);

      this.prvCurrentJob[indexValue] = this._es.mapToOption(this.minCurrentJob);
      let currentJobLen = this.currentJobDetails.length;
      if (!this.isCollectMinYearEmp && !!obj['jobFrom']) {
        this.checkMinYearComplete(obj['jobFrom']);
      }
    }

    // Gap Finder
    if (this.activeEmpType !== 'prior') {
      this.isNoPriorJob = false;
      this.prvJob = this._es.findMinimumJob(currentServerData);
      this.minCurrentJob = this._es.findMinimumCurrentJobObj(currentServerData);
      this.minPriorJob = this._es.findMinimumPriorJobObj(currentServerData);
      this.prvPriorJob[indexValue] = this._es.mapToOption(this.minPriorJob);
    }
  }


  private afterJobPeriodSave(obj, item?) {
    if (this.faaSafetyCheckPreferenceEnabled && (obj['type'] === 'current' || obj['type'] === 'prior' || (obj['type'] === 'selfEmployed' && this.selfEmploymentPreferenceEnabled))) {
      this.sliderIndex = this.empSteps.indexOf('job-title');
      this.setSlider(this.sliderIndex, 'right', this.currentRow);
      this.alertUserOnBlankPage(this.currentSlide);
    } else if ((obj['type'] === 'selfEmployed' && !this.isCheckSelfEmploymentEnabled[this.currentRow] && !this.isPaDOTDirectEnabled) || obj['type'] === 'unemployed'
      || obj['type'] === 'inSchool' || obj['type'] === 'internship') {
      this.afterJobTitleSave(obj, item);
    } else {
      let currnetFormGroup = this.getCurrnetFormGroup(obj['indexDetail']['row']);
      if (!!(<FormControl>currnetFormGroup.controls['jobFrom'].value)) {
        this.sliderIndex = this.empSteps.indexOf('job-title');
        this.setSlider(this.sliderIndex, 'right', this.currentRow);
        this.alertUserOnBlankPage(this.currentSlide);
      }
    }
    // this.prepareIntlFlagArray(item);
    if (obj['indexDetail']['row'] > 0) {
      this.existingform = false;
    }
  }


  private afterSeverGapcheck(obj) {
    let serverData: Object[] = [];
    serverData = this._es.getServerData();
    let jobs = (JSON.parse(JSON.stringify(serverData)));

    if (!!this.isGaps[this.currentRow + 1]) {
      if (this.isGaps[this.currentRow + 1]) {
        this.hideEmpWaitModal();
        
        this.userDetails['isGap'] = true;
        this.sliderIndex = this.empSteps.indexOf('category');
        // console.log(this.gapOptions);
        this.recordId = serverData[this.currentRow]['id'];
        const control = <FormArray>this.employmentForm.controls['employmentSubForm'];
        let controlLen = control.length;
        if ((this.isINTLDepartmentArr[this.currentRow] || this.isINTLEmployeeIdArr[this.currentRow] || this.isINTLCompNameInLocalArr[this.currentRow]
          || ((this.empTypes[this.currentRow] === 'current' || this.empTypes[this.currentRow] === 'prior')
            && this.selfEmploymentPreferenceEnabled && obj['countryCode'] !== AppConstants.DOMESTIC_COUNTRY_CODE)) && (!this.isINTLFieldsCaptured[this.currentRow])) {
          this.sliderIndex = this.empSteps.indexOf('intl-emp');
          this.setSlider(this.sliderIndex, 'right', this.currentRow);
          this.alertUserOnBlankPage(this.currentSlide);
        } else if (this.currentRow === (controlLen - 1)) {
          if (this.repeateEmployment(obj)) {
            this.sliderIndex = this.empSteps.indexOf('category');
            this.currentRow = this.currentRow + 1;
            this.recordId = null;
          }
          this.empTypes.push(null);
          this.setSlider(this.sliderIndex, 'right', this.currentRow);
          this.alertUserOnBlankPage(this.currentSlide);
        } else {
          this.jumpToNextIteration();
          return;
        }
        return;
      }
    }

    if (obj['type'] === 'current' || obj['type'] === 'prior' || obj['type'] === 'selfEmployed' || obj['type'] === 'unemployed' || obj['type'] === 'inSchool' || obj['type'] === 'internship') {
      this.currentJobDetails = this._es.getAllCurrentJob(serverData);
      this.minCurrentJob = this.prvJob = this._es.findMinimumCurrentJobObj(serverData);
      let currentJobLen = this.currentJobDetails.length;

      if (!this.isCollectMinYearEmp && !!obj['jobFrom']) {
        this.checkMinYearComplete(obj['jobFrom']);
      }

      if (this.isScopeCompleted && this.isCollectMinYearEmp && !((this.isINTLDepartmentArr[this.currentRow] || this.isINTLEmployeeIdArr[this.currentRow] || this.isINTLCompNameInLocalArr[this.currentRow]
        || ((this.empTypes[this.currentRow] === 'current' || this.empTypes[this.currentRow] === 'prior')
          && this.selfEmploymentPreferenceEnabled && obj['countryCode'] !== AppConstants.DOMESTIC_COUNTRY_CODE))
        && !this.isINTLFieldsCaptured[this.currentRow])) {
        // this.routeToNextStage();
        obj['COMPONENT_STATE']['stage_status'] = 'PENDING';
        
        if (this.isAdditionalEmpPrefEnabled && !this.isReview) {
          this.goToReconfirmSlide();
        } else {
          this.goToNextStage(obj); // decide stage_status as completed
        }
      } else {
        this.hideEmpWaitModal();
        const control = <FormArray>this.employmentForm.controls['employmentSubForm'];
        let controlLen = control.length;
        if (this.currentRow === (controlLen - 1)) {
          if (this.repeateEmployment(obj)) {

            if ((this.isINTLDepartmentArr[this.currentRow] || this.isINTLEmployeeIdArr[this.currentRow]
              || ((this.empTypes[this.currentRow] === 'current' || this.empTypes[this.currentRow] === 'prior')
                && this.selfEmploymentPreferenceEnabled && obj['countryCode'] !== AppConstants.DOMESTIC_COUNTRY_CODE))
              && (!this.isINTLFieldsCaptured[this.currentRow])) {
              //if (this.isINTLDepartment || this.isINTLEmployeeId) {
              this.sliderIndex = this.empSteps.indexOf('intl-emp');
            } else {
              this.sliderIndex = this.empSteps.indexOf('category');
              this.currentRow = this.currentRow + 1;
              this.recordId = null;
            }

            this.empTypes.push(null);
            this.setSlider(this.sliderIndex, 'right', this.currentRow);
            this.alertUserOnBlankPage(this.currentSlide);
          }
        } else {
          if ((this.isINTLDepartmentArr[this.currentRow] || this.isINTLEmployeeIdArr[this.currentRow] || this.isINTLCompNameInLocalArr[this.currentRow]
            || ((this.empTypes[this.currentRow] === 'current' || this.empTypes[this.currentRow] === 'prior')
              && this.selfEmploymentPreferenceEnabled && obj['countryCode'] !== AppConstants.DOMESTIC_COUNTRY_CODE))
            && (!this.isINTLFieldsCaptured[this.currentRow])) {
            //if (this.isINTLDepartment || this.isINTLEmployeeId) {
            this.sliderIndex = this.empSteps.indexOf('intl-emp');
            this.setSlider(this.sliderIndex, 'right', this.currentRow);
            this.alertUserOnBlankPage(this.currentSlide);
          } else {
            this.jumpToNextIteration();
          }
          return;
        }
      }
    }
  }

  private jumpToNextIteration() {
    let serverData: Object[] = [];
    serverData = (JSON.parse(JSON.stringify(this._es.getServerData())));

    // increase current row
    this.currentRow = this.currentRow + 1;
    let currentServerData = serverData[this.currentRow];
    this.recordId = null;
    this.activeEmpType = null;

    if (!!currentServerData && !!currentServerData['id']) {
      this.recordId = currentServerData['id'];
      this.activeEmpType = currentServerData['type'] || null;
    }
    this.sliderIndex = this.empSteps.indexOf('category');
    this.setSlider(this.sliderIndex, 'right', this.currentRow);
    this.alertUserOnBlankPage(this.currentSlide);
  }

  private updateGapStageStaus(item) {
    if (!!this.isGaps[this.currentRow] && !!item && !!item['state'] && item['state']['stage_status'] === 'COMPLETED') {
      let body = {
        'employment-data-list': []
      };
      let step: string = 'employment-step';
      body['employment-data-list'].push(item);
      item['state']['stage_status'] = 'PENDING';
      item['state']['current_iteration'] = this.currentRow;
      body['employment-data-list'].push(item);
      this.gapPayloadUpdate(body, step);
    }
  }

  private serverGapcheckfunction(obj) {
    let that = this;
    this._es.getGapdata().subscribe(response => {
      // let data = JSON.parse(response._body);
      let data = response;
      let serverGapflag = [];
      that.gapOptions = [];
      that.isGaps = [];
      let isGap: boolean = true;
      let gap: Object = {};
      gap['from'] = {};
      gap['to'] = {};
      that.activeGapOptions[that.currentRow + 1] = null;

      that.userDetails['isGap'] = false;
      let serverGapflaglength = data['gaps'].length;
      let scopecompletedflagyear = data['pending-emp-period']['year'];
      let scopecompletedflagmonth = data['pending-emp-period']['month'];
      let scopecompletedflagdays = 0;
      if (this.isEmploymentGapInDaysEnabled) {
        scopecompletedflagdays = data['pending-emp-period']['days'];
      }
      if (scopecompletedflagyear == 0 && scopecompletedflagmonth == 0 && scopecompletedflagdays === 0) {
        that.isCollectMinYearEmp = true;
        that.isScopeCompleted = true;
      } else {
        that.isCollectMinYearEmp = false;
        that.isScopeCompleted = false;
      }
      that.serverGapcheck = true;
      if (serverGapflaglength > 0) {
        serverGapflag = data['gaps'][0];
        /**
         * Below function call used to convert Epoch format to date format
         * Arguments
         * 1. Epoch format
         * 2. which format we need EX- MMMM YYYY
         * 3. Force to convert to english date format
         */
        if (!!serverGapflag['gap_from']) {
          serverGapflag['gap_start'] = dateUtils.getDateFromYYYYMMDD(serverGapflag['gap_from']).getTime();
        }
        if (!!serverGapflag['gap_to']) {
          serverGapflag['gap_end'] = dateUtils.getDateFromYYYYMMDD(serverGapflag['gap_to']).getTime();
        }
        gap['from']['date'] = that._tsPipe.transform(serverGapflag['gap_end'], this.getDateFormat(), true);
        gap['to']['date'] = that._tsPipe.transform(serverGapflag['gap_start'], this.getDateFormat(), true);

        that.gapOptions[that.currentRow + 1] = gap;
        this.activeGap[this.currentRow + 1] = gap;
        that.isGaps[that.currentRow + 1] = isGap;
        that.isGapResolve = true;
        that.userDetails['isGap'] = true;
        that.activeGapOptions[that.currentRow + 1] = that.gapOptions;

        const control = <FormArray>this.employmentForm.controls['employmentSubForm'];
        let formLen = control.length;
        if ((formLen - 1) === obj['indexDetail']['row']) {
          that.isGapData[obj['indexDetail']['row']] = true;
        }
      }
      that.afterSeverGapcheck(obj);
    }, error => {

    });
  }

  private serverGapCheckMoveoubackarrow(obj) {
    let that = this;
    this._es.getGapdata().subscribe(response => {
      // let data = JSON.parse(response._body);
      let data = response;
      let serverGapflag = [];
      that.gapOptions = [];
      that.activeGapOptions.push("null")
      that.isGaps = [];
      let isGap: boolean = true;
      let gap: Object = {};
      gap['from'] = {};
      gap['to'] = {};

      that.userDetails['isGap'] = false;
      let serverGapflaglength = data['gaps'].length;
      let scopecompletedflagyear = data['pending-emp-period']['year'];
      let scopecompletedflagmonth = data['pending-emp-period']['month'];
      if (scopecompletedflagyear == 0 && scopecompletedflagmonth == 0) {
        that.isCollectMinYearEmp = true;
        that.isScopeCompleted = true;
      } else {
        that.isCollectMinYearEmp = false;
        that.isScopeCompleted = false;
      }
      that.serverGapcheck = true;
      if (serverGapflaglength > 0) {
        serverGapflag = data['gaps'][0];
        /**
         * Below function call used to convert Epoch format to date format
         * Arguments
         * 1. Epoch format
         * 2. which format we need EX- MMMM YYYY
         * 3. Force to convert to english date format
         */
        
        if (!!serverGapflag['gap_from']) {
          serverGapflag['gap_start'] = dateUtils.getDateFromYYYYMMDD(serverGapflag['gap_from']).getTime();
        }
        if (!!serverGapflag['gap_to']) {
          serverGapflag['gap_end'] = dateUtils.getDateFromYYYYMMDD(serverGapflag['gap_to']).getTime();
        }
        
        gap['from']['date'] = that._tsPipe.transform(serverGapflag['gap_end'], this.getDateFormat(), true);
        gap['to']['date'] = that._tsPipe.transform(serverGapflag['gap_start'], this.getDateFormat(), true);

        const subFormArray = <FormArray>this.employmentForm.controls['employmentSubForm'];
        let subFormArrayLength = subFormArray.length;
        this.activeGap[this.currentRow + 1] = gap;
        that.gapOptions[this.currentRow + 1] = gap;
        that.isGaps[this.currentRow + 1] = isGap;

        that.isGapResolve = true;
        that.userDetails['isGap'] = true;
      }
      // that.afterSeverGapcheck(obj);
      if (that.isScopeCompleted && that.isCollectMinYearEmp && serverGapflaglength == 0) {
        this.activeGap = [];
        // that.routeToNextStage();
        let serverData: Object[] = [];
        serverData = (JSON.parse(JSON.stringify(this._es.getServerData())));
        this.goToNextStageFromBackArraow(serverData, obj);
      }
      else {
        that.currentRow += 1;
        that.setSlider(that.sliderIndex, 'right', that.currentRow);
      }
    }, error => {
    });
  }

  private gapAvialabilityCheck(gapMode?, isPrePopulate?, enddate?) {
    let that = this;
    this._es.getGapDataReviewedit().subscribe(response => {
      // let data = JSON.parse(response._body);
      let data = response;
      let serverGapflag = []
      // this.gapOptions = [];
      that.gapOptions = [];
      that.isGaps = [];
      let isGap: boolean = true;
      let gap: Object = {};
      gap['from'] = {};
      gap['to'] = {};

      that.userDetails['isGap'] = false;
      let serverGapflaglength = data['gaps'].length;
      that.serverGapcheck = true;
      if (serverGapflaglength > 0) {
        serverGapflag = data['gaps'][0];
        
        if (!!serverGapflag['gap_from']) {
          serverGapflag['gap_start'] = dateUtils.getDateFromYYYYMMDD(serverGapflag['gap_from']).getTime();
        }
        if (!!serverGapflag['gap_to']) {
          serverGapflag['gap_end'] = dateUtils.getDateFromYYYYMMDD(serverGapflag['gap_to']).getTime();
        }
        
        gap['from']['date'] = that._es.getFormatedDate(serverGapflag['gap_end'], this.getDateFormat());
        gap['to']['date'] = that._es.getFormatedDate(serverGapflag['gap_start'], this.getDateFormat());

        that.gapOptions[that.currentRow] = gap;
        this.activeGap[that.currentRow] = gap
        that.isGaps[that.currentRow] = isGap;
        that.isGapResolve = true;
        that.userDetails['isGap'] = true;
      }

      if (this.isGaps[this.currentRow]) {
        this.initDateOptions();
      }
    }, error => {
    });
  }

  private existingGapAvialabilityCheck(gapMode?, isPrePopulate?, enddate?) {
    let that = this;
    this._es.getGapdata().subscribe(response => {
      // let data = JSON.parse(response._body);
      let data = response;
      let serverGapflag = []
      // this.gapOptions = [];
      that.gapOptions = [];
      that.isGaps = [];
      let isGap: boolean = true;
      let gap: Object = {};
      gap['from'] = {};
      gap['to'] = {};

      that.userDetails['isGap'] = false;
      let serverGapflaglength = data['gaps'].length;
      that.serverGapcheck = true;
      if (serverGapflaglength > 0) {
        serverGapflag = data['gaps'][0];
        
        if (!!serverGapflag['gap_from']) {
          serverGapflag['gap_start'] = dateUtils.getDateFromYYYYMMDD(serverGapflag['gap_from']).getTime();
        }
        if (!!serverGapflag['gap_to']) {
          serverGapflag['gap_end'] = dateUtils.getDateFromYYYYMMDD(serverGapflag['gap_to']).getTime();
        }
        
        gap['from']['date'] = that._es.getFormatedDate(serverGapflag['gap_end'], this.getDateFormat());
        gap['to']['date'] = that._es.getFormatedDate(serverGapflag['gap_start'], this.getDateFormat());

        that.gapOptions[that.currentRow] = gap;
        this.activeGap[that.currentRow] = gap;
        that.isGaps[that.currentRow] = isGap;
        that.isGapResolve = true;
        that.userDetails['isGap'] = true;
        that.setGapValueRandom = this._utilService.getCryptoRandom();
      }

      if (that.isGaps[this.currentRow]) {
        // that.initDateOptions();
      }
    }, error => {
    });
  }

  private empGap(obj): void {
    obj['trakEventMsg'] = 'gap data is entered';
    let i: number = this.currentRow = obj['indexDetail']['row'];
    if (this.currentRow === 0) {
      obj['current_employment_confirm'] = obj['currentEmploymentConfirm'] = false;
      obj['prior_employment_confirm'] = obj['priorEmploymentConfirm'] = true;
    }
    obj = this.currentPriorConfirmflagCheck(obj);
    obj = this._es.mapToOption(obj);
    this.afterGapSave(obj);
    // this.initPayload(obj);
  }

  empGapSwipe(obj) {
    let direction = 'left';
    let row = this.currentRow || 0;
    let currentSlideLabel: string = '';

    if (
      !!this.isGaps[this.currentRow] &&
      !!this.gapOptions[this.currentRow] &&
      !!this.isGapGeneratedFromJobPeriod) {
      currentSlideLabel = 'job-period';
      this.sliderIndex = this.empSteps.indexOf(currentSlideLabel);
      this.isGapGeneratedFromJobPeriod = false;
    } else {
      currentSlideLabel = this._arrowservice.getprevarrowarray('gap', row);
      if (currentSlideLabel.indexOf('_') > -1) {
        currentSlideLabel = currentSlideLabel.split('_')[0];
      }
      this.sliderIndex = this.empSteps.indexOf(currentSlideLabel);
    }
    this.setSlider(this.sliderIndex, 'right', row);
  }

  private afterGapSave(obj, item?): void {
    this.localStorage.setItem('isATS', 'NO');
    if (this.atsArrayDataIndex !== -1 && this.selectedATSCardId !== ''
      && this.atsArrayDataIndex !== null && this.selectedATSCardId !== null
      && this.atsArrayDataIndex !== undefined && this.selectedATSCardId !== undefined) {
      this.updateATSRecord().subscribe((value) => {
        if (value.res) { }
      });
    } else {
    }

    if (obj['gapFlag']) {
      this.activeGapOptions[this.currentRow + 1] = this.gapOptions[this.currentRow];
      let empType = this.activeEmpType = 'prior';
      this.empTypes[this.currentRow + 1] = empType;
      this.isGapResolve = true;
      if (this.repeateEmployment(obj)) {
        this.currentRow += 1;
        this.sliderIndex = this.empSteps.indexOf('company-name');
        this.recordId = null;
        this.setSlider(this.sliderIndex, 'right', this.currentRow);
      }
    } else {
      this.sliderIndex = this.empSteps.indexOf('gap-confirm');
      this.setSlider(this.sliderIndex, 'right', this.currentRow);
    }
  }

  private empGapConfirm(obj): void {
    obj['trakEventMsg'] = 'gap confirm is provided';
    let i: number = this.currentRow = obj['indexDetail']['row'];
    if (this.currentRow === 0) {
      obj['current_employment_confirm'] = obj['currentEmploymentConfirm'] = false;
      obj['prior_employment_confirm'] = obj['priorEmploymentConfirm'] = true;
    }
    obj = this.currentPriorConfirmflagCheck(obj);
    obj = this._es.mapToOption(obj);
    if (obj['gapConfirm']) {
      this.empGapConfirmDecisionChangesBySaveExit();
      this.afterGapConfirmSave(obj);
      // this.initPayload(obj);
    } else {
      let employmentDataItem = {};
      if (!this.recursionPriorCheck) {
        let isInsert = true;
        this.formGapPayload(employmentDataItem, obj, isInsert);
      } else {
        let isInsert = false;
        this.formGapPayload(employmentDataItem, obj, isInsert);
      }

      this.saveJob(obj, employmentDataItem).subscribe((value) => {
      });
    }
  }

  private empGapConfirmDecisionChangesBySaveExit() {
    let serverData: Object[] = [];
    serverData = (JSON.parse(JSON.stringify(this._es.getServerData())));
    let serverDataLen = serverData.length;
    let currentRecordIdObj: Object;
    if (serverDataLen > 0) {
      if (this.stateConfig['stage_status'] === 'PENDING' && this.stateConfig['stage_status'] === 'PENDING' && this.stateConfig['current_sub_step'] === 'gap-confirm') {

        let currentRecordItemArr = serverData.filter(obj => {
          return obj['id'] === this.recordId;
        });
        if (currentRecordItemArr.length > -1) {
          currentRecordIdObj = currentRecordItemArr[0];
        }
        let prvObjIndex = serverData.indexOf(currentRecordIdObj);
        let prvObj: Object;
        if (prvObjIndex > -1) {
          prvObj = serverData[prvObjIndex - 1];
          this._es.tempGapRecordId = this.recordId;
          this.recordId = prvObj['id'] || null;
        }
      }
    }
  }

  empGapConfirmSwipe(obj) {
    let direction = 'left';
    let row = this.currentRow || 0;
    this.currentSlide = this._arrowservice.getprevarrowarray('gap-confirm', row);
    this.empGapConfirmDecisionChangesBySaveExit();
    this.currentSlideWithDirection = `${this.currentSlide}_${direction}`;
  }

  private afterGapConfirmSave(obj, item?) {
    this.localStorage.setItem('isATS', 'NO');
    if (this.atsArrayDataIndex !== -1 && this.selectedATSCardId !== ''
      && this.atsArrayDataIndex !== null && this.selectedATSCardId !== null
      && this.atsArrayDataIndex !== undefined && this.selectedATSCardId !== undefined) {
      this.updateATSRecord().subscribe((value) => {
        if (value.res) { }
      });
    } else { }

    // Delete unnecsaary gap avialable in db
    this.deleteDuplicationRecord();

    if (obj['gapConfirm']) {
      let empType = this.activeEmpType = 'prior';
      this.isGapResolve = true;
      this.sliderIndex = this.empSteps.indexOf('company-name');
      this.recordId = null;

      if (!!this._es.tempGapRecordId) {
        this.recordId = this._es.tempGapRecordId;
        this._es.tempGapRecordId = null;
      }

      if (!this.isReview) {
        this.activeGapOptions[this.currentRow + 1] = this.gapOptions[this.currentRow];
        this.empTypes[this.currentRow + 1] = empType;
        this.currentRow += 1;
        if (this.repeateEmployment(obj)) {
          this.setSlider(this.sliderIndex, 'right', this.currentRow);
        }
      } else {
        this.recordId = item['id'] || null;
        this.activeGapOptions[this.currentRow] = this.gapOptions[this.currentRow];
        this.empTypes[this.currentRow] = empType;
        this.setSlider(this.sliderIndex, 'right', this.currentRow);
      }
    } else {
      if (this.recursionPriorCheck) {
        this.recursionPriorCheck = false;
        this.goToNextStage(item);
        return;
      }

      this.isGapResolve = false;
      if (!this.isScopeCompleted || (!this.isCollectMinYearEmp && !!obj['gapOption']['from']['date'] && !this.checkMinYearComplete(obj['gapOption']['from']['date']))) {
        if (!this.isReview) {
          this.gapAvialabilityCheck('final');
          if (this.isGaps[this.currentRow]) {
            this.recordId = this.allRecordId[this.currentRow];
            this.sliderIndex = this.empSteps.indexOf('prior-employment-confirm');
            this.setSlider(this.sliderIndex, 'right', this.currentRow);
            return;
          }
        } else {
          this.goToReview(obj, item);
        }
      } else {
        (!this.isReview) ? this.goToNextStage(item) : this.goToReview(obj, item);
        return;
      }
    }
  }

  private repeateEmployment(obj): boolean {
    let config = {};
    let countryOptions = {};

    if (this.isSameCountry) {
      countryOptions['country'] = obj['country'];
      countryOptions['countryShortName'] = obj['countryShortCode'];
    } else {
      countryOptions['country'] = '';
      countryOptions['countryShortName'] = obj['countryShortCode'];
    }

    config['countryOptions'] = countryOptions;
    this.options.push(config);
    this.initGaps();

    if (!this.isGapResolve) {
      let activeGapOption = null;
      this.activeGapOptions.push(activeGapOption);
    }
    this.addEsForm(this.options);
    return true;
  }

  private initGaps() {
    let isGap = false;
    let gapOption = null;
    this.isGaps.push(isGap);
    this.gapOptions.push(gapOption);
  }

  private getCurrentDate(): any {
    let now: Date = new Date();
    let currentMonth: string = this._es.monthNames[now.getMonth()];
    let currentYear: number = now.getFullYear();
    return `${currentMonth} ${now.getFullYear()}`;
  }

  private initGapConfig(gapObj: Object) {
    let serverData = this._es.getServerData();
    let gapStatus = gapObj['gapstatus'];
    let fromObj: any;
    let toObj: any;
    let fromObjId: any;
    let toObjId: any;
    let isGap: boolean = true;
    let gap: Object = {};
    gap['from'] = {};
    gap['to'] = {};

    if (gapStatus === 'between') {
      fromObjId = gapObj['endId'];
      toObjId = gapObj['startId'];
      fromObj = this._es.filterObjById(fromObjId);
      toObj = this._es.filterObjById(toObjId);
      gap['gapStatus'] = gapStatus;
      if (!!fromObj) {
        gap['from']['obj'] = fromObj;
        gap['from']['date'] = this._es.getFormatedDate(fromObj['end-date'], 'MMMM YYYY');
        gap['from']['companyName'] = fromObj['name'];
      }

      if (!!toObj) {
        gap['to']['obj'] = toObj;
        gap['to']['date'] = this._es.getFormatedDate(toObj['start-date'], 'MMMM YYYY');
        gap['to']['companyName'] = toObj['name'];
      }
    }

    if (gapStatus === 'head') {
      fromObjId = gapObj['startId'];
      fromObj = this._es.filterObjById(fromObjId);
      gap['gapStatus'] = gapStatus;
      toObj = null;
      if (!!fromObj) {
        gap['from']['obj'] = fromObj;
        gap['from']['date'] = this._es.getFormatedDate(fromObj['end-date'], 'MMMM YYYY');
        gap['from']['companyName'] = fromObj['name'];
      }

      if (!toObj) {
        gap['to']['obj'] = toObj;
        gap['to']['date'] = this._es.getLabelDate(gapObj['gapEndDate']);
        gap['to']['companyName'] = null;
      }
    }

    if (gapStatus === 'tail') {
      gap['gapStatus'] = gapStatus;
      toObjId = gapObj['endId'];
      toObj = this._es.filterObjById(toObjId);
      fromObj = null;

      if (!fromObj) {
        gap['from']['obj'] = null;
        gap['from']['date'] = this._es.getLabelDate(gapObj['gapStartDate']);
        gap['from']['companyName'] = null;
      }

      if (!!toObj) {
        gap['to']['obj'] = toObj;
        let toDateLabelFormat = this._es.getLabelDate(gapObj['gapEndDate']);
        gap['to']['date'] = this._es.getFormatedDate(toObj['start-date'], 'MMMM YYYY');
        gap['to']['companyName'] = toObj['name'];
      }
    }

    if (this.currentRow > 0) {
      this.gapOptions[this.currentRow + 1] = gap;
      this.isGaps[this.currentRow + 1] = isGap;
      if (this.initexistingflag) {
        this.gapOptions[this.currentRow] = gap;
        this.isGaps[this.currentRow] = isGap;
      }
      this.isGapResolve = true;
    } else if (this.currentRow == 0 && !this.isReview) {
      this.gapOptions[this.currentRow + 1] = gap;
      this.isGaps[this.currentRow + 1] = isGap;
      this.isGapResolve = true;
    } else if (this.currentRow == 0 && this.isReview) {
      this.gapOptions[this.currentRow] = gap;
      this.isGaps[this.currentRow] = isGap;
      this.isGapResolve = true;
    }
  }

  private directGapProcess(obj, item?) {
    let diff: number;
    let formatedCurDate = this._es.formatDate(this.getCurrentDate());
    let curJobTo = this._es.formatDate(obj['jobTo']);
    diff = this._es.monthDiffOfDates(curJobTo, formatedCurDate);

    if (diff > this.gapLimit) {
      this.gapAvialabilityCheck('final');
      if (this.isGaps[this.currentRow]) {
        this.sliderIndex = this.empSteps.indexOf('category');
        this.recordId = this.allRecordId[this.currentRow];
        this.setSlider(this.sliderIndex, 'right', this.currentRow);
      }
      return;
    } else {
      if (this.isScopeCompleted && this.isCollectMinYearEmp) {
        this.goToNextStage(item);
        return;
      } else {
        this.setSlider(this.sliderIndex, 'right', this.currentRow);
        return;
      }
    }
  }

  private gapFinder(obj, status) {
    let now: Date = new Date();
    let currentMonth: string = this._es.monthNames[now.getMonth()];
    let currentYear: number = now.getFullYear();
    let minDate = `${currentMonth} ${(now.getFullYear() - this.minYear)}`;
    let isGap = true;
    let gapFromDate;
    let gap: Object = {};
    gap['from'] = {};
    gap['to'] = {};

    if (status === 'inBetween' || status === 'directPrior') {
      let formateJobToDate = this._es.formatDate(obj['jobTo']);
      let formateMinDate = this._es.formatDate(minDate);
      let monthDiff = this._es.monthDiffOfDates(formateMinDate, formateJobToDate);
      gapFromDate = obj['jobTo'];
      if (monthDiff < 0) {
        gapFromDate = minDate;
      }
    }

    if (status === 'inBetween') {
      gap['from']['date'] = gapFromDate;
      gap['from']['companyName'] = obj['companyName'];
      gap['to']['date'] = this.prvJob['jobFrom'];
      gap['to']['companyName'] = this.prvJob['companyName'];
    }

    if (status === 'directPrior') {
      gap['from']['date'] = gapFromDate;
      gap['from']['companyName'] = obj['companyName'];
      gap['to']['date'] = this.getCurrentDate();
    }

    if (status === 'final') {
      gap['from']['date'] = minDate;
      gap['to']['date'] = obj['jobFrom'];
      gap['to']['companyName'] = obj['companyName'];
    }

    this.gapOptions[this.currentRow] = gap;
    this.isGaps[this.currentRow] = isGap;
  }

  private initDateOptions(obj?, indexValue?, isRepeate?, isUpdateInitialItem?) {
    let dateOptions = {};
    if (this.currentRow == 0 && !this.isReview) {
      dateOptions['from'] = (!!obj && !!obj['jobFrom']) ? this._es.fromDateOptions(obj['jobFrom']) : this._es.fromDateOptions();
      dateOptions['to'] = null;
    }

    if ((this.currentRow > 0) && !this.isReview) {
      let fromatedDate;
      fromatedDate = (!!obj && !!obj['jobFrom']) ? this._es.fromDateOptions(obj['jobFrom']) : this._es.fromDateOptions();
      dateOptions['from'] = fromatedDate;
      let serverData: Object[] = [];
      serverData = (JSON.parse(JSON.stringify(this._es.getServerData())));
      let jobs = (JSON.parse(JSON.stringify(serverData)));
      let jobsLen = jobs.length || 0;
      let currentServerData = [];

      if (!!indexValue) {
        for (let i = 0; i <= indexValue; i++) {
          if (!!serverData[i]) {
            currentServerData.push(serverData[i]);
          }
        }
      } else {
        currentServerData = serverData;
      }

      if (currentServerData.length > 0) {
        let minimumJob = this.prvJob = this._es.findMinimumJob(currentServerData);
        this.minCurrentJob = this._es.findMinimumCurrentJobObj(currentServerData);
        this.minPriorJob = this._es.findMinimumPriorJobObj(currentServerData);
        this.prvPriorJob[indexValue] = this._es.mapToOption(this.minPriorJob);

        if (!this.isGapResolve) {
          let formatedToDate = '';

          if (!!minimumJob['jobFrom']) {
            formatedToDate = this._es.formatDate(minimumJob['jobFrom']);
          }

          if (!!obj && !!obj['jobTo'] && !isRepeate) {
            formatedToDate = this._es.formatDate(obj['jobTo']);
          }
          dateOptions['to'] = (!!formatedToDate) ? this._es.toDateOptions(formatedToDate) : this._es.toDateOptions();
        }
      }

      // Gap Resolve
      if (!!obj && !!obj['gapOption'] && this.isGapResolve) {
        let fromatedDateGapFrom = this._es.formatDate(obj['gapOption']['from']['date']);
        let fromatedDateGapTo = this._es.formatDate(obj['gapOption']['to']['date']);
        dateOptions['from'] = this._es.fromDateOptions(fromatedDateGapFrom);
        dateOptions['to'] = this._es.toDateOptions(fromatedDateGapTo);
      }
    } else {
      if (this.isReviewAdd) {
        dateOptions['from'] = this._es.fromDateOptions();
        dateOptions['to'] = this._es.toDateOptions();
        let isPrePopulate: boolean = true;
        let gapObj = this.gapOptions[this.currentRow];
        let isGap = this.isGaps[this.currentRow];
        if (!!isGap) {
          let fromatedDateGapFrom = this._es.formatDate(gapObj['from']['date']);
          let fromatedDateGapTo = this._es.formatDate(gapObj['to']['date']);
          dateOptions['from'] = this._es.fromDateOptions(fromatedDateGapFrom);
          dateOptions['to'] = this._es.toDateOptions(fromatedDateGapTo);
        } else {
          if (!!obj && !!obj['start-date']) {
            dateOptions['from'] = this._es.fromDateOptions(obj['start-date']);
          }

          if (!!obj && !!obj['end-date']) {
            dateOptions['to'] = this._es.toDateOptions(obj['end-date']);
          }
        }
      }

      if (this.isReviewEdit) {
        if (!!obj && !!obj['start-date']) {
          dateOptions['from'] = this._es.fromDateOptions(obj['start-date']);
        }

        if (!!obj && !!obj['end-date']) {
          dateOptions['to'] = this._es.toDateOptions(obj['end-date']);
        }
      }
    }

    if (!!isUpdateInitialItem) {
      dateOptions['from'] = this._es.fromDateOptions();
      dateOptions['to'] = null;
      this.dateConfigs[0] = dateOptions;
    } else if (!!this.isReview) {
      this.dateConfigs[0] = dateOptions;
    } else {
      if (!!this.dateConfigs && !!this.dateConfigs[this.currentRow]) {
        this.dateConfigs[this.currentRow] = dateOptions;
      } else {
        this.dateConfigs.push(dateOptions);
      }
    }
  }

  private checkMinYearComplete(date): boolean {
    if (!!date) {
      let minimumMonth = this._es.getMinimumScopeInMonths();
      if (this.isEmploymentGapInDaysEnabled) {
        let now = new Date();
        let daysDiff = dateUtils.dateDiffInDays(date, now);
        let minimumDate = new Date(now.getFullYear(), now.getMonth() - minimumMonth, now.getDate());
        let minimumDays = dateUtils.dateDiffInDays(minimumDate, now);
        if (daysDiff >= minimumDays) {
          this.isCollectMinYearEmp = true;
        } else {
          this.isCollectMinYearEmp = false;
        }
      } else {
        let monthDiff = this._es.monthDiff(this._es.formatDate(date));
        if (monthDiff >= minimumMonth) {
          this.isCollectMinYearEmp = true;
        } else {
          this.isCollectMinYearEmp = false;
        }
      }
      return this.isCollectMinYearEmp;
    }
  }

  private deleteDuplicationRecord() {
    let serverData: Object[] = [];
    let serverDataLen: number;
    serverData = (JSON.parse(JSON.stringify(this._es.getServerData())));
    serverDataLen = serverData.length;
    if (serverDataLen > 2) {
      for (let i = 0; i < serverDataLen; i++) {
        let currentServerData = serverData[i];
        if (!!currentServerData['start-date'] && !!currentServerData['end-date']) {
          let duplicateArr = serverData.filter(obj => {
            return (obj['start-date'] === currentServerData['start-date'] && obj['end-date'] === currentServerData['end-date'] && !obj['name']);
          });

          if (duplicateArr.length > 2) {
            let currentDuplicateItem = duplicateArr[0];
            this._es.deleteData(currentDuplicateItem['id']).subscribe(response => {
              // Service serverData
              serverData = (JSON.parse(JSON.stringify(this._es.getServerData())));
              let serviceServerDataArr = serverData.filter(obj => {
                return (obj['id'] === currentDuplicateItem['id']);
              });

              if (serviceServerDataArr.length > 0) {
                let currentItem = serviceServerDataArr[0];
                let currentItemIndex = serviceServerDataArr.indexOf(currentItem);
                if (currentItemIndex > -1) {
                  serviceServerDataArr.splice(currentItemIndex, 1);
                }

                this._es.setServerData([serviceServerDataArr]);
              }
            }, error => {
            });
          }
        }
      }
    }
  }

  private getLatestRecords() {
    this.allRecordId = this._es.recordIdObj;
    this.allRecordIdForGap = this._es.recordIdGapArr;
  }

  private get2D(num) {
    if (num.toString().length < 2) {
      return '0' + num;
    } else {
      return num.toString();
    }
  }

  private initPayload(obj, gapOperation?) {    
    let employmentDataItem = {};
    this.formPayload(employmentDataItem, obj);
    this.saveJob(obj, employmentDataItem);
  }

  private formGapPayload(employmentDataItem, obj, isInsert?): void {
    employmentDataItem['state'] = {};
    let stageStatus: string = 'PENDING';
    if (obj['gapOption'] && obj['gapOption']['from']['date']) {
      employmentDataItem['start-date'] = this._es.getDateToTs(obj['gapOption']['from']['date']);
    }

    if (obj['gapOption'] && obj['gapOption']['to']['date']) {
      employmentDataItem['end-date'] = this._es.getDateToTs(obj['gapOption']['to']['date']);
    }

    employmentDataItem['type'] = 'unemployment';
    if (isInsert) {
      this.recordId = null;
    } else {
      if (!!this.recordId) {
        employmentDataItem['id'] = this.recordId;
      }
    }

    if (obj['COMPONENT_STATE']) {
      if (obj['COMPONENT_STATE']['status'] === 'COMPLETED' && obj['COMPONENT_STATE']['subStep'] === 'gap-confirm' && this.isScopeCompleted && this.checkMinYearComplete(obj['gapOption']['from']['date'])) {
        stageStatus = 'PENDING';
      }
      employmentDataItem['state']['stage_status'] = stageStatus;
      employmentDataItem['state']['current_stage'] = obj['COMPONENT_STATE']['name'];
      employmentDataItem['state']['current_step'] = obj['COMPONENT_STATE']['step'];
      employmentDataItem['state']['current_step_index'] = obj['COMPONENT_STATE']['stepIndex'];
      employmentDataItem['state']['current_sub_step'] = obj['COMPONENT_STATE']['subStep'];
      employmentDataItem['state']['current_sub_step_index'] = obj['COMPONENT_STATE']['subStepIndex'];
      employmentDataItem['state']['current_iteration'] = obj['COMPONENT_STATE']['iteration'];

      if (this.isReview) {
        employmentDataItem['state']['current_iteration'] = (!!this.recordId) ? `${employmentDataItem['state']['current_iteration']}***${this.recordId}` : `${employmentDataItem['state']['current_iteration']}***`;

        if (obj['COMPONENT_STATE']['status'] === 'PENDING' && !!this.isReviewAdd) {
          employmentDataItem['state']['stage_status'] = 'REVIEW_ADD_PENDING';
        }

        if (obj['COMPONENT_STATE']['status'] === 'PENDING' && !!this.isReviewEdit) {
          employmentDataItem['state']['stage_status'] = 'REVIEW_EDIT_PENDING';
        }
      }

      employmentDataItem['state']['step_status'] = obj['COMPONENT_STATE']['status'];
      employmentDataItem['state']['current_status'] = obj['COMPONENT_STATE']['status'];
    }
  }

  private decideStageStatus(obj: Object): string {
    let stageStatus: string = 'PENDING';
    if (this.isReviewAdd && !!obj['COMPONENT_STATE'] && obj['COMPONENT_STATE']['status'] === 'PENDING') {
      stageStatus = 'REVIEW_ADD_PENDING';
    } else if (this.isReviewEdit) {
      stageStatus = 'REVIEW_EDIT_PENDING';
      if (obj['COMPONENT_STATE']['status'] !== 'PENDING' && obj['COMPONENT_STATE']['subStep'] === 'job-period' && !this.isGaps[this.currentRow]) {
        stageStatus = 'COMPLETED';
      }
    }
    if (obj['jobFrom'] && obj['jobFrom'].length > 0) {
      if (this.isScopeCompleted && this.checkMinYearComplete(obj['jobFrom'])) {
        if (!this.isReviewEdit && !!obj['COMPONENT_STATE'] &&
          obj['COMPONENT_STATE']['subStep'] === 'job-period' &&
          obj['COMPONENT_STATE']['status'] === 'COMPLETED') {
          stageStatus = 'PENDING';
        } else {
          if (!!obj['COMPONENT_STATE'] && obj['COMPONENT_STATE']['status'] === 'COMPLETED' && (obj['COMPONENT_STATE']['subStep'] === 'job-period' || obj['COMPONENT_STATE']['subStep'] === 'gap-confirm')) {
            stageStatus = 'PENDING';
          } else if (!!obj['COMPONENT_STATE'] && obj['COMPONENT_STATE']['status'] === 'PENDING' && !!this.isReviewAdd) {
            stageStatus = 'REVIEW_ADD_PENDING';
          }
        }
      } else {
        if (this.isReviewAdd && !!obj['COMPONENT_STATE'] && obj['COMPONENT_STATE']['subStep'] === 'job-period') {
          stageStatus = 'REVIEW_ADD_PENDING';
        }
      }
    } else {
      if (stageStatus !== 'REVIEW_EDIT_PENDING') {
        stageStatus = 'PENDING';
      }
    }

    if (!!this.isReviewAdd) {
      stageStatus = 'REVIEW_ADD_PENDING';
    }
    if (!this.isReview && !obj['confirm'] && obj['COMPONENT_STATE']['status'] === 'COMPLETED' && obj['COMPONENT_STATE']['subStep'] === 'verification') {
      stageStatus = 'COMPLETED';
    }
    if (!this.isReview && obj['confirm'] && obj['COMPONENT_STATE']['status'] === 'COMPLETED' && (obj['COMPONENT_STATE']['subStep'] === 'job-period' || obj['COMPONENT_STATE']['subStep'] === 'job-title')) {
      stageStatus = 'COMPLETED';
    }

    if(obj['type'] === 'neverEmployed'){
      stageStatus = 'COMPLETED';
    }
    return stageStatus;
  }

  private formPayload(employmentDataItem, obj, newJob?): void {
    employmentDataItem['state'] = {};
    
    if (!!this.recordId && !!newJob) {
      newJob['id'] = this.recordId;
    }
    
    if (newJob) {
      newJob['type'] = this.activeEmpType;
      employmentDataItem['type'] = this.activeEmpType;
    } else {
      employmentDataItem['type'] = this.activeEmpType;
    }

    if (obj['type'] === 'neverEmployed') {
      employmentDataItem['service_action'] = 'PERSIST';

      let startDate = new Date();
      startDate.setFullYear(startDate.getFullYear() - this.minYear);
      employmentDataItem['start-date'] = startDate.getTime();
      employmentDataItem['from_date'] = dateUtils.getDateFormatYYYYMMDD(employmentDataItem['start-date']);
    }

    if (!this.isScopeAvailable) {
      if (obj['confirm']) {
        employmentDataItem['confirm'] = 'true';
        employmentDataItem['service_action'] = 'PERSIST';
      } else {
        employmentDataItem['confirm'] = 'false';
        employmentDataItem['service_action'] = 'PERSIST';
      }
      if (obj['reconfirm'] === 'true') {
        employmentDataItem['reconfirm'] = 'true';
      } else {
        employmentDataItem['reconfirm'] = 'false';
      }
    }
    
    if (this.currentRow === 0 && !this.isReview) {
      employmentDataItem['is_current'] = true;
    } else if (this.isReviewEdit) {
      if (this.currentJob === true) {
        employmentDataItem['is_current'] = true;
      }
    }

    if (obj['permissionToContact'] && obj['permissionToContact'].length > 0) {
      if (this.activeEmpType === 'current' || (this.activeEmpType === 'selfEmployed' && (this.currentRow === 0) && this.isCheckSelfEmploymentEnabled[this.currentRow])) {
        if (newJob) {
          newJob['permissionToContact'] = obj['permissionToContact'];
        }
        employmentDataItem['permission-to-contact'] = obj['permissionToContact'];
        let d = new Date(obj['datetocontact']);
        let getMonth = this.get2D(d.getMonth() + 1);
        let getDate = this.get2D(d.getDate());
        if (obj['datetocontact']) {
          obj['datetocontact'] = d.getFullYear() + '-' + getMonth + '-' + getDate;
          employmentDataItem['when-to-contact'] = obj['datetocontact'];
        }
      }
    }

    if (!!obj['indexDetail'] && (this.isGapData[obj['indexDetail']['row'] - 1])) {
      employmentDataItem['isGapFulfilled'] = true;
    }

    if (obj['companyName'] && obj['companyName'].length > 0) {
      if (newJob) {
        newJob['companyName'] = obj['companyName'];
      }
      employmentDataItem['name'] = obj['companyName'];
    }

    if (!!obj['indexDetail'] && !!this.selectedCompany[obj['indexDetail']['row']]) {
      employmentDataItem['source_id'] = this.selectedCompany[obj['indexDetail']['row']]['id'];
    }

    if (obj['jobTitle'] && obj['jobTitle'].length > 0) {
      if (newJob) {
        newJob['jobTitle'] = obj['jobTitle'];
      }
      employmentDataItem['service_action'] = obj['service_action'];
      employmentDataItem['job-title'] = obj['jobTitle'];
    }

    if (obj['zipCode'] && obj['zipCode'].length > 0) {
      if (newJob) {
        newJob['zipCode'] = obj['zipCode'];
      }
      employmentDataItem['zip'] = obj['zipCode'];
    }

    if (obj['jobFrom'] && obj['jobFrom'].length > 0) {
      if (newJob) {
        newJob['jobFrom'] = obj['jobFrom'];
      }
      if (((obj['type'] !== 'current' && obj['type'] !== 'prior') || (obj['type'] === 'current' && this.isCitadelData[this.currentRow])) && !!obj['service_action']) {
        employmentDataItem['service_action'] = obj['service_action'];
      }
      employmentDataItem['start-date'] = this._es.getDateToTs(obj['jobFrom']);
      employmentDataItem['from_date'] = dateUtils.getDateFormatYYYYMMDD(employmentDataItem['start-date']);
      
    }

    if (obj['jobTo'] && obj['jobTo'].length > 0) {
      if (this.activeEmpType === 'prior' || this.activeEmpType === 'selfEmployed' || this.activeEmpType === 'unemployed' || this.activeEmpType === 'inSchool' || this.activeEmpType === 'internship') {
        if (newJob) {
          newJob['jobTo'] = obj['jobTo'];
        }
        if ((obj['type'] !== 'current' && obj['type'] !== 'prior') && !!obj['service_action']) {
          employmentDataItem['service_action'] = obj['service_action'];
        }
        employmentDataItem['end-date'] = this._es.getDateToTs(obj['jobTo']);
        employmentDataItem['to_date'] = dateUtils.getDateFormatYYYYMMDD(employmentDataItem['end-date']);
        
      }
    }
    
    employmentDataItem['zone_offset'] = dateUtils.getTimeZoneOffset();

    if (obj['countryName'] && obj['countryName'].length > 0) {
      if (newJob) {
        newJob['countryName'] = obj['countryName'];
        newJob['countryCode'] = obj['countryCode'];
      }
      employmentDataItem['country'] = obj['countryCode'];
      employmentDataItem['country_name'] = obj['countryName'];
    }

    if (obj['city'] && obj['city'].length > 0) {
      if (newJob) {
        newJob['city'] = obj['city'];
      }
      employmentDataItem['city'] = obj['city'];
    }

    if (obj['stateName'] && obj['stateName'].length > 0) {
      if (newJob) {
        newJob['stateName'] = obj['stateName'];
        newJob['stateCode'] = obj['stateCode'];
      }
      employmentDataItem['state_code'] = obj['stateCode'];
      employmentDataItem['state_name'] = obj['stateName'];
    } else  if (this.isCitadelData[this.currentRow]) {
      if (newJob) {
        //newJob['stateName'] = obj['stateName'];
        newJob['stateCode'] = obj['stateCode'];
      }
      employmentDataItem['state_code'] = obj['stateCode'];
     // employmentDataItem['state_name'] = obj['stateName'];
    }
    
    if (this.isCitadelData[this.currentRow] && !this.isOptOutCitadelData[this.currentRow]) {
      employmentDataItem['source'] = EmploymentConfig.EMP_SOURCE_CITADEL;
    }

    if (obj['supervisorName'] && obj['supervisorName'].length > 0) {
      if (newJob) {
        newJob['contactName'] = obj['supervisorName'];
      }
      employmentDataItem['contact'] = obj['supervisorName'];
    }

    if (obj['managerName']) {
      if (newJob) {
        newJob['managerName'] = obj['managerName'];
      }
      employmentDataItem['manager_name'] = obj['managerName'];
    }

    if (obj['position']) {
      if (newJob) {
        newJob['position'] = obj['position'];
      }
      employmentDataItem['manager_position'] = obj['position'];
    }

    if (obj['managerCountryCode']) {
      if (newJob) {
        newJob['managerCountryCode'] = obj['managerCountryCode'];
      }
      employmentDataItem['manager_country_code'] = obj['managerCountryCode'];
    }

    if (obj['phoneNumber']) {
      if (newJob) {
        newJob['phoneNumber'] = obj['phoneNumber'];
      }
      employmentDataItem['manager_phone_number'] = obj['phoneNumber'];
    }

    employmentDataItem['tel_country'] = obj['tel_country'];

    if (obj['companyNameInLocalLang']) {
      if (newJob) {
        newJob['companyNameInLocalLang'] = obj['companyNameInLocalLang'];
      }
      employmentDataItem['company_name_in_local_lang'] = obj['companyNameInLocalLang'];
    }

    if (!!obj['countryCode'] && obj['countryCode'] === 'US' && this.isPaDOTDirectEnabled && obj['streetAddress']) {
      if (newJob) {
        newJob['companyAddress'] = obj['streetAddress'];
      }
      employmentDataItem['company_address'] = obj['streetAddress'];
    }

    if (!!obj['countryCode'] && obj['countryCode'] === 'US' && this.isPaDOTDirectEnabled && obj['streetAddress']) {
      if (newJob) {
        newJob['companyAddress'] = obj['streetAddress'];
      }
      employmentDataItem['company_address'] = obj['streetAddress'];
    }

    if (obj['companyAddress']) {
      if (newJob) {
        newJob['companyAddress'] = obj['companyAddress'];
      }
      employmentDataItem['company_address'] = obj['companyAddress'];
    }

    if (obj['managerEmail']) {
      if (newJob) {
        newJob['managerEmail'] = obj['managerEmail'];
      }
      employmentDataItem['manager_email'] = obj['managerEmail'];
    }

    if (obj['supervisorNo']) {
      if (newJob) {
        newJob['contactNumber'] = obj['supervisorNo'];
      }
      employmentDataItem['contact-number'] = obj['supervisorNo'];
    }

    this.setMultiplePositionValues(obj, employmentDataItem, newJob);

    if (obj['employeeId']) {
      if (newJob) {
        newJob['employeeId'] = obj['employeeId'];
      }
      employmentDataItem['employee_id'] = obj['employeeId'];
    }

    if (obj['department']) {
      if (newJob) {
        newJob['department'] = obj['department'];
      }
      employmentDataItem['department'] = obj['department'];
    }

    if (obj['jobType']) {
      if (newJob) {
        newJob['jobType'] = obj['jobType'];
      }
      employmentDataItem['job_type'] = obj['jobType'];
    }
    
    if (this.isINTLSUPComponentAvailable && obj['managerResidenceCountryCode']) {
      if (newJob) {
        newJob['managerResidenceCountryCode'] = obj['managerResidenceCountryCode'];
        newJob['managerResidenceCountry'] = obj['managerResidenceCountry'];
      }
      employmentDataItem['manager_residence_country'] = obj['managerResidenceCountryCode'];
      employmentDataItem['manager_residence_country_name'] = obj['managerResidenceCountry'];
    }
    
    if (this.isINTLSUPComponentAvailable && obj['managerResidenceStateCode']) {
      if (newJob) {
        newJob['managerResidenceStateCode'] = obj['managerResidenceStateCode'];
        newJob['managerResidenceStateName'] = obj['managerResidenceStateName'];
      }
      employmentDataItem['manager_residence_state'] = obj['managerResidenceStateCode'];
      employmentDataItem['manager_residence_state_name'] = obj['managerResidenceStateName'];
    }

    if (!!obj['COMPONENT_STATE']) {
      employmentDataItem['state']['stage_status'] = this.decideStageStatus(obj);
      employmentDataItem['state']['current_stage'] = obj['COMPONENT_STATE']['name'];
      employmentDataItem['state']['current_step'] = obj['COMPONENT_STATE']['step'];
      employmentDataItem['state']['current_step_index'] = obj['COMPONENT_STATE']['stepIndex'];
      employmentDataItem['state']['current_sub_step'] = obj['COMPONENT_STATE']['subStep'];
      employmentDataItem['state']['current_sub_step_index'] = obj['COMPONENT_STATE']['subStepIndex'];
      employmentDataItem['state']['current_iteration'] = obj['COMPONENT_STATE']['iteration'];
      employmentDataItem['state']['current_iteration'] = (!!this.recordId) ? `${employmentDataItem['state']['current_iteration']}***${this.recordId}` : `${employmentDataItem['state']['current_iteration']}***`;
      employmentDataItem['state']['step_status'] = obj['COMPONENT_STATE']['status'];
      employmentDataItem['state']['current_status'] = obj['COMPONENT_STATE']['status'];
    }
    if (!this._utilService.isEmpty(employmentDataItem['state']['current_sub_step']) && employmentDataItem['state']['current_sub_step'] === 'intl-emp' && this._utilService.isEmpty(employmentDataItem['service_action'])) {
      employmentDataItem['service_action'] = obj['service_action'];
    }

    if (this.recordId) {
      employmentDataItem['id'] = this.recordId;
    }

    if (obj['forms'] && obj['forms'][0]) {
      employmentDataItem['form'] = obj['forms'][0];
      if (!!obj['service_action']) {
        employmentDataItem['service_action'] = obj['service_action'];
      }
    }
    if (obj['driverDuties'] !== undefined) {
      employmentDataItem['has_driver_duties'] = obj['driverDuties'];
    }
    if (obj['faaSafetyCheck'] !== undefined) {
      employmentDataItem['faa_safety_check'] = obj['faaSafetyCheck'];
    }

    if (obj['reasonForLeaving'] !== undefined) {
      employmentDataItem['reason_for_leaving'] = obj['reasonForLeaving'];
      if (!!obj['service_action']) {
        employmentDataItem['service_action'] = obj['service_action'];
      }
    }
    //add paSyncId to out going put request
    if (obj['paSyncId']) {
      employmentDataItem['pa_sync_id'] = obj['paSyncId'];
    }
    
    if (obj['empDataProvider']) {
      employmentDataItem['data_provider'] = obj['empDataProvider']
    }
    
    // Service action to update only the state on PUT call of employment-step
    if (this.isPlaidPreferenceEnabled && this.showPlaidData[this.currentRow] && obj['type'] === 'current') {
      employmentDataItem['service_action'] = obj['service_action'];
    }
  }

  private initPendingPeriod() {
    let pendingYear;
    let pendingMonth;
    let pendingPeriod = {};
    pendingPeriod['year'] = this.minYear;
    if (this.activeEmpType === 'current') {
      this.pendingPeriods.push(pendingPeriod);
    } else if (this.activeEmpType === 'prior') {
      let date;
      if (this.minPriorJob) {
        date = this._es.formatDate(this.minPriorJob['jobFrom']);
      } else if (this.minCurrentJob) {
        date = this._es.formatDate(this.minCurrentJob['jobFrom']);
      }
      if (date) {
        let givenMonth = this._es.monthDiff(date);
        let totalMonth = this.minYear * 12;
        pendingYear = Math.floor((totalMonth - givenMonth) / 12);
        pendingMonth = (totalMonth - givenMonth) % 12;
        pendingPeriod['year'] = pendingYear;
        pendingPeriod['month'] = pendingMonth;
      }
      this.pendingPeriods.push(pendingPeriod);
    }
  }

  private closeModal(id) {
    $(id).modal('hide');
  }

  private getCurrentRow(): any {
    if (this.currentSlide) {
      let curSlideDetails = this.currentSlide.split('_');
      let row = curSlideDetails[curSlideDetails.length - 1];
      return row;
    } else {
      return this.currentRow;
    }
  }

  private currentPriorConfirmflagCheck(obj): Object {
    let serverData: Object[] = [];
    serverData = (JSON.parse(JSON.stringify(this._es.getServerData())));
    if (!!serverData[this.currentRow]) {
      if (!!serverData[this.currentRow]['prior_employment_confirm']) {
        obj['priorEmploymentConfirm'] = true;
        obj['currentEmploymentConfirm'] = false;
      } else if (!!serverData[this.currentRow]['current_employment_confirm']) {
        obj['priorEmploymentConfirm'] = false;
        obj['currentEmploymentConfirm'] = true;
      }
    }
    return obj;
  }

  agree(id) {
    let row = this.getCurrentRow();
    this.verificationFlags[row] = 'YES';
    this.closeModal(id);
  }

  private disagreeNext(id) {
    let row = this.getCurrentRow();
    this.verificationFlags[row] = 'No';
    this.closeModal(id);
  }

  changeMyMind(id) {
    this.closeModal(id);
  }

  noScopeNo(id) {
    this.routeToNextStage();
    this.closeModal(id);
  }

  empReconfirm(obj) {
    if (!this.isAjaxCallProccess) {
      // this.initPayload(obj);
      this.afterReconfirmSave(obj);
    }
  }

  private afterReconfirmSave(obj) {
    if (obj['reconfirm'] == "true") {
      const control = <FormArray>this.employmentForm.controls['employmentSubForm'];
      let controlLen = control.length;
      if (this.currentRow === (controlLen - 1)) {
        if (this.repeateEmployment(obj)) {
          this.sliderIndex = this.empSteps.indexOf('category');
          this.currentRow = this.currentRow + 1;
          this.recordId = null;
          this.empCategories = this._utilService.getArrayFromJsonString(this._ts.translate(EmploymentConfig.EMPLOYMENT_CATEGORIES));
          this.empTypes.push(null);
          this.setSlider(this.sliderIndex, 'right', this.currentRow);
        }
      } else {
        this.jumpToNextIteration();
        return;
      }
    } else {
      this.routeToNextStage();
    }
  }

  empReconfirmSwipe(obj) {
    let direction = 'left';
    this.sliderIndex = this.empSteps.indexOf('job-period');
    this.setSlider(this.sliderIndex, direction, this.currentRow);
  }

  private goToNextStage(item?) {
    if (!!item && !!item['COMPONENT_STATE'] && item['COMPONENT_STATE']['stage_status'] !== 'COMPLETED') {
      let step: string = 'employment-step';
      let body = {
        'employment-data-list': []
      };
      let employmentDataItem = {};
      this.formPayload(employmentDataItem, item);
      employmentDataItem['state']['stage_status'] = 'COMPLETED';
      body['employment-data-list'].push(employmentDataItem);

      this.goToNextStageUpdatePayload(body, step);
    } else {
      this.routeToNextStage();
    }
    return false;
  }

  private routeToNextStage() {
    this._progressbarStatus.setEmployement('true');
    this._progressbarStatus.createEmployeementProgressbar(this.nextdisplayname);
    let flagstatus = this._statusBar.getCompletedMenustatus('employment-data-v1');
    if (!flagstatus) {
      this._statusBar.setstatusEmployement('employment-data-v1');
      let statusBarobject = this._statusBar.getStatusBarValue('employmentStatus');
      let remainingSection = statusBarobject['remainingsection'];
      let percentage = statusBarobject['percentage'];
      let remainingTime = statusBarobject['timeremaining'];
      let completedMenu = statusBarobject['completedMenu'];
      this._sharedService.setStatusBar(remainingSection, percentage, remainingTime, completedMenu, this._eventBus);
    }

    this.nextURL = this._es.getNextURL();
    this.hideEmpWaitModal();
    if (!!this.nextURL) {
      this._route.navigate([this.nextURL]);
      jQuery('.responsive').slick('next');
    }
  }

  private goToDashboard() {
    this._route.navigate(['/dashboard']);
  }

  private afterSave(obj, item) {
    this.assignIdsToMultiPosition(obj, item);
    // this.prepareIntlFlagArray(item);
    if (!!obj['service_action'] && (obj['service_action'] === 'PERSIST' || obj['service_action'] === 'ONLY_UPDATE_STATE')) {
      this._es.afterSaveUpdateServerData(item);
    }
    // let componentName: string = this.currentSlide.split('_')[0];
    let componentName = obj['COMPONENT_STATE']['subStep'];
    let afterSaveFuntion = this._es.getAfterSaveFunctionName(componentName);
    this[afterSaveFuntion](obj, item);
  }

  private serverValidator(control: FormControl): { [s: string]: boolean } {
    if (this.fromServerError) {
      return { serverError: true };
    } else {
      return { serverError: false };
    }
  }

  private makeServerValidator(control, flag, obj?) {
    const subFormArray = <FormArray>this.employmentForm.controls['employmentSubForm'];
    let subFormArrayLength = subFormArray.length;
    let currentSlideArr = this.currentSlide.split('_');
    let currentRow = currentSlideArr[currentSlideArr.length - 1];
    const subFormGroup = <FormGroup>subFormArray.controls[currentRow];
    if (!!obj && !!obj['country']) {
      if (subFormGroup.contains('countryCode')) {
        subFormGroup.controls['countryCode'].setValue(obj['country']);
      }
    }

    if (!!obj && !!obj['state_code']) {
      if (subFormGroup.contains('stateCode')) {
        subFormGroup.controls['stateCode'].setValue(obj['state_code']);
      }
    }

    this.fromServerError = flag;
    if (!!subFormGroup && subFormGroup.controls[control] !== undefined) {
      subFormGroup.controls[control].clearValidators();
      subFormGroup.controls[control].setValidators(Validators.compose([Validators.required, this.serverValidator.bind(this)]));
      subFormGroup.controls[control].updateValueAndValidity();
    }
  }

  private saveJob(obj, item): Observable<any> {
    this.zipCodeError = false;
    this.localStorage.setItem('atsArrayDataIndex', this.atsArrayDataIndex);
    this.localStorage.setItem('selectedATSCardId', this.selectedATSCardId);
    if (this.isAjaxCallProccess) {
      console.log('Something went wrong');
      // console.log(obj);
      // console.log(item);
    } else if (!this.isAjaxCallProccess) {
      this.isAjaxCallProccess = true;
      let body = {
        'employment-data-list': []
      };
      body['employment-data-list'].push(item);
      let that = this;
      this._es.save(body).subscribe(response => {
        let empDataList = body['employment-data-list'][0];
        let empDataState = empDataList['state'];
        // let resObj = JSON.parse(response._body);
        let resObj = response;
        if (resObj) {
          this._recordidservice.setprofileBuilderbutton(false);
          setTimeout(() => this.isAjaxCallProccess = false, 700);
          //  this.isAjaxCallProccess = false;
        }
        let empId = resObj['employment-data-list'][0]['id'];

        if (empDataState['current_sub_step'] !== 'gap' && empDataState['current_sub_step'] !== 'gap-confirm') {
          if (!!this.recordId) {
            let iterationRow = (!!empDataState['current_iteration'] && empDataState['current_iteration'].indexOf('***') > -1) ? empDataState['current_iteration'].split('***')[0] : empDataState['current_iteration'];
            if (iterationRow == 'null') {
              iterationRow = 0;
            }
            this._es.setRecordIdCollection(iterationRow, empId);
          }
        }

        if (empDataState['current_sub_step'] === 'gap-confirm') {
          let allRecordIdForGapLen: number = this._es.recordIdGapArr.length;
          if (allRecordIdForGapLen > 0) {
            if (this._es.recordIdGapArr.indexOf(empId) === -1) {
              this._es.recordIdGapArr.push(empId);
            }
          } else {
            this._es.recordIdGapArr.push(empId);
          }
        }

        let compArr = this.currentSlide.split('_');
        let row = compArr[1] || 0;
        this.recordId = resObj['employment-data-list'][0]['id'];

        if (empDataState['current_sub_step'] === 'custform') {
          let subFormcontrol = <FormArray>this.employmentForm.controls['employmentSubForm'];
          let formGroup = <FormGroup>subFormcontrol.controls[row];
          (<FormControl>formGroup.controls['form']).setValue(resObj['employment-data-list'][0]['form']);
        }

        if ((empDataState['current_sub_step'] === 'current-employment-confirm' && empDataList['current_employment_confirm']) || (empDataState['current_sub_step'] === 'prior-employment-confirm' && empDataList['prior_employment_confirm']) || (empDataState['current_sub_step'] === 'gap-confirm' && !!obj['gapConfirm']) || (empDataState['current_sub_step'] === 'gap' && !!obj['gapConfirm'])) {
          this.recordId = null;
        }
        if (empDataState['current_status'] === 'COMPLETED') {
          obj['trakEventMsg'] = 'save data to db';
          this.trackEvent(obj, 'next click', true, true);
          let serverEmpRes = resObj['employment-data-list'][0];

          if (serverEmpRes['type'] === 'current' || serverEmpRes['type'] === 'prior' || (serverEmpRes['type'] === 'selfEmployed' && (this.isPaDOTDirectEnabled || this.isCheckSelfEmploymentEnabled[row]))) {
            this.makeServerValidator('city', false, serverEmpRes);
            this.makeServerValidator('stateName', false, serverEmpRes);
            this.makeServerValidator('countryName', false, serverEmpRes);
          }

          /***
           * Nth year calculation starts here
           * (remaining_scope) will give the current ID pending scope - (To display pending scope for back and forth)
           * (total_pending_period) will give the total pending scope for whole iteartion (On flow)
           */
          if (resObj['employment-data-list'][0]['remaining_scope'] !== undefined && compArr[0] != "category") {
            if (this.isEmploymentGapInDaysEnabled) {
              this.setRemainingYearMonthAndDay(JSON.parse(resObj['employment-data-list'][0]['remaining_scope']));
            } else {
              this.setRemainingYearAndMonth(JSON.parse(resObj['employment-data-list'][0]['remaining_scope']));
            }
          }

          if (obj['COMPONENT_STATE']['subStep'] === 'job-period' || obj['COMPONENT_STATE']['subStep'] === 'job-title' 
          || obj['COMPONENT_STATE']['subStep'] === 'position' || obj['COMPONENT_STATE']['subStep'] === 'intl-emp'
          || ( compArr[0] === "category" && obj['type'] === 'neverEmployed' ) 
          || (obj['COMPONENT_STATE']['subStep'] === 'payroll-lookup' && (this.isCitadelData[this.currentRow] ))
          || (obj['COMPONENT_STATE']['subStep'] === 'data-provider')) {
            const control = <FormArray>this.employmentForm.controls['employmentSubForm'];
            let formLen = control.length;
            if ((formLen - 1) === obj['indexDetail']['row']) {
              that.isGapData[obj['indexDetail']['row']] = false;
              if (this.isEmploymentGapInDaysEnabled) {
                this.setRemainingYearMonthAndDay(resObj['employment-data-list'][0]['total_pending_period']);
              } else {
                this.setRemainingYearAndMonth(resObj['employment-data-list'][0]['total_pending_period']);
              }
            }
          }

          /***
           * Nth year Implementation ends here
           */

          // Make transition properly in city-state and reporting manager page
          if (empId === undefined && obj['COMPONENT_STATE']['subStep'] === 'city-state') {
            this.afterCityStateSave(obj);
          } else if (empId === undefined && obj['COMPONENT_STATE']['subStep'] === 'reporting-manager-details') {
            this.afterReportingManagerDetailsSave(obj);
          } else {
            this.afterSave(obj, serverEmpRes);
          }
        } else if (empDataState['current_status'] === 'PENDING') {
          this.goToDashboard();
        }
        this.getLatestRecords();

        if(compArr[0] === "category" && obj['type'] === 'neverEmployed' ){
          obj['COMPONENT_STATE']['subStep'] = 'reconfirm';
          if(this.isReview ){
            this.empGoToProfileBuilder();
            return;
          }
        }

        this.data$.next({ counter: 1 }
        );
      }, error => {
        if (error === 'EMPCITY') {
          this.makeServerValidator('city', true);
          if (error !== 'EMPSTATE') {
            this.makeServerValidator('stateName', false);
          }
        } else if (error === 'EMPSTATE') {
          if (error !== 'EMPCITY') {
            this.makeServerValidator('city', false);
          }
          this.makeServerValidator('stateName', true);
        } else if (error === 'EMPZIP') {
          this.zipCodeError = true;
          this.makeServerValidator('zipCode', true);
        } else if (error === 'EMPCOUNTRY') {
          this.makeServerValidator('countryName', true);
        } else if (error === 'EMPINVALIDISDCODE' || error === 'EMPINVALIDPHONENUMBER' || error === 'EMPINVALIDISDCODE EMPINVALIDPHONENUMBER') {
          let random = this._utilService.getCryptoRandom();
          this.ErrorCode = error + '_' + random;
        }
        this.isAjaxCallProccess = false;
        this.hideEmpWaitModal();
        this.data$.next({ counter: -1 });
      });
      return this.data$;
    }
  }

  private goToReview(obj, item) {
    if (this.isReview) {
      this.gapOptions = [];
      this.isGaps = [];
      this.isGapResolve = false;
      let step: string = 'employment-step';
      let body = {
        'employment-data-list': []
      };
      item['state']['stage_status'] = 'COMPLETED';
      body['employment-data-list'].push(item);
      this.goToReviewNoGapUpdatePayload(body, step);

      /* 
       * Do not go to next iteration to fill the gap 
       * instead land to review edit if the employment record edited from review edit
       */
    } else {
      this.hideEmpWaitModal();
      this.goToReviewUpdate();
    }
  }

  private gapPayloadUpdate(body, step) {
    this._cs.payLoadUpdate(body, step).subscribe(response => {

    }, error => {
      console.log('Not Update');
    });
  }

  private reviewWithGap(body, step) {
    this._cs.payLoadUpdate(body, step).subscribe(response => {
      console.log('Success Update');
      this.getData();
    }, error => {
      console.log('Not Update');
    });
  }

  private goToNextStageUpdatePayload(body, step) {
    this._cs.payLoadUpdate(body, step).subscribe(response => {
      this.routeToNextStage();
    }, error => {
    });
  }

  private goToReviewNoGapUpdatePayload(body, step) {
    this._cs.payLoadUpdate(body, step).subscribe(response => {
      console.log('Success Update');
      this.goToReviewUpdate();
    }, error => {
      this.hideEmpWaitModal();
      console.log('Not Update');
    });
  }

  private goToReviewUpdate() {
    this._progressbarStatus.setEmployement('true');
    this._progressbarStatus.createEmployeementProgressbar(this.nextdisplayname);
    this.hideEmpWaitModal();

    if (!!this.nextIntlURL) {
      this._route.navigate([this.nextIntlURL]);
    } else {
      this._route.navigate(['/home/review/show-all']);
    }
    return;
  }

  private createCurrentSlide(currentObject?) {
    let nextComponent: string;
    let currentComponent: string;
    let currentComponentIndex: number;
    let nextComponentIndex: number;
    let currentStatus = this.stateConfig['current_status'];
    let stageStatus = this.stateConfig['stage_status'];
    let currentSubStep = this.stateConfig['current_sub_step'];
    if (this.isAlertUserOnBlankScreen && !this.stateConfig['current_iteration']) {
      this.showBlankPage = true;
      return;
    }

    let iterationRow = (this.stateConfig['current_iteration'].indexOf('***')) > -1 ? this.stateConfig['current_iteration'].split('***')[0] : this.stateConfig['current_iteration'];
    let nextIteration: any;
    this.getLatestRecords();

    if (stageStatus === 'PENDING') {
      if (currentStatus === 'PENDING') {
        currentComponent = nextComponent = this.stateConfig['current_sub_step'];
        this.sliderIndex = parseInt(this.stateConfig['current_sub_step_index'], 10);
      } else if (currentStatus === 'COMPLETED') {
        currentComponent = this.stateConfig['current_sub_step'];
        currentComponentIndex = this.empSteps.indexOf(currentComponent);

        if (currentComponentIndex < this.empSteps.length - 1) {
          nextComponentIndex = currentComponentIndex;
          nextComponent = this.empSteps[nextComponentIndex];
        } else {
          nextComponent = currentComponent;
          nextComponentIndex = currentComponentIndex;
        }
        this.currentSlideWithDirection = `${this.currentSlide}` + AppConfig.APP_CONSTANTS.DIRECTION_RIGHT;
        this.sliderIndex = nextComponentIndex;
      }
      if (iterationRow === 'null') {
        iterationRow = 0;
      }

      if (currentStatus === 'COMPLETED' && nextComponent === 'verification-confirm' && this.stateConfig['current_sub_step'] !== 'verification') {
        iterationRow = parseInt(iterationRow, 10);
        this.currentRow = iterationRow = iterationRow;
      }

      if (currentStatus === 'COMPLETED' && nextComponent === 'verification-confirm' && this.stateConfig['current_sub_step'] === 'verification') {
        iterationRow = parseInt(iterationRow, 10);
        this.currentRow = iterationRow = iterationRow;
      }

      (nextComponent === 'verification') ? `${nextComponent}_${iterationRow || 0}` : `${nextComponent}_${iterationRow || 0}`;
      this.sliderIndex = this.empSteps.indexOf(nextComponent);
      if (this.sliderIndex === 0) {
        this.currentRow = 0;
        this.setSlider(this.sliderIndex, 'right', this.currentRow);
      } else {
        this.currentRow = parseInt(iterationRow, 10);
        this.setSlider(this.sliderIndex, 'right', this.currentRow);
      }

      this.activeEmpType = this.empTypes[this.currentRow] || 'prior';
      this.recordId = this.allRecordId[this.currentRow] || null;
    }

    if (currentStatus === 'PENDING' && (stageStatus === 'REVIEW_EDIT_PENDING')) {
      currentComponent = nextComponent = this.stateConfig['current_sub_step'];
      let iteration = this.stateConfig['current_iteration'].split('***')[0] || 0;
      this.currentSlide = `${nextComponent}_${iteration}`;
      this.currentSlideWithDirection = `${this.currentSlide}` + AppConfig.APP_CONSTANTS.DIRECTION_RIGHT;
      this.sliderIndex = parseInt(this.stateConfig['current_sub_step_index'], 10);
    }

    if (currentStatus === 'COMPLETED' && (stageStatus === 'REVIEW_EDIT_PENDING')) {
      currentComponent = this.stateConfig['current_sub_step'];
      currentComponentIndex = this.empSteps.indexOf(currentComponent);
      nextComponent = currentComponent;
      nextComponentIndex = currentComponentIndex;
      
      let iteration = this.stateConfig['current_iteration'].split('***')[0] || 0;
      if (currentObject['type'] === 'current' || currentObject['type'] === 'prior'
        || (currentObject['type'] === 'selfEmployed' && this.isCheckSelfEmploymentEnabled[this.currentRow])) {
        this.sliderIndex = nextComponentIndex;
      } else {
        this.sliderIndex = this.empSteps.indexOf('job-period');
      }

      this.setSlider(this.sliderIndex, 'right', iteration);
    }
  }

  empGoToProfileBuilder() {
    if (!!this.nextIntlURL) {
      this._route.navigate([this.nextIntlURL]);
    } else {
      let clipboardUri = `/home/review/show-all`;
      this._route.navigate([clipboardUri]);
    }
    return;
  }

  private trackEvent(obj, actionValue, isBehavioralMetrics, isOperationalMetrics) {
    let startTime = obj['startTime'];
    let trakEventMsg = obj['trakEventMsg'];
    let endTime = new Date().getTime();
    let timeDiff = (endTime - startTime) / 1000;
    timeDiff = Math.floor(timeDiff);

    let trackObj = {
      stage_name: 'employment',
      time_spent: timeDiff,
      action_value: actionValue
    };
    window.track_event(trakEventMsg, trackObj,isBehavioralMetrics, isOperationalMetrics);
  }

  setATSData(inParams) {
    console.log('employment setATSData');
    /*
     * Below call will parepare the hide/show fields for an iteration
     */
    this.prepareIntlFlagArray(this.ATSData['employment-data-list'][this.atsArrayDataIndex], inParams['iteration']);

    this.isATSData = true;
    this.atsArrayDataIndex = inParams['index'];
    this.ats_data_delete_link = inParams['delete_link'];

    this.isATSData = true;
    this.localStorage.setItem('isATS', 'YES');
    console.log('employment : ATSDATA to initialize form');
    // console.log(this.ATSData['employment-data-list'][this.atsArrayDataIndex]);

    this.selectedATSCardId = this.ATSData['employment-data-list'][this.atsArrayDataIndex]['id'];
    this.empTypes[inParams['iteration']] = inParams['selectedCategory'];
    let rowObj = {
      row: inParams['iteration']
    };
    let currentItemObj = {};
    currentItemObj['indexDetail'] = rowObj;

    let opt = {};

    this.isCheckSelfEmploymentEnabled[this.currentRow] = this._es.isSelfEmploymentCheckRequired(
      inParams['selectedCategory'],
      this.ATSData['employment-data-list'][this.atsArrayDataIndex]['country']
    );

    this.makeDynamicFormGroupControls(currentItemObj, opt);

    let cityState = this.ATSData['employment-data-list'][this.atsArrayDataIndex]['city'] + ', ' + this.ATSData['employment-data-list'][this.atsArrayDataIndex]['state_name'];
    if (this.employmentForm.controls['employmentSubForm']['controls'][inParams['iteration']].controls['city'] !== undefined)
      (<FormControl>this.employmentForm.controls['employmentSubForm']['controls'][inParams['iteration']].controls['city']).setValue(this.ATSData['employment-data-list'][this.atsArrayDataIndex]['city']);
    if (this.employmentForm.controls['employmentSubForm']['controls'][inParams['iteration']].controls['cityState'] !== undefined)
      (<FormControl>this.employmentForm.controls['employmentSubForm']['controls'][inParams['iteration']].controls['cityState']).setValue(cityState);
    if (this.employmentForm.controls['employmentSubForm']['controls'][inParams['iteration']].controls['companyName'] !== undefined)
      (<FormControl>this.employmentForm.controls['employmentSubForm']['controls'][inParams['iteration']].controls['companyName']).setValue(this.ATSData['employment-data-list'][this.atsArrayDataIndex]['name']);
    if (this.employmentForm.controls['employmentSubForm']['controls'][inParams['iteration']].controls['countryCode'] !== undefined)
      (<FormControl>this.employmentForm.controls['employmentSubForm']['controls'][inParams['iteration']].controls['countryCode']).setValue(this.ATSData['employment-data-list'][this.atsArrayDataIndex]['country']);

    if (this.employmentForm.controls['employmentSubForm']['controls'][inParams['iteration']].controls['countryName'] !== undefined) {
      (<FormControl>this.employmentForm.controls['employmentSubForm']['controls'][inParams['iteration']].controls['countryName']).setValue(this.ATSData['employment-data-list'][this.atsArrayDataIndex]['country_name']);
    }

    if (this.employmentForm.controls['employmentSubForm']['controls'][inParams['iteration']].controls['streetAddress'] !== undefined) {
      (<FormControl>this.employmentForm.controls['employmentSubForm']['controls'][inParams['iteration']].controls['streetAddress']).setValue(this.ATSData['employment-data-list'][this.atsArrayDataIndex]['company_address']);
    }

    if (this.employmentForm.controls['employmentSubForm']['controls'][inParams['iteration']].controls['zipCode'] !== undefined) {
      (<FormControl>this.employmentForm.controls['employmentSubForm']['controls'][inParams['iteration']].controls['zipCode']).setValue(this.ATSData['employment-data-list'][this.atsArrayDataIndex]['zip']);
    }

    if (this.employmentForm.controls['employmentSubForm']['controls'][inParams['iteration']].controls['jobFrom'] !== undefined &&
      this.ATSData['employment-data-list'][this.atsArrayDataIndex]['start-date']) {
      (<FormControl>this.employmentForm.controls['employmentSubForm']['controls'][inParams['iteration']].controls['jobFrom']).setValue(this.ATSData['employment-data-list'][this.atsArrayDataIndex]['start-date']);
    }

    if (this.employmentForm.controls['employmentSubForm']['controls'][inParams['iteration']].controls['jobTitle'] !== undefined)
      (<FormControl>this.employmentForm.controls['employmentSubForm']['controls'][inParams['iteration']].controls['jobTitle']).setValue(this.ATSData['employment-data-list'][this.atsArrayDataIndex]['job-title']);

    if (this.employmentForm.controls['employmentSubForm']['controls'][inParams['iteration']].controls['driverDuties'] !== undefined)
      (<FormControl>this.employmentForm.controls['employmentSubForm']['controls'][inParams['iteration']].controls['driverDuties']).setValue(this.ATSData['employment-data-list'][this.atsArrayDataIndex]['has_driver_duties']);

    if (this.employmentForm.controls['employmentSubForm']['controls'][inParams['iteration']].controls['faaSafetyCheck'] !== undefined)
      (<FormControl>this.employmentForm.controls['employmentSubForm']['controls'][inParams['iteration']].controls['faaSafetyCheck']).setValue(this.ATSData['employment-data-list'][this.atsArrayDataIndex]['faa_safety_check']);

    if (this.employmentForm.controls['employmentSubForm']['controls'][inParams['iteration']].controls['reasonForLeaving'] !== undefined)
      (<FormControl>this.employmentForm.controls['employmentSubForm']['controls'][inParams['iteration']].controls['reasonForLeaving']).setValue(this.ATSData['employment-data-list'][this.atsArrayDataIndex]['reason_for_leaving']);

    if (this.employmentForm.controls['employmentSubForm']['controls'][inParams['iteration']].controls['stateCode'] !== undefined)
      (<FormControl>this.employmentForm.controls['employmentSubForm']['controls'][inParams['iteration']].controls['stateCode']).setValue(this.ATSData['employment-data-list'][this.atsArrayDataIndex]['state_code']);
    if (this.employmentForm.controls['employmentSubForm']['controls'][inParams['iteration']].controls['stateName'] !== undefined)
      (<FormControl>this.employmentForm.controls['employmentSubForm']['controls'][inParams['iteration']].controls['stateName']).setValue(this.ATSData['employment-data-list'][this.atsArrayDataIndex]['state_name']);

    if (this.employmentForm.controls['employmentSubForm']['controls'][inParams['iteration']].controls['type'] !== undefined)
      (<FormControl>this.employmentForm.controls['employmentSubForm']['controls'][inParams['iteration']].controls['type']).setValue(inParams['selectedCategory']);
    
    /*
     * For ATS/ Rescreen
     * Following code will only set the employeeId, & department if those data is available
     */

    if (this.ATSData['employment-data-list'][this.atsArrayDataIndex]['employee_id']) {
      if (this.employmentForm.controls['employmentSubForm']['controls'][inParams['iteration']].controls['employeeId'] !== undefined)
        (<FormControl>this.employmentForm.controls['employmentSubForm']['controls'][inParams['iteration']].controls['employeeId']).setValue(this.ATSData['employment-data-list'][this.atsArrayDataIndex]['employee_id']);
    }
    if (this.ATSData['employment-data-list'][this.atsArrayDataIndex]['department']) {
      if (this.employmentForm.controls['employmentSubForm']['controls'][inParams['iteration']].controls['department'] !== undefined)
        (<FormControl>this.employmentForm.controls['employmentSubForm']['controls'][inParams['iteration']].controls['department']).setValue(this.ATSData['employment-data-list'][this.atsArrayDataIndex]['department']);
    }

    if (this.employmentForm.controls['employmentSubForm']['controls'][inParams['iteration']].controls['jobType'] !== undefined && this.selfEmploymentPreferenceEnabled)
      (<FormControl>this.employmentForm.controls['employmentSubForm']['controls'][inParams['iteration']].controls['jobType']).setValue(this.ATSData['employment-data-list'][this.atsArrayDataIndex]['job_type']);
    if (this.employmentForm.controls['employmentSubForm']['controls'][inParams['iteration']].controls['companyNameInLocalLang'] !== undefined)
      (<FormControl>this.employmentForm.controls['employmentSubForm']['controls'][inParams['iteration']].controls['companyNameInLocalLang']).setValue(this.ATSData['employment-data-list'][this.atsArrayDataIndex]['company_name_in_local_lang']);
    if (this.employmentForm.controls['employmentSubForm']['controls'][inParams['iteration']].controls['companyAddress'] !== undefined)
      (<FormControl>this.employmentForm.controls['employmentSubForm']['controls'][inParams['iteration']].controls['companyAddress']).setValue(this.ATSData['employment-data-list'][this.atsArrayDataIndex]['company_address']);

    if (!this.reportingManagerSkip) {
      if (this.employmentForm.controls['employmentSubForm']['controls'][inParams['iteration']].controls['managerName'] !== undefined)
        (<FormControl>this.employmentForm.controls['employmentSubForm']['controls'][inParams['iteration']].controls['managerName']).setValue(this.ATSData['employment-data-list'][this.atsArrayDataIndex]['manager_name']);
      if (this.employmentForm.controls['employmentSubForm']['controls'][inParams['iteration']].controls['position'] !== undefined)
        (<FormControl>this.employmentForm.controls['employmentSubForm']['controls'][inParams['iteration']].controls['position']).setValue(this.ATSData['employment-data-list'][this.atsArrayDataIndex]['manager_position']);
      if (this.employmentForm.controls['employmentSubForm']['controls'][inParams['iteration']].controls['managerCountryCode'] !== undefined)
        (<FormControl>this.employmentForm.controls['employmentSubForm']['controls'][inParams['iteration']].controls['managerCountryCode']).setValue(this.ATSData['employment-data-list'][this.atsArrayDataIndex]['manager_country_code']);
      if (this.employmentForm.controls['employmentSubForm']['controls'][inParams['iteration']].controls['phoneNumber'] !== undefined)
        (<FormControl>this.employmentForm.controls['employmentSubForm']['controls'][inParams['iteration']].controls['phoneNumber']).setValue(this.ATSData['employment-data-list'][this.atsArrayDataIndex]['manager_phone_number']);
      if (this.employmentForm.controls['employmentSubForm']['controls'][inParams['iteration']].controls['managerEmail'] !== undefined)
        (<FormControl>this.employmentForm.controls['employmentSubForm']['controls'][inParams['iteration']].controls['managerEmail']).setValue(this.ATSData['employment-data-list'][this.atsArrayDataIndex]['manager_email']);
    }
    
      // set paSyncId in form controls
      (<FormControl>this.employmentForm.controls['employmentSubForm']['controls'][inParams['iteration']].controls['paSyncId']).setValue(this.ATSData['employment-data-list'][this.atsArrayDataIndex]['pa_sync_id']);

    let dateOptions = {};

    /**
     * 'from' is set here from 'start-date'
     * 'to' is set few lines below
     */
    dateOptions['from'] = (this.ATSData['employment-data-list'][this.atsArrayDataIndex]['start-date']) ? this._es.fromDateOptions(this.ATSData['employment-data-list'][this.atsArrayDataIndex]['start-date']) : null;
    dateOptions['to'] = null;

    /*
     * Notify Reporting Manager Component to run validation methods if all manager details controls exist
     */
    if (!this.reportingManagerSkip && this.employmentForm.controls['employmentSubForm']['controls'][inParams['iteration']].controls['managerName'] &&
      this.employmentForm.controls['employmentSubForm']['controls'][inParams['iteration']].controls['position'] &&
      this.employmentForm.controls['employmentSubForm']['controls'][inParams['iteration']].controls['managerCountryCode'] &&
      this.employmentForm.controls['employmentSubForm']['controls'][inParams['iteration']].controls['phoneNumber'] &&
      this.employmentForm.controls['employmentSubForm']['controls'][inParams['iteration']].controls['managerEmail']) {

      this._sharedService.notifyManager({
        'needToNotify': true,
        'manager_country_code': this.ATSData['employment-data-list'][this.atsArrayDataIndex]['manager_country_code']
      });
    }

    if (this.employmentForm.controls['employmentSubForm']['controls'][inParams['iteration']].controls['countryCode'] !== undefined &&
      this.employmentForm.controls['employmentSubForm']['controls'][inParams['iteration']].controls['countryName'] !== undefined &&
      this.ATSData['employment-data-list'][this.atsArrayDataIndex]['country'] &&
      this.ATSData['employment-data-list'][this.atsArrayDataIndex]['country_name']) {

      this._sharedService.notifyCountry({
        'country': true,
        'country_name': this.ATSData['employment-data-list'][this.atsArrayDataIndex]['country_name'] 
      });
    }

    if (inParams['selectedCategory'] !== 'current') {
      if (this.employmentForm.controls['employmentSubForm']['controls'][inParams['iteration']].controls['jobTo'] !== undefined &&
        this.ATSData['employment-data-list'][this.atsArrayDataIndex]['end-date']) {
        (<FormControl>this.employmentForm.controls['employmentSubForm']['controls'][inParams['iteration']].controls['jobTo']).setValue(this.ATSData['employment-data-list'][this.atsArrayDataIndex]['end-date']);
      }

      dateOptions['to'] = (this.ATSData['employment-data-list'][this.atsArrayDataIndex]['end-date']) ? this._es.toDateOptions(this.ATSData['employment-data-list'][this.atsArrayDataIndex]['end-date']) : null;
    }

    this.dateConfigs[this.currentRow] = dateOptions;
    let countryOptions = this.options[this.currentRow]['countryOptions'];
    countryOptions['country'] = countryOptions['country_name'] = this.ATSData['employment-data-list'][this.atsArrayDataIndex]['country_name'];
    countryOptions['countryShortName'] = this.ATSData['employment-data-list'][this.atsArrayDataIndex]['country'];
    this.options[inParams['iteration']]['countryOptions'] = countryOptions;

    this.setMultipositionListForATS(this.ATSData, this.atsArrayDataIndex, inParams['iteration']);
  }


  /* ************ processData : This function separates  ATS data and regular PA Data. ************ */
  private processData(data: any): void {
    console.log('employment : processData');
    // console.log('employment : DATA : -------------------');
    // console.log(data);

    this.ATSData['employment-data-list'] = [];
    this.ATSData['display-data-list'] = [];
    this.regularData['employment-data-list'] = [];

    this._es.useFromAndToDate(data['employment-data-list']);
    let allPositionListIndex = 0;
    
    for (let i = 0; i < data['employment-data-list'].length; i++) {
      EmploymentConfig.ats = new Object();
      EmploymentConfig.regular = new Object();
      EmploymentConfig.displayLabel = new Object();

      if (data['employment-data-list'][i]['source'] === 'ATS' || data['employment-data-list'][i]['source'] == 'PADVPR' || data['employment-data-list'][i]['source'] == 'CITADELPR' || data['employment-data-list'][i]['source'] == 'PLAIDPR') {   // PADVPR is DOT check
        EmploymentConfig.ats['id'] = data['employment-data-list'][i]['id'];
        EmploymentConfig.displayLabel['type'] = EmploymentConfig.ats['type'] = data['employment-data-list'][i]['type'];

        //get pa_sync_id and store it
        EmploymentConfig.ats['pa_sync_id'] = data['employment-data-list'][i]['pa_sync_id'] || undefined; 
        EmploymentConfig.displayLabel['Name'] = EmploymentConfig.ats['name'] = data['employment-data-list'][i]['name'];
        EmploymentConfig.ats['contact'] = data['employment-data-list'][i]['contact'];
        EmploymentConfig.ats['contact-number'] = data['employment-data-list'][i]['contact-number'];
        EmploymentConfig.ats['permission-to-contact'] = data['employment-data-list'][i]['permission-to-contact'];
        EmploymentConfig.displayLabel['Country'] = EmploymentConfig.ats['country'] = data['employment-data-list'][i]['country'];
        EmploymentConfig.ats['same_country'] = data['employment-data-list'][i]['same_country'];
        EmploymentConfig.displayLabel['State'] = EmploymentConfig.ats['state_code'] = data['employment-data-list'][i]['state_code'];
        EmploymentConfig.displayLabel['City'] = EmploymentConfig.ats['city'] = data['employment-data-list'][i]['city'];
        EmploymentConfig.displayLabel['Job Title'] = EmploymentConfig.ats['job-title'] = data['employment-data-list'][i]['job-title'];
        EmploymentConfig.ats['has_driver_duties'] = data['employment-data-list'][i]['has_driver_duties'];
        EmploymentConfig.ats['faa_safety_check'] = data['employment-data-list'][i]['faa_safety_check'];
        EmploymentConfig.ats['reason_for_leaving'] = data['employment-data-list'][i]['reason_for_leaving'];
        EmploymentConfig.displayLabel['when-to-contact'] = EmploymentConfig.ats['when-to-contact'] = data['employment-data-list'][i]['when-to-contact'];
        let startDate = new Date(data['employment-data-list'][i]['start-date']);
        let endDate = new Date(data['employment-data-list'][i]['end-date']);

        /**
         * START DATE Check
         * If START date is missing in ATS
         */
        if (data['employment-data-list'][i]['start-date']) {
          if (this.isEmploymentGapInDaysEnabled) {
            EmploymentConfig.displayLabel['Start Date'] = EmploymentConfig.ats['start-date'] = this.getMonthInMMMFormat(startDate.getMonth()) + ' ' + startDate.getDate() + ',' + startDate.getFullYear();
          } else {
            EmploymentConfig.displayLabel['Start Date'] = EmploymentConfig.ats['start-date'] = this.getMonthInMMMFormat(startDate.getMonth()) + ' ' + startDate.getFullYear();
          }
        } else {
          EmploymentConfig.displayLabel['Start Date'] = EmploymentConfig.ats['start-date'] = undefined;
        }

        /**
         * END DATE Check
         */
        if (data['employment-data-list'][i]['end-date'] !== undefined
          && data['employment-data-list'][i]['end-date'] !== null) {
            if (this.isEmploymentGapInDaysEnabled) {
              EmploymentConfig.displayLabel['End Date'] = EmploymentConfig.ats['end-date'] = this.getMonthInMMMFormat(endDate.getMonth()) + ' ' + endDate.getDate() + ',' + endDate.getFullYear();
            } else {
              EmploymentConfig.displayLabel['End Date'] = EmploymentConfig.ats['end-date'] = this.getMonthInMMMFormat(endDate.getMonth()) + ' ' + endDate.getFullYear();
            }
        } else if ((data['employment-data-list'][i]['end-date'] === undefined
          || data['employment-data-list'][i]['end-date'] === null || data['employment-data-list'][i]['end-date'] === "") && data['employment-data-list'][i]['type'] !== 'current') {
          /**
           * Not Current Employment - may be Prior Employment but END Date is missing (undefined | null | "") in ATS data
           */
          EmploymentConfig.displayLabel['End Date'] = undefined;
        } else {
          EmploymentConfig.displayLabel['End Date'] = 'Present';
        }

        EmploymentConfig.ats['zip'] = data['employment-data-list'][i]['zip'];
        EmploymentConfig.ats['has_employment'] = data['employment-data-list'][i]['has_employment'];
        EmploymentConfig.ats['confirm'] = data['employment-data-list'][i]['confirm'];
        EmploymentConfig.displayLabel['is_current'] = EmploymentConfig.ats['is_current'] = data['employment-data-list'][i]['is_current'];
        EmploymentConfig.ats['reconfirm'] = data['employment-data-list'][i]['reconfirm'];
        EmploymentConfig.ats['status'] = data['employment-data-list'][i]['status'];
        EmploymentConfig.ats['country_name'] = data['employment-data-list'][i]['country_name'];
        EmploymentConfig.ats['state_name'] = data['employment-data-list'][i]['state_name'];
        EmploymentConfig.ats['verification'] = data['employment-data-list'][i]['verification'];
        EmploymentConfig.ats['current_employment_confirm'] = data['employment-data-list'][i]['current_employment_confirm'];
        EmploymentConfig.ats['prior_employment_confirm'] = data['employment-data-list'][i]['prior_employment_confirm'];
        EmploymentConfig.displayLabel['Source'] = EmploymentConfig.ats['source'] = data['employment-data-list'][i]['source'];
        EmploymentConfig.ats['company_name_in_local_lang'] = data['employment-data-list'][i]['company_name_in_local_lang'];
        EmploymentConfig.ats['company_address'] = data['employment-data-list'][i]['company_address'];

        if (data['employment-data-list'][i]['country'] === this.DOMESTIC_COUNTRY_CODE && this.isPaDOTDirectEnabled) {
          EmploymentConfig.ats['street_address'] = data['employment-data-list'][i]['company_address'];
        }

        EmploymentConfig.ats['manager_name'] = data['employment-data-list'][i]['manager_name'];
        EmploymentConfig.ats['manager_position'] = data['employment-data-list'][i]['manager_position'];
        EmploymentConfig.ats['manager_country_code'] = data['employment-data-list'][i]['manager_country_code'];
        EmploymentConfig.ats['manager_phone_number'] = data['employment-data-list'][i]['manager_phone_number'];
        EmploymentConfig.ats['manager_email'] = data['employment-data-list'][i]['manager_email'];
        EmploymentConfig.ats['employee_id'] = data['employment-data-list'][i]['employee_id'];
        EmploymentConfig.ats['department'] = data['employment-data-list'][i]['department'];
        EmploymentConfig.ats['job_type'] = data['employment-data-list'][i]['job_type'];
        EmploymentConfig.ats['employment_position_list'] = data['employment-data-list'][i]['employment_position_list'];
        EmploymentConfig.ats['pending-emp-period'] = data['employment-data-list'][i]['pending-emp-period'];
        EmploymentConfig.ats['form'] = data['employment-data-list'][i]['form'];
        EmploymentConfig.ats['source_id'] = data['employment-data-list'][i]['source_id'];

        EmploymentConfig.displayLabel['delete_link'] = this.localStorage.getItem('workflow_instance_id') + '/employment-step/' + data['employment-data-list'][i]['id'];
        this.ATSData['employment-data-list'].push(EmploymentConfig.ats);
        this.ATSData['display-data-list'].push(EmploymentConfig.displayLabel);
      } else {
        EmploymentConfig.regular['id'] = data['employment-data-list'][i]['id'];
        EmploymentConfig.regular['type'] = data['employment-data-list'][i]['type'];
        EmploymentConfig.regular['name'] = data['employment-data-list'][i]['name'];
        EmploymentConfig.regular['contact'] = data['employment-data-list'][i]['contact'];
        EmploymentConfig.regular['contact-number'] = data['employment-data-list'][i]['contact-number'];
        EmploymentConfig.regular['when-to-contact'] = data['employment-data-list'][i]['when-to-contact'];
        EmploymentConfig.regular['permission-to-contact'] = data['employment-data-list'][i]['permission-to-contact'];
        EmploymentConfig.regular['country'] = data['employment-data-list'][i]['country'];
        EmploymentConfig.regular['same_country'] = data['employment-data-list'][i]['same_country'];
        EmploymentConfig.regular['state_code'] = data['employment-data-list'][i]['state_code'];
        EmploymentConfig.regular['city'] = data['employment-data-list'][i]['city'];
        EmploymentConfig.regular['job-title'] = data['employment-data-list'][i]['job-title'];
        EmploymentConfig.regular['has_driver_duties'] = data['employment-data-list'][i]['has_driver_duties'];
        EmploymentConfig.regular['faa_safety_check'] = data['employment-data-list'][i]['faa_safety_check'];
        EmploymentConfig.regular['reason_for_leaving'] = data['employment-data-list'][i]['reason_for_leaving'];
        EmploymentConfig.regular['start-date'] = data['employment-data-list'][i]['start-date'];
        EmploymentConfig.regular['end-date'] = data['employment-data-list'][i]['end-date'];
        EmploymentConfig.regular['zip'] = data['employment-data-list'][i]['zip'];
        EmploymentConfig.regular['status'] = data['employment-data-list'][i]['status'];
        EmploymentConfig.regular['country_name'] = data['employment-data-list'][i]['country_name'];
        EmploymentConfig.regular['has_employment'] = data['employment-data-list'][i]['has_employment'];
        EmploymentConfig.regular['confirm'] = data['employment-data-list'][i]['confirm'];
        EmploymentConfig.regular['is_current'] = data['employment-data-list'][i]['is_current'];
        EmploymentConfig.regular['reconfirm'] = data['employment-data-list'][i]['reconfirm'];
        EmploymentConfig.regular['state_name'] = data['employment-data-list'][i]['state_name'];
        EmploymentConfig.regular['verification'] = data['employment-data-list'][i]['verification'];
        EmploymentConfig.regular['current_employment_confirm'] = data['employment-data-list'][i]['current_employment_confirm'];
        EmploymentConfig.regular['prior_employment_confirm'] = data['employment-data-list'][i]['prior_employment_confirm'];
        EmploymentConfig.regular['source'] = data['employment-data-list'][i]['source'];
        EmploymentConfig.regular['company_name_in_local_lang'] = data['employment-data-list'][i]['company_name_in_local_lang'];
        EmploymentConfig.regular['company_address'] = data['employment-data-list'][i]['company_address'];
        
        if (data['employment-data-list'][i]['country'] === this.DOMESTIC_COUNTRY_CODE && this.isPaDOTDirectEnabled) {
          EmploymentConfig.regular['street_address'] = data['employment-data-list'][i]['company_address'];
        }

        EmploymentConfig.regular['manager_name'] = data['employment-data-list'][i]['manager_name'];
        EmploymentConfig.regular['manager_position'] = data['employment-data-list'][i]['manager_position'];
        EmploymentConfig.regular['manager_country_code'] = data['employment-data-list'][i]['manager_country_code'];
        EmploymentConfig.regular['manager_phone_number'] = data['employment-data-list'][i]['manager_phone_number'];
        EmploymentConfig.regular['manager_email'] = data['employment-data-list'][i]['manager_email'];
        EmploymentConfig.regular['employee_id'] = data['employment-data-list'][i]['employee_id'];
        EmploymentConfig.regular['department'] = data['employment-data-list'][i]['department'];
        EmploymentConfig.regular['job_type'] = data['employment-data-list'][i]['job_type'];
        EmploymentConfig.regular['employment_position_list'] = data['employment-data-list'][i]['employment_position_list'];
        EmploymentConfig.regular['pending-emp-period'] = data['employment-data-list'][i]['pending-emp-period'];
        EmploymentConfig.regular['form'] = data['employment-data-list'][i]['form'];
        EmploymentConfig.regular['source_id'] = data['employment-data-list'][i]['source_id'];
        EmploymentConfig.regular['pa_sync_id'] = data['employment-data-list'][i]['pa_sync_id'] || undefined; //for review and edit
        EmploymentConfig.regular['data_provider'] = data['employment-data-list'][i]['data_provider'];

        if (this.isINTLSUPComponentAvailable) {
          EmploymentConfig.regular['manager_residence_country'] = data['employment-data-list'][i]['manager_residence_country'];
          EmploymentConfig.regular['manager_residence_country_name'] = data['employment-data-list'][i]['manager_residence_country_name'];
          EmploymentConfig.regular['manager_residence_state'] = data['employment-data-list'][i]['manager_residence_state'];
          EmploymentConfig.regular['manager_residence_state_name'] = data['employment-data-list'][i]['manager_residence_state_name'];
        }
        
        this.regularData['employment-data-list'].push(EmploymentConfig.regular);
      }
    }

    if (data['state'] !== '' && data['state'] !== undefined && data['state'] !== null) {
      this.ATSData['state']['step_status'] = data['state']['step_status'];
      this.ATSData['state']['stage_status'] = data['state']['stage_status'];
      this.ATSData['state']['current_iteration'] = data['state']['current_iteration'];
      this.ATSData['state']['current_stage'] = data['state']['current_stage'];
      this.ATSData['state']['current_step'] = data['state']['current_step'];
      this.ATSData['state']['current_step_index'] = data['state']['current_step_index'];
      this.ATSData['state']['current_sub_step'] = data['state']['current_sub_step'];
      this.ATSData['state']['current_sub_step_index'] = data['state']['current_sub_step_index'];
      this.ATSData['state']['current_status'] = data['state']['current_status'];

      this.regularData['state']['step_status'] = data['state']['step_status'];
      this.regularData['state']['stage_status'] = data['state']['stage_status'];
      this.regularData['state']['current_iteration'] = data['state']['current_iteration'];
      this.regularData['state']['current_stage'] = data['state']['current_stage'];
      this.regularData['state']['current_step'] = data['state']['current_step'];
      this.regularData['state']['current_step_index'] = data['state']['current_step_index'];
      this.regularData['state']['current_sub_step'] = data['state']['current_sub_step'];
      this.regularData['state']['current_sub_step_index'] = data['state']['current_sub_step_index'];
      this.regularData['state']['current_status'] = data['state']['current_status'];
    }

    if (data['pending-emp-period'] !== '' && data['pending-emp-period'] !== undefined && data['pending-emp-period'] !== null) {
      this.ATSData['pending-emp-period']['year'] = data['pending-emp-period']['year'];
      this.ATSData['pending-emp-period']['month'] = data['pending-emp-period']['month'];
      if (this.isEmploymentGapInDaysEnabled) {
        this.ATSData['pending-emp-period']['days'] = data['pending-emp-period']['days'];
      }
      
      this.regularData['pending-emp-period']['year'] = data['pending-emp-period']['year'];
      this.regularData['pending-emp-period']['month'] = data['pending-emp-period']['month'];
      if (this.isEmploymentGapInDaysEnabled) {
        this.regularData['pending-emp-period']['days'] = data['pending-emp-period']['days'];
      }
    }

    // console.log('****************** : Employment : DISPLAY LABEL : ************************');
    // console.log(this.ATSData['display-data-list']);

    // console.log('****************** : Employment :  pending-emp-period: ************************');
    // console.log(this.regularData['pending-emp-period']);

    // console.log('****************** : Employment : ATS DATA : ************************');
    // console.log(this.ATSData);

    // console.log('****************** : Employment : REGULAR DATA : ************************');
    // console.log(this.regularData);

    if (data !== undefined && data['ADDITIONAL_EMP_COLLECTION_PREF_VALUE'] !== undefined) {
      this.isAdditionalEmpPrefEnabled = data['ADDITIONAL_EMP_COLLECTION_PREF_VALUE'];
    } else {
      this.isAdditionalEmpPrefEnabled = false;
    }
  }

  /**
   * Set values for multiple positions
   *
   */
  private setMultiplePositionValues(obj, employmentDataItem, newJob) {
    if (obj['employment_position_list'] && obj['employment_position_list'].length > 0) {
      employmentDataItem['employment_position_list'] = [];
      let currentStartDateStr;
      let currentEndDateStr;
      let currentStartDate;
      let currentEndDate;
      for (let i = 0; i < obj['employment_position_list'].length; i++) {

        if (!this._utilService.isEmpty(obj['employment_position_list'][i]['empPosition'])) {
          if (this._utilService.isEmpty(obj['employment_position_list'][i]['status']) || obj['employment_position_list'][i]['status']) {
            if (i === 0) {
              
              if (employmentDataItem['type'] === 'current' || employmentDataItem['is_current'] === true) {
                // force to set end date is Present if employment type is current or is_current is true 
                // and for the 1'st position which is considered as current position
                obj['employment_position_list'][i]['intlEndDate'] = 'Present';
              }

              currentStartDateStr = obj['employment_position_list'][i]['intlStartDate'];
              currentStartDate = this._es.getDateToTs(obj['employment_position_list'][i]['intlStartDate']);
              if (!this._utilService.isEmpty(obj['employment_position_list'][i]['intlEndDate']) && obj['employment_position_list'][i]['intlEndDate'].toString() !== 'Present') {
                currentEndDate = this._es.getDateToTs(obj['employment_position_list'][i]['intlEndDate']);
                currentEndDateStr = obj['employment_position_list'][i]['intlEndDate'];
              } else {
                currentEndDateStr = '';
                currentEndDate = new Date().getTime();
              }
            } else {
              let currentStartDate1 = this._es.getDateToTs(obj['employment_position_list'][i]['intlStartDate']);
              if (currentStartDate >= currentStartDate1) {
                currentStartDate = currentStartDate1;
                currentStartDateStr = obj['employment_position_list'][i]['intlStartDate'];
              }

              if (!this._utilService.isEmpty(obj['employment_position_list'][i]['intlEndDate']) && obj['employment_position_list'][i]['intlEndDate'].toString() !== 'Present') {
                let currentEndDate1 = this._es.getDateToTs(obj['employment_position_list'][i]['intlEndDate']);
                if (currentEndDate <= currentEndDate1) {
                  currentEndDate = currentEndDate1;
                  currentEndDateStr = obj['employment_position_list'][i]['intlEndDate'];
                }
              }
            }
          }

          let empObj = {};
          if (!this._utilService.isEmpty(obj['employment_position_list'][i]['id'])) {
            empObj['id'] = obj['employment_position_list'][i]['id'];
          }

          if (!this._utilService.isEmpty(obj['employment_position_list'][i]['status'])) {
            empObj['status'] = obj['employment_position_list'][i]['status'];
          } else {
            empObj['status'] = true;
          }

          empObj['position'] = obj['employment_position_list'][i]['empPosition'];
          if (this._utilService.isEmpty(obj['employment_position_list'][i]['status']) || obj['employment_position_list'][i]['status']) {
            empObj['start-date'] = this._es.getDateToTs(obj['employment_position_list'][i]['intlStartDate']);
            empObj['from_date'] = dateUtils.getDateFormatYYYYMMDD(empObj['start-date']);
            
          }

          let endDate = new Date();
          if (obj['employment_position_list'][i]['intlEndDate'].toString() !== 'Present') {
            empObj['end-date'] = this._es.getDateToTs(obj['employment_position_list'][i]['intlEndDate']);
            empObj['to_date'] = dateUtils.getDateFormatYYYYMMDD(empObj['end-date']);
            
          } else {
            empObj['end-date'] = undefined;
          }

          employmentDataItem['start-date'] = this._es.getDateToTs(obj['employment_position_list'][i]['intlStartDate']);

          employmentDataItem['employment_position_list'].push(empObj);
        }
      }

      if (!this._utilService.isEmpty(currentStartDateStr)) {
        if (newJob) {
          newJob['jobFrom'] = currentStartDateStr;
        }

        employmentDataItem['start-date'] = this._es.getDateToTs(currentStartDateStr);
        employmentDataItem['from_date'] = dateUtils.getDateFormatYYYYMMDD(employmentDataItem['start-date']);
        
      }

      if (!this._utilService.isEmpty(currentEndDateStr)) {

        if (newJob) {
          newJob['jobTo'] = currentEndDateStr;
        }
        employmentDataItem['end-date'] = this._es.getDateToTs(currentEndDateStr);
        employmentDataItem['to_date'] = dateUtils.getDateFormatYYYYMMDD(employmentDataItem['end-date']);
      } 
      
      if (employmentDataItem['type'] === 'current' || employmentDataItem['is_current'] === true) {
        employmentDataItem['end-date'] = null;
        employmentDataItem['to_date'] = null;
      }
      
      employmentDataItem['service_action'] = obj['service_action'];
    }
  }

  /**
   * set multiple position list for review and edit
   *
   * @param currentItem
   */
  private setMultiPositionListForReviewEdit(currentItem) {
    if (this.isReviewEdit && currentItem['type'] !== 'neverEmployed') {
      this.employeePositionList = {};
      this.employeePositionList[0] = [];
      if (!this._utilService.isEmpty(currentItem['employment_position_list']) && currentItem['employment_position_list'].length > 0) {
        let currentList = currentItem['employment_position_list'];
        for (let index = 0; index < currentList.length; index++) {
          let currentPosition = [];
          currentPosition['id'] = currentList[index]['id'];
          currentPosition['empPosition'] = currentList[index]['position'];
          currentPosition['intlStartDate'] = this._es.getLangFormatedDate(currentList[index]['start-date'], this.getDateFormat());
          if (!this._utilService.isEmpty(currentList[index]['end-date'])) {
            currentPosition['intlEndDate'] = this._es.getLangFormatedDate(currentList[index]['end-date'], this.getDateFormat());
          } else {
            currentPosition['intlEndDate'] = 'Present';
          }
          if (this._utilService.isEmpty(currentList[index]['status']) || currentList[index]['status']) {
            currentPosition['status'] = true;
          } else {
            currentPosition['status'] = false;
          }
          this.employeePositionList[0][index] = currentPosition;
        }
      }
    }
  }

  /**
   * set values for multiple positions
   */
  private setMultipositionList(data, row) {
    let multiplePositions = [];
    let positions = data['employment-data-list'][row]['employment_position_list'];
    if (positions !== undefined && positions.length > 0) {
      this.showEndDate = true;
      for (let i = 0; i < positions.length; i++) {
        let option = {};
        option['id'] = positions[i]['id'];
        option['empPosition'] = positions[i]['position'];
        // option['intlStartDate'] = this.getFormatedDate(positions[i]['start-date'], 'MMMM YYYY');
        if (!this._utilService.isEmpty(positions[i]['start-date'])) {
          option['intlStartDate'] = this._es.getFormatedDate(positions[i]['start-date'], this.getDateFormat());
        }

        if (!this._utilService.isEmpty(positions[i]['end-date'])) {
          option['intlEndDate'] = this._es.getFormatedDate(positions[i]['end-date'], this.getDateFormat());
        } else {
          option['intlEndDate'] = 'Present';
        }
        if (this._utilService.isEmpty(positions[i]['status']) || positions[i]['status']) {
          option['status'] = true;
        } else {
          option['status'] = false;
        }
        multiplePositions.push(option);
      }
      this.employeePositionList[row] = multiplePositions;
    }
  }

  private updateATSRecord(): Observable<any> {
    let that = this;
    this._es.softDeleteDataItemOnATSSelection(this.selectedATSCardId).subscribe(response => {
      this.ATSData['employment-data-list'].splice(this.atsArrayDataIndex, 1);
      this.ATSData['display-data-list'].splice(this.atsArrayDataIndex, 1);
      this.atsArrayDataIndex = -1;
      this.selectedATSCardId = '';
      that.localStorage.setItem('atsArrayDataIndex', this.atsArrayDataIndex);
      that.localStorage.setItem('selectedATSCardId', this.selectedATSCardId);
      this.responseStatus$.next({ res: true });
    }, error => {
      this.responseStatus$.next({ res: false });
    });
    return this.responseStatus$;
  }

  disableEnterButton(event) {
    if (event.target.tagName == 'TEXTAREA') {
      return true;
    }
    let x = event.keyCode;
    if (x === 13)
      return false;
  }

  private getMonthInMMMFormat(month: number): string {
    let monthArray = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    return monthArray[month];
  }

  private changeDecision(obj, currentIteration): void {
    this.deleteExtensionForm(obj, currentIteration);

    if (obj['type'] !== undefined && obj['type'] === 'current') {
      this.removeCurrentEmp(obj, currentIteration);
    } else if (obj['type'] !== undefined && obj['type'] === 'prior') {
      this.removePriorEmp(obj, currentIteration);
    } else if (obj['type'] !== undefined && obj['type'] === 'selfEmployed') {
      this.removeOtherEmp(obj, currentIteration);
    } else if (obj['type'] !== undefined && obj['type'] === 'unemployed') {
      this.removeOtherEmp(obj, currentIteration);
    } else if (obj['type'] !== undefined && obj['type'] === 'inSchool') {
      this.removeOtherEmp(obj, currentIteration);
    } else if (obj['type'] !== undefined && obj['type'] === 'internship') {
      this.removeOtherEmp(obj, currentIteration);
    } else if (obj['type'] !== undefined && obj['type'] === 'neverEmployed') {
      this.removeOtherEmp(obj, currentIteration);
    }
  }

  private deleteExtensionForm(obj, currentIteration): void {
    const control = <FormArray>this.employmentForm.controls['employmentSubForm'];
    const formGroup = <FormGroup>control.controls[currentIteration];
    let form = (<FormControl>formGroup.controls['form']);
    if (form && form.value && form.value != '') {
      let formId = form.value.form_id;
      if (formId && formId != '') {
        (<FormControl>formGroup.controls['form']).setValue('');
        this.isFormDeleted[currentIteration] = true;
        this._cfs.deleteData(formId).subscribe(response => {
          console.log('Extension form is deleted for form id .' + formId);
        }, error => {
          console.log('Not Deleted');
        });
      }
    }
  }

  private removeCurrentEmp(obj, currentIteration): void {
    const control = <FormArray>this.employmentForm.controls['employmentSubForm'];
    let controlLen = control.length;
    const formGroup = <FormGroup>control.controls[currentIteration];
    (<FormControl>formGroup.controls['companyName']).setValue('');
    (<FormControl>formGroup.controls['type']).setValue(this.activeEmpType);
    (<FormControl>formGroup.controls['jobTitle']).setValue('');
    if (formGroup.controls['driverDuties'] !== undefined) {
      (<FormControl>formGroup.controls['driverDuties']).setValue('');
    }
    if (formGroup.controls['reasonForLeaving'] !== undefined) {
      (<FormControl>formGroup.controls['reasonForLeaving']).setValue('');
    }
    (<FormControl>formGroup.controls['countryName']).setValue('');
    if (formGroup.controls['permissionToContact'] !== undefined)
      (<FormControl>formGroup.controls['permissionToContact']).setValue('');
    (<FormControl>formGroup.controls['countryCode']).setValue('');
    (<FormControl>formGroup.controls['city']).setValue('');
    (<FormControl>formGroup.controls['stateName']).setValue('');
    (<FormControl>formGroup.controls['stateCode']).setValue('');
    (<FormControl>formGroup.controls['zipCode']).setValue('');

    if (formGroup.controls['managerName'] !== undefined)
      (<FormControl>formGroup.controls['managerName']).setValue('');
    if (formGroup.controls['position'] !== undefined)
      (<FormControl>formGroup.controls['position']).setValue('');
    if (formGroup.controls['managerEmail'] !== undefined)
      (<FormControl>formGroup.controls['managerEmail']).setValue('');
    if (formGroup.controls['managerCountryCode'] !== undefined)
      (<FormControl>formGroup.controls['managerCountryCode']).setValue('');
    if (formGroup.controls['phoneNumber'] !== undefined)
      (<FormControl>formGroup.controls['phoneNumber']).setValue('');

    if (formGroup.controls['employeeId'] !== undefined)
      (<FormControl>formGroup.controls['employeeId']).setValue('');
    if (formGroup.controls['department'] !== undefined)
      (<FormControl>formGroup.controls['department']).setValue('');
    if (formGroup.controls['jobType'] !== undefined)
      (<FormControl>formGroup.controls['jobType']).setValue('');
    if (formGroup.controls['companyNameInLocalLang'] !== undefined)
      (<FormControl>formGroup.controls['companyNameInLocalLang']).setValue('');
    if (formGroup.controls['companyAddress'] !== undefined)
      (<FormControl>formGroup.controls['companyAddress']).setValue('');
  }

  private removePriorEmp(obj, currentIteration): void {
    const control = <FormArray>this.employmentForm.controls['employmentSubForm'];
    let controlLen = control.length;
    const formGroup = <FormGroup>control.controls[currentIteration];
    (<FormControl>formGroup.controls['companyName']).setValue('');
    (<FormControl>formGroup.controls['type']).setValue(this.activeEmpType);
    (<FormControl>formGroup.controls['jobTitle']).setValue('');
    if (formGroup.controls['driverDuties'] !== undefined) {
      (<FormControl>formGroup.controls['driverDuties']).setValue('');
    }
    if (formGroup.controls['reasonForLeaving'] !== undefined) {
      (<FormControl>formGroup.controls['reasonForLeaving']).setValue('');
    }
    (<FormControl>formGroup.controls['countryName']).setValue('');
    (<FormControl>formGroup.controls['countryCode']).setValue('');
    (<FormControl>formGroup.controls['city']).setValue('');
    (<FormControl>formGroup.controls['stateName']).setValue('');
    (<FormControl>formGroup.controls['stateCode']).setValue('');
    (<FormControl>formGroup.controls['zipCode']).setValue('');
    // (<FormControl>formGroup.controls['jobFrom']).setValue('');

    if (formGroup.controls['managerName'] !== undefined)
      (<FormControl>formGroup.controls['managerName']).setValue('');
    if (formGroup.controls['position'] !== undefined)
      (<FormControl>formGroup.controls['position']).setValue('');
    if (formGroup.controls['managerEmail'] !== undefined)
      (<FormControl>formGroup.controls['managerEmail']).setValue('');
    if (formGroup.controls['managerCountryCode'] !== undefined)
      (<FormControl>formGroup.controls['managerCountryCode']).setValue('');
    if (formGroup.controls['phoneNumber'] !== undefined)
      (<FormControl>formGroup.controls['phoneNumber']).setValue('');
    // if (formGroup.controls['jobTo'] !== undefined)
    //   (<FormControl>formGroup.controls['jobTo']).setValue(obj['jobTo']);

    if (formGroup.controls['employeeId'] !== undefined)
      (<FormControl>formGroup.controls['employeeId']).setValue('');
    if (formGroup.controls['department'] !== undefined)
      (<FormControl>formGroup.controls['department']).setValue('');
    if (formGroup.controls['jobType'] !== undefined)
      (<FormControl>formGroup.controls['jobType']).setValue('');
    if (formGroup.controls['companyNameInLocalLang'] !== undefined)
      (<FormControl>formGroup.controls['companyNameInLocalLang']).setValue('');
    if (formGroup.controls['companyAddress'] !== undefined)
      (<FormControl>formGroup.controls['companyAddress']).setValue('');
  }

  private removeOtherEmp(obj, currentIteration): void {
    const control = <FormArray>this.employmentForm.controls['employmentSubForm'];
    let controlLen = control.length;
    const formGroup = <FormGroup>control.controls[currentIteration];
    let isNeverEmployed = (obj['type'] === 'neverEmployed') ? true : false;
    // (<FormControl>formGroup.controls['jobFrom']).setValue('');

    // if (formGroup.controls['jobTo'] !== undefined)
    //   (<FormControl>formGroup.controls['jobTo']).setValue(obj['jobTo']);
    if (formGroup.controls['driverDuties'] !== undefined) {
      (<FormControl>formGroup.controls['driverDuties']).setValue('');
    }
    if (formGroup.controls['reasonForLeaving'] !== undefined) {
      (<FormControl>formGroup.controls['reasonForLeaving']).setValue('');
    }    
    if (formGroup.controls['managerName'] !== undefined)
      (<FormControl>formGroup.controls['managerName']).setValue('');
    if (formGroup.controls['position'] !== undefined)
      (<FormControl>formGroup.controls['position']).setValue('');
    if (formGroup.controls['managerEmail'] !== undefined)
      (<FormControl>formGroup.controls['managerEmail']).setValue('');
    if (formGroup.controls['managerCountryCode'] !== undefined)
      (<FormControl>formGroup.controls['managerCountryCode']).setValue('');
    if (formGroup.controls['phoneNumber'] !== undefined)
      (<FormControl>formGroup.controls['phoneNumber']).setValue('');
    /* if (formGroup.controls['jobTo'] !== undefined)
       (<FormControl>formGroup.controls['jobTo']).setValue(''); */

    if (formGroup.controls['employeeId'] !== undefined)
      (<FormControl>formGroup.controls['employeeId']).setValue('');
    if (formGroup.controls['department'] !== undefined)
      (<FormControl>formGroup.controls['department']).setValue('');
    if (formGroup.controls['jobType'] !== undefined)
      (<FormControl>formGroup.controls['jobType']).setValue('');
    if (formGroup.controls['companyNameInLocalLang'] !== undefined)
      (<FormControl>formGroup.controls['companyNameInLocalLang']).setValue('');
    if (formGroup.controls['companyAddress'] !== undefined)
      (<FormControl>formGroup.controls['companyAddress']).setValue('');
  }

  empManagerDetails(obj): void {
    if (!this.isAjaxCallProccess) {
      obj['trakEventMsg'] = 'manager details is provided';
      obj = this.currentPriorConfirmflagCheck(obj);
      //  obj = this._es.mapToOption(obj);
      let i: number = this.currentRow = obj['indexDetail']['row'];
      this.initPayload(obj);
    }
  }

  empManagerDetailsSwipe(obj) {
    let current = this.currentSlide;
    let row = this.currentRow || 0;
    let direction = 'left';
    this.sliderIndex = this.empSteps.indexOf('company-name');
    this.setSlider(this.sliderIndex, direction, this.currentRow);
  }

  private afterReportingManagerDetailsSave(obj, item?) {
    let currnetFormGroup = this.getCurrnetFormGroup(obj['indexDetail']['row']);
    if ((obj['type'] === 'current' || obj['type'] === 'prior') && this.isMultiplePositionEnabled(obj['countryCode'], obj['type'])) {
      this.sliderIndex = this.empSteps.indexOf('position');
      this.setSlider(this.sliderIndex, 'right', this.currentRow);
    } else {
      if (!!(<FormControl>currnetFormGroup.controls['managerName'].value)) {
        this.sliderIndex = this.empSteps.indexOf('job-period');
        this.setSlider(this.sliderIndex, 'right', this.currentRow);
      }
    }
    this.alertUserOnBlankPage(this.currentSlide);
  }

  private afterPositionSave(obj, item?) {

    this.afterPositioSaveServerObj = item;
    if (this.isReasonForLeavingMandatory && (obj['type'] === 'current' || obj['type'] === 'prior' || (obj['type'] === 'selfEmployed' && this.selfEmploymentPreferenceEnabled))) {
      this.sliderIndex = this.empSteps.indexOf('job-title');
      this.setSlider(this.sliderIndex, 'right', this.currentRow);
      this.alertUserOnBlankPage(this.currentSlide);
    } else if (this.faaSafetyCheckPreferenceEnabled && (obj['type'] === 'current' || obj['type'] === 'prior' || (obj['type'] === 'selfEmployed' && this.selfEmploymentPreferenceEnabled))) {
      this.sliderIndex = this.empSteps.indexOf('job-title');
      this.setSlider(this.sliderIndex, 'right', this.currentRow);
      this.alertUserOnBlankPage(this.currentSlide);
    } else {
      this.afterJobTitleSave(obj, item);
    }

    if (this._utilService.isEmpty(this.employeePositionList) || this._utilService.isEmpty(this.employeePositionList[this.currentRow + 1])) {
      this.employeePositionList[this.currentRow + 1] = [];
    }
  }

  empAddEmpPosition(obj) {
    if (!this.isAjaxCallProccess) {
      this.addNewPosition = true;
      this.editInd = '';
      let multiplePositions = [];
      let index: number = obj['indexDetail']['row'];
      let option = {};
      option['empPosition'] = obj['empPosition'];
      option['intlStartDate'] = obj['intlStartDate'];
      obj['jobFrom'] = obj['intlStartDate'];
      if (!!obj['intlEndDate']) {
        option['intlEndDate'] = obj['intlEndDate'];
        let dateOptions = {};
        dateOptions['from'] = obj['intlEndDate'];
        this.dateConfigs[this.currentRow] = dateOptions;
        obj['jobTo'] = obj['intlEndDate'];
      } else {
        option['intlEndDate'] = 'Present';
      }
      option['status'] = true;
      this.currentEditableObj = {};

      /**
       * 1. Check gap is available in iteration level and bind the data in to field
       * 2. Check gap is available in Position same screen and bind the data in to field
       * 3. else bind bind only the end date
       */
      if (this.posGapFlag[index]) {
        let posLen = !!this.employeePositionList[index] ? this.employeePositionList[index].length - 1 : 0;
        let posIndex = posLen > 0 ? posLen : 0;
        this.currentEditableObj['intlStartDate'] = this.employeePositionList[index][posIndex]['intlStartDate'];
        this.currentEditableObj['intlEndDate'] = this.employeePositionList[index][posIndex]['intlEndDate'];
      } else if (obj['posLevelGapExist']) {
        this.currentEditableObj['intlStartDate'] = obj['posLevelGapDetails']['posGapStartDate'];
        this.currentEditableObj['intlEndDate'] = obj['posLevelGapDetails']['posGapEndDate'];
      } else {
        this.currentEditableObj['intlStartDate'] = null;
        this.currentEditableObj['intlEndDate'] = this.getNextEndDate(this.employeePositionList);
      }

      if (obj['type'] === 'current' && this._utilService.isEmpty(this.employeePositionList[this.currentRow])) {
        this.showEndDate = false;
        this.currentEditableObj['intlStartDate'] = null;
        this.currentEditableObj['intlEndDate'] = null;
      }

      this._recordidservice.createModalForMultiplePositions(this.getCurrentRow());
    }
  }

  private getNextEndDate(positionList) {
    let nextDate;
    let nextDateValue;
    if (this._utilService.isEmpty(this.employeePositionList) || this._utilService.isEmpty(this.employeePositionList[this.currentRow])) {
      let data = this._es.getServerData();
      if (!this._utilService.isEmpty(data) && data.length > 0) {
        if (data.length > 1) {
          this.showEndDate = true;
        }
        for (let obj of data) {
          if (this._utilService.isEmpty(nextDateValue)) {
            nextDateValue = this._es.getLangFormatedDate(obj['start-date'], this.getDateFormat());
            nextDate = obj['start-date'];
          } else {
            let temp = obj['start-date'];
            if (temp < nextDate) {
              nextDate = temp;
              nextDateValue = this._es.getLangFormatedDate(obj['start-date'], this.getDateFormat());
            }
          }
        }
      }
    } else {
      for (let row = this.currentRow; row >= 0; row--) {
        if (!this._utilService.isEmpty(this.employeePositionList[row])) {
          this.showEndDate = true;
          for (let currentIndex = 0; currentIndex < this.employeePositionList[row].length; currentIndex++) {
            if (this._utilService.isEmpty(nextDateValue)) {
              nextDate = this._es.getLangDateToTs(this.employeePositionList[row][currentIndex]['intlStartDate']);
              nextDateValue = this._es.getLangFormatedDate(nextDate, this.getDateFormat());
            } else {
              let temp = this._es.getLangDateToTs(this.employeePositionList[row][currentIndex]['intlStartDate']);
              if (temp < nextDate) {
                nextDate = temp;
                nextDateValue = this._es.getLangFormatedDate(nextDate, this.getDateFormat());
              }
            }
          }
        }
      }
    }
    return nextDateValue;
  }

  empAddEmpPositionSwipe(obj) {
    let current = this.currentSlide;
    let row = this.currentRow || 0;
    let direction = 'left';
    if (this.reportingManagerSkip) {
      this.sliderIndex = this.empSteps.indexOf('company-name');
    } else {
      this.sliderIndex = this.empSteps.indexOf('reporting-manager-details');
    }
    this.setSlider(this.sliderIndex, direction, this.currentRow);
  }

  empSkipEmpPosition(obj) {
    jQuery('#empWaitModal').modal('show');
    if (!this.isAjaxCallProccess) {
      obj['trakEventMsg'] = 'position is provided';
      let row = this.currentRow || 0;
      obj = this.currentPriorConfirmflagCheck(obj);
      obj = this._es.mapToOption(obj);
      this.activeEmpType = obj['type'];
      let currentPositionList = [];
      if (!this._utilService.isEmpty(this.employeePositionList[this.currentRow])) {
        for (let i = 0; i < this.employeePositionList[this.currentRow].length; i++) {
          currentPositionList.push(this.employeePositionList[this.currentRow][i]);
        }
      }
      this.isINTLFieldsCaptured[this.currentRow] = false;
      obj['employment_position_list'] = currentPositionList;
      obj['service_action'] = 'PERSIST';
      this.initPayload(obj);
    }
  }

  empAddPosition(obj) {
    this.addNewPosition = false;
    let multiplePositions = [];
    let currentPositionIndex = 0;
    let id = '#empPositionModel_' + this.getCurrentRow();
    this.closeModal(id);
    let option = {};

    option['id'] = obj['id'];
    option['empPosition'] = obj['empPosition'];
    option['intlStartDate'] = obj['intlStartDate'];
    option['intlEndDate'] = obj['intlEndDate'];
    if (this._utilService.isEmpty(option['intlEndDate'])) {
      option['intlEndDate'] = 'Present';
    }
    option['status'] = true;

    currentPositionIndex = this._utilService.isEmpty(this.employeePositionList[this.currentRow]) || this.employeePositionList[this.currentRow].length < 0 || (this.employeePositionList[this.currentRow].length === 1 && this.posGapFlag[this.currentRow]) ? 0 : this.employeePositionList[this.currentRow].length;
    if (currentPositionIndex === 0) {
      this.employeePositionList[this.currentRow] = [];
    }

    if (!!this.userDetails && this.userDetails['isGap']) {
      this.posGapFlag[this.currentRow] = false;
    }

    // this.multiplePositionList.push(option);

    this.employeePositionList[this.currentRow][currentPositionIndex] = option;
    let rand = this._utilService.getCryptoRandom();
    this.positionchange = rand;
    this.addMorePositions = true;
    this.showEndDate = true;
  }


  editRecord(obj) {
    this.addNewPosition = false;
    if (!!obj && obj['editIndicator']) {
      this.editInd = obj['editIndicator'];
    }
    this.currentEditableObj = obj;
    if (this._utilService.isEmpty(obj['intlEndDate']) || obj['intlEndDate'] === 'Present') {
      this.showEndDate = false;
    } else {
      this.showEndDate = true;
    }
    this._recordidservice.createModalForMultiplePositions(this.getCurrentRow());
  }

  deleteRecord(obj) {
    let multiplePositions = [];
    let index = obj['indexOfMultiPos'];
    if (index > -1) {
      let currentObj = this.employeePositionList[this.currentRow][index];
      if (this._utilService.isEmpty(obj['id'])) {
        this.employeePositionList[this.currentRow].splice(index, 1);
      } else {
        currentObj['status'] = false;
        this.employeePositionList[this.currentRow][index] = currentObj;
      }
      let rand = this._utilService.getCryptoRandom();
      this.positionchange = rand;
    }
  }

  empEditPosition(obj) {
    let multiplePositions = [];
    let id = '#empPositionModel_' + this.getCurrentRow();
    this.closeModal(id);
    let option = {};

    option['id'] = this.currentEditableObj['id'];
    let index = this.currentEditableObj['indexOfMultiPos'];
    option['empPosition'] = obj['empPosition'];
    option['intlStartDate'] = obj['intlStartDate'];
    option['intlEndDate'] = obj['intlEndDate'];
    if (this._utilService.isEmpty(option['intlEndDate'])) {
      option['intlEndDate'] = 'Present';
    }
    option['status'] = true;

    this.employeePositionList[this.currentRow][index] = option;

    let rand = this._utilService.getCryptoRandom();
    this.positionchange = rand;
    this.showEndDate = true;
  }


  empINTLAdditionalSwipe(obj) {
    let row = this.currentRow || 0;
    let current = this.currentSlide;
    let direction = 'left';
    if (this.isReasonForLeavingMandatory && (obj['type'] === 'current' || obj['type'] === 'prior')) {
      this.sliderIndex = this.empSteps.indexOf('job-title');
    }else if (this.faaSafetyCheckPreferenceEnabled && (obj['type'] === 'current' || obj['type'] === 'prior' || (obj['type'] === 'selfEmployed' && this.selfEmploymentPreferenceEnabled))) {
      this.sliderIndex = this.empSteps.indexOf('job-title');
      this.setSlider(this.sliderIndex, 'right', this.currentRow);
    } else  if (this.isMultiplePositionEnabled(obj['countryCode'], obj['type'])) {
      let serverData: Object[] = [];
      serverData = (JSON.parse(JSON.stringify(this._es.getServerData())));
      let currentServerData = serverData[this.currentRow];
      this.UpdateMultiplePositionCurrentRow(currentServerData);
      this.sliderIndex = this.empSteps.indexOf('position');
    } else {
      this.sliderIndex = this.empSteps.indexOf('job-title');
    }


    this.setSlider(this.sliderIndex, direction, this.currentRow);
  }


  empINTLAdditional(obj) {
    jQuery('#empWaitModal').modal('show');
    if (!this.isAjaxCallProccess) {

      obj['trakEventMsg'] = 'additional details is provided';
      // obj = this.currentPriorConfirmflagCheck(obj);
      // obj = this._es.mapToOption(obj);
      let i: number = this.currentRow = obj['indexDetail']['row'];
      obj['service_action'] = 'PERSIST';
      if (!!this.employeePositionList && !!this.employeePositionList[i] && this.employeePositionList[i].length > 0) {
        obj['employment_position_list'] = this.employeePositionList[i];
      }
      this.initPayload(obj);
    }
  }

  private afterIntlEmpSave(obj, item?) {
    let i: number = this.currentRow = obj['indexDetail']['row'];
    this.isINTLFieldsCaptured[i] = true;
    this.isCustomFormCaptured[i] = false;
    this.afterJobTitleAndExtFormSave(obj, item);
  }

  empPositionSaveAncExit(obj) {
    this.goToDashboard();
  }

  private prepareIntlFlagArray(data, currentRow, obj?, item?) {
    if (!this._utilService.isEmpty(data) && !this._utilService.isEmpty(data['country'])) {
      let optForRuleCheck = {};
      optForRuleCheck['country'] = data['country'];
      optForRuleCheck['id'] = data['id'];
      this.isINTLEmployeeIdArr[currentRow] = false;
      this._es.getIntlAdditionalFields(optForRuleCheck).subscribe(response => {
        // let intlData = JSON.parse(response._body);
        let intlData = response;

        this.isINTLCompNameInLocalArr[currentRow] = false;
        this.isINTLEmployeeIdArr[currentRow] = false;
        this.isINTLDepartmentArr[currentRow] = false;
        this.isINTLFieldsCaptured[currentRow] = false;
        this.intlAbaCbcArr[currentRow] = false;

        if (!!intlData && !!intlData['intl-addl-field-list'] && intlData['intl-addl-field-list'].length > 0) {
          for (let j = 0; j < intlData['intl-addl-field-list'].length; j++) {
            if (intlData['intl-addl-field-list'][j]['name'] === 'EMPLOYEE_ID') {
              this.isINTLEmployeeIdArr[currentRow] = true;
            }

            if (intlData['intl-addl-field-list'][j]['name'] === 'EMP_NAME_IN_LOCAL_LANGUAGE') {
              this.isINTLCompNameInLocalArr[currentRow] = true;
            }

            if (intlData['intl-addl-field-list'][j]['name'] === 'DEPARTMENT') {
              this.isINTLDepartmentArr[currentRow] = true;
            }
          }

          if (intlData['is_aba_cbc_enabled'] !== undefined && intlData['is_aba_cbc_enabled'] === 'YES') {
            this.intlAbaCbcArr[currentRow] = true;
          }
        }

        if(!!obj && !!item) {
          this.afterJobTitleAndExtFormSave(obj, item);
        }
      }, error => {
        this.isINTLCompNameInLocalArr[currentRow] = false;
        this.isINTLEmployeeIdArr[currentRow] = false;
        this.isINTLDepartmentArr[currentRow] = false;
        this.isINTLFieldsCaptured[currentRow] = false;
        this.intlAbaCbcArr[currentRow] = false;

        if(!!obj && !!item) {
          this.afterJobTitleAndExtFormSave(obj, item);
        }
      });
    }
  }

  private goToNextStageFromBackArraow(serverObj, obj) {
    let item = serverObj[this.currentRow];
    let serverObjLen = serverObj.length;
    if (serverObjLen > 0) {
      let formattedCurrentItrStartDate = this._es.formatDate(obj['jobFrom']);
      let formattedLastItrStartDate = serverObj[serverObjLen - 1]['start-date'];
      let monthDifference = this._es.monthDiffOfDates(formattedCurrentItrStartDate, formattedLastItrStartDate);

      let intlDepartmentAndEmployeeIdArrExists = (this.isINTLDepartmentArr[this.currentRow] || this.isINTLEmployeeIdArr[this.currentRow] || this.isINTLCompNameInLocalArr[this.currentRow]
        || ((this.empTypes[this.currentRow] === 'current' || this.empTypes[this.currentRow] === 'prior')
          && this.selfEmploymentPreferenceEnabled && obj['countryCode'] !== AppConstants.DOMESTIC_COUNTRY_CODE));
      let isINTLFieldCapturedForCurrentRow = this.isINTLFieldsCaptured[this.currentRow];

      if (monthDifference >= 0 || !(intlDepartmentAndEmployeeIdArrExists && !isINTLFieldCapturedForCurrentRow)) {
        if (!!item && !!item['state'] && item['state']['stage_status'] !== 'COMPLETED') {
          let step: string = 'employment-step';
          let body = {
            'employment-data-list': []
          };
          item['state']['stage_status'] = 'COMPLETED';
          body['employment-data-list'].push(item);
          this.goToNextStageUpdatePayload(body, step);
          //  this.deleteUnusedData(item);
        }
      } else {
        this.currentRow += 1;
        this.setSlider(this.sliderIndex, 'right', this.currentRow);
      }
    }
  }

  private deleteUnusedData(obj) {
    let serverData: Object[] = [];
    serverData = (JSON.parse(JSON.stringify(this._es.getServerData())));
    const subFormArray = <FormArray>this.employmentForm.controls['employmentSubForm'];
    let subFormArrayLength = subFormArray.length;

    for (let i = this.currentRow + 1; i < subFormArrayLength; i++) {
      this._es.deleteData(serverData[i]['id']).subscribe(response => {
        console.log('Record deleted successfully');
      }, error => {
        console.log('Some Issue in Deleting record');
      });
    }
  }

  private isMultiplePositionEnabled(countryCode: string, empType: string) {
    // isMultiPositionEnabled is true if allow_multi_position is true in workflow preference and it enables multiple position
    if (this.isMultiPositionEnabled &&
      countryCode !== this.DOMESTIC_COUNTRY_CODE &&
      (!!empType && (empType === 'current') || empType === 'prior')) {
      return true;
    } else {
      return false;
    }
  }

  private assignIdsToMultiPosition(obj, item) {
    if (obj['employment_position_list'] !== undefined && obj['employment_position_list'].length > 0 && item !== undefined
      && item['employment_position_list'] !== undefined && item['employment_position_list'].length > 0) {
      for (let i = 0; i < obj['employment_position_list'].length; i++) {
        if (item['employment_position_list'][i] !== undefined && item['employment_position_list'][i]['id'] !== undefined) {
          obj['employment_position_list'][i]['id'] = item['employment_position_list'][i]['id'];
        }
      }
    }
  }

  initConsentCountry() {
    this.consentCountry = this.localStorage.getItem('consentCountry');
    this.isConsentCountry = this.localStorage.getItem('is_country_based_consent');
  }

  updateDefaultConsentCountry(options) {
    let countryOptions = options['countryOptions'];
    let countryData = JSON.parse(this.consentCountry);

    if (countryOptions['country'] === '' || countryOptions['country'] === undefined) {
      countryOptions['country'] = countryData['country_name'];
    }

    if (countryOptions['countryShortName'] === '' || countryOptions['countryShortName'] === undefined) {
      countryOptions['countryShortName'] = countryData['country_code'];
    }
  }

  isConsentCountryValue() {
    return (this.consentCountry !== undefined && this.isConsentCountry) ? true : false;
  }
  /**
   * Takes the below params and sets the gap date in multi position object
   * @param row
   * @param options
   */
  setPositionGapDate(row: any, options: any) {
    let opts = {};
    opts['from'] = options['start-date'];
    opts['to'] = options['end-date'];
    this.dateConfigs[row] = opts;
    let posObj = {
      'empPosition': '',
      'id': undefined,
      'intlEndDate': '',
      'intlStartDate': '',
      'status': false
    };

    posObj['intlStartDate'] = this._es.getLangFormatedDate(opts['from'], this.getDateFormat());
    posObj['intlEndDate'] = this._es.getLangFormatedDate(opts['to'],  this.getDateFormat());
    this.employeePositionList[row] = [];
    this.posGapFlag[row] = true;
    this.employeePositionList[row].push(posObj);
  }
  /**
   * Returns true flag for employee type as current or prior
   * @param type
   */
  getTypeCheckFlag(type: any) {
    let typeCheckFlag = (type === 'prior') ? true : false;
    return typeCheckFlag;
  }

  private setMultipositionListForATS(data, row, iteration) {
    let multiplePositions = [];
    let positions = data['employment-data-list'][row]['employment_position_list'];
    if (positions !== undefined && positions.length > 0) {
      this.showEndDate = true;
      for (let i = 0; i < positions.length; i++) {
        let option = {};
        option['id'] = positions[i]['id'];
        option['empPosition'] = positions[i]['position'];
        // option['intlStartDate'] = this.getFormatedDate(positions[i]['start-date'], 'MMMM YYYY');
        if (!this._utilService.isEmpty(positions[i]['start-date'])) {
          option['intlStartDate'] = this._es.getFormatedDate(positions[i]['start-date'], this.getDateFormat());
        }

        if (!this._utilService.isEmpty(positions[i]['end-date'])) {
          option['intlEndDate'] = this._es.getFormatedDate(positions[i]['end-date'], this.getDateFormat());
        } else {
          option['intlEndDate'] = 'Present';
        }
        if (this._utilService.isEmpty(positions[i]['status']) || positions[i]['status']) {
          option['status'] = true;
        } else {
          option['status'] = false;
        }
        multiplePositions.push(option);
      }
      this.employeePositionList[iteration] = multiplePositions;

    }
  }

  getDateFormat() {
    if (this.isEmploymentGapInDaysEnabled || !!this.isCitadelData[this.currentRow] || !!this.showPlaidData[this.currentRow]) {
      return 'MMMM dd,YYYY';
    } else {
      return 'MMMM YYYY';
    }
  }

  /**
   *  Returns true if data source is not ATS else returns false
   */
  getPadvtype() {
    let padvFlag = (this.localStorage.getItem('isATS') !== 'YES') ? true : false;
    return padvFlag;
  }

  decideToDisplayAdditionalCard(regularDataList) {
    // decider to display additional card or not when user did save and exit
    for (let i = 0; i < regularDataList.length; i++) {
      if (!!regularDataList[i]['employee_id']) {
        this.isINTLEmployeeIdArr[i] = true;
      } else {
        this.isINTLEmployeeIdArr[i] = false;
      }
      if (!!regularDataList[i]['department']) {
        this.isINTLDepartmentArr[i] = true;
      } else {
        this.isINTLDepartmentArr[i] = false;
      }
      if (!!regularDataList[i]['company_name_in_local_lang']) {
        this.isINTLCompNameInLocalArr[i] = true;
      } else {
        this.isINTLCompNameInLocalArr[i] = false;
      }
    }
  }

  // set config for auto prefill company name.
  setCompanyPrefillConfig(index, countryCode, stateCode) {
    let config: any = {
      country: countryCode,
      state: stateCode,
      type: 'FEMP'
    }
    this.companyPrefillConfig[index] = config;
  }

  // clear company field values
  clearComapanyFields(subFormGroup, obj) {
    /*
    if (subFormGroup.controls['companyName'] !== undefined) {
      subFormGroup.controls['companyName'].setValue('');
    }
    obj['companyName'] = '';
    */

    if (obj['countryCode'] !== this.DOMESTIC_COUNTRY_CODE) {
      if (subFormGroup.controls['companyAddress'] !== undefined) {
        subFormGroup.controls['companyAddress'].setValue('');
      }
      obj['companyAddress'] = '';
    }
  }

  hideEmpWaitModal(){
    jQuery('#empWaitModal').modal('hide');    
    
    try{
      jQuery('.modal-backdrop').remove();
    } catch(err) {
      console.log("error on emp hideEmpWaitModal()");
    }
  }

  disableFutureDateFlags() {
    this.futureDateFlags['isStartDateIsFutureDate'] = false;
    this.futureDateFlags['isEndDateIsFutureDate'] = false;
  }

  confirmCategoryChange() {
    const employmentSubFormArr = <FormArray>this.employmentForm.controls['employmentSubForm'];
    employmentSubFormArr.value[0]['type'] = 'neverEmployed';
    this.empCategoryComponent.category(employmentSubFormArr.value[0]);
    jQuery('#empCategoryChangeModal').modal('hide');
  }

  declineCategoryChange() {
    jQuery('#empCategoryChangeModal').modal('hide');
  }

  empNeverEmployedSelect() {
    if (this.isReviewEdit) {
      let row = this.currentRow;
      let serverData: Object[] = [];
      serverData = (JSON.parse(JSON.stringify(this._es.getServerData())));792

      if (serverData[row]['type'] === 'neverEmployed')
        this.isCurrentEmpNeverEmployed = true;
    }

    jQuery('#empCategoryChangeModal').modal('show');
    return;
  }

  goToReconfirmSlide() {
    this.sliderIndex = this.empSteps.indexOf('reconfirm');
    //this.hideEmpWaitModal()
    this.setSlider(this.sliderIndex, 'right', this.currentRow);
    this.alertUserOnBlankPage(this.currentSlide);
  }

  onPayrollLookup(obj) {
    this.isOptOutCitadelData[this.currentRow] = false;
    if (this.showCitadelData[this.currentRow]) {
      jQuery('#empWaitModal').modal('show');
      obj['service_action'] = 'PERSIST';
      this.initPayload(obj);
    } else {
      this.isInvalidCitadelData = false;
      this.workflowInstanceAuditTrack("Selected Citadel");
      this.initiateCitadelLookup();
    }
  }
  
  onPayrollOptOut(obj) {
    if (!!this.isCitadelData[this.currentRow]) {
      this.clearEmploymentData(obj, this.currentRow);
    }
    this.isOptOutCitadelData[this.currentRow] = true;
    this.isCitadelData[this.currentRow] = false;
    this.showCitadelData[this.currentRow] = false;
    this.isOptOutAutoFullEmpData[this.currentRow] = true;

    if (this.isTriggerTrackEvent)
      this.citadelTrackEvent('citadel import skipped', 'user payroll opt out', true, true);
    else 
      this.isTriggerTrackEvent = true;

    this.afterPayrollLookupSave(obj);
  }

  onProviderOptOut(obj) {
    if (this.isPresentEmpAutoFulfilment) {
      if (!!this.isCitadelData[this.currentRow]) {
        this.clearEmploymentData(obj, this.currentRow);
      }
      this.isOptOutCitadelData[this.currentRow] = true;
      this.isCitadelData[this.currentRow] = false;
      this.showCitadelData[this.currentRow] = false;

      if (this.isTriggerTrackEvent)
        this.citadelTrackEvent('citadel import skipped', 'user provider opt out', true, true);
      else
        this.isTriggerTrackEvent = true;
    }
       
    if(this.isPlaidPreferenceEnabled){
      this.isOptOutAutoFullEmpData[this.currentRow] = true;
    }
    
    this.workflowInstanceAuditTrack("Selected Enter Manually");
    this.afterPayrollLookupSave(obj);
  }  
  
  citadelTrackEvent(eventName, actionValue, isBehavioralMetrics, isOperationalMetrics) {
    let trackEventMsg = eventName; 
    let trackObj = {
      stage_name: 'citadel',
      action_value: actionValue
    };
    
    window.track_event(trackEventMsg, trackObj, isBehavioralMetrics, isOperationalMetrics);
  }     

  empCitadelErrorClose() {
    jQuery('#empCitadelErrorModal').modal('hide');
    this.isTriggerTrackEvent = false;

    let obj = {};
    obj['type'] = 'current';
    this.onPayrollOptOut(obj);
  }

  onPayrollLookupPrevSwipe(obj) {
    let direction = 'left';
    let row = this.currentRow || 0;
    this.currentSlide = this._arrowservice.getprevarrowarray('payroll-lookup', row);
    this.currentSlideWithDirection = `${this.currentSlide}_${direction}`;
  }

  empCitadelOptout() {
    let obj = {};
    obj['type'] = 'current';
    this.onPayrollOptOut(obj);
  }
  
  employmentDataProviderSelect(obj) {
    if (obj['empDataProvider'] === 'BANK') {
      this.onPlaidLookup(obj);      
    } else if((!!obj && obj['empDataProvider'] === 'PAYROLL')) {
      this.citadelTrackEvent('payroll provider selected', 'click', true, true);
      // Citadel workflow
      this.onPayrollLookup(obj);
    }
  }
  
  onPlaidLookup(obj) {
    if (this.showPlaidData[this.currentRow]) {
      jQuery('#empWaitModal').modal('show');
      // Service action to update only the state on PUT call of employment-step
      obj['service_action'] = 'ONLY_UPDATE_STATE';    
    
      this.initPayload(obj);
    } else {
      jQuery('#empWaitModal').modal('show');
      this.plaidTrackEvent('bank provider selected', 'click', true, true);
      this.workflowInstanceAuditTrack("Selected Plaid");
      this.initializePlaidWorkflow(obj);
    }
  }
  
  initializePlaidWorkflow(value) {
    // Get link token, initialize plaid instance, and open widget
    const requestId: string = uuidv4();
    this.localStorage.setItem('plaid_request_id', requestId);

    let body = {
      "inviteId": this.localStorage.getItem('inviteId'),
      "inviteKey": this.localStorage.getItem("invite_key"),
      "requestId": requestId
    };
    
    this._es.fetchPlaidLinkToken(body).subscribe((response) => {
      this.plaidLinkToken = response['token'];
      
      delete this.config['env'];
      this.config["token"] = response['token'];
      this.plaidTrackEvent('link token received', 'fetch', true, true);

      this._plaidLinkService
      .createPlaid(
        Object.assign({}, this.config, {
          onSuccess: (token, metadata) => this.onPlaidSuccess(token, metadata, value),
          onExit: (error, metadata) => this.onPlaidExit(error, metadata),
          onEvent: (eventName, metadata) => this.onPlaidEvent(eventName, metadata)
        })
      )
      .then((handler: PlaidLinkHandler) => {
        this.plaidLinkHandler = handler;
        this.plaidTrackEvent('open widget', 'initialize', true, true);

        this.openPlaid();
      });
    }, error => {
      this.plaidTrackEvent('fetch link token failed', 'error', true, true);

      this.hideEmpWaitModal();
      console.log(error);
    })
  }
  
  // Plaid library event - Open
  openPlaid() {
    this.plaidLinkHandler.open();
  }

  // Plaid library event - Exit
  exitPlaid() {
    this.plaidLinkHandler.exit();
  }

  // Plaid library event - onSuccess
  onPlaidSuccess(token, metadata, value?) {    
    let companyName = this.employmentForm.controls['employmentSubForm']['controls'][this.currentRow].controls['companyName'].value;
    let empDetailsDate = this.employmentForm.controls['employmentSubForm']['controls'][this.currentRow].controls['jobFrom'].value;
    let type = this.employmentForm.controls['employmentSubForm']['controls'][this.currentRow].controls['type'].value;
    let isoEmpDetailsDate = dateUtils.formatToISODate(empDetailsDate, this._es.monthNames);
    let empStartDate = this._es.getDateToTs(empDetailsDate);
    
    let body = {
      "invite_id": this.localStorage.getItem('inviteId'),
      "invite_key": this.localStorage.getItem("invite_key"),
      "request_id": this.localStorage.getItem("plaid_request_id"),
      "id": this.isReviewEdit ? this.recordId: null,
      "name": companyName,
      "type": type,      
      "from_date": isoEmpDetailsDate,
      "start_date": empStartDate,
      "state": {
        'stage_status': 'PENDING',
        'current_status': 'PENDING',
        'step_status': 'PENDING',
        'current_stage': value['COMPONENT_STATE']['name'],
        'current_step': value['COMPONENT_STATE']['step'],
        'current_step_index': value['COMPONENT_STATE']['stepIndex'],
        'current_sub_step': value['COMPONENT_STATE']['subStep'],
        'current_sub_step_index': value['COMPONENT_STATE']['subStepIndex'],
        'current_iteration': value['COMPONENT_STATE']['iteration']
      }
    }

    this._es.updatePublicToken(body).subscribe((response) => {
      // Update Pass flag to data-provider selection screen and show the card with details
      this.recordId = response['id'];
      this.showPlaidData[this.currentRow] = true;
      this.plaidTrackEvent('update db', 'submit', true, true);
      this.hideEmpWaitModal();
    }, error => {
      console.log(error);
      this.plaidTrackEvent('update db failed', 'error', true, true);
      this.hideEmpWaitModal();
    });
  }

  // Plaid library event - onEvent
  onPlaidEvent(eventName, metadata) {
    // console.log("We got an event:", eventName);
    // console.log("We got metadata:", metadata);
  }

  // Plaid library event - onExit
  onPlaidExit(error, metadata) {
    console.log("We exited:", error);
    console.log("We got metadata:", metadata);
    this.plaidTrackEvent('widget closed', 'exit', true, true);
    this.workflowInstanceAuditTrack("Closed Plaid widget");
    this.hideEmpWaitModal();
  }
  
  employmentDataProviderSwipe(value) {
    let direction = 'left';
    this.sliderIndex = this.empSteps.indexOf('emp-details');
    this.setSlider(this.sliderIndex, direction, this.currentRow);
  }
  
  empDataProviderModalOpen() {
    jQuery('#empDataProviderInfoModal').modal('show');
  }
  
  empDataProviderModalClose() {
    jQuery('#empDataProviderInfoModal').modal('hide');
  }
  
  plaidTrackEvent(eventName, actionValue, isBehavioralMetrics, isOperationalMetrics){
    let trackEventMsg = eventName; 
    let trackObj = {
      stage_name: 'plaid',
      action_value: actionValue
    };
    
    window.track_event(trackEventMsg, trackObj, isBehavioralMetrics, isOperationalMetrics);
  }

  workflowInstanceAuditTrack(auditData){
    this.instructionsService.saveWorkflowInstanceAuditData(auditData).subscribe((response) => {
      console.log("response is ----", response);
    }, error => {
      console.log("Error while saving workflow instance audit for " + auditData, error);
    });
  }
}
