import { Field } from 'framework/dynamicCard/models/field.interface';
import { Component, Renderer2 } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FieldConfig } from 'framework/dynamicCard/models/fieldconfig.interface';
import { TranslateService } from 'framework/i18n';


@Component({
    selector: 'form-ssn',
    styleUrls: ['form-ssn.component.scss'],
    templateUrl: './form-ssn.component.html'
})
export class FormSSNComponent implements Field {
    config: FieldConfig;
    group: FormGroup;

    errorMessage: string = '';
    isError: boolean = false;
    isErrorInfo: boolean = false;
    isConfirmError: boolean = false;
    isConfirmErrorInfo: boolean = false;
    confirmErrorMessage: string = '';
    isSSNValid: boolean = false;
    storeSSN = '';
    confirmStoreSSN = '';  
    ssnControlName='';
    confirmControlName='';
    
    constructor(private _renderer: Renderer2,private _ts: TranslateService,){

    }

    ngOnInit() {
        console.log("FormSSNComponent ----");
        this.ssnControlName = this.config.name+'_ssn';
        let ssnControl: FormControl = new FormControl('', Validators.required);
        this.group.addControl(this.ssnControlName, ssnControl);
        this.confirmControlName = this.config.name+'_confirm';
        let confirmControl: FormControl = new FormControl('', Validators.required);
        this.group.addControl(this.confirmControlName, confirmControl);        
    }

    validateSSN(event, value: string) {
        let val = value.replace(/\D/g, '');
        let newVal = '';
    
        if (val.length > 4) {
          value = val;
        }
        if ((val.length > 3) && (val.length < 6)) {
          newVal += val.substr(0, 3) + '-';
          val = val.substr(3);
        }
        if (val.length > 5) {
          newVal += val.substr(0, 3) + '-';
          newVal += val.substr(3, 2) + '-';
          val = val.substr(5);
        }
        newVal += val;
       this.group.controls[this.ssnControlName].setValue(newVal);
       this.storeSSN = newVal;
        if (!newVal.match(/^([0-9]+-)*[0-9]+$/) && newVal.length > 0) {
          this.isError = true;
          this.isErrorInfo = true;
          this.errorMessage = this._ts.instant('SSN_ERROR_MSG');
          this.isSSNValid = false;
        } else {
          this.isError = false;
          this.isErrorInfo = false;
          this.errorMessage = '';
          this.isSSNValid = true;
        }
        this._renderer.setStyle(event.target, 'gray80', true);
        if(!this.isErrorInfo){
            this.compareSSNAndConfirmSSN();
        }        
      }    

      maskSSN(val) {
        let newVal = '';
        let ssn = val;
        this.storeSSN = val;
    
        if (ssn.length === 11) {
          newVal = ssn.replace(ssn.substr(0, 6), '***-**');
         this.group.controls[this.ssnControlName].setValue(newVal);
        }
        if (ssn.length > 0) {
          let isSSNValid = this.ssnValidation(ssn);
    
          if (!isSSNValid) {
            this.isError = true;
            this.isErrorInfo = true;
            this.errorMessage = this._ts.instant('SSN_INVALID_ERROR_MSG');
            this.isSSNValid = false;
          } else {
            this.isError = false;
            this.isErrorInfo = false;
            this.errorMessage = '';
            this.isSSNValid = true;
          }
        } else {
          this.isError = false;
          this.isErrorInfo = false;
          this.errorMessage = '';
          this.isSSNValid = true;
        }
        if(this.isSSNValid){
            this.compareSSNAndConfirmSSN();
        }
                 
      }

      unmaskSSN(event) {
        this.group.controls[this.ssnControlName].setValue(this.storeSSN);
        this._renderer.setStyle(event.target, 'gray80', true);
      }

      unmaskConfirmSSN(event) {
        this.group.controls[this.confirmControlName].setValue(this.confirmStoreSSN);
        this._renderer.setStyle(event.target, 'gray80', true);
      }
      
      validateConfirmSSN(event, value: string) {
        let val = value.replace(/\D/g, '');
        let newVal = '';
    
        if (val.length > 4) {
          value = val;
        }
        if ((val.length > 3) && (val.length < 6)) {
          newVal += val.substr(0, 3) + '-';
          val = val.substr(3);
        }
        if (val.length > 5) {
          newVal += val.substr(0, 3) + '-';
          newVal += val.substr(3, 2) + '-';
          val = val.substr(5);
        }
        newVal += val;
        this.group.controls[this.confirmControlName].setValue(newVal);
        this.confirmStoreSSN = newVal;
        if (!newVal.match(/^([0-9]+-)*[0-9]+$/) && newVal.length > 0) {
          this.isConfirmError = true;
          this.isConfirmErrorInfo = true;
          this.confirmErrorMessage = this._ts.instant('SSN_ERROR_MSG');
        } else {
          this.isConfirmError = false;
          this.isConfirmErrorInfo = false;
          this.confirmErrorMessage = '';
        }
        this._renderer.setStyle(event.target, 'gray80', true);
        if(!this.isConfirmErrorInfo){
            this.compareSSNAndConfirmSSN();
        }        
      }

      storeConfirmSSN(val) {
        this.confirmStoreSSN = val;
        let confssn = val;
        let newVal = '';

        if (confssn.length === 11) {
          newVal = confssn.replace(confssn.substr(0, 6), '***-**');
          this.group.controls[this.confirmControlName].setValue(newVal);
        }
    
        if (val.length > 0) {
          let isConfSSNValid = this.ssnValidation(confssn);
    
          if (!isConfSSNValid) {
            this.isConfirmError = true;
            this.isConfirmErrorInfo = true;
            this.confirmErrorMessage = this._ts.instant('SSN_INVALID_ERROR_MSG');
          } else {
            this.isConfirmError = false;
            this.isConfirmErrorInfo = false;
            this.confirmErrorMessage = '';
          }
        } else {
          this.isConfirmError = false;
          this.isConfirmErrorInfo = false;
          this.confirmErrorMessage = '';
        }
        if(!this.isConfirmErrorInfo){
            this.compareSSNAndConfirmSSN();
        }

      }  
      
      compareSSNAndConfirmSSN(){
        if (this.storeSSN.length === 11 && this.confirmStoreSSN.length === 11) {
            if(this.storeSSN !== this.confirmStoreSSN){
                // Mismatch error
                this.isConfirmError = true;
                this.isConfirmErrorInfo = true;
                this.confirmErrorMessage = this._ts.instant('SSN_MISMATCH'); 
                this.group.controls[this.config.name].setValue('');
            } else {
                this.group.controls[this.config.name].setValue(this.storeSSN);
                this.isConfirmError = false;
                this.isConfirmErrorInfo = false;
                this.confirmErrorMessage = '';                
            }
        }          
      }

      closeSSNError() {
        this.isError = false;
      }
      
      closeConfirmSSNError() {
        this.isConfirmError = false;
      }  
      
      ssnValidation(ssn) {
        let regex = new RegExp('^(?!\\b(\\d)\\1+-(\\d)\\1+-(\\d)\\1+\\b)(?!123-45-6789|219-09-9999|078-05-1120)(?!666|000|9\\d{2})\\d{3}-(?!00)\\d{2}-(?!0{4})\\d{4}$');
    
        return regex.test(ssn);
      }      

}