
export class InternationalConfig {
    /* 
     * DEFINE CONSTANTS to be used in Component and Services for better
     * code readability
    */
    static STAGE_ID: string = 'international-data-v1';
    static STEP_ID: string = 'international-step-v1';
    static STEP_INDEX: number = 0;
    static BUTTON_CLICKED: string = 'CLICKED_NEXT_BUTTON';
    static VISITED: string = 'VISITED';
    /* 
     * yes : <Increment_Index>,
     * no : <Increment_Index>
     * 
     * 999 - Next Module
     * 0 - no action
     * 
     * If there are no 'yes' or 'no' then go to next card.
     * 
     * Below are for the DBS cards navigation configuration (without DBS Consent)
     * 
     * card_index_0 = // Current Card - Do you have a DBS profile number?
     * card_index_1 = // Current Card - What is your DBS Number?
     * card_index_2 = // Current Card - Do you want DBS paper certificate?
     * card_index_3 = // Current Card - Do you want to send DBS paper certificate to your current address?
     * card_index_4 = // Current Card - Please provide the country where you want to send the DBS paper certificate.
     * card_index_5 = // Current Card - Address, Zip, State and City fields.
     * card_index_6 = // Current Card - Please provide your recipient and department name.
     * card_index_7 = // Current Card - DBS Consent
    */
   static dbsCardsNavPath = [
        {
            'yes': 1, 
            'no': 2
        },
        {
            'yes': 1,
            'no': 0 // nothing to do
        },
        {
            'yes': 1,
            'no':  5 // DBS Consent (999)
        },
        {
            'yes': 4, // DBS Consent (999)
            'no': 1
        },
        {
            'yes': 1,
            'no': 0 // nothing to do
        },
        {
            'yes': 1,
            'no': 0 // nothing to do
        },
        {
            'yes': 1, // DBS Consent (999)
            'no': 0 // nothing to do
        },
        {
            'yes': 1,
            'no': 0 // nothing to do
        }
    ];

    //WWC Implementation - Setting Paths
    static wwcCardsNavPath = [
        {
            'yes': 1, 
            'no': 999 //This card doesn't exist, so that it goes to the next module
        },
        {
            'yes': 1, 
            'no': 999
        }     
    ];

    //WWC Implementation - Setting the list of states for which we
    //need to ask the WWC Number.
    static wwcStatesForNumber = ['new south wales','northern territory','norfolk island'];
    //Using the codes for extracting the appropriate regex for WWC_NUMBER
    static wwcStateCodesForNumber = ['NSW','NT','NF'];
    
    static cutsomKeys = ['COUNTRYCODE', 'PHONENUMBER'];
    static dbsButtonsLabels = ['YES', 'NO'];
    static inactiveButtons = ['SAVE AND EXIT', 'NO'];
    static customCSSObject = {
        'DBSCONSENTCSS': 'col-md-12 col-sm-12 col-xs-12 m-b-20',
        //Please donot use col-md-* at this level we are handling this in component html
        'CUSTOMFORMCSS': 'col-sm-6 col-xs-12 m-b-20'
    };
    static defaultConfig  = [
            {
            'name': 'International Page',
            'header': 'Additional Information',
            'sub_header': 'Additional Information',
            'swipe_left': false,
            'swipe_right': false,
            'inputs': [
                {
                    name: 'NEXT',
                    label: 'NEXT',
                    type: 'BUTTON',
                    show: true
                },
                {
                    name: 'SAVE AND EXIT',
                    label: 'SAVE AND EXIT',
                    type: 'BUTTON',
                    show: true
                }
                ]
            }
        ];
    
    static headerSubheaders  = [{
        'header': 'INTL_TAB_HEADING_2',
        'sub_header': 'Additional Information'
    },
    {
        'header': 'Additional Information',
        'sub_header': 'Additional Information'
    }];

    static fieldTypes: Array<string> = ['text', 'country', 'state', 'address', 'city',
                                        'phone', 'select', 'date', 'radio', 'checkbox',
                                        'objectselect', 'button', 'textarea', 'numeric' , 'checkboxex', 'esignature', 'TextArea',
                                        'yesnoquestionwithexplanation','nationality', 'maskdltextbox', 'maskssntextbox', 'maskdobtextbox'
                                       ];

    // Event Names
    static intlTrackEventNames = {
        'ADDITIONAL_FIELD': 'Addional_Fields_Provided',
        'DBS_FIELD': 'DBS_Fields_Provided',
        'CPIC_FIELD': 'CPIC_Fields_Provided',
        'CUSTOMFORM_FIELD': 'CustomForm_Fields_Provided',
        'ACIC_FIELD': 'ACIC_Fields_Provided',
        'INTLREG_FIELD': 'INTLREG_Fields_Provided'
    };                                       

    static getDefaultConfig(): Array<any> {
        return InternationalConfig.defaultConfig ;
    }
    
    static getHeaderSubheaders(): any {
        return InternationalConfig.headerSubheaders ;
    }
    
    static getFieldTypes(): Array<string> {
        return InternationalConfig.fieldTypes;
    }
    
    static getDbsCardPath(currentDbsCardIndex, cuurentArrLength) {
       let dbsCardsNav = InternationalConfig.dbsCardsNavPath[currentDbsCardIndex];

      /*
       * let y = {
                    'yes': cuurentArrLength + x['yes'],
                    'no': cuurentArrLength + x['no']
            }; 
        */
       let temp = {};

       if (dbsCardsNav !== undefined) {
        temp['yes'] = (dbsCardsNav['yes'] !== 999) ? cuurentArrLength + dbsCardsNav['yes'] : dbsCardsNav['yes'];

        if (dbsCardsNav['no'] !== 0) {
            temp['no'] = (dbsCardsNav['no'] !== 999) ? cuurentArrLength + dbsCardsNav['no'] : dbsCardsNav['no'];
        }
       }

       return temp;
    }

    static getNationalCardPath(cuurentArrLength) {
        let dbsCardsNav = { 'yes': 1, 'no': 1 };
        let temp = {};
        if (dbsCardsNav !== undefined) {
            temp['yes'] = (dbsCardsNav['yes'] !== 999) ? cuurentArrLength + dbsCardsNav['yes'] : dbsCardsNav['yes'];
            if (dbsCardsNav['no'] !== 0) {
                temp['no'] = (dbsCardsNav['no'] !== 999) ? cuurentArrLength + dbsCardsNav['no'] : dbsCardsNav['no'];
            }
        }
        return temp;
    }

    /**
      * Card navigation identification for WWC
      * @param currentWwcCardIndex - the current card index
      * @param cuurentArrLength - the length of the array which contains all the previous cards
      * returns - and object of pattern {yes:<<number>>, no:<<number>>}
    */
    static getWwcCardPath(currentWwcCardIndex, cuurentArrLength) {
        let wwcCardsNav = InternationalConfig.wwcCardsNavPath[currentWwcCardIndex];      
        let temp = {}; 
        if (wwcCardsNav !== undefined) {
         temp['yes'] = (wwcCardsNav['yes'] !== 999) ? cuurentArrLength + wwcCardsNav['yes'] : wwcCardsNav['yes']; 
         if (wwcCardsNav['no'] !== 0) {
             temp['no'] = (wwcCardsNav['no'] !== 999) ? cuurentArrLength + wwcCardsNav['no'] : wwcCardsNav['no'];
         }
        }
        return temp;
     }

    static setCustomClass(element){
        let cssVal = '';
        let name = element['name'];
        switch (name) { 
            case 'DBS_CONSENT_IAGREE': 
                cssVal = this.customCSSObject['DBSCONSENTCSS'];
                element['checkBoxClass'] = cssVal;
                break; 
            
            default: 
                cssVal = this.customCSSObject['CUSTOMFORMCSS'];
                element['checkBoxClass'] = cssVal;
                break; 
         }
    }

    static getbase64ImageAppend() {
        return 'data:image/png;base64,';
    }

    static getCustomKeys() {
        return InternationalConfig.cutsomKeys;
    }

    static maxDateConfiguration = {
        'DBS_DRIVER_LICENSE_VALID_FROM': new Date(),
        'DBS_PASSPORT_ISSUE_DATE': new Date()
    }

    static namesForRnf = {
        'DO_YOU_HAVE_RNF_NUMBER': 'DO_YOU_HAVE_RNF_NUMBER',
        'RNF_NUMBER': 'RNF_NUMBER',
        'NAME_DOCUMENT_TYPE': 'NAME_DOCUMENT_TYPE',
        'NAME_AS_PER_DOCUMENT': 'NAME_AS_PER_DOCUMENT'
    }

    static namesForUan = {
        'HAS_UAN_NUMBER': 'HAS_UAN_NUMBER',
        'UAN_NUMBER': 'UAN_NUMBER'
    };

    static namesForRtwKYP = {
        'DO_YOU_HAVE_UK_GOVT_SHARE_CODE': 'DO_YOU_HAVE_UK_GOVT_SHARE_CODE',
        'PROVIDE_SHARE_CODE': 'PROVIDE_SHARE_CODE',
        'REGISTER_NEW_SHARE_CODE': 'REGISTER_NEW_SHARE_CODE',
        'RIGHT_TO_WORK_STATUS_OPTIONS': 'RIGHT_TO_WORK_STATUS_OPTIONS',
        'PROVIDE_SHARE_CODE_1': 'PROVIDE_SHARE_CODE_1'
    };

    static namesForAuMedicalCheck = {
        'APPOINTMENT_DATE_1' : 'APPOINTMENT_DATE_1',
        'APPOINTMENT_DATE_2' : 'APPOINTMENT_DATE_2',
        'APPOINTMENT_DATE_3' : 'APPOINTMENT_DATE_3',
        'APPOINTMENT_TYPE_1' : 'APPOINTMENT_TYPE_1',
        'APPOINTMENT_TYPE_2' : 'APPOINTMENT_TYPE_2',
        'APPOINTMENT_TYPE_3' : 'APPOINTMENT_TYPE_3'
    }
    
    static headerForAuMedicalCheck = [{
        'header': 'ADDITIONAL_INFORMATION_HEADING_1',
        'sub_header': 'ADDITIONAL_INFORMATION_SUBHEADING_1'
    },
    {
        'header': 'ADDITIONAL_INFORMATION_HEADING_2',
        'sub_header': 'ADDITIONAL_INFORMATION_SUBHEADING_2'
    },
    {
        'header': 'ADDITIONAL_INFORMATION_HEADING_3',
        'sub_header': 'ADDITIONAL_INFORMATION_SUBHEADING_3'
    }]

    static getAuMedicalHeader(): any {
        return InternationalConfig.headerForAuMedicalCheck ;
    }
}
