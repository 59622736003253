import { Component, Input, Output, EventEmitter, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormControl, FormArray, FormBuilder, Validators } from '@angular/forms';
import { EmploymentConfig } from '../employment.config';
import { EmploymentService } from '../employment.service';
import { BehaviorSubject, of } from 'rxjs';

import { EventBusService } from '../../../framework/eventbus.service';
import * as dateUtils from 'framework/utils/date.utils';
//State
import { Store } from '@ngrx/store';
import * as ACTIONS_INTERFACE from '../../actions';
import { TranslateService } from 'framework/i18n';
import { SharedService } from './../../shared/services/shared.service';
import { catchError, distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'job-period',
  templateUrl: './job-period.component.html',
  styleUrls: ['./job-period.component.css']
})
export class JobPeriodComponent implements OnInit {
  @Input('group')
  public empSubForm: FormGroup;

  _data = new BehaviorSubject<any>([]);
  gapCheck = new BehaviorSubject<any>([]);
  _futureDateFlag = new BehaviorSubject<any>([]);

  public checkGapClick: boolean = true;
  public invalidDate: boolean = false;
  monthNames = this._ts.instant('MONTH_NAMES');
  @Input() row: any;
  @Input() empType: string;
  @Input() options: any;
  @Input() currentJob: any;
  @Input() currentSlide: any;
  @Input() IsSaveSuccess: boolean;
  @Input() isJobfromValid: boolean;
  @Input() isCheckSelfEmploymentEnabled: boolean;

  @Input()
  set dateOptions(value) {
    this._data.next(value);
  };

  get dateOptions() {
    return this._data.getValue();
  }

  @Input()
  set inputGapCheck(value) {
    this.gapCheck.next(value);
  };

  get inputGapCheck() {
    return this.gapCheck.getValue();
  }

  @Input() userDetails: any;
  @Input() activeGapOption: any;
  @Input() configOptions: any;
  @Input() isReviewEdit: boolean;
  @Input() isReview: boolean;
  @Input() isReviewAdd: boolean;
  @Input() isEmploymentGapInDaysEnabled: boolean;
  @Input() isCitadelData: boolean
  @Input() showPlaidData: boolean
  @Input()
  set futureDateFlags(value) {
    this._futureDateFlag.next(value);
  };

  get futureDateFlags() {
    return this._futureDateFlag.getValue();
  };
  
  @Input() isInSchoolAddressDetailsRequired: boolean;

  @Output() onJobPeriod: EventEmitter<any> = new EventEmitter();
  @Output() onJobPeriodSwipe: EventEmitter<any> = new EventEmitter();
  jobFromId;
  jobToId;
  fromDateOptions: Object;
  toDateOptions: Object;
  fromMinDate: Date;
  fromMaxDate: Date;
  fromDefaultDate: Date;
  toMinDate: Date;
  toMaxDate: Date;
  toDefaultDate: Date;
  toSetDate: Date;
  fromSetDate: Date;
  isViewInit;
  isJobPeriodError: boolean = false;
  displayType: any;
  isJobToValid: boolean = false;
  checkGapValue: boolean = false;

  //SE functionality
  static STAGE_ID: string;
  static STEP_ID: string;
  static STEP_INDEX: number;
  public SUB_STEP_ID: string;
  public SUB_STEP_INDEX: number;
  PARENT_STAGE_STEP_CONFIG: Object;

  //State Maintenance Related Activity
  static BUTTON_CLICKED: string = 'CLICKED_SAVE_EXIT_BUTTON';
  currentStatus: string = 'VISITED';
  payLoad: Object;
  startTime: any;
  isStartDateIsFutureDate: boolean = false;
  isEndDateIsFutureDate: boolean = false;
  showStartDateRangeErrorMsg: boolean = false;
  showEndDateRangeErrorMsg: boolean = false;
  
  countryId: string;
  countryList: any = [];
  selectedCountry: string;
  isCountryChanged: boolean = false;
  isCountryValid: boolean;
  errorMsgShow: boolean;
  isCountryError: boolean;
  latestCountryLnamFromApi: any;
  countryOptions: Object;
  countryShortName: any;
  validCountryFlag: boolean;
  
  zipId: any;
  cityStateId;
  cityId;
  stateId;
  stateCode: string = '';
  cityCode: string = '';
  stateShortName: string = '';
  showCityErr: boolean = false;
  showStateErr: boolean = false;
  validStateFlag: boolean = false;
  validCityFlag: boolean = false;
  getGeoOption: any;
  cityOptions;
  stateOptions;
  isOpenGoogleSuggestion: boolean;
  cityStateErrFlag: any;
  showCitySateErr: boolean;
  optionsStateCode: any;
  
  isStateListToShow: boolean = false;
  isCityListToShow: boolean = false;
  isZipListToShow: boolean = false;
  
  stateList: any = [];
  cityList: any = [];
  zipCodeList: any = [];
  
  selectedState: string;
  selectedCity: string;
  selectedZipCode: string;
  
  countryTypeahead = new EventEmitter<string>();
  stateTypeahead = new EventEmitter<string>();
  cityTypeahead = new EventEmitter<string>();
  zipCodeTypeahead = new EventEmitter<string>();
  
  private _zipCodeError = new BehaviorSubject<any>([]);
  @Input()
  set zipCodeError(value) {
    this._zipCodeError.next(value);
  }
  get zipCodeError() {
    return this._zipCodeError.getValue();
  }
  
  constructor(private _store: Store<ACTIONS_INTERFACE.AppState>,
    private _eventBus: EventBusService, // Auto Generated PPA-8745 - Arun Supreet for Save n Exit Patch
    private _es: EmploymentService,
    private _sharedService: SharedService,
    private _ts: TranslateService,
    private _cdr: ChangeDetectorRef) {
      this.startTime = new Date().getTime();
  }

  ngOnInit(): void {
    this._data
      .subscribe(x => {
        this.setDate();
      });

    this.gapCheck
      .subscribe(y => {
        if (y !== undefined) {
          this.checkGapValue = true;
        }
      });

    this._futureDateFlag
      .subscribe(futureDateflgs => {
        if (futureDateflgs !== undefined) {
          this.isStartDateIsFutureDate = futureDateflgs['isStartDateIsFutureDate'];
          this.isEndDateIsFutureDate = futureDateflgs['isEndDateIsFutureDate'];
        }
      });
    
    this.countryId = 'empCountry_' + this.row;
    this.isCountryValid = true;
    this.zipCodeList = [];
    this.isZipListToShow = false;
    this.isOpenGoogleSuggestion = false;
    
    this.cityStateId = 'empCityState_' + this.row;
    this.cityId = 'empCity_' + this.row;
    this.stateId = 'empState_' + this.row;
    this.zipId = 'empZipCode_' + this.row;
    this.cityStateErrFlag = false;
    this.showCitySateErr = false;
    
    this.isViewInit = false;
    this.jobFromId = `empJobFrom_${this.row}`;
    this.jobToId = `empJobTo_${this.row}`;
    
    this.getGeoOption = this._es.getGeoOptions();
    this.cityOptions = this.getGeoOption['city'];
    this.stateOptions = this.getGeoOption['state'];
    
    if (this.activeGapOption) {
      this.setDate();
    }

    this.displayType = this._es.getCategory(this.empType);
  }

  ngOnChanges() {
    if (this.isViewInit && (this.empType === 'prior' || this.empType === 'selfEmployed')) {
      this.setDate();
    }
    
    if (this.currentSlide === undefined || this.currentSlide !== undefined && this.currentSlide !== null
      && (this.currentSlide.indexOf("job-period") > -1)) {
      this.displayType = this._es.getCategory(this.empType);
    }
    
    if (this.activeGapOption) {
      this.setDate();
    }

    if (this.IsSaveSuccess) {
      this.checkGapClick = this.IsSaveSuccess;
    }
    
    if (this.empType === 'inSchool' && this.isInSchoolAddressDetailsRequired) {
      let defaultCountryOptions = EmploymentConfig.defaultCountryOptions();
      this.countryOptions = this.options['countryOptions'];
      this.optionsStateCode = this.options['stateOptions'];
      
      if (this.row === 0) {
        this.countryShortName = defaultCountryOptions['defaultCountryShortName'];
        this.isCountryValid = true;

        let countryName = <FormControl>this.empSubForm.controls['schoolCountryName'].value || '';
        let countryCode = <FormControl>this.empSubForm.controls['schoolCountryCode'].value || '';

        if (!!countryCode) {
          this.countryShortName = countryCode || defaultCountryOptions['defaultCountryShortName'];
        }

        if (!this.isReviewEdit) {
          this.selectedCountry = countryName || defaultCountryOptions['defaultCountry'];

          (<FormControl>this.empSubForm.controls['schoolCountryName']).setValue(countryName || defaultCountryOptions['defaultCountry']);
          (<FormControl>this.empSubForm.controls['schoolCountryCode']).setValue(countryCode || this.countryShortName);
        }
      }
      
      if (this.countryOptions['country'] !== null && this.countryOptions['country'] !== undefined && this.countryOptions['country'] !== '') {
        this.countryShortName = this.countryOptions['countryShortName'];
      
        this.selectedCountry = this.countryOptions['country'];
        (<FormControl>this.empSubForm.controls['schoolCountryName']).setValue(this.countryOptions['country']);
        (<FormControl>this.empSubForm.controls['schoolCountryCode']).setValue(this.countryOptions['countryShortName']);
      } else {
        this.countryShortName = defaultCountryOptions['defaultCountryShortName'];

        this.selectedCountry = defaultCountryOptions['defaultCountry'];
        (<FormControl>this.empSubForm.controls['schoolCountryName']).setValue(defaultCountryOptions['defaultCountry']);
        (<FormControl>this.empSubForm.controls['schoolCountryCode']).setValue(defaultCountryOptions['defaultCountryShortName']);
      }
      
      this.latestCountryLnamFromApi = (<FormControl>this.empSubForm.controls['schoolCountryName']).value;

      if (this.optionsStateCode !== undefined && this.optionsStateCode['stateCode'] !== undefined) {
        this.stateCode = this.optionsStateCode['stateCode'];
      }
      
      this.validateCountry(this.empSubForm.controls['schoolCountryName'].value);
      this.validateCity(this.empSubForm.controls['schoolCity'].value);
      this.validateState(this.empSubForm.controls['schoolStateName'].value);
      
      this.selectedCountry = this.empSubForm.controls['schoolCountryName'].value;
      this.selectedState = this.empSubForm.controls['schoolStateName'].value;
      this.selectedCity = this.empSubForm.controls['schoolCity'].value;
      this.selectedZipCode = this.empSubForm.controls['schoolZipCode'].value;
      
      this.countryServerSideSearch();
      this.stateServerSideSearch();
      this.cityServerSideSearch();
      this.zipCodeServerSideSearch();
    }
    
    this.isJobfromValid = this.isJobfromValid;
  }

  ngAfterViewInit(): void {
    this.processConfig();
    
    if (this.empSubForm.controls['jobTo'] !== undefined && this.empSubForm.controls['jobTo'].value !== '') {
      this.isJobToValid = true;
    }
    
    this.isViewInit = true;
    this._cdr.detectChanges();
  }

  private processConfig(): void {
    this.PARENT_STAGE_STEP_CONFIG = this.configOptions['PARENT_CONFIG'];
    this.SUB_STEP_ID = EmploymentConfig.subStepIndexMapping['job_period']['subStep'];
    this.SUB_STEP_INDEX = EmploymentConfig.subStepIndexMapping['job_period']['subStepIndex'];

    this.setState();
  }

  private setState(): void {
    this.payLoad = {
      name: this.PARENT_STAGE_STEP_CONFIG['STAGE_ID'],
      step: this.PARENT_STAGE_STEP_CONFIG['STEP_ID'],
      stepIndex: this.PARENT_STAGE_STEP_CONFIG['STEP_INDEX'],
      subStep: this.SUB_STEP_ID,
      subStepIndex: this.SUB_STEP_INDEX,
      iteration: this.row,
      status: this.currentStatus,
      dbRecordPresent: this.PARENT_STAGE_STEP_CONFIG['IS_DB_RECORD_PRESENT']
    };

    this._store.dispatch({ type: this.currentStatus, payload: this.payLoad });
  }

  private setDate() {
    if (!this.isCitadelData && !this.showPlaidData && (!!this.currentSlide && this.currentSlide.indexOf("emp-details") === -1 && this.currentSlide.indexOf("data-provider") === -1)) {
      this.fromDateOptions = this.dateOptions['from'];

      this.toDateOptions = this.dateOptions['to'];
      this.setFromDate();
      this.setToDate();
    }
  }

  private setFromDate() {
    if (this.dateOptions['from']) {
      this.fromMinDate = this.fromDateOptions['minDate'];
      this.fromMaxDate = this.fromDateOptions['maxDate'];
      this.fromDefaultDate = this.fromDateOptions['defaultDate'];
      
      if (this.empSubForm.controls['jobFrom'] !== undefined) {
        let jobFrom = (<FormControl>this.empSubForm.controls['jobFrom']).value;
        
        if (this.activeGapOption || this.isReviewEdit || this.checkGapValue || !!jobFrom) {
          if (!!jobFrom) {
            let currentLang = this._ts.instant("MONTH_NAMES");
            let engLang = this._sharedService.getEngMonths();
            let formatToEngLang: string;
            if (this.isEmploymentGapInDaysEnabled) {
              formatToEngLang = dateUtils.convertFullDateToEngFormat(jobFrom, currentLang, engLang);
            } else {
              formatToEngLang = dateUtils.convertToEngFormat(jobFrom, currentLang, engLang);
            }
            this.fromSetDate = this._es.fromDateOptions(formatToEngLang)['defaultDate'];
          } else {
            this.fromSetDate = this.fromDateOptions['defaultDate'];
          }

          let setMonthNo = new Date(this.fromSetDate).getMonth();
          let setyear = new Date(this.fromSetDate).getFullYear();
          let setMonthName = this.monthNames[setMonthNo];
          let setMonthYear = `${setMonthName} ${setyear}`;
          if (this.fromSetDate == undefined) {
            let setMonthYear = ''
            this.getFromDpValue(setMonthYear);
          }
          else {
            if (this.isEmploymentGapInDaysEnabled) {
              let setDay = new Date(this.fromSetDate).getDate();
              let setMonthDayYear = `${setMonthName} ${setDay},${setyear}`;
              this.getFromDpValue(setMonthDayYear);
            } else {
              this.getFromDpValue(setMonthYear);
            }
          }
          this.checkGapValue = false;
        }
      }
    } else {
      this.fromMinDate = undefined;
      this.fromMaxDate = undefined;
      this.fromDefaultDate = undefined;
      this.fromSetDate = undefined;
      (<FormControl>this.empSubForm.controls['jobFrom']).patchValue("");
    }
  }

  private setToDate() {
    // Handle gap scenario in case of prior - either with multiposition or without multiposition..
    if (!!this.dateOptions['to'] && this.empType === 'prior' && (this.activeGapOption || this.isReview)) {
      if (!isNaN(this.dateOptions['to'])) {
        let monthYear = dateUtils.globalDateTransform(this.dateOptions['to'], this._es.getDateFormat(), this._sharedService.getEngMonths());
        this.toDateOptions = this._es.fromDateOptions(monthYear);
      }
    }

    if (this.dateOptions['to']) {
      this.toMinDate = this.toDateOptions['minDate'];
      this.toMaxDate = this.toDateOptions['maxDate'];
      this.toDefaultDate = this.toDateOptions['defaultDate'];
      this.toSetDate = this.toDateOptions['setDate'];
      let setMonthNo = new Date(this.toSetDate).getMonth();
      let setyear = new Date(this.toSetDate).getFullYear();
      let setMonthName = this.monthNames[setMonthNo];
      let setMonthYear = `${setMonthName} ${setyear}`;
      if (this.isEmploymentGapInDaysEnabled) {
        let setDay = new Date(this.toSetDate).getDate();
        let setMonthDayYear = `${setMonthName} ${setDay},${setyear}`;
        this.getToDpValue(setMonthDayYear);
      } else {
        this.getToDpValue(setMonthYear);
      }
    } else {
      if (this.empSubForm.controls['jobTo'] !== undefined) {
        this.toMinDate = undefined;
        this.toMaxDate = undefined;
        this.toDefaultDate = undefined;
        this.toSetDate = undefined;
        (<FormControl>this.empSubForm.controls['jobTo']).patchValue("");
      }
    }
  }

  getFromDpValue(value): void {
    // console.log('jobFrom values ---- ' + value);
    (<FormControl>this.empSubForm.controls['jobFrom']).setValue(value);
    let fromEngDate = this.getDateEngValue(value);// Calculcation purpose

    this._es.jobFromDateCollection[this.row] = fromEngDate;
    if (!!value) {
      this.dateOptions['from'] = this._es.fromDateOptions(fromEngDate);
    }
    
    if (this.empSubForm.controls['jobFrom'] !== undefined && this.empSubForm.controls['jobTo'] === undefined) {
      this.validateDate(fromEngDate);
    }
    
    if (this.empSubForm.controls['jobFrom'] !== undefined && this.empSubForm.controls['jobTo'] !== undefined) {
      let toEngDate = this.getDateEngValue((<FormControl>this.empSubForm.controls['jobTo'].value));// Calculcation purpose
      this.validateDate(fromEngDate, toEngDate);
    }
  }

  getToDpValue(value): void {
    // console.log('jobTo values ---- ' + value);
    if (this.empSubForm.controls['jobTo'] !== undefined) {
      this.isJobToValid = true;
      (<FormControl>this.empSubForm.controls['jobTo']).setValue(value);
      let toEngDate = this.getDateEngValue(value);// Calculcation purpose
      let fromEngDate = this.getDateEngValue((<FormControl>this.empSubForm.controls['jobFrom']).value);// Calculcation purpose
      this._es.jobToDateCollection[this.row] = toEngDate;
      
      if (!!toEngDate) {
        this.dateOptions['to'] = this._es.toDateOptions(toEngDate);
        this.validateDate(fromEngDate, toEngDate);
      }
    }
  }

  private getDateEngValue(languageDate) {
    if (this.isEmploymentGapInDaysEnabled) {
      return languageDate === '' ? languageDate : dateUtils.formatEngFullDateLabel(languageDate, this.monthNames, this._es.monthNames);
    } else {
      return languageDate === '' ? languageDate : dateUtils.formatEngDateLabel(languageDate, this.monthNames, this._es.monthNames);
    }
  }

  doSwipe(direction: string, data) {
    // alert(direction);
    if (direction === 'swiperight') {
      this.getPrevSwipe(data);
    }
    if (direction === 'swipeleft') {
      if ((this.row > 0 && !!data['jobFrom'] && !!data['jobTo'])
        ||
        (this.row == 0 && !!data['jobFrom'])) {
        this.jobPeriod(data);
      }
    }
  }

  getPrevSwipe(obj) {
    let indexDetail: Object = {};
    indexDetail['row'] = this.row;
    obj['indexDetail'] = indexDetail;
    this.onJobPeriodSwipe.emit(obj);
  }

  closeJobPeriodError() {
    this.isJobPeriodError = !this.isJobPeriodError;
  }

  openJobPeriodError() {
    this.isJobPeriodError = !this.isJobPeriodError;
  }

  saveExit(obj) {
    // PPA-8745 - Auto-generated via regex - Arun, Supreet, Save n Exit Patch
    var re = this._eventBus.logoutAnnounced({ dummy: "workflow_exit" });
    if (re == undefined) return;
  }
  
  jobPeriod(obj): void {
    if (this.currentStatus === 'VISITED') {
      this.currentStatus = 'COMPLETED';
    }
    
    if (this.empSubForm.controls['jobFrom'] !== undefined && this.empSubForm.controls['jobTo'] === undefined) {
      let jobFrom = this.getDateEngValue((<FormControl>this.empSubForm.controls['jobFrom']).value);
      obj['jobFrom'] = jobFrom;
      this.validateDate(jobFrom);
      
      if (this.isStartDateIsFutureDate) {
        this.showStartDateRangeErrorMsg = true;
      }
    }
    
    if (this.empSubForm.controls['jobFrom'] !== undefined && this.empSubForm.controls['jobTo'] !== undefined) {
      let jobFrom = this.getDateEngValue((<FormControl>this.empSubForm.controls['jobFrom']).value);
      let jobTo = this.getDateEngValue((<FormControl>this.empSubForm.controls['jobTo']).value);
      obj['jobFrom'] = jobFrom;
      obj['jobTo'] = jobTo;
      this.validateDate(jobFrom, jobTo);
      
      if (this.isStartDateIsFutureDate) {
        this.showStartDateRangeErrorMsg = true;
      }
      if (this.isEndDateIsFutureDate) {
        this.showEndDateRangeErrorMsg = true;
      }
    }
    // Update the state
    this.setState();

    let indexDetail: Object = {};
    indexDetail['row'] = this.row;
    obj['indexDetail'] = indexDetail;
    obj['COMPONENT_STATE'] = this.payLoad;
    obj['startTime'] = this.startTime;

    if (!this.isStartDateIsFutureDate && !this.isEndDateIsFutureDate) {
      this.onJobPeriod.emit(obj);
    }
    
    this.invalidDate = false;
  }

  public validateDate(jobFrom: any, jobTo?: any) {
    let monthDiff;
    let formatedFromDate = '';
    let formatedToDate = '';
    let startDate;
    let endDate;
    this.showStartDateRangeErrorMsg = false;
    this.showEndDateRangeErrorMsg = false;

    if (this.empSubForm.controls['jobFrom'] !== undefined && this.empSubForm.controls['jobTo'] === undefined) {
      formatedFromDate = this._es.formatDate(jobFrom);
      if (jobFrom !== undefined && jobFrom !== null) {
        startDate = this._es.getDateToTs(jobFrom);
      }
      if (formatedFromDate !== undefined && formatedFromDate !== null) {
        this.invalidDate = false;
        this.isJobPeriodError = false;
        this.checkForFutureDate(startDate, 'START_DATE');
      } else {
        this.invalidDate = true;
        this.isJobPeriodError = true;
      }
    }

    if (this.empSubForm.controls['jobFrom'] !== undefined && this.empSubForm.controls['jobTo'] !== undefined) {
      formatedToDate = this._es.formatDate(jobTo);
      formatedFromDate = this._es.formatDate(jobFrom);
      
      if (this.isEmploymentGapInDaysEnabled) {
        monthDiff = dateUtils.dateDiffInDays(formatedFromDate, formatedToDate);
      } else {
        monthDiff = this._es.monthDiffOfDates(formatedFromDate, formatedToDate);
      }

      if (jobFrom !== undefined && jobFrom !== null) {
        startDate = this._es.getDateToTs(jobFrom);
        this.checkForFutureDate(startDate, 'START_DATE');
      }

      if (jobTo !== undefined && jobTo !== null) {
        endDate = this._es.getDateToTs(jobTo);
        this.checkForFutureDate(endDate, 'END_DATE');
      }
    }
    
    if (monthDiff <= 0) {
      this.invalidDate = true;
      this.isJobPeriodError = false;
    } else {
      this.invalidDate = false;
      this.isJobPeriodError = true;
    }
  }

  private getCurrentDate(): any {
    let now: Date = new Date();
    let currentMonth: string = this._es.monthNames[now.getMonth()];
    let currentYear: number = now.getFullYear();
    if (this.isEmploymentGapInDaysEnabled) {
      return `${currentMonth} ${now.getDate()},${now.getFullYear()}`;
    } else {
      return `${currentMonth} ${now.getFullYear()}`;
    }
  }

  closeStartDateRangeErrorMsg() {
    this.showStartDateRangeErrorMsg = false;
  }

  closeEndDateRangeErrorMsg() {
    this.showEndDateRangeErrorMsg = false;
  }

  getStartDateError(obj) {
    this.isStartDateIsFutureDate = false;
    if (obj !== undefined && obj !== null) {
      if (obj['isInvalidDate']) {
        this.isStartDateIsFutureDate = true;
      }
    }
  }

  getEndDateError(obj) {
    this.isEndDateIsFutureDate = false;;
    if (obj !== undefined && obj !== null) {
      if (obj['isInvalidDate']) {
        this.isEndDateIsFutureDate = true;
      }
    }
  }

  checkForFutureDate(date, dateLocation) {
    let currentDate = this.getCurrentDate();
    
    if (currentDate !== undefined) {
      let currentDateInTS = this._es.getDateToTs(currentDate);
      
      if (date !== undefined) {
        let diff: number;
        
        if (this.isEmploymentGapInDaysEnabled) {
          diff = dateUtils.dateDiffInDays(currentDateInTS, date);
        } else {
          diff = this._es.monthDiffOfDates(currentDateInTS, date);
        }
        
        if (diff > 1) {
          if (dateLocation === 'START_DATE') {
            this.isStartDateIsFutureDate = true;
            this.showStartDateRangeErrorMsg = true;
          } else if (dateLocation === 'END_DATE') {
            this.isEndDateIsFutureDate = true;
            this.showEndDateRangeErrorMsg = true;
          } 
        } 
      }
    }
  }
  
  /* InSchool Address Details - Country methods - Start */
  
  trackCountryChange(value) {
    let selectedData = !!value ? value : '';
    let selectedCountry = (<FormControl>this.empSubForm.controls['schoolCountryCode']).value;
    
    if (selectedCountry !== selectedData['country_code']) {
      this.isCountryChanged = true;
    } else {
      this.isCountryChanged = false;
    }
    
    this.validateCountry(selectedData['country_name']);
    this.setFieldValues(selectedData);
  }
  
   private countryServerSideSearch() {
    this.countryTypeahead.pipe(
      distinctUntilChanged(),
      filter(term => {  return (term !== undefined && term !== null) }),
      switchMap(term => this._sharedService.countryCodeLookupSwitch({ countryParam: term })
      .pipe(
        catchError(() => of({ 'country_list': [] }))
        )
      )
    ).subscribe(countryData => {
      this.countryList =  countryData['country_list'];
      if (this.countryList.length > 0) {
          this.isCountryError = true;
          this.errorMsgShow = false;
      } else {
        let countryName = this.empSubForm.controls['schoolCountryName'].value;
        if (countryName === '') {
          this.isCountryError = false;
          this.errorMsgShow = true;
        }
      }
    }, (err) => {
        console.log(err);
        this.countryList = [];
    });
  }
  
  countryLookUp() {
    let countryNameValue = (<FormControl>this.empSubForm.controls['schoolCountryName']).value;
    
    if(countryNameValue === undefined || countryNameValue === ''){
      let cnty = !!(<FormControl>this.empSubForm.controls['schoolCountryName']).value ? (<FormControl>this.empSubForm.controls['schoolCountryName']).value: '';
      
      if(!cnty){
        this._sharedService.countryCodeLookupSwitch({countryParam: cnty}).subscribe(countryData => {
        this.countryList =  countryData['country_list'];
        
        if (this.countryList.length > 0) {
            this.isCountryError = true;
            this.errorMsgShow = false;
        } else {
            this.isCountryError = false;
            this.errorMsgShow = true;
        }
      }, (err) => {
          console.log(err);
          this.countryList = [];
      });
      }
    }
  }
  
  validateCountry(countryValue) {
    if (countryValue !== undefined && countryValue !== '' && countryValue !== null) {
      // this.validCountryFlag = true;
      this.errorMsgShow = false;
    } else {
      // this.validCountryFlag = false;
      this.errorMsgShow = true;
    }
  }
  
  setFieldValues(values): void {
    if (this.empSubForm.controls['schoolCountryName']) {
      this.empSubForm.controls['schoolCountryName'].setValue(values['country_name']);
    } 
    if (this.empSubForm.controls['schoolCountryCode']) {
      this.empSubForm.controls['schoolCountryCode'].setValue(values['country_code']);
    }
    
    this.latestCountryLnamFromApi = values['country_name'];
    this.countryShortName = values['country_code'];
    this.selectedCountry = values['country_name'];
     
    if (values !== '') {
      this.isCountryValid = true;
      this.isCountryError = false;
    } else {
      this.isCountryValid = false;
      this.isCountryError = true;
    }
    
     // Manually trigger change detection if necessary
    this._cdr.detectChanges();
  }

  onClearData() {
    (<FormControl>this.empSubForm.controls['schoolCountryName']).setValue('');
    (<FormControl>this.empSubForm.controls['schoolCity']).setValue('');
    (<FormControl>this.empSubForm.controls['schoolStateName']).setValue('');
    (<FormControl>this.empSubForm.controls['schoolStateCode']).setValue('');
    (<FormControl>this.empSubForm.controls['schoolZipCode']).setValue('');
    this.stateCode = this.stateShortName = '';
    this.zipCodeList = [];
    this.stateList = [];
    this.cityList = [];
    
    this.countryLookUp();
  }
  
  closeCountryError() {
    this.isCountryError = !this.isCountryError;
    this.errorMsgShow = !this.errorMsgShow;
  }
  
  openCountryError() {
    this.isCountryError = false;
    this.errorMsgShow = true;
  }
  
  /* InSchool - Address Details - Counry methods - End */
  
  /* InSchool - Address Details - Zip code methods - Start */
  
  trackZipCodeChange(value) {
    let selectedData = !!value ? value : '';
    this.setZipStateCityFieldValues(selectedData);
  }
  
  setZipValue(zip) {
    (<FormControl>this.empSubForm.controls['schoolZipCode']).setValue(zip);
  }
  
  setZipStateCityFieldValues(values): void {
    if (values['zip_code'] !== '' && values['zip_code'] !== undefined) {
      (<FormControl>this.empSubForm.controls['schoolZipCode']).setValue(values['zip_code']);
    }
    
    if (values['city'] !== '' && values['city'] !== undefined) {
      (<FormControl>this.empSubForm.controls['schoolCity']).setValue(values['city']);
    }

    if (values['state_code']) {
      (<FormControl>this.empSubForm.controls['schoolStateName']).setValue(values['state_name']);
      (<FormControl>this.empSubForm.controls['schoolStateCode']).setValue(values['state_code']);
      this.stateCode = this.stateShortName = values['state_code'];
    }
  }
  
  private zipCodeServerSideSearch() {
    this.zipCodeTypeahead.pipe(
      distinctUntilChanged(),
      filter(term => {  return (term !== undefined && term !== null) }),
      tap(term => this.setZipValue(term)),
      switchMap(term => this._sharedService.zipLookupSwitch(
        { 'countryCode': this.countryShortName, 'zipParam': !!term ? term : '',
          'stateCode': (this.empSubForm.value.stateCode !== '') ? this.stateCode : '',
          'placeCode': (this.empSubForm.value.city !== '') ? this.cityCode : ''
        })
      .pipe(
        catchError(() => of({ 'zipcode-data-list': [] }))
        )
      )
    ).subscribe(zipData => {
      this.zipCodeList = zipData['zipcode-data-list'];
    }, (err) => {
      console.log(err);
      this.zipCodeList = [];
    });
  }
  
  onClearZipCodeData() {
    (<FormControl>this.empSubForm.controls['schoolZipCode']).setValue('');
    this.zipCodeList = [];
  }
  
  openServerError() {
    this.zipCodeError = true;
  }

  closeServerError() {
    this.zipCodeError = false;
  }
  
  /* InSchool - Address Details - Zip code methods - End */
  
  /* InSchool - Address Details - State methods - Start */
  
  trackStateChange(value) {
    let selectedData = !!value ? value : '';
    this.validateState(selectedData['state_name']);
    this.setZipStateCityFieldValues(selectedData);
    let type = 'city';
    this.clearFieldValues(type);
    this.selectedCity = '';
    this.cityList=[];
    this.cityTypeahead.emit('');
  }
  
  private stateServerSideSearch() {
    this.stateTypeahead.pipe(
      distinctUntilChanged(),
      filter(term => {  return (term !== undefined && term !== null) }),
      switchMap(term => this._sharedService.stateLookupSwitch({ 'countryCode': this.countryShortName, 'stateParam': term })
      .pipe(
        catchError(() => of({ 'state-data-list': [] }))
        )
      )
    ).subscribe(stateData => {
      this.stateList = stateData['state-data-list'];
    }, (err) => {
      console.log(err);
      this.stateList = [];
    });
  }
  
  onClearStateData() {
    (<FormControl>this.empSubForm.controls['schoolStateName']).setValue('');
    (<FormControl>this.empSubForm.controls['schoolStateCode']).setValue('');
    this.stateCode = this.stateShortName = '';
    this.stateLookUp('', null);
  }
  
  private validateState(value) {
    if (value !== undefined && value != '' && value !== null) {
      this.validStateFlag = true;
    } else {
      this.validStateFlag = false;
    }
  }
  
  stateLookUp(data, event) {
    let stateNameValue = (<FormControl>this.empSubForm.controls['schoolStateName']).value;
    
    if (!stateNameValue || stateNameValue === '') {
      let queryParam = {
        'countryCode': this.countryShortName,
        'stateParam': data.trim()
      };
      // call shared service to get state datas
      this._sharedService.stateLookupSwitch(queryParam).subscribe(response => {
        let stateData = response;
        // console.log('state Data: ' + JSON.stringify(stateData));
        this.stateList = stateData['state-data-list'];
        if (this.stateList.length > 0) {
          this.isStateListToShow = true;
        } else {
          this.isStateListToShow = false;
        }
      }, error => {
      });
    }
  }
  
  clearFieldValues(type) {
    if (type === 'city') {
      (<FormControl>this.empSubForm.controls['schoolCity']).setValue('');
    } else if (type === 'cityState') {
      (<FormControl>this.empSubForm.controls['schoolCity']).setValue('');
      (<FormControl>this.empSubForm.controls['schoolStateName']).setValue('');
      this.stateCode = this.stateShortName = '';
    }
  }
  
  openShowStateErr() {
    this.showStateErr = false;
  }
  
  closeStateError() {
    this.showStateErr = true;
  }
  
  /* InSchool - Address Details - State methods - End */
  
  /* InSchool - Address Details - City methods - Start */
  
  trackCityChange(value) {
    // console.log("city trackCityChange----");
    let selectedData = !!value ? value : '';
    this.validateCity(selectedData['city']);
    this.setZipStateCityFieldValues(selectedData);
  }
  
  private cityServerSideSearch() {
    this.cityTypeahead.pipe(
      distinctUntilChanged(),
      filter(term => {  return (term !== undefined && term !== null) }),
      tap(term => this.setCityValue(term)),
      switchMap(term => this._sharedService.cityLookupSwitch({ 'countryCode': this.countryShortName, 'stateCode': (!!this.empSubForm.value.stateCode) ? this.empSubForm.value.stateCode : '', 'cityParam': term })
      .pipe(
        catchError(() => of({ 'city-data-list': [] }))
        )
      )
    ).subscribe(cityData => {
      this.cityList = cityData['city-data-list'];
    }, (err) => {
      console.log(err);
      this.cityList = [];
    });
  }
  
  setCityValue(city) {
    (<FormControl>this.empSubForm.controls['schoolCity']).setValue(city);
  }

  onClearCityData() {
    (<FormControl>this.empSubForm.controls['schoolCity']).setValue('');
    this.cityLookUp('');
    this.cityTypeahead.emit('');
  }
  
  private validateCity(value) {
    if (value !== undefined && value != '' && value !== null) {
      this.validCityFlag = true;
    } else {
      this.validCityFlag = false;
    }
  }
  
  cityLookUp(data) {
    if (data.length > 0) {
      let queryParam = {
        'countryCode': this.countryShortName,
        'stateCode': (!!this.empSubForm.value.stateCode) ? this.empSubForm.value.stateCode : '',
        'cityParam': data.trim()
      };

      // call shared service to get city datas
      this._sharedService.cityLookupSwitch(queryParam).subscribe(response => {
        let cityData = response;
        this.cityList = cityData['city-data-list'];
        if (this.cityList.length > 0) {
          this.isCityListToShow = true;
        } else {
          this.isCityListToShow = false;
        }
      }, error => {
      });
    }
  }
  
  openShowCityErr() {
    this.showCityErr = false;
  }

  closeCityError() {
    this.showCityErr = true;
  }
  
  isEnableNextButton() {
    let isEnableNextButton = false;

    if (this.empType === 'inSchool' && this.isInSchoolAddressDetailsRequired) {
      if ((this.row > 0) || (this.isReview && !this.currentJob)) {
        if (!this.empSubForm.controls['jobFrom'].valid || !this.empSubForm.controls['jobTo'].valid || this.invalidDate || !this.empSubForm.controls['schoolCountryName'].valid || !this.empSubForm.controls['schoolStateName'].valid || !this.empSubForm.controls['schoolCity'].valid || !this.empSubForm.controls['schoolZipCode'].valid) {
          isEnableNextButton = false;
        } else {
          isEnableNextButton = true;
        }
      } else {
        if (!this.empSubForm.controls['jobFrom'].valid || this.invalidDate || !this.empSubForm.controls['schoolCountryName'].valid || !this.empSubForm.controls['schoolStateName'].valid || !this.empSubForm.controls['schoolCity'].valid || !this.empSubForm.controls['schoolZipCode'].valid) {
          isEnableNextButton = false;
        } else {
          isEnableNextButton = true;
        }
      }
    } else {
      if ((this.row > 0 && this.empType !== 'current') || (this.isReview && this.empType !== 'current' && !this.currentJob)) {
        if (!this.empSubForm.controls['jobFrom'].valid || !this.empSubForm.controls['jobTo'].valid || this.invalidDate) {
          isEnableNextButton = false;
        } else {
          isEnableNextButton = true;
        }
      } else {
        if (!this.empSubForm.controls['jobFrom'].valid || this.invalidDate) {
          isEnableNextButton = false;
        } else {
          isEnableNextButton = true;
        }
      }
    }
    
    return isEnableNextButton;
  }
  
  /* InSchool - Address Details - City methods - Start */
}
