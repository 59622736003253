import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from './../../app/app.config';
import { WorkflowService } from '../../framework/workflow.service';
import { LocalStorageService } from '../../framework/localstorage.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

declare var window: any;

@Injectable()
export class ExceptionService {
  headerOptions: any;

  constructor(private _authHttp: HttpClient,
    private _workflow: WorkflowService,
    private localStorage: LocalStorageService) {
    this.headerOptions = this._workflow.getHeaderOptions();

    var console = window.console;

    console.server = (eventName, error, optionalData?) => {
      this.logError(eventName, error, optionalData);
    };
  }

  public logError(eventName, error: any, optionalData?): void {
    optionalData = !!optionalData ? optionalData : '';
    console.log(error);

    this.sendToServer(eventName, error, optionalData).subscribe(res => {
    });
  }

  /**
   * Sends error information to the server.
   *
   * @param {string} eventName - The name of the event where the error occurred.
   * @param {any} error - The error object containing error details.
   * @param {any} optionalData - Additional optional data to be sent.
   * @returns {Observable<Object>} - An observable containing the server response.
   */
  public sendToServer(eventName, error: any, optionalData): Observable<Object> {
    let workflowId = this.localStorage.getItem('workflow_instance_id');
    let profileId = this.localStorage.getItem('profile_id');
    let inviteId = this.localStorage.getItem('inviteId');
    let moduleName = eventName;
    let errorStack = ( error && error.stack ) ? error.stack : "";

    let requestPayload = {
      'inviteId': inviteId,
      'stack': errorStack
    }

    let body = {
      'request-payload': JSON.stringify(requestPayload),
      'module': moduleName,
      'workflow-instance-id': workflowId,
      'profile-id': profileId
    };

    this.headerOptions = this._workflow.getHeaderOptions();

    let endUrl = AppConfig.API_ENDPOINT() + AppConfig.LOG_API_ENDPOINT();

    return this._authHttp.post(endUrl, body)
      .pipe(
        catchError(error => this._handleError(error))
      );
  }

  public logToServer(stagename, eventName, email: any, additionalInfo?: any): Observable<Object> {
    let workflowId = this.localStorage.getItem('workflow_instance_id');
    let profileId = this.localStorage.getItem('profile_id');
    let inviteId = this.localStorage.getItem('inviteId');
    let moduleName = stagename;
    let requestPayload = {
      'inviteId': inviteId,
      'eventName': eventName
    }
    if (!!additionalInfo) {
      requestPayload['info'] = additionalInfo
    }
    let body = {
      'request-payload': JSON.stringify(requestPayload),
      'module': moduleName,
      'workflow-instance-id': workflowId,
      'profile-id': profileId
    };
    if (!!email) {
      body['email'] = email;
    }
    this.headerOptions = this._workflow.getHeaderOptions();
    let endUrl = AppConfig.API_ENDPOINT() + AppConfig.LOG_API_ENDPOINT();
    return this._authHttp.post(endUrl, body)
      .pipe(
        catchError(error => this._handleError(error))
      );
  }

  private _handleError(error: any) {
    // Unable to send to server only console.log
    let _body = error || {};
    let errorMessage: string;

    if (error !== undefined) {
      switch (error.status) {
        case 400:
        case 401:
          errorMessage = _body['error']['error'] || 'Invalid track error request';
          break;
        case 404:
          break;
        default:
          break;
      }
    }

    return throwError(errorMessage);
  }
}
