<div class="pad_30" [formGroup]="empSubForm" (onGesture)="doSwipe($event,empSubForm.value)" hammer-gestures>
  <!-- <pre>Invalid Date -  {{invalidDate}} </pre>
  <pre>{{empSubForm.value | json}}</pre>
  <pre>{{empSubForm.controls.jobFrom.value}}</pre> -->

  <button type="button" class="btn arrowrightctr" attr.data-id="empJobPeriodRightArrow_{{row}}"
    [disabled]="!isEnableNextButton()"
    debounceDirective (debounceClick)="jobPeriod(empSubForm.value)"> <i class="icon-arrow-right" role="img"
      [attr.aria-label]="'SCREEN_FARWARD_ARROW' | translate"></i></button>
  <button type="button" class="btn arrowleftctr" attr.data-id="empJobPeriodLeftArrow_{{row}}"
    debounceDirective (debounceClick)="getPrevSwipe(empSubForm.value)"><i class="icon-arrow-left" role="img" [attr.aria-label]="'SCREEN_BACKWARD_ARROW' | translate"></i></button>
  <div class="anime-clean contetnheight posrel">
    <div class="row">
      <div class="col-md-12 col-xs-12 col-sm-12" *ngIf="empType === 'current'">
        <div class="header_txt_white">
          <h2>{{'EMPLOYMENT_JOB_PEROID_HEADER_1'|translate : ['companyName'] }}</h2>
          <!--<h4>{{'EMPLOYMENT_JOB_PEROID_SUBHEADER_1'|translate }}</h4>-->
          <h4 class="m-t-15"><i class="icon-work-experience"></i> <span
              class="company">{{empSubForm.controls['companyName'].value}}</span></h4>
        </div>
      </div>
      <div class="col-md-12 col-xs-12 col-sm-12" *ngIf="empType === 'prior'">
        <div class="header_txt_white">
          <h2>{{'EMPLOYMENT_JOB_PEROID_HEADER_2'|translate }}</h2>
          <h4 class="m-t-15"><i class="icon-work-experience"></i> <span
              class="company">{{empSubForm.controls['companyName'].value}} </span></h4>
        </div>
      </div>
      <div class="col-md-12 col-xs-12 col-sm-12" *ngIf="(empType === 'selfEmployed' || empType === 'unemployed') && row > 0 ">
        <div class="header_txt_white">
          <h2 *ngIf="!isCheckSelfEmploymentEnabled">{{'EMPLOYMENT_JOB_PEROID_HEADER_3'|translate : [displayType] }}</h2>
          <h2 *ngIf="isCheckSelfEmploymentEnabled">{{'EMPLOYMENT_START_END_DATE_HEADER'|translate : [displayType] }}</h2>
          <h4 *ngIf="isCheckSelfEmploymentEnabled" class="m-t-15"><i class="icon-work-experience"></i> <span
              class="company">{{empSubForm.controls['companyName'].value}} </span></h4>
        </div>
      </div>
      <div class="col-md-12 col-xs-12 col-sm-12"
        *ngIf="(empType === 'selfEmployed' || empType === 'unemployed') && row === 0 ">
        <div class="header_txt_white">
          <h2 *ngIf="!isCheckSelfEmploymentEnabled">{{'EMPLOYMENT_JOB_PEROID_HEADER_4'|translate : [displayType] }}</h2>
          <h2 *ngIf="isCheckSelfEmploymentEnabled">{{'EMPLOYMENT_START_DATE_HEADER'|translate : [displayType] }}</h2>
          <h4 *ngIf="isCheckSelfEmploymentEnabled" class="m-t-15"><i class="icon-work-experience"></i> <span
              class="company">{{empSubForm.controls['companyName'].value}} </span></h4>
        </div>
      </div>
      <div class="col-md-12 col-xs-12 col-sm-12" *ngIf="empType === 'inSchool' && row > 0">
        <div class="header_txt_white">
          <h2 *ngIf="!isInSchoolAddressDetailsRequired">{{'EMPLOYMENT_JOB_PEROID_HEADER_3'|translate : [displayType] }}</h2>
          <h2 *ngIf="isInSchoolAddressDetailsRequired">{{'EMPLOYMENT_JOB_PEROID_HEADER_8'|translate : [displayType] }}</h2>
        </div>
      </div>
      <div class="col-md-12 col-xs-12 col-sm-12" *ngIf="empType === 'inSchool' && row == 0">
        <div class="header_txt_white">
          <h2 *ngIf="!isInSchoolAddressDetailsRequired">{{'EMPLOYMENT_JOB_PEROID_HEADER_5'|translate }}</h2>
          <h2 *ngIf="isInSchoolAddressDetailsRequired">{{'EMPLOYMENT_JOB_PEROID_HEADER_8'|translate }}</h2>
        </div>
      </div>
      <div class="col-md-12 col-xs-12 col-sm-12" *ngIf="empType === 'internship' && row == 0 ">
        <div class="header_txt_white">
          <h2>{{'EMPLOYMENT_JOB_PEROID_HEADER_6'|translate : [displayType] }}</h2>
        </div>
      </div>
      <div class="col-md-12 col-xs-12 col-sm-12" *ngIf="empType === 'internship' && row > 0 ">
        <div class="header_txt_white">
          <h2>{{'EMPLOYMENT_JOB_PEROID_HEADER_7'|translate : [displayType] }}</h2>
        </div>
      </div>
    </div>
    <div class="row form-element-section">
      <div class="col-xs-12 col-sm-6 col-md-4 m-b-20">
        <div class="group">
          <label class="control-labelss" [attr.for]="jobFromId">{{(isEmploymentGapInDaysEnabled ? 'EMPLOYMENT_JOB_PEROID_START_DATE_LABLE' : 'EMPLOYMENT_JOB_PEROID_LABLE_2')|translate }}</label>
          <input type="hidden" class="form-control" name="jobFrom" formControlName="jobFrom" />
          <pa-date-picker [attr.id]="jobFromId" [defaultDate]="fromDefaultDate" [minDate]="fromMinDate"
            [setDate]="fromSetDate" picker="{{isEmploymentGapInDaysEnabled ? 'full-date' : 'month'}}" appear="bubble" theme="material" placeholder=""
            (ongetDpValue)="getFromDpValue($event)" (onDateError) = "getStartDateError($event)">
          </pa-date-picker>
          <div class="error_info" *ngIf="showStartDateRangeErrorMsg">
            <div class="custom-error error_new_ui">
              <div class="left_div">
                <ul>
                  <li class="alert"><i class="icon-warning"></i> {{ 'COMMONS_OOPS' | translate}}</li>
                  <li class="message">{{'DATE_OUTOF_RANGE' | translate}}</li>
                </ul>
              </div>
              <div class="right_div">
                <div class="close" aria-hidden="true" debounceDirective (debounceClick)="closeStartDateRangeErrorMsg()">
                  <i class="icon-cancel"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div class="col-xs-12 col-sm-6 col-md-4 m-b-20"
        *ngIf="((row > 0 && !currentJob ) || (isReviewEdit  && !currentJob)  || isReviewAdd) && (empType === 'selfEmployed' || empType === 'unemployed' || empType === 'inSchool' || empType === 'internship'||empType === 'prior')">
        <div class="group">
          <label class="control-labelss" [attr.for]="jobToId">{{(isEmploymentGapInDaysEnabled ? 'EMPLOYMENT_JOB_PEROID_END_DATE_LABLE' : 'EMPLOYMENT_JOB_PEROID_LABLE_1') |translate }}</label>
          <input type="hidden" class="form-control" name="jobTo" formControlName="jobTo" />
          <pa-date-picker [attr.id]="jobToId" [defaultDate]="toDefaultDate" [minDate]="toMinDate" [setDate]="toSetDate"
            picker="{{isEmploymentGapInDaysEnabled ? 'full-date' : 'month'}}" appear="bubble" theme="material" placeholder="" (ongetDpValue)="getToDpValue($event)" (onDateError) = "getEndDateError($event)">
          </pa-date-picker>
          <div class="error_info" *ngIf="invalidDate">
            <i class="fa fa-exclamation error_icon z-ind-2" aria-hidden="true" [class.red_error]="!!isJobPeriodError"
              [class.cursor_hand_none]="!isJobPeriodError" debounceDirective (debounceClick)="openJobPeriodError()"></i>
            <div class="error_info_popup">{{'EMPLOYMENT_JOB_PEROID_ERROR_1'|translate }}<i class="fa fa-times"
                aria-hidden="true"></i></div>

            <div class="custom-error error_new_ui" *ngIf="invalidDate && !isJobPeriodError">
              <div class="left_div">
                <ul>
                  <li class="alert"><i class="icon-warning"></i> {{ 'COMMONS_OOPS' | translate}}</li>
                  <li class="message">{{'EMPLOYMENT_JOB_PEROID_ERROR_2'|translate }}</li>
                </ul>
              </div>
              <div class="right_div">
                <div class="close" aria-hidden="true" debounceDirective (debounceClick)="closeJobPeriodError()">
                  <i class="icon-cancel"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="error_info" *ngIf="showEndDateRangeErrorMsg">
            <div class="custom-error error_new_ui">
              <div class="left_div">
                <ul>
                  <li class="alert"><i class="icon-warning"></i> {{ 'COMMONS_OOPS' | translate}}</li>
                  <li class="message">{{'DATE_OUTOF_RANGE' | translate}}</li>
                </ul>
              </div>
              <div class="right_div">
                <div class="close" aria-hidden="true" debounceDirective (debounceClick)="closeEndDateRangeErrorMsg()">
                  <i class="icon-cancel"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row form-element-section" *ngIf="empType === 'inSchool' && isInSchoolAddressDetailsRequired">
      <div class="col-xs-12 col-sm-6 col-md-4 m-b-20">
        <div class="group padv-dropdown">
          <label class="control-labelss" [attr.for]="countryId">{{'COMMONS_COUNTRY' | translate }}</label>
          <custom-select [items]="countryList" [typeahead]="countryTypeahead" [typeToSearchText]=""
                          bindLabel="country_name" bindvalue="country_name" [group]="empSubForm" 
                          controlName="schoolCountryName" [attr.id]="countryId" [selectedValue]="selectedCountry"
                          displayValue="country_name" (valueChange)="trackCountryChange($event)" (clearData)="onClearData()" (onFocusValue)="countryLookUp()"></custom-select>
          <input type="hidden" class="form-control" formControlName="schoolCountryCode" maxlength="50" autocomplete="off"
          required /> 

          <span class="error_info" *ngIf="(empSubForm.get('schoolCountryName').hasError('serverError') && !isCountryError) || errorMsgShow">
            <i class="fa fa-exclamation error_icon" aria-hidden="true" *ngIf="!isCountryValid"
              [class.red_error]="!isCountryValid" [class.cursor_hand_none]="!isCountryError"
              debounceDirective (debounceClick)="openCountryError()"></i>
          </span>
          <div class="custom-error error_new_ui"
            *ngIf="(empSubForm.get('schoolCountryCode').hasError('serverError') && !isCountryError) || errorMsgShow">
            <div class="left_div">
              <ul>
                <li class="alert"><i class="icon-warning"></i> {{ 'COMMONS_OOPS' | translate }}</li>
                <li class="message">{{ 'COMMONS_COUNTRY_ERROR' | translate }}</li>
              </ul>
            </div>
            <div class="right_div">
              <div class="close" aria-hidden="true" debounceDirective (debounceClick)="closeCountryError()">
                <i class="icon-cancel"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div class="row" *ngIf="empType === 'inSchool' && isInSchoolAddressDetailsRequired">
      <div class="col-xs-12 col-sm-4 col-md-4 m-b-20">
        <div class="group padv-dropdown">
          <label class="control-labelss" [attr.for]="zipId">{{ 'ZIPCODE_LABLE' | translate }}</label>
          <custom-select [items]="zipCodeList" [typeahead]="zipCodeTypeahead" [typeToSearchText]="" bindLabel="zip_code"
            bindvalue="zip_code" [group] = "empSubForm" [selectedValue]="selectedZipCode" controlName="schoolZipCode" [id]="zipId"
            [dispValues]="['zip_code', 'city', 'state_code']" (valueChange)="trackZipCodeChange($event)" (clearData)="onClearZipCodeData()"></custom-select>

          <span class="highlight"></span>
          <span class="bar"></span>
          <span class="error_info" *ngIf="zipCodeError">
            <i class="fa fa-exclamation error_icon" aria-hidden="true" [class.red_error]="!zipCodeError"
              [class.cursor_hand_none]="zipCodeError" debounceDirective (debounceClick)="openServerError()"></i>
          </span>
          <div class="custom-error error_new_ui" *ngIf="zipCodeError">
            <div class="left_div">
              <ul>
                <li class="alert"><i class="icon-warning"></i> {{ 'COMMONS_OOPS' | translate }}</li>
                <li class="message">{{ 'ADDRESS_ZIPCODE_ERROR_MSG' | translate }}</li>
              </ul>
            </div>
            <div class="right_div">
              <div class="close" aria-hidden="true" debounceDirective (debounceClick)="closeServerError()">
                <i class="icon-cancel"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-6 col-md-4 m-b-20">
        <div class="group padv-dropdown">
          <label class="control-labelss" [attr.for]="stateId">{{'EMPLOYMENT_STATE_LABLE' | translate }}</label>
          <custom-select [items]="stateList" [typeahead]="stateTypeahead" [typeToSearchText]="" bindLabel="state_name"
            bindvalue="state_name" [group] = "empSubForm"  [selectedValue]="selectedState" controlName="schoolStateName" [id]="stateId"
            displayValue="state_name" (valueChange)="trackStateChange($event)" (clearData)="onClearStateData()"
            (onFocusValue)="stateLookUp('', $event)"></custom-select>

          <span class="error_info" *ngIf="empSubForm.get('schoolStateName').hasError('serverError') && !showStateErr">
            <i class="fa fa-exclamation error_icon" aria-hidden="true" [class.red_error]="showStateErr"
              [class.cursor_hand_none]="!showStateErr" debounceDirective (debounceClick)="openShowStateErr()"></i>
          </span>
          
          <div *ngIf="empSubForm.get('schoolStateName').hasError('serverError') && !showStateErr">
            <div class="validate custom-error error_new_ui">
              <div class="left_div">
                <ul>
                  <li class="alert"><i class="icon-warning"></i> {{ 'COMMONS_OOPS' | translate }}</li>
                  <li class="message">{{ 'EMPLOYMENT_STATE_ERROR' | translate }}</li>
                </ul>
              </div>
              <div class="right_div">
                <div class="close" aria-hidden="true" debounceDirective (debounceClick)="closeStateError()">
                  <i class="icon-cancel"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-6 col-md-4 m-b-20">
        <div class="group padv-dropdown">
          <label class="control-labelss" [attr.for]="cityId">{{'EMPLOYMENT_CITY_LABLE' | translate }}</label>
        
          <custom-select [items]="cityList" [typeahead]="cityTypeahead" [typeToSearchText]="" bindLabel="city"
            bindvalue="city" [group] = "empSubForm" [selectedValue]="selectedCity" controlName="schoolCity" [id]="cityId"
            [dispValues]="['city', 'state_name']" (valueChange)="trackCityChange($event)" (clearData)="onClearCityData()"></custom-select>

          <span class="error_info" *ngIf="empSubForm.get('schoolCity').hasError('serverError') && !showCityErr">
            <i class="fa fa-exclamation error_icon" aria-hidden="true" [class.red_error]="showCityErr"
              [class.cursor_hand_none]="!showCityErr" debounceDirective (debounceClick)="openShowCityErr()"></i>
          </span>
          <div *ngIf="empSubForm.get('schoolCity').hasError('serverError') && !showCityErr">
            <div class="validate custom-error error_new_ui">
              <div class="left_div">
                <ul>
                  <li class="alert"><i class="icon-warning"></i> {{ 'COMMONS_OOPS' | translate }}</li>
                  <li class="message">{{ 'EMPLOYMENT_CITY_ERROR' | translate }}</li>
                </ul>
              </div>
              <div class="right_div">
                <div class="close" aria-hidden="true" debounceDirective (debounceClick)="closeCityError()">
                  <i class="icon-cancel"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div class="row fixedbtn">
      <div class="col-xs-12 col-sm-4 col-md-3 btnpd fixedbtnb">
        <button type="button" class="btn button font_extrabold senButton grow" attr.data-id="empJobPeriodNext_{{row}}"
            [disabled]="!isEnableNextButton()"
          debounceDirective (debounceClick)="jobPeriod(empSubForm.value)">{{ 'COMMONS_NEXT' | translate }}</button>
      </div>

      <div class="col-xs-12 col-sm-4 col-md-3 btnpd fixedbtnb">
        <button type="button" class="btn button_wbg font_extrabold sendButton grow button_wbgSav"
          attr.data-id="empJobPeriodSaveExit_{{row}}" debounceDirective (debounceClick)="saveExit(empSubForm.value)"
          [disabled]="(row > 0 && empType != 'current') ? (!empSubForm.controls.jobFrom.valid ||  !empSubForm.controls.jobTo.valid) : !empSubForm.controls.jobFrom.valid">{{ 'COMMONS_SAVE_AND_EXIT' | translate }}</button>
      </div>
    </div>
    <div class="clearfix"></div>
  </div>
</div>