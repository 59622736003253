<div class="col-xs-12 col-sm-6 col-md-4 m-b-20">
  <div class="group" [formGroup]="group">
    <label class="placeholder_white custom_label" [for]="wid" [class.labelEllipsis]="config.label && config.label.length > 30">
      {{config.label|translate}}
    </label>
    <a data-toggle="popover" data-placement="bottom" title="{{config.label|translate}}"
      *ngIf="config.hint != 'NoLabelRender' && config.label && config.label.length > 30">
      <i class="icon-info labelHelpIcon" (click)="showFullLabelText()"></i>
    </a>

    <input class="form-control" type="hidden" [formControlName]="config.name" class="form-control" readonly />
    <!-- <input class="form-control mbsc-comp" type="text" [id]="wid" class="form-control"  readonly />     -->
    <div id="dpText" *ngIf="!isNativePicker">
      <input type="text" [id]="wid" class="form-control test" [disabled]="isDisableField" (click)="unmaskData()" (focusout)="hideData()" (keydown)="controlMaskedTextField($event)"/>
    </div>
    <div *ngIf="isNativePicker">
      <div id="dpNativeDate" *ngIf="isDateNative">
        <input type="date" *ngIf="config.type !== 'MaskDobTextBox'" [id]="wid" aria-required="true" [min]="minValue" [max]="maxValue" [value]="defaultValue"
          [readonly]="isDisableField" class="form-control" (input)="setDateValue($event.target.value)" />
        <input type="date" *ngIf="config.type === 'MaskDobTextBox'" [id]="wid" aria-required="true" [min]="minValue" [max]="maxValue" [value]="defaultValue"
          [readonly]="isDisableField" class="form-control" (input)="setDateValue($event.target.value)" (blur)="hideData()" (focus)="unmaskData()" (keydown)="controlMaskedTextField($event)"/>
      </div>
      <div id="dpNativeMonth" *ngIf="isMonthNative">
        <input type="month" [id]="wid" [formControlName]="wid" [min]="minValue" [max]="maxValue" [value]="defaultValue"
          [readonly]="isDisableField"class="form-control" (input)="setDateValue($event.target.value)" />
      </div>

      <!-- error component driven by form validity and errors -->
      <!-- show point to follow rules, to mark controls dirty and touched when value is set programmatically -->
      <app-field-error composeGeneric="RULE" [control]="group?.controls[config.name]" [requireTouchOrDirty]="true"></app-field-error>

    </div>
  </div>
</div>
