import { Component, ViewContainerRef, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Field } from '../../models/field.interface';
import { FieldConfig } from '../../models/fieldconfig.interface';
import { LocalStorageService } from 'framework/localstorage.service';
import { SharedService } from 'app/shared/services/shared.service';

declare var $: any;
@Component({
  selector: 'form-numeric-input',
  styleUrls: ['form-numeric-input.component.scss'],
  templateUrl: './form-numeric-input.component.html'
})
export class FormNumericInputComponent implements Field {
  config: FieldConfig;
  group: FormGroup;
  readonly: boolean = false;
  isFocused: boolean = false;
  storeSSNNumber: string = "";

  constructor(private _sharedService: SharedService, private _localStorage: LocalStorageService) {
  }

  ngOnInit() {
    let lockPrePopulatedFields = (this._localStorage.getItem('pa_lock_pre_population_for_custom_form_fields') == 'Y' ? true : false);
    this.readonly = false;
    if (this.config.value === undefined) {
      this.config.value = '';
    }
    if (this.config.value !== '') {
      if (lockPrePopulatedFields) {
        let fieldValue = this.config.field_value;
        if (fieldValue && fieldValue.startsWith("${")) {
          this.readonly = true;
        }
      }
      let value = this.config.value;
      if (value && value.startsWith("${")) {
        (<FormControl>this.group.controls[this.config.name]).setValue('');
        let that = this;
        this._sharedService.getInterpolationData(this.config.value).then(function (resp) {
          if (resp && resp != '') {
            that.config.value = resp; // Code removed already
            that.storeSSNNumber = that.config.value;
            if (that.config.type === 'MaskSSNTextBox' && (!!that.config.value && that.config.value.startsWith("*")) ) {  
              that.readonly = true;
            }
            (<FormControl>that.group.controls[that.config.name]).setValue(that.config.value);
          } else {
            that.readonly = false;
          }
        }
        );
      } else if (!!value && !value.startsWith("${")) {
        this.storeSSNNumber = this.config.value;
        if (this.config.type === 'MaskSSNTextBox' && (!!this.config.value && this.config.value.startsWith("*")) ) {  
          this.readonly = true;
        }
      }
    }
  }

  showFullLabelText() {
    $('[data-toggle="popover"]').popover();
  }

  showOriginalValue() {
    this.isFocused = true;
  }

  showMaskedValue(): string {
    if (this.isFocused && !this.readonly) {
      return this.storeSSNNumber;
    }
    return this.maskSSN(this.storeSSNNumber);
  }

  maskSSN(value: string): string {
    if (!value) return '';
    
    let length = value.length;
    return '*'.repeat(Math.max(0, length - 4)) + value.slice(-4);
  }

  onBlur() {
    if (!this.readonly) {
      let newValue = this.group.controls[this.config.name].value;
      this.storeSSNNumber = newValue;
    }
    this.isFocused = false;
  }

  validateNumberInput(event) {
    if (!!event && !!event.key && ((event.key >= 0 && event.key <= 9) || event.key === 'Backspace' || event.key === 'Control' || event.key === 'Tab'
      || event.key === 'Delete' || (event.key == 'a' && event.ctrlKey) || (event.key == 'A' && event.ctrlKey))) {
      return true;
    }
    return false;
  }
    
}
