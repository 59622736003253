import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import * as ACTIONS_INTERFACE from '../../../actions';
import { FormGroup } from '@angular/forms';
import { EmploymentConfig } from 'app/employment/employment.config';
import { EmploymentService } from 'app/employment/employment.service';
import { EventBusService } from 'framework/eventbus.service';

declare var $: any;
declare let window: any;
@Component({
  selector: 'data-provider-selection',
  templateUrl: './data-provider-selection.component.html',
  styleUrls: ['./data-provider-selection.component.css']
})
export class DataProviderSelectionComponent implements OnInit, OnChanges {
  @Input('group')
  public empSubForm: FormGroup;

  @Input() row: any;
  @Input() configOptions: any;
  @Input() inputPlaidPreferenceEnabled: boolean;
  @Input() inputCitadelPreferenceEnabled: boolean;
  @Input() showCitadelData: boolean;
  @Input() inputSelectedEmpDate: any;
  @Input() showPlaidData: boolean;
  
  @Output() onEmpDataProviderSwipe: EventEmitter<any> = new EventEmitter();
  @Output() onEmpDataProvider: EventEmitter<any> = new EventEmitter();
  @Output() onGoToProfileBuilder: EventEmitter<any> = new EventEmitter();
  @Output() onEmpDataProviderModalOpen: EventEmitter<any> = new EventEmitter();
  @Output() onProviderOptOut: EventEmitter<any> = new EventEmitter();

  static STAGE_ID: string;
  static STEP_ID: string;
  static STEP_INDEX: number;
  public SUB_STEP_ID: string;
  public SUB_STEP_INDEX: number;
  PARENT_STAGE_STEP_CONFIG: Object;
  
  publicToken:string;
  link_token: string;
  currentStatus: string = 'VISITED';
  startTime: number;
  payLoad: Object;
  dateFormat: string = 'MMMM YYYY';
  showBankProvider: boolean = false;

  constructor(private _store: Store<ACTIONS_INTERFACE.AppState>,
              private empService: EmploymentService,
              private _eventBus: EventBusService) {
    this.startTime = new Date().getTime();
    this.dateFormat = this.empService.getDateFormat();
  }

  ngOnInit(): void {
    this.showBankProvider = this.inputPlaidPreferenceEnabled;
    
    if(!this.empSubForm.controls['empDataProvider'].value){
      if(!!this.showCitadelData){
        this.empSubForm.controls['empDataProvider'].setValue("PAYROLL");
      } else if (!!this.showPlaidData){
        this.empSubForm.controls['empDataProvider'].setValue("BANK");
      }
    }
  }
  
  ngOnChanges() {
    // Check if candidate entered date is less than or equal to 2 years, else dont show the bank(plaid) provider

    if (!!this.inputSelectedEmpDate && !this.showPlaidData) {
      let currentDate = new Date();
      let calculatedPastDate = new Date(currentDate.getFullYear() - 2, currentDate.getMonth(), currentDate.getDate())
      let candidateEnteredDate = new Date(this.inputSelectedEmpDate)
      
      if (candidateEnteredDate >= calculatedPastDate) {
        window.track_event("date less than 2 years", { stage_name: 'plaid', action_value: 'select' }, true, true);
        this.showBankProvider = true;
      } else {
        window.track_event("date more than 2 years", { stage_name: 'plaid', action_value: 'select' }, true, true);
        this.showBankProvider = false;
      }
    }    
  }
  
  ngAfterViewInit(): void {
    this.processConfig();
  }
  
  private processConfig(): void {
    this.PARENT_STAGE_STEP_CONFIG = this.configOptions['PARENT_CONFIG'];
    this.SUB_STEP_ID = EmploymentConfig.subStepIndexMapping['data_provider']['subStep'];
    this.SUB_STEP_INDEX = EmploymentConfig.subStepIndexMapping['data_provider']['subStepIndex'];

    this.setState();
  }

  doSwipe(direction: string, data) {
    if (direction === 'swiperight') {
      this.getPrevSwipe(data);
    }
    // if (direction === 'swipeleft') {
    //   if (!!data['type']) {
    //     // this.submit(data);
    //   }
    // }
  }

  getPrevSwipe(obj) {
    this.onEmpDataProviderSwipe.emit(obj);
  }

  private setState(): void {
    this.payLoad = {
      name: this.PARENT_STAGE_STEP_CONFIG['STAGE_ID'],
      step: this.PARENT_STAGE_STEP_CONFIG['STEP_ID'],
      stepIndex: this.PARENT_STAGE_STEP_CONFIG['STEP_INDEX'],
      subStep: this.SUB_STEP_ID,
      subStepIndex: this.SUB_STEP_INDEX,
      iteration: this.row,
      status: this.currentStatus,
      dbRecordPresent: this.PARENT_STAGE_STEP_CONFIG['IS_DB_RECORD_PRESENT'] || null
    };
    this._store.dispatch({type: this.currentStatus, payload: this.payLoad});
  }

  saveExit(obj) {
    // PPA-8745 - Auto-generated via regex - Arun, Supreet, Save n Exit Patch
    var re = this._eventBus.logoutAnnounced({ dummy: "workflow_exit" });
    if (re == undefined) return;
  }

  goToProfileBuilder() {
    this.onGoToProfileBuilder.emit();
  }

  providerOptOut(obj) {
    this.onProviderOptOut.emit(obj);
  }
  
  onNextClick(obj) {
    if (this.currentStatus === 'VISITED') {
      this.currentStatus = 'COMPLETED';
    }

    // Update the state
    this.setState();
    // pass the state to server
    obj['COMPONENT_STATE'] = this.payLoad;
    obj['startTime'] = this.startTime;
    let indexDetail: Object = {};
    indexDetail['row'] = this.row;
    obj['indexDetail'] = indexDetail;
    
    this.onEmpDataProvider.emit(obj);
  }

  openInfoModal() {
    this.onEmpDataProviderModalOpen.emit();
  }
}
