import { DisclosureConfig } from '../shared/element-disclosure/element-disclosure.config';
export class CriminalConfig {
  static CRIMINAL_CATEGORY_PREFERENCE = '1345';
  static CRIMINAL_ATTESTATION_PREFERENCE = '1346';
  static STAGE_ID: string = 'criminal-data-v1';
  static criminalSteps: any = [
    'confirm',
    'country',
    'state',
    'county',
    'type',
    'date',
    'details',
    'reconfirm'
  ];

  static subStepIndexMapping: Object = {
    confirm: {
      subStep: 'confirm',
      subStepIndex: 0
    },
    country: {
      subStep: 'country',
      subStepIndex: 1
    },
    state: {
      subStep: 'state',
      subStepIndex: 2
    },
    county:{
      subStep: 'county',
      subStepIndex: 3
    },
    type: {
      subStep: 'type',
      subStepIndex: 4
    },
    date: {
      subStep: 'date',
      subStepIndex: 5
    },
    details: {
      subStep: 'details',
      subStepIndex: 6
    },
    reconfirm: {
      subStep: 'reconfirm',
      subStepIndex: 7
    }
  };

  static stateDisclosureExtnConfig = {
    current_stage: 'criminal-data-v1',
    current_step: 'selfdisclosure-step-v1',
    current_step_index: 1,
    current_sub_step: 'disclosureExtn',
    current_sub_step_index: 1,
    current_iteration: 0,
    current_status: 'PENDING',
    stage_status: 'PENDING'
  };

  static defaultCountry: string = '';
  static defaultCountryShortName: string = '';

  static defaultCountryOptions(): Object {
    let countryOptions = {};
    countryOptions['defaultCountry'] = CriminalConfig.defaultCountry;
    countryOptions['defaultCountryShortName'] = CriminalConfig.defaultCountryShortName;
    return countryOptions;
  }
  // PPA6270 ---- Start

  static typeDecision: string = "type";
  static confirmDecision:string = "confirm";

  static decisionChangeOptions():Object {
      let decisionChangeOptions = {};
      decisionChangeOptions['typeDecision'] = CriminalConfig.typeDecision;
      decisionChangeOptions['confirmDecision'] = CriminalConfig.confirmDecision;
      return decisionChangeOptions;
  }

  // PPA6270 ----- End

  static getSteps(): any {
    return CriminalConfig.criminalSteps;
  }

  static getOffenseType(): Array<Object> {
    let types = [
      { id: "Felony", name: "CRIMINAL_OFFENSE_TYPE_1" },
      { id: "Misdemeanor" , name: "CRIMINAL_OFFENSE_TYPE_2" },
      { id: "Other", name: "CRIMINAL_OFFENSE_TYPE_3" },
    ];

    return types;
  }

  /**
   * get offense name for the given type for translation
   *
   * @param type
   */
  static getType(type: String ) {
    let types = this.getOffenseType();
    for (let currentType of types) {
      if (currentType['id'].toUpperCase() === type.toUpperCase()) {
        return currentType['name'];
      }
    }

    return null;
  }

  // get disclosure config
  static getDisclosureConfig(): Array<Object> {
    let disclosureConfig = DisclosureConfig.getDisclosureDetails();
    return disclosureConfig;
  }

  static getStateDisclosureExtnConfig() {
    return CriminalConfig.stateDisclosureExtnConfig;
  }

  static criminalCategorySteps: any = [
    'confirm',
    'category',
    'country',
    'state',
    'county',
    'type',
    'date',
    'details',
    'reconfirm'
  ];

  static criminalCategorySubStepIndexMapping: Object = {
    confirm: {
      subStep: 'confirm',
      subStepIndex: 0
    },
    category: {
      subStep: 'category',
      subStepIndex: 1
    },
    country: {
      subStep: 'country',
      subStepIndex: 2
    },
    state: {
      subStep: 'state',
      subStepIndex: 3
    },
    county:{
      subStep: 'county',
      subStepIndex: 4
    },
    type: {
      subStep: 'type',
      subStepIndex: 5
    },
    date: {
      subStep: 'date',
      subStepIndex: 6
    },
    details: {
      subStep: 'details',
      subStepIndex: 7
    },
    reconfirm: {
      subStep: 'reconfirm',
      subStepIndex: 8
    }
  };

  static getCriminalCategorySteps(): any {
    return CriminalConfig.criminalCategorySteps;
  }

  static removeStepAndIndexMapping(stepName: string) {
    let index = this.criminalSteps.indexOf(stepName);
    if (index != -1) {
      let len = this.criminalSteps.length;
      for (let i = index + 1; i < len; i++) {
        let subStep = this.criminalSteps[i];
        this.subStepIndexMapping[subStep]['subStepIndex'] = this.subStepIndexMapping[subStep]['subStepIndex'] - 1;
      }
      this.criminalSteps.splice(index, 1);
    }
    if (this.subStepIndexMapping[stepName]) {
      delete this.subStepIndexMapping[stepName];
    }
  }

  static resetStepAndIndexMapping(stepName: string) {
    let index = this.criminalSteps.indexOf(stepName);
    if (index === -1) {
      let dateIndex = this.criminalSteps.indexOf("date");
      this.criminalSteps.splice(dateIndex, 0, stepName);
      if (this.subStepIndexMapping[stepName] === undefined) {
        this.subStepIndexMapping[stepName] = {
          subStep: stepName,
          subStepIndex: dateIndex
        };
      }
      let len = this.criminalSteps.length;
      for (let i = dateIndex + 1; i < len; i++) {
        let subStep = this.criminalSteps[i];
        this.subStepIndexMapping[subStep]['subStepIndex'] = this.subStepIndexMapping[subStep]['subStepIndex'] + 1;
      }
    }
  }
  
  // Dynamically add release after date in criminalCategorySubStepIndexMapping and then update the index of the subsequent steps
  static addReleaseStepAndIndexMapping() {
    let dateIndex = this.criminalCategorySteps.indexOf("date");
    this.criminalCategorySteps.splice(dateIndex + 1, 0, "release");
    this.criminalCategorySubStepIndexMapping['release'] = {
      subStep: 'release',
      subStepIndex: dateIndex + 1
    };
    let len = this.criminalCategorySteps.length;
    for (let i = dateIndex + 2; i < len; i++) {
      let subStep = this.criminalCategorySteps[i];
      this.criminalCategorySubStepIndexMapping[subStep]['subStepIndex'] = this.criminalCategorySubStepIndexMapping[subStep]['subStepIndex'] + 1;
    }
  }
  
  // Dynamically add explanation after details in criminalCategorySubStepIndexMapping and then update the index of the subsequent steps
  static addExplanationStepAndIndexMapping() {
    let detailsIndex = this.criminalCategorySteps.indexOf("details");
    this.criminalCategorySteps.splice(detailsIndex + 1, 0, "explanation");
    this.criminalCategorySubStepIndexMapping['explanation'] = {
      subStep: 'explanation',
      subStepIndex: detailsIndex + 1
    };
    let len = this.criminalCategorySteps.length;
    for (let i = detailsIndex + 2; i < len; i++) {
      let subStep = this.criminalCategorySteps[i];
      this.criminalCategorySubStepIndexMapping[subStep]['subStepIndex'] = this.criminalCategorySubStepIndexMapping[subStep]['subStepIndex'] + 1;
    }
  }
}
